import React, { useState, useContext, useEffect, useCallback } from "react";

const UserContext = React.createContext({});

function user_defaults() {

	return {
		user: app.user,
		prefs: app.user.prefs,
		profs: app.user.profs,
		user_validated: false,
	}
}
const UserProvider = ({ children }) => {
	const [user, setUser] = useState(user_defaults());

	useEffect(()=>{
		setUser(user_defaults())
	},[app.user.uid])

	const authHandler = useCallback((e) => {
		if (e == "AuthStateChanged") {
			setUser(user=>({ ...user, user_validated: true }));
		}
		setUser(user=>({
			...user,
			user: app.user,
			prefs: app.user.prefs,
			profs: app.user.profs,
		}));
	},[])
	
	const setContext = useCallback((keyOrData,data) => {
		if (keyOrData === null) 
			setUser(user_defaults())
		if (typeof keyOrData === 'string')
			setUser(state=>({...state,[keyOrData]:data}))
		else
			setUser(state=>({...state,...keyOrData}))
	},[])
	
	useEffect(() => {
		
		app.on(app.events.USER_UPD, authHandler);
		app.on(app.events.USER_CONTEXT, setContext);

		return () => {
			app.off(app.events.USER_UPD, authHandler);
			app.off(app.events.USER_CONTEXT, setContext);
		};
	}, []);

	return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

function UserConsumer({ children }) {
	return (
		<UserContext.Consumer>
			{(context) => {
				if (context === undefined) {
					throw new Error(
						"UserConsumer must be used within a UserProvider"
					);
				}
				return children(context);
			}}
		</UserContext.Consumer>
	);
}


export { UserContext, UserProvider, UserConsumer };
