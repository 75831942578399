import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

/**
 * A functional component that renders a tabbed interface.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {{id: string, name: string, content: JSX.Element}[]} props.elements - An array of objects representing the tab elements.
 * Each object should contain a ⁠ name ⁠, ⁠ id ⁠, and ⁠ content ⁠.
 *
 * This component renders a header containing buttons for each tab, and a section
 * that displays the content of the currently active tab. Clicking a button updates
 * the active tab and moves a visual indicator ("menu-pointer") to the location of the
 * clicked button (active tab) on md sizes and above, or displays an underline on mobile view.
 */
export default function StepsTabs({ elements }) {
    const currentElement = useRef(elements[0].id);
    const [currentDisplayComponent, setCurrentDisplayComponent] = useState(elements[0].content);
    const wrapperRef = useRef(null);
    const menuPointerRef = useRef(null);
    const tabsBoxRef = useRef(null);

    const updatePointerPosition = useCallback(() => {
        if (!wrapperRef.current || !menuPointerRef.current) return;

        const currentItem = document.getElementById(currentElement.current);
        if (!currentItem) return;

        const { left, width } = currentItem.getBoundingClientRect();
        const wrapperBounds = wrapperRef.current.getBoundingClientRect();
        let elementMiddlePosition = left + (width - 40) / 2;

        if (window.innerWidth > wrapperBounds.width) {
            elementMiddlePosition -= wrapperBounds.left;
        }

        menuPointerRef.current.style.setProperty("--left", `${elementMiddlePosition}px`);
        menuPointerRef.current.style.setProperty("--top", "0px");
    }, []);

    useEffect(() => {
        const TIMEOUT_UNTIL_MOUNT = 1000;

        function handleMountFirstElement() {
            updatePointerPosition();
            setCurrentDisplayComponent(elements.find(el => el.id === currentElement.current)?.content);
        }

        handleMountFirstElement();
        const timeout = setTimeout(handleMountFirstElement, TIMEOUT_UNTIL_MOUNT);

        const resizeObserver = new ResizeObserver(updatePointerPosition);
        if (tabsBoxRef.current) resizeObserver.observe(tabsBoxRef.current);

        return () => {
            clearTimeout(timeout);
            resizeObserver.disconnect();
        };
    }, [elements, updatePointerPosition]);

    const handleElementClick = useCallback((e) => {
        e.target.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });

        setTimeout(() => {
            currentElement.current = e.target.id;
            updatePointerPosition();
            setCurrentDisplayComponent(elements.find(el => el.id === e.target.id)?.content);
        }, 400);
    }, [updatePointerPosition, elements]);

    return (
        <div ref={wrapperRef} id="steps-tabs-wrapper" className="overflow-hidden bg-is2-yellow-100">
            <header ref={tabsBoxRef} id="tabs-component" className="z-40 flex items-center justify-center w-full py-3 text-black bg-is2-yellow-100">
                <nav className="overflow-auto xl:block no-scrollbar">
                    <ul className="flex text-sm [&>button]:transition-colors [&>button]:duration-500 [&>button]:text-current [&>button]:font-medium [&>button]:inline-block [&>button]:px-4 [&>button]:py-2">
                        {elements.map((item) => (
                            <button
                                key={item.id}
                                id={item.id}
                                className={`flex-grow cursor-pointer text-nowrap text-[18px] leading-[28px] px-0 mx-[1rem]
                                    ${currentElement.current === item.id ? "!text-is2-gray-450 !border-is2-blue" : "!text-is2-gray-150"}
                                    border-b-2 border-transparent transition-all duration-300 
                                    md:border-b-0 md:hover:text-is2-blue
                                    `}
                                onClick={handleElementClick}
                            >
                                {item.name}
                            </button>
                        ))}
                    </ul>
                </nav>
            </header>

            <section className="relative w-full p-6 text-white bg-is2-blue rounded-2xl md:mt-2">
                <div ref={menuPointerRef} id="menu-pointer" className="absolute hidden md:block p-5 transform rotate-45 bg-is2-blue translate-x-[var(--left)] translate-y-[var(--top)] left-0 top-0 transition-all duration-500 ease-in-out"></div>
                {currentDisplayComponent}
            </section>
        </div>
    );
}

StepsTabs.propTypes = {
    elements: PropTypes.array.isRequired,
};
