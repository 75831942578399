import React from 'react'
import { cls } from '../../NUI/utils'

export const icons = {
    google: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 22 22"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <path
                    fill="#4285F4"
                    d="M21.5 11.2c0-.8-.1-1.5-.2-2.2H11v4.2h5.9c-.3 1.4-1 2.5-2.2 3.3v2.7h3.5c2.2-1.8 3.3-4.6 3.3-8z"
                ></path>
                <path
                    fill="#34A853"
                    d="M11 21.9c3 0 5.4-1 7.2-2.6l-3.5-2.7c-1 .7-2.2 1-3.7 1-2.8 0-5.3-1.9-6.1-4.5H1.3v2.8c1.8 3.6 5.5 6 9.7 6z"
                ></path>
                <path
                    fill="#FBBC05"
                    d="M4.9 13.1c-.2-.7-.3-1.4-.3-2.1 0-.7.1-1.4.3-2.1V6.1H1.3C.5 7.6.1 9.2.1 11c0 1.8.4 3.4 1.2 4.9l3.6-2.8z"
                ></path>
                <path
                    fill="#EA4335"
                    d="M11 4.4c1.6 0 3 .6 4.2 1.6l3.1-3.1C16.5 1.1 14 .1 11 .1c-4.3 0-8 2.5-9.8 6l3.7 2.8c.9-2.6 3.3-4.5 6.1-4.5z"
                ></path>
            </g>
        </svg>
    ),
    apple: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 17 17"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <path
                    fill="#000"
                    fillRule="nonzero"
                    d="M8.874 3.787c.741 0 1.67-.501 2.222-1.169.501-.605.866-1.45.866-2.295 0-.114-.01-.23-.031-.323-.824.031-1.815.553-2.41 1.252-.47.532-.897 1.366-.897 2.222 0 .125.021.25.031.292.053.01.136.02.22.02zM6.267 16.409c1.011 0 1.46-.678 2.722-.678 1.283 0 1.565.657 2.692.657 1.105 0 1.846-1.022 2.545-2.024.782-1.147 1.106-2.274 1.127-2.326-.073-.02-2.191-.886-2.191-3.317 0-2.107 1.67-3.057 1.763-3.13-1.106-1.585-2.785-1.627-3.244-1.627-1.242 0-2.254.751-2.89.751-.688 0-1.596-.71-2.67-.71C4.076 4.006 2 5.696 2 8.889c0 1.982.772 4.079 1.721 5.435.814 1.147 1.523 2.086 2.546 2.086z"
                ></path>
            </g>
        </svg>

    ),
    facebook: (
        <svg
            height="24"
            width="24"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_206:8172)">
                <path
                    d="M23.4 12.0672C23.4 5.73401 18.296 0.599976 12 0.599976C5.70392 0.599976 0.599976 5.73401 0.599976 12.0672C0.599976 17.7908 4.76879 22.5348 10.2187 23.395V15.3819H7.32419V12.0672H10.2187V9.5408C10.2187 6.66684 11.9207 5.07935 14.5247 5.07935C15.7719 5.07935 17.0765 5.30331 17.0765 5.30331V8.12532H15.639C14.2229 8.12532 13.7812 9.00925 13.7812 9.9161V12.0672H16.9429L16.4375 15.3819H13.7812V23.395C19.2312 22.5348 23.4 17.7908 23.4 12.0672Z"
                    fill="#1877F2"
                    fillRule="evenodd"
                />
            </g>
            <defs>
                <clipPath id="clip0_206:8172">
                    <rect
                        height="22.8"
                        width="22.8"
                        fill="white"
                        transform="translate(0.599976 0.599976)"
                    />
                </clipPath>
            </defs>
        </svg>
    ),
    onepass: (
        <img src="https://avatars.slack-edge.com/2022-05-16/3513428079223_1bcf24a16dcd4594a471_34.png" height={24} width={24} />
    )
}

export function SocialIcon({ type, className }) {
    if (!icons[type]) return null

    return (
        <div className={cls("inline-block", className)}>
            {icons[type]}
        </div>
    )
} 