import React, { useMemo, useState } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import Alert from "@/views/NUI/Alert";
import StyledDrawer from "@/views/NUI/StyledDrawer";
import { useDebouncedValue } from "@/xAppLib/Hooks";
import { useDisclosure } from "@/xAppLib/Hooks/useDisclosure";
import { ComplexField } from "@/views/ordering/components/ComplexField";
import { ChroCond_keysToLabels, CHRONIC_TYPE } from "@/xAppLib/UIelems/ChronicConditionsModal";

export function format(value) {
	return Array.isArray(value) && value.length > 0
		? ChroCond_keysToLabels(value).join(', ')
		: '';
}

export function ChronicConditions({onChange, ...props}) {
	const disclosure = useDisclosure();

	const formattedValue = props.formattedValue ?? format(props.value);

	return (
		<>
			<ComplexField {...props} value={formattedValue} onEdit={disclosure.onOpen}/>
			<ChronicConditionsDrawer value={props.value}
									 open={disclosure.isOpen}
									 onChange={value => {
										 onChange(value);
										 disclosure.onClose();
									 }}
									 onCancel={disclosure.onClose}
									 label={props.label}
			/>
		</>
	);
}

export function ChronicConditionsGuardAction({action}) {
	return (
		<ChronicConditionsDrawer value={action.config.value}
								 open={action.active}
								 onChange={action.resolve}
								 onCancel={() => action.reject(false)}
								 label={action.config.label}
		/>
	);
}

function ChronicConditionsDrawer({value, open, label, title = label, onChange, onCancel}) {
	const [input, setInput] = useState(value ?? []);
	const selected = new Set(input);

	const selectedConditions = input.filter(key => key !== 'none');

	const [search, setSearch] = useState('');
	const debouncedSearch = useDebouncedValue(search, search.length === 0 ? 0 : 300);

	const types = useMemo(() => {
		if (debouncedSearch.trim().length > 0) {
			const lcSearch = debouncedSearch.trim().toLowerCase();
			return CHRONIC_TYPE
				.filter(item => item.key !== 'none')
				.filter(({label}) => label.toLowerCase().includes(lcSearch));
		}
		return CHRONIC_TYPE;
	}, [debouncedSearch]);

	const form_valid = input.length > 0;

	const onSave = () => onChange(input);

	return (
		<StyledDrawer
			isOpen={open}
			drawerStyle="blue"
			dismissible
			onClose={onCancel}
			title={title}
			description="Select any chronic conditions you have"
			content={
				<div className={'max-h-[100vh]'}>
					<p>Please list any significant health issues or concerns the doctor should know about.</p>
					<div className="space-y-6 pb-3">
						<Form.Input icon
									value={search}
									onChange={e => setSearch(e.target.value.trim())}
									fluid
									iconPosition="left"
									placeholder="Search..."
						>
							<input className="rounded-full"/>
							<Icon name="search"/>
						</Form.Input>

						{/*Min height here is to offset the soft keyboard on mobile*/}
						<div className="space-y-3 min-h-[60vh] md:min-h-fit">
							{types.map(item => (
								<Button key={item.key}
										type="button"
										fluid
										style={{textAlign: 'left'}}
										primary={selected.has(item.key)}
										basic={!selected.has(item.key)}
										onClick={() => {
											if (types.length === 1) {
												// Searched and found the specific entry, so clear out the filter
												// If there are multiple results, keep the filter because they might be making another selection
												setSearch('');
											}

											if (item.key === 'none') {
												// Remove all other selections
												setInput([item.key]);
											} else if (selected.has(item.key)) {
												setInput(prev => (
													prev.length === 1
														// Auto-select None when removing the last option
														? ['none']
														// Otherwise just remove the selected option
														: prev.filter(pkey => pkey !== item.key)
												));
											} else {
												// Select a new option, and ensure None is while we're at it
												setInput(prev => prev.filter(key => key !== 'none').concat(item.key));
											}
										}}
								>
									{item.label}
								</Button>
							))}
							{types.length === 0 && (
								<Alert info header="No matching conditions"/>
							)}
						</div>
					</div>
				</div>
			}
			footer={(
				<div className="flex flex-row space-x-3 items-stretch">
					<Button type="button" fluid secondary onClick={onCancel}>
						Cancel
					</Button>

					<Button type="button" fluid primary disabled={!form_valid} onClick={onSave}>
						Save {selectedConditions.length > 0 && ` (${selectedConditions.length})`}
					</Button>
				</div>
			)}
		/>
	);
}

ChronicConditions.format = format;