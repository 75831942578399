import React, { useState } from 'react';
import {
		Popup,
		Button,
	} from 'semantic-ui-react'

import { ObjectInspector, chromeLight } from 'react-inspector';

import API_service from 'xAppLib/providers/API_service';


const LoadShowJson = props => {

	const {row, index} = props;
	const {by_ref = 'sid', ref_id = row?.sid} = props;

	const [data, setData] = useState()

	const load_data = async u => {

		const load_data = await API_service.load_data( `serv_comms/load/${by_ref}/${ref_id}` )

		setData( load_data.res=='ok' && load_data.data || load_data);

	}

	return <Popup
						trigger={<Button icon='comments' 
								onClick={ _=> load_data() }
							/>}
						position='left center'
						on='click'
						style={{height: '80%', width: '80%', minWidth: 700, overflow: 'scroll', maxHeight:'90vh'}}
				>
					<ObjectInspector
							data={ data }
							expandLevel={ 3 }
							theme={{...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 })}}
						 />
				</Popup>
};

export default LoadShowJson
