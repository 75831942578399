import React from 'react';
import NotificationBanners from '../NotificationBanners';
import { useBannerNotifications } from '../../hooks/useNotifications';

const NotificationBannersApp = () => {
    const { bannerNotifications, loading, setNotificationRead, usePlugin } = useBannerNotifications();

    return <NotificationBanners
        notifications={bannerNotifications}
        loading={loading}
        setNotificationRead={setNotificationRead}
        usePlugin={usePlugin}
    />;
};

export default NotificationBannersApp;