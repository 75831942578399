import React from 'react';

export default function TrophySVGIcon({ width = 32, height = 32, fill = "transparent" }) {

    return (
        <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_14375_43155)">
                <path d="M10.75 22.75H21.25C21.25 22.75 22.75 22.75 22.75 24.25V25.75C22.75 25.75 22.75 27.25 21.25 27.25H10.75C10.75 27.25 9.25 27.25 9.25 25.75V24.25C9.25 24.25 9.25 22.75 10.75 22.75Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.25 4.75H5.773C5.50168 4.75 5.24148 4.85778 5.04963 5.04963C4.85778 5.24148 4.75 5.50168 4.75 5.773C4.75 8.75669 5.93526 11.6182 8.04505 13.728C10.1548 15.8377 13.0163 17.023 16 17.023C18.9837 17.023 21.8452 15.8377 23.955 13.728C26.0647 11.6182 27.25 8.75669 27.25 5.773C27.25 5.50168 27.1422 5.24148 26.9504 5.04963C26.7585 4.85778 26.4983 4.75 26.227 4.75H19.75" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.8817 16.8242L12.1387 22.7502H19.6387L18.0627 16.8342" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.6002 5.07502L17.8482 7.53302H19.9752C20.092 7.53068 20.2069 7.56352 20.3049 7.62728C20.4028 7.69105 20.4794 7.78279 20.5246 7.8906C20.5697 7.99842 20.5815 8.11732 20.5582 8.23187C20.5349 8.34643 20.4778 8.45135 20.3942 8.53302L18.4382 10.452L19.5202 12.937C19.5678 13.0509 19.5784 13.1769 19.5505 13.2971C19.5226 13.4173 19.4577 13.5258 19.3649 13.6071C19.272 13.6885 19.156 13.7386 19.0331 13.7504C18.9103 13.7622 18.7868 13.7352 18.6802 13.673L16.0652 12.2L13.4502 13.673C13.3435 13.7348 13.2202 13.7615 13.0976 13.7495C12.9749 13.7375 12.8592 13.6874 12.7665 13.6061C12.6739 13.5249 12.6091 13.4166 12.5812 13.2966C12.5533 13.1765 12.5638 13.0508 12.6112 12.937L13.6932 10.452L11.7402 8.53002C11.6565 8.4483 11.5993 8.34328 11.5761 8.22863C11.5528 8.11397 11.5646 7.99498 11.6099 7.88713C11.6552 7.77927 11.7319 7.68754 11.8301 7.62385C11.9282 7.56016 12.0432 7.52747 12.1602 7.53002H14.2862L15.5352 5.07202C15.5861 4.97465 15.6627 4.89313 15.7568 4.83634C15.8509 4.77956 15.9587 4.74969 16.0686 4.75C16.1784 4.75031 16.2861 4.78079 16.3799 4.8381C16.4736 4.89541 16.5498 4.97737 16.6002 5.07502Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_14375_43155">
                    <rect width="24" height="24" fill="white" transform="translate(4 4)"/>
                </clipPath>
            </defs>
        </svg>
    )
}