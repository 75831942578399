import React, {Component} from 'react'
import API_service from '../providers/API_service'
import {Search, Grid, Dimmer, Form, Loader, Button, Message} from 'semantic-ui-react'
import debounce from '../libs/debounce'
import { v4 as generate_id } from 'uuid';
import logger from 'xAppLib/libs/logger'
import { parseState,formatComponents,getFinalAddress, reverseGeoCode } from 'helpers/address';

const DEBUG = false

const STATES  = {
	'VIC':'Victoria',
	"NSW":'New South Wales',
	'ACT':'Australian Capital Territory',
	'QLD':'Queensland',
	'NT':'Northern Territory',
	'WA':'Western Australia',
	'SA':'South Australia',
	'TAS':'Tasmania',
}

class AddressSearch extends Component {
	constructor(props) {
		super(props)
		let data = {
			isLoading: false,
			isLoadingDetails:false,
			results: [],
			formatted: typeof props.value == 'string' && props.value || '',
			address: '', 
			address2:'', 
			suburb:'', 
			state:'', 
			postcode:'',
			geo:undefined,
		}
		if (typeof props.value == 'object') {
			data = {...data,...this.props.value}
			if (data.state)
				data.state = parseState(data.state)
		}
		this.state = data
		
		this.findPlaceDebounced = debounce(this.findPlace, 1000)
	}

	handleResultSelect = async (e, {result}) => {
		const address = this.state.address;
		DEBUG && console.log("handleResultSelect(%o)", {e, result});
		const {
			mode = "simple"
		} = this.props
		this.setState({isLoadingDetails:true, error: null})
		const { final, data, error } = await getFinalAddress(result.title,this.state.address2,this.props.allowLocalArea).catch(error => ({ error }))
		DEBUG && console.log("handleResultSelect output %o", { final, data } );
		
		if (final) {
			this.setComponents({formatted:final,...data})
		} else {
			this.setComponents({
				formatted:'',
				address: '', 
				address2:'', 
				suburb:'', 
				state:'', 
				postcode:'',
				geo:undefined
			})
		}
		
		this.setState({
			isLoadingDetails:false,
			...(error && {
				error,
				result,
				address,
			})
		})
		


	}
	
	componentDidMount() {
		DEBUG && console.log("AddressSearch.componentDidMount(%o,%o)",this.props.value,this.props.mode);
		this.sessionToken = generate_id() 
		this.parsePropsValue()
	}

	componentDidUpdate(prevProps, prevState) {
		DEBUG && console.log("AddressSearch.componentDidUpdate(%o,%o)",this.props.value,this.props.mode);
		if (this.state.state != prevState.state) {
			this.parseState(this.state.state)
		}
		if (this.props.value == prevProps.value)
			return
		this.parsePropsValue()
	}

	parsePropsValue() {
		if (this.props.mode == 'components' && this.props.value) {
				
			if (typeof this.props.value == 'string') {
				// migrate string value to components
				(async ()=>{
					try {
						DEBUG && console.log("componentDidMount:migrating components passed value %o",this.props.value);
						const { final , data } = await getFinalAddress(this.props.value,this.state.address2,this.props.allowLocalArea)
						this.setComponents({formatted:final, ...data})
					} catch (e) {
						console.error(e);
					} 
				})()
				
			} else if (typeof this.props.value == 'object') {
				const data = {...this.props.value}
				if (data.state)
					data.state = parseState(data.state)
				this.setState(data)
			}
		} 
		else if ( typeof this.props.value == 'string') {
			this.setState({formatted:this.props.value})
		}  
	}
	
	async get_user_location() {
		DEBUG && console.log("get_user_location()");
		this.setState({searchField:'',searchingLocation:true})
		const {latitude,longitude}  = await new Promise((resolve,reject)=>{
			navigator.geolocation && navigator.geolocation.getCurrentPosition((position) => {
				DEBUG && console.log("got ",position);
				const {latitude,longitude} = position.coords
				resolve(position.coords)
			},(error)=>{
				alert("Could not detect your location.")
				resolve({latitude:null,longitude:null})
			});
		})
		
		if (latitude) {
			logger.usg_log('get_user_location_FE', location.pathname, null, {latitude:latitude, longitude:longitude})
			const address = await reverseGeoCode({latitude,longitude})
			DEBUG && console.log("address=",address);
			if (address?.formatted_address && this.props.return_type === 'components') {
				const {final, data} = await getFinalAddress(address.formatted_address, "", false, address)
				if (final) {
					this.setComponents({formatted:final,...data})	
				}
			} else if (address) {
				DEBUG && console.log("searchField=",address.formatted_address);
				this.setComponents({formatted:address.formatted_address,geo:address})
			}
		}
		
		this.setState({searchingLocation:false})
				
		
	}
	
	
	notifyChange(data) {
		const { formatted, address, address2, suburb, state, postcode, geo} = Object.assign({},this.state,data)
		const { onChange, return_type } = this.props
		DEBUG && console.log("notifyChange(%o, %o)", { formatted, address, address2, suburb, state, postcode,geo});
		if (!onChange)
			return
		if (this.props.mode == 'components' || return_type == 'components') {
			onChange({formatted, address, address2, suburb, state, postcode,geo})
		} else {
			// simple
			onChange(formatted,geo)
		}
		
	}

	setComponents(data) {
		DEBUG && console.log("setComponents(%o)", data);
		let {formatted, address, address2, suburb, state, postcode,geo} = Object.assign({},this.state,data)
		if (this.props.mode == 'components') {
			formatted = formatComponents({address, address2, suburb, state, postcode},this.props.allowLocalArea)
		}
		this.setState({formatted, address, address2, suburb, state, postcode, geo})
		this.notifyChange({formatted, address, address2, suburb, state, postcode, geo})
	}

	
	findPlace = async address => {
		DEBUG && console.log('findPlace(%o)', address)
		this.setState({isLoading: true, results: null})
		const result = await new Promise((resolve, reject) => {
			API_service.call_api('autocomplete', {
				address,
				sessionToken:this.sessionToken
			}, resolve, reject)
		}).catch(error => ({error}));
		DEBUG && console.log('result = %o', result)
		this.setState({isLoading: false})
		if (result && !result.error) {
			this.setState({
				results: result.map(r => ({
					title: r.description,
					...r,
					description: null
				}))
			})
		}
		return result
	}



	handleSearchChange = (e, {value}) => {
		DEBUG && console.log("handleSearchChange(%o)", {e, value});

		const {
			mode = "simple"
		} = this.props

		if (mode == 'simple') {
			this.setComponents({formatted:value, geo:null})

			if (value.length > 5) 
				this.findPlaceDebounced(value)
		} else {
			this.findPlaceDebounced(value)
			const {address, address2, suburb, state, postcode} = Object.assign({},this.state,{address:value})
			const formatted = formatComponents({address, address2, suburb, state, postcode},this.props.allowLocalArea)
			DEBUG && console.log({formatted, address, address2, suburb, state, postcode});
			this.setComponents({formatted,address, geo:null})

		}

	}

	render() {
		const {results, isLoading} = this.state
		const {
			mode = "simple"
		} = this.props

		return mode == 'simple'
			? this.render_simple()
			: this.render_full()

	}

	render_simple() {
		const {formatted, address,results, isLoading, isLoadingDetails, searchingLocation} = this.state
		const {
			placeholder = 'Address',
			showLocate = false,
			allowLocalArea,
			title = <h5>Location based on your primary address</h5>,
			...restofProps
		} = this.props

		return (
			<>
				<div className='address-search relative w-full'>
					{title}
					{isLoadingDetails && <Dimmer className='mt-2' active inverted><Loader /></Dimmer>}
					<Search fluid
							{...restofProps}
							placeholder={placeholder} input={{fluid: true,autoComplete: "false",iconPosition: this.props.icon && 'left'}} 
							loading={isLoading} onResultSelect={this.handleResultSelect}
							onSearchChange={this.handleSearchChange} minCharacters={5} 
							results={results} value={formatted}  autoComplete='false'
							noResultsMessage={isLoading?"Searching...":"No results found."}
							/>
				</div>
				{showLocate && 
					<Button 
							style={{display: 'flex', alignItems: 'center', padding: '.5rem 0 0 0' }}
							inverted
							icon
							loading={searchingLocation} 
							type="button" 
							onClick={_=> this.get_user_location()}
						>
							<svg className="text-is-blue mr-2" width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M2.05025 2.05025C4.78392 -0.683417 9.21608 -0.683418 11.9497 2.05025C14.6834 4.78392 14.6834 9.21608 11.9497 11.9497L7 16.8995L2.05025 11.9497C-0.683418 9.21608 -0.683418 4.78392 2.05025 2.05025ZM7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z" fill="#0065F2"/>
							</svg>
						<p className="text-is-blue text-sm">Use my current location</p>
					</Button>
				}
			</>
		 )
	}

	parseState(state) {
		this.setComponents({state:parseState(state)})
	}

	render_full() {
		const {results, isLoading, isLoadingDetails, address , shortStatesDisplay = true, shortStatesKey = true} = this.state
		const {
			placeholder = 'Eg. 637 Flinders St.',
			value: originalValue,
			iconPosition,
			error,
			...restofProps
		} = this.props

		const states = [{key:null,value:null,text:'Select'},...Object.keys(STATES).map(k => ({key:k, value:shortStatesKey ? k : STATES[k], text:shortStatesDisplay ? k : STATES[k]}))]
		
		return (
			<div className='address-search-full relative'>
				{isLoadingDetails &&  <Dimmer className='mt-4' active inverted><Loader /></Dimmer>}
				<Grid >
					<Grid.Row>
				      <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4} widescreen={4} verticalAlign="bottom">
						  <Form.Input fluid value={this.state.address2} onChange={(e, {value}) => this.setComponents({address2: value, geo:null})} placeholder='Unit (Optional)' label='Unit' data-testid='input-unit'/>
					  </Grid.Column>
				      <Grid.Column mobile={16} tablet={12} computer={12} largeScreen={12} widescreen={12} verticalAlign="bottom">
						  <div className="field">
						  <label>Address</label>
						  <Search fluid placeholder = {placeholder}
							input = {{ fluid: true, autoComplete:"false", iconPosition: this.props.icon && 'left'  }}
  							loading = {isLoading}
  							onResultSelect = {this.handleResultSelect}
  							onSearchChange = {this.handleSearchChange}
  							minCharacters = {5}
  							results = {results}
  							value = {address} 
  							{...restofProps}
  							autoComplete = 'false'
  							noResultsMessage={isLoading?"Searching...":"No results found."}
  							
  							/> 
						</div>
					  </Grid.Column>
					 </Grid.Row>
					 <Grid.Row>
 						<Grid.Column mobile={16} tablet={6} computer={6} largeScreen={6} widescreen={6}><Form.Input fluid value={this.state.suburb} onChange={(e, {value}) => this.setComponents({suburb: value, geo:null})} label='Suburb' placeholder='Suburb' data-testid='input-suburb'/></Grid.Column>
						<Grid.Column mobile={16} tablet={5} computer={5} largeScreen={5} widescreen={5}><Form.Select fluid value={this.state.state} onChange={(e, {value}) => this.setComponents({state: value, geo:null})} label='State' placeholder='State' options={states} data-testid='dropdown-state'/></Grid.Column>
 						<Grid.Column mobile={16} tablet={5} computer={5} largeScreen={5} widescreen={5}><Form.Input fluid value={this.state.postcode} onChange={(e, {value}) => this.setComponents({postcode: value, geo:null})} label='Postcode' placeholder='Postcode' data-testid='input-postcode'/></Grid.Column>
 				   </Grid.Row>
					{this.state.error && !this.state.postcode && (
						<Grid.Row>
							<Grid.Column width={16}>
								<Message error visible>
									Error looking up address. Please complete manually, or{" "}
									<Button
										size="tiny"
										secondary
										basic
										onClick={() => this.handleResultSelect(null, {result: this.state.result})}
									>
										try again
									</Button>
								</Message>
							</Grid.Column>
						</Grid.Row>
					)}
				</Grid>
				
					
					
					
					
			</div>)

	}
}

export default AddressSearch
