
import React, { useState } from 'react';

import { BottomMenuItem } from './BottomMenuItem';
import { BottomMenuSubMenu } from './BottomMenuSubMenu';
import { menuItems } from './BottomMenu.data';

export const BottomMenu = ({ closeMenu }) => {
	const [expandedIndex, setExpandedIndex] = useState();

	const handleClick = (index) => {
		if (expandedIndex === index) {
			setExpandedIndex();
		}
		else {
			setExpandedIndex(index);
		}
	};

	const isExpanded = (index) => {
		return expandedIndex === index;
	};

	return (
		<div className="bg-is2-blue-50 px-6 lg:px-16 pt-11 pb-20 lg:py-11">
			<div className="mb-6">
				<h3 className="text-is2-gray-700">About InstantScripts</h3>
			</div>
			<div className="bg-white rounded-lg">
				{menuItems.map((menuItem, index) => (
					<div className="border-b border-b-is2-gray-50 last:border-b-0" key={index}>
						<BottomMenuItem expanded={isExpanded(index)} iconName={menuItem.iconName} imageUri={menuItem.imageUri} label={menuItem.label} onClick={() => handleClick(index)} />
						<BottomMenuSubMenu closeMenu={closeMenu} expanded={isExpanded(index)} menuItems={menuItem.children} />
					</div>
				))}
			</div>
		</div>
	);
};

export default BottomMenu;
