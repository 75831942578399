import { useUrgentFollowUp } from "./urgent_follow_up/useUrgentFollowUp";
import { useScriptsNeedReview } from "./scripts_need_review/useScriptsNeedReview";
import { useEScriptsOnHold } from "./escripts_on_hold/useEScriptsOnHold";
import { usePathologyResults } from "./pathology_results/usePathologyResults";
// import { usePatientAppointment } from "./patient_appointment/usePatientAppointment";
import { usePrescs } from "./prescs/usePrescs";
import { useInProgressVideoConsults } from "./in_progress_video_consults/useInProgressVideoConsults";
import { useCouldNotCallConsults } from "./could_not_call_consults/useCouldNotCallConsults";

export const useDynamicNotifications = () => {
    useUrgentFollowUp();
    usePathologyResults();
    useScriptsNeedReview();
    useEScriptsOnHold();
    // usePatientAppointment();
    usePrescs();
    useInProgressVideoConsults();
    useCouldNotCallConsults();
}