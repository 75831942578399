import React from 'react';
import { Link } from 'react-router-dom';
import SIcon from '../../../NUI/StreamlineIcons/SIcon';
import { cls } from '../../../NUI/utils';

export default function ServiceCard({ icon, title, description, price, href, onMenuChange }) {
    return (
        <Link to={href} className={cls("bg-is2-blue-50 hover:bg-is2-blue-100/50 transition-colors duration-300 rounded-[20px] !px-4 lg:!px-[22px] !pt-6 !pb-4 lg:!py-6 text-center flex items-center flex-col ")} data-testid="card-services" onClick={() => {onMenuChange(false); }}>
            {typeof icon === 'string' && <SIcon name={icon} alt={icon} size="md" cls="text-is2-blue mx-auto lg:mx-0 !h-[40px] !w-[40px]" bold />}
            {typeof icon !== 'string' && <div className="p-1 h-[40px] w-[40px] text-is2-blue mx-auto shrink-0 inline-block">{icon}</div>}
            <div className="text-center mt-3">
                <h5 className="flex items-center text-base font-bold text-is2-gray-450 my-1 lg:mb-0 mt-3 max-w-[120px] min-h-12 lg:max-w-auto">{title.abbr||title}</h5>
                {!price?.includes('undefined') && <p className="text-sm font-normal text-is2-gray-150 mt-2">{price}</p>}
            </div>
            <p className="text-sm text-is2-gray-150 mb-2 border-t border-t-white mt-3.5 pt-3.5 text-center">{description}</p>
        </Link>
    )
}