import SearchPharm from "@/views/UIelems/SearchPharm";
import API_service from "@/xAppLib/providers/API_service";
import React, { useState } from "react";
import { Button, Checkbox, Modal } from "semantic-ui-react";

export function ReqChangePharm({ row }) {
	const [state, setState] = useState({});

	return 	!state['phach_sent_'+row.sid] &&
		<Modal
			// style={{maxHeight:'50vh',overflow:'scroll',width:'450px',zIndex:1000}}
			trigger={<Button
				color='yellow'
				// size='mini'
				content = 'Ask to send script to another pharmacy'
			/>}
			// basic
			// style={{ position: 'static', margin: '0 auto', }}
			closeIcon
			on='click'
			wide='very'
			// position='left center'
			onClose={() => setState((prev) => ({ ...prev, ['phach_'+row.sid] : null, ['phach_notcoll_'+row.sid]: null})) }
		>
			<Modal.Header>Request to send this script to another pharmacy</Modal.Header>
			<Modal.Content >
				This script for {row.med_db_data__name} {row.med_db_data__size} was sent to <b>{row.org__name}</b>, {row.org__address}

				<br/><br/>

				<b>If you still didn't collect your medication</b>, you can ask us to send this script to another pharmacy.

				<br/><br/>
				Choose another pharmacy you'd like to send this script to:

				<div className='ui form' style={{paddingBottom:'100px'}}>
					<SearchPharm
						// error={valids && !valids.sendto_oid}
						onSelect={ (oid, pn, pa, sa, org) => setState((prev) => ({ ...prev, ['phach_'+row.sid]: {
								pha_oid: oid,
								pha_nm:pn,
								pha_adr:pa,
								srch_adr:sa
							} }))
						}
						intro={<></>}
						// oid={row.org__oid}
						// noChange={PARTN_PHARM}
						optionHeader={name => <p style={{lineHeight:'1.1em',marginBottom:0}}><strong>{name}</strong></p>}
					/>

				</div>

				<br />

				{state['phach_'+row.sid]?.pha_oid &&
					<Checkbox
						label="I confirm that this script was not collected yet"
						onChange={ (e, d) => setState((prev) => ({ ...prev, ['phach_notcoll_'+row.sid] : d.checked })) }
					/> }

				<br />
				<br />

				{state['phach_'+row.sid]?.pha_oid && state['phach_notcoll_'+row.sid] &&
					<Button
						color='green'
						content='Send request'

						onClick={ async _=> {
							const res = await API_service.load_data(
								'scripts/req_cha_pha',
								{sid: row.sid, oid: state['phach_'+row.sid]?.pha_oid, phach: state['phach_'+row.sid]}
							);
							setState((prev) => ({ ...prev, ['phach_sent_'+row.sid]: res.res=='ok'&&res.to }))
						} }
					/>
				}
			</Modal.Content>
		</Modal>
		||
		<>
			We received your request to change the pickup pharmacy to
			<br/>
			<b>{state['phach_'+row.sid].pha_nm}</b>
		</>

}