import React from "react";
import { Icon, Label } from "semantic-ui-react";

const NotificationIndicator = ({
    onClick,
    open = false,
    unreadCount = 0,
}) => (
    <button
        onClick={onClick}
        title={`Notifications ${unreadCount > 0 ? `(${unreadCount} unread)` : ''}`}
        aria-label={`Notifications ${unreadCount > 0 ? `(${unreadCount} unread)` : ''}`}
        aria-expanded={open}
        aria-haspopup="true"
        data-toggle-notifications="true"
        className={`
                notifications---indicator
                notifications---indicator--${open ? 'open' : 'closed'}
                ${open
                ? "bg-notifications-indicatorIcon-active"
                : "bg-transparent"}
                relative
                py-1
                px-2 lg:px-4
                inline-flex
                gap-2
                items-center
                justify-center
                focus:outline-none
                ${!open ? "focus:ring-1 focus:ring-blue-500" : ""}
                rounded-full
                !border-none
                group
            `}
    >
        <div className="hidden lg:block relative group-hover:no-underline after:absolute after:left-0 after:right-0 after:-bottom-[0px] after:h-[1px] after:bg-current after:opacity-0 group-hover:after:opacity-100 after:transition-opacity after:duration-200">Notifications</div>
        <span>
            <Icon
                name="bell outline"
                aria-hidden="true"
                className={"text-notifications-indicatorIcon"}
            />
            {unreadCount > 0 && (
                <Label
                    circular
                    color="orange"
                    size="mini"
                    className="absolute -top-1 right-0"
                    aria-hidden="true"
                >
                    {unreadCount > 99 ? '99+' : unreadCount}
                </Label>
            )}
        </span>
    </button>
);

export default NotificationIndicator;