import React from "react";
import { BrowserRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { Deeplinks } from '@ionic-native/deeplinks';
import { Loader, Modal,Header, Button, Icon } from 'semantic-ui-react';

import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.min.css';

import { prep_env } from './prep_env'
import prep_ionic from './prep_ionic'

import "styles/semantic-sass/semantic-ui.scss";
import "styles/themes/index.scss";
import "styles/tailwind.css";
import "styles/video-react.css";
import "rc-tree/assets/index.css";
import 'jsoneditor/dist/jsoneditor.css';
import 'react-toastify/dist/ReactToastify.css';


import Page from "Page";
import logger from 'xAppLib/libs/logger'
import Logo from 'views/UIelems/Logo'

import { UserProvider } from "xAppLib/context";
import { AppProvider,AppConsumer } from "xAppLib/context";
import { UserDataProvider } from "xAppLib/context/UserDataContext";

import {
    PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import AppHistory from "./xAppLib/App/AppHistory";
import { NotificationsProvider } from './notifications/context/NotificationsContext';
import {EcommerceDataProvider} from "./xAppLib/context/EcommerceDataContext";
import LegacyMessageAdapter from './notifications/view/app/LegacyMessageAdapter';

const public_sites = ['www.instantscripts.com.au', 'app.instantscripts.com.au', 'instantcosmetics.com.au', 'instantcosmetic.com.au','hivprevent.com.au','thegreendoc.com.au', 'goodleaf.com.au', 'goodleaf.au', 'benicura.com.au', 'benicura.au', 'benicura.com', 'haiku.health','blsclinics.com.au']

class App extends React.Component {

	constructor (props) {
		super(props)
		
		prep_env( _=> {
			app.state.loaded = true
			this.setState({env_loaded: true})
		} )
		this.state = {env_loaded: false, newVersion:false }
		
	}
	
	//	------------------------	------------------------	------------------------

	componentDidMount() {

		this.ionicCleanup = prep_ionic();
		// window.addEventListener('hashchange', () => {app.history.push(app.history.location.hash.substring(1))})

		this.histCleanup = app.history.listen((location,action)=>{

			// Remove trailing slashes
			const pathname = location.pathname?.replace(/\/+$/, '');
			try {
				let p = pathname?.split('/').pop()
				if (p=='')
					p = 'home'
				if (this.last_path != p) {
					if (this.last_path) {
						document.body.classList.remove(this.last_path)
					}
					document.body.classList.add(p)
					this.last_path = p
				}
			} catch(e) {
				//console.log(e)
			}
			if (this.prev_loc != location && action === 'PUSH') {
				(app.settings.is_ionic ? document.getElementById('app') : window).scrollTo(0, 0);
			}
			this.prev_loc = location
		},true)
	}
	
	componentWillUnmount() {
		this.ionicCleanup?.()
		this.histCleanup?.()
	}
	

//	------------------------	------------------------	------------------------

	componentDidCatch(error, errorInfo) {
		logger.report_error('ERROR in componentDidCatch', 'error', error, errorInfo);
	}

	static getDerivedStateFromError(error) {
		if (error.name=="ChunkLoadError")
			return {newVersion:true}
		return { error };
	}


//	------------------------	------------------------	------------------------

	render() {

		const pp_prod_id = "AcP9n6G_C0I_dbtIbCkEzosOHasFGNv98oArQSagXRInWO_ep_zP2hgSaRs4vCqVrYHBxfyxuRc0za2F"
		const pp_dev_id = "Ad_9EBwljSiKKnWSrorQXbwOfFZl6knenT_Yt1fgCPsMagmpptnlaC2q5L8QF0dsGiJfsZEUxZpRjIoG"

		const paypalInitialOptions = {
			"client-id":
			app.settings.pp_prod ? pp_prod_id : pp_dev_id, 
			currency: "AUD",
			intent: "authorize",
		};

		if (this.state.error) {
				return this.render_error()
		}

		return 	<BrowserRouter>
					<AppHistory />
					<PayPalScriptProvider options={paypalInitialOptions}>
						<AppProvider>
							<UserProvider>
								<UserDataProvider>
									<EcommerceDataProvider>
										<NotificationsProvider>
											<LegacyMessageAdapter />
											<Helmet titleTemplate={`%s – ${app.runtime.name}`}>
												<meta name="robots" content={public_sites.includes(location.hostname)?"index":"noindex"} />
											</Helmet> 
											<ToastContainer
													enableMultiContainer
													containerId={'default'}
													position="top-center"
													autoClose={5000}
													hideProgressBar={false}
													newestOnTop={false}
													closeOnClick
													rtl={false}
													pauseOnFocusLoss
													draggable
													pauseOnHover
													style={{
														zIndex:2147483006
													}}
												/>
											<ToastContainer
													enableMultiContainer
													containerId={'actionable'}
													autoClose={false}
													closeOnClick={false}
													position="bottom-left"
													newestOnTop
													rtl={false}
													draggable
													limit={5}
													style={{
														zIndex:2147483006
													}}
												/>
											<AppConsumer>
												{({site_status})=>{
													if (site_status.maint_mode) 
														return this.render_maint_mode(site_status.maint_msg)
													else return <>
														{this.state.newVersion || site_status.newVersion ? this.render_new_version() : <Page />}
													</>
												}}
											</AppConsumer>
										</NotificationsProvider>
									</EcommerceDataProvider>
								</UserDataProvider>
							</UserProvider>
						</AppProvider>
					</PayPalScriptProvider>
				</BrowserRouter>
			
	}
	
	render_new_version() {
		return <div style={{"position":"absolute","top":"0","left":"0","width":"100vw","height":"100vh","display":"flex","flexDirection":"column","justifyContent":"center",alignItems:'center',background:'white',zIndex:10000}}>
			<Logo />
			<br/>
			<h3>New {app.runtime.name} app is available. </h3>
			<Button color="green" size="big" 
					as={app.settings.is_ionic && 'a'} 
					target={app.settings.is_ionic && 'upd'} 
					href={app.settings.is_ionic && (app.settings.is_ionic_ios && 'https://apps.apple.com/au/app/instantscripts/id1456598384' || app.settings.is_ionic_android && 'https://play.google.com/store/apps/details?id=au.com.instant')}
					onClick={_=>window.location.reload()}
				>Click to update to the latest version</Button>
			
		</div>
	}

	render_error() {
		return <div style={{"position":"absolute","top":"0","left":"0","width":"100vw","height":"100vh","display":"flex","flexDirection":"column","justifyContent":"center",alignItems:'center',background:'white',zIndex:10000}}>
			<Logo />
			<br/>
			<h3>{app.dev_env ? this.state.error.message : `Something went wrong.`}</h3>
			<p>We have been notified of the problem.</p>
			<p>Please reload and try again.</p>
			<Button color="green" size="big" onClick={()=>window.location.reload()}>Reload</Button>
			{app.dev_env && <pre style={{fontSize:'10px',maxWidth:'100vw',padding:'2em',overflow:'scroll'}}>
				{(this.state.error.stack)}
			</pre>}
			
		</div>
	}
	
	render_maint_mode(msg) {
		return <div style={{"position":"absolute","top":"0","left":"0","width":"100vw","height":"100vh","display":"flex","flexDirection":"column","justifyContent":"center",alignItems:'center',background:'white',zIndex:10000}}>
			<Logo />
			<br/>
			<h3>{app.runtime.name} is currently unavailable. </h3>
			<h3>{msg || 'Please try again soon.'}</h3>
			{/* <Button outline  
					as={app.settings.is_ionic && 'a'} 
					target={app.settings.is_ionic && 'upd'} 
					href={app.settings.is_ionic && (app.settings.is_ionic_ios && 'https://apps.apple.com/au/app/instantscripts/id1456598384' || app.settings.is_ionic_android && 'https://play.google.com/store/apps/details?id=au.com.instant')}
					onClick={_=>window.location.reload()}
				>Click to refresh</Button>  */}
			
		</div>
	}

}

export default App;
