import React, {useCallback} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SplitPage from "../../../views/UIelems/v2/SplitPage";
import { AuthenticationOptions } from '../../../views/UIelems/v2/single_use/AuthenticationOptions'
import SIcon from 'views/NUI/StreamlineIcons/SIcon';
import { cls } from '../../../views/NUI/utils';

const UserSignUpAuthentication = ({
    onSelectProvider,
    setSignUpState,
    error,
    loading,
    onAlternativeLinkClick
}) => {
    const navigate = useNavigate();

    const handleAlternativeLinkClick = useCallback(() => {
        onAlternativeLinkClick ? onAlternativeLinkClick() : navigate('/login');
    }, [onAlternativeLinkClick])

    return (
        <SplitPage
            fullHeight={true}
            calloutSection={
                (
                    <div>
                        <Link
                            to="/"
                            className={cls(
                                "block md:hidden mb-5 flex items-center justify-center bg-is2-blue text-white hover:text-white rounded-full w-[40px] h-[40px] cursor-pointer -rotate-[270deg]"
                            )}
                        >
                            <SIcon name="chevron" size="xxs" bold />
                        </Link>
                        <h1 className="m-0 pb-2 text-lg md:text-2xl font-bold !text-white">Create account</h1>
                        <p className="m-0 py-2 text-base">
                            Join over <span className="font-medium text-is2_lg">2 million</span> other Australians. <br />Enjoy the convenience of online healthcare.
                        </p>
                        <div className="flex flex-row pt-10 hidden md:block">
                            <SIcon name="doc-steth" size="sm" svg_cls='' cls="text-[#0065F2] pr-3 mr-2" bold />
                            <SIcon name="pill-bottle" size="sm" svg_cls='' cls="text-[#0065F2] pr-3 mr-2" bold />
                            <SIcon name="med-cert" size="sm" svg_cls='' cls="text-[#0065F2] pr-3 mr-2" bold />
                            <SIcon name="patho-blood-drop" size="sm" svg_cls='' cls="text-[#0065F2] pr-3 mr-2" bold />
                        </div>
                    </div>
                )
            }
        >
            <AuthenticationOptions
                variant='signup'
                onSelectProvider={onSelectProvider}
                onAlternativeLinkClick={handleAlternativeLinkClick}
                error={error?.message}
                loading={loading}
                setSignUpState={setSignUpState}
            />
        </SplitPage>
    );
}

export default UserSignUpAuthentication;