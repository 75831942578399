import React from 'react';
import SIcon from '../../../NUI/StreamlineIcons/SIcon';
import { Link } from 'react-router-dom';
import { cls } from "../../../NUI/utils";
import menu_model from '../../../../models/menu_model';
import { useUnreadByType } from '../../../../notifications/hooks/useNotifications';

export function HeaderNav({ curr, sidebar = false }) {
    const menu = menu_model.get_headermenu();

    const newPatientPrescriptionCount = useUnreadByType('patient_prescription').length;
    const newPatientCertificateCount = useUnreadByType('patient_certificate').length;
    const newPatientReferralCount = useUnreadByType('patient_referral').length;
    const newPatientTestResultCount = useUnreadByType('patient_test_result').length;

    return (
        <div className={cls("p-6 lg:p-5 gap-2 grid grid-cols-2 lg:max-w-[744px] mx-auto lg:w-full bg-is2-blue-300 ", sidebar && "lg:grid-cols-1 lg:bg-transparent lg:gap-6")}>
            {menu.map((el, i) => {
                let newItemCount = 0;
                if (el.type === 'script') {
                    newItemCount = newPatientPrescriptionCount;
                } else if (el.type === 'medcert') {
                    newItemCount = newPatientCertificateCount;
                } else if (el.type === 'docrefr') {
                    newItemCount = newPatientReferralCount;
                } else if (el.type === 'patho') {
                    newItemCount = newPatientTestResultCount;
                }

                return (
                    <Link to={el.link} key={el.link + i} className={cls(
                        (el.name === curr && `border-white ${sidebar && "lg:bg-is2-blue-300 lg:border-none"}`),
                        "relative flex flex-col px-3 py-1.5 rounded gap-2 border border-transparent hover:border-white items-center transition-border ease-in-out duration-150",
                        sidebar ? "lg:px-0 lg:flex-row lg:items-center lg:gap-1" : "lg:px-1.5 lg:py-3"
                    )}>
                        <SIcon name={el.icon} size="s" cls="text-white" bold />
                        <div className="flex">
                            <p className={cls("text-base leading-5 mb-0 mr-1 lg:mr-0", !sidebar && "text-center", newItemCount > 0 && "font-bold")}>
                                {el.name}
                            </p>
                            {newItemCount > 0 && (
                                <div className="bg-is2-orange text-xs rounded px-1.5 lg:absolute right-1.5">
                                    {newItemCount} new
                                </div>
                            )}
                        </div>
                    </Link>
                );
            })}
        </div>
    );
}