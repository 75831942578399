import React from "react";
import { Container, Accordion, ButtonGroup, Button } from "semantic-ui-react";
import { cls } from '../../../views/NUI/utils';

const FaqSection = ({data, title }) => {

        const faq_data = data.map((el, i) => ({ key:i, title: el.title, content: {content : el.content} }))
                          
        return  <div className={cls("bg-white ui 4 faq" )}>
                    <Container>
                        <div className="relative flex flex-col lg:flex-row lg:justify-between w-full max-w-[1124px] mx-auto py-24 md:py-[120px] gap-9">
				
                            <h2 className="absolute top-24 w-full lg:w-fit text-center lg:text-left mb-0 md:mb-6 text-is2-gray-800">{title}</h2>

                            <div className="w-full sm:w-fit order-2 lg:order-1 lg:mt-[132px] mx-auto lg:mx-0">
                                <p className="text-center lg:text-left text-is2-yellow-500 text-base mb-3">Still need help?</p>
                                <ButtonGroup className="w-full lg:w-fit gap-2">
                                    <Button basic as={"a"} href="https://help.instantscripts.com.au/en/">Help articles</Button>
                                    <Button basic onClick={()=>window.Intercom('show')}>Chat to us</Button>
                                </ButtonGroup>
                            </div>

                            <div className="w-full lg:w-[522px] order-1 lg:order-2 flex-end mt-32 lg:mt-0">
                                <Accordion styled panels={faq_data} className="w-full max-w-full lg:w-1/2 lg:!max-w-[522px] mx-0" /> 
                            </div>
                        </div>
                    </Container>
                </div>
       
}

export default FaqSection;