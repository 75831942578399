import React from 'react';
import { useAuthenticationOptionsProps } from "@/views/UIelems/v2/single_use/AuthenticationOptions.hooks";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import SplitPage from "../UIelems/v2/SplitPage";
import { AuthenticationOptions } from '../UIelems/v2/single_use/AuthenticationOptions'
import SIcon from 'views/NUI/StreamlineIcons/SIcon';
import { cls } from '../NUI/utils';

const LoginPage = () => {
    const navigate = useNavigate()

    const props = useAuthenticationOptionsProps();

    return <div>
        <Helmet>
            <title>Log in</title>
        </Helmet>
        <LoginPage.Contents authOptionsProps={props} onAlternativeLinkClick={() => navigate("/signup")} />
    </div>
}

LoginPage.Contents = ({authOptionsProps, onAlternativeLinkClick}) => {
    return <div>
        <SplitPage
            fullHeight={true}
            calloutSection={
                (
                    <div>
                        <Link
                            to="/"
                            className={cls(
                                "block md:hidden mb-5 flex items-center justify-center bg-is2-blue text-white hover:text-white rounded-full w-[40px] h-[40px] cursor-pointer -rotate-[270deg]"
                            )}
                        >
                            <SIcon name="chevron" size="xxs" bold />
                        </Link>
                        <h1 className="m-0 pb-2 text-lg md:text-2xl font-bold !text-white">Log in</h1>
                        <p className="m-0 py-2">
                            Log in to your InstantScripts account.
                        </p>
                        <div className="flex flex-row pt-10 hidden md:block">
                            <SIcon name="doc-steth" size="sm" svg_cls='' cls="text-[#0065F2] pr-3 mr-2" bold />
                            <SIcon name="pill-bottle" size="sm" svg_cls='' cls="text-[#0065F2] pr-3 mr-2" bold />
                            <SIcon name="med-cert" size="sm" svg_cls='' cls="text-[#0065F2] pr-3 mr-2" bold />
                            <SIcon name="patho-blood-drop" size="sm" svg_cls='' cls="text-[#0065F2] pr-3 mr-2" bold />
                        </div>
                    </div>
                )
            }
        >
            <AuthenticationOptions {...authOptionsProps} onAlternativeLinkClick={onAlternativeLinkClick}
            />
        </SplitPage>
    </div>
}


export default LoginPage
