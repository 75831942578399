import { serverTimestamp } from "firebase/database";
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { Icon, Popup, Button, List, Segment } from 'semantic-ui-react'
import moment from 'moment-timezone'

import db_lib from 'xAppLib/libs/db_lib';
import { RecentAppScripts } from "../views/doc/doccons/RecentAppScripts";
import { participantStatus } from "xAppLib/PhoneCall/PhoneCall";
import {Duration} from "../xAppLib/UIelems/Duration";
import API_service from 'xAppLib/providers/API_service'
import {DataShow} from "../xAppLib/DataTable";
import scripts_list_model from 'models/scripts_list_model'

import Patient from 'views/patients/Patient'
import {from_melb_ui_tm, UI_DATETIME_FMT} from "../helpers/datetime";
import logger from 'xAppLib/libs/logger';
import { AnswersList } from "../xAppLib/Users/AnswersList";
import { AnswerFlag } from "../xAppLib/Users/AnswerFlag";
import { flagged_answers } from "../views/med/utils";
import Photos from 'xAppLib/Photos/Photos';
import phonecall_model from "./phonecall_model";
import { TYPE_DICT } from "./treatment_plan_model";
import CopyField from "../xAppLib/UIelems/CopyField";
import FieldMetaNotes from "../views/UIelems/fields/FieldMetaNotes";
import PhoneCalls from "../xAppLib/PhoneCall/PhoneCalls";
import SimpleShowMore from "../xAppLib/UIelems/SimpleShowMore";
import { sort_med_scripts_for_wr, withUr } from "./utils";

const _FRDB_LOC = 'MedScripts';

const DEBUG = false

function ActiveTreatmentPlans({list}) {
	const SHOW_MORE = 5;
	const [showAll, setShowAll] = useState(false);

	const plans = Object.values(list);

	const filtered = showAll
		? plans
		: plans.slice(0, SHOW_MORE);

	return (
		<Segment size="small">
			<div className="flex justify-between items-center">
				<b>Active Treatment Plans ({plans.length})</b>
				{plans.length > SHOW_MORE && (
					<Button type="button"
							basic
							color="black"
							size="mini"
							onClick={() => setShowAll(x => !x)}
					>
						{showAll ? 'Less' : 'More'}
					</Button>
				)}
			</div>
			<List divided>
				{filtered.map(plan => (
					<List.Item key={plan.tpid}>
						<List.Content className="whitespace-nowrap">
							<i>{TYPE_DICT[plan.type] || plan.type}</i> -{" "}
							<b>{plan.med.name}</b> <small>{plan.med.size} {plan.med.qnty}</small>{" "}
							({plan.used} of {plan.total} used)
						</List.Content>
					</List.Item>
				))}
			</List>
		</Segment>
	);
}

function FlaggedAnswers({answs, title = "Flagged Answers", popup}) {
	const flags = useMemo(() => Array.from(new Set(flagged_answers(answs))), [answs]);
	if (flags.length === 0) return null;

	return (
		<Segment size="small">
			<div className="flex justify-between items-center">
				<b className="whitespace-nowrap mr-2">{title}</b>
				{popup}
			</div>
			<List>
				{flags.map(flg_id => <List.Item key={flg_id}><AnswerFlag flg_id={flg_id}/></List.Item>)}
			</List>
		</Segment>
	);
}

function HasConsulted() {
	return (
		<Segment color="red" className="text-red-700">
			<Icon name="warning sign" color="red"/> A doctor has indicated this request may need a consultation, take care when approving.
		</Segment>
	);
}

function MustReview() {
	return (
		<Segment color="red" className="text-red-700 whitespace-nowrap">
			<Icon name="warning sign" color="red"/> You must review before approving
		</Segment>
	);
}

function ReviewSection({row}) {
	const sections = [
		row.consulted && <HasConsulted key="has-consulted" />,
		row.must_review && <MustReview key="must-review" />,
		flagged_answers(row.answs).length && <FlaggedAnswers key="flagged-answers" answs={row.answs}/>,
		flagged_answers(row.prev_flg_ans_scr?.answs).length && (
			<FlaggedAnswers key="prev-flagged-answers" answs={row.prev_flg_ans_scr?.answs} title="Recent Flagged Answers" popup={<Popup
				trigger={<Button icon='question' compact/>}
				content={<>
					<p>Flagged answers from a previous form submission for the same medication:</p>
					<AnswersList answs={row.prev_flg_ans_scr?.answs}/>
				</>}
				wide='very'
				on={['click']}
			/>}/>
		),
		row.recent_app_scripts && <RecentAppScripts key="recents" compact list={Object.values(row.recent_app_scripts)}/>,
		row.active_treatment_plans && <ActiveTreatmentPlans key="active-treatment-plans" list={row.active_treatment_plans}/>,
	].filter(Boolean);

	if (sections.length) {
		return (
			<Segment.Group>
				{sections}
			</Segment.Group>
		);
	}

	return null;
}



const _LIST_DET_FIELDS = [

	{
		name: 'Wait Time',
		type: 'compound',
		parts : [
			{
				name: 'add_tm',
				jpath: 'add_tm'
			},
			{
				name: 'hist',
				jpath: 'hist'
			},
			{
				name: 'script_type',
				jpath: 'script_type'
			},
			{
				name: 'req_type',
				jpath: 'req_type'
			},
			{
				name: 'rev_tm',
				jpath: 'rev_tm'
			},
			{
				name: 'doc',
				jpath: 'owner.name'
			},
		],
		template: ({row, add_tm, hist, script_type, req_type, doc, rev_tm}) => {
			if (!add_tm) {
				return null;
			}
			// TODO duplication with cosm

			const transitions = Object.values(hist || {})
				.filter(h => h.tm && h.to?.status)
				.sort((x, y) => x.tm - y.tm)
				.map(h => ({ status: h.to.status, tm: h.tm }));

			const first_entered_wr = from_melb_ui_tm(add_tm);
			const last_entered_wr = transitions
				.filter(h => h.status === 'instcons_await')
				.slice(-1)[0];

			const consult_taken = transitions
				// If they were put back into WR, make sure we're looking after that time
				.filter(h => h.status === 'instcons_taken' && h.tm > (last_entered_wr?.tm || 0))
				.slice(-1)[0];

			const consult_finished = transitions
				// Unlikely, but if the consult was approved/rejected then sent back to WR this
				// ensures we're only finding the last transition after that time
				.filter(h => /^instcons_(approved|rejected|cancel)$/.test(h.status) && h.tm > ((consult_taken || last_entered_wr)?.tm || 0))
				.slice(-1)[0];

			// Covers the case where the dr instant approved/rejects the consult, i.e. bypassing
			// consult taken. In this situation the consult time will be zero
			const action_taken = (consult_taken || consult_finished);

			return <>
					<div className="pl-6">
						{first_entered_wr.format(UI_DATETIME_FMT)}
					</div>
					<div className="pl-6">
						<b>{scripts_list_model.TYPE_DICT(script_type, req_type)}</b>
					</div>

					<div style={{ fontFamily: "monospace"}}>
						<Duration icon live={!action_taken}
								  start={first_entered_wr}
								  end={action_taken?.tm}
								  iconColor={d => (
									  d.asMinutes() <= 2 && 'grey'
									  || d.asMinutes() <= 3 && 'green'
									  || d.asMinutes() <= 5 && 'blue'
									  || d.asMinutes() <= 8 && 'yellow'
									  || d.asMinutes() <= 12 && 'orange'
									  || 'red'
								  )}
									/>
					</div>

					{rev_tm && (app.acl.is_admin || app.acl.is_supp) && (
						<div style={{ fontFamily: 'monospace' }}>
							<Duration
								live
								icon="doctor"
								start={moment(rev_tm)}
								iconColor={d => d.asMinutes() >= 30 ? 'red' : 'grey'}
							/>
						</div>
					)}

				{consult_taken &&
					<div className="pl-6">
						{moment(consult_taken.tm).format(UI_DATETIME_FMT)}
					</div>
				}
				{action_taken && (<>
					{action_taken.status !== 'instcons_cancel' && (
						<div style={{ fontFamily: "monospace"}}>
							<Duration icon={'doctor'}
									  live={!consult_finished}
									  start={action_taken.tm}
									  end={consult_finished?.tm}
									  iconColor={d => d.asMinutes() < 1 && 'green' || 'blue'}
							/>
						</div>
					)}
					<div className="pl-6">
						{consult_finished && moment(consult_finished.tm).format(UI_DATETIME_FMT)}
					</div>
				</>)}
				{app.user?.claims?.root && doc && <div className="mt-4">
					<Icon name={row.extra?.t===false?"code":"doctor"} /> {doc} {row.srv_tm && row.rev_tm && (lag => `${lag > 0 ? '+' : ''}${lag.toFixed(2)}s`)((row.rev_tm - row.srv_tm) / 1000)}
				</div>}
			</>;
		}
	},

	{
		name: 'Patient',
		type: 'compound',
		parts : [
			{
				name: 'First',
				jpath: 'spd_data.first_name'
			},
			{
				name: 'Last',
				jpath: 'spd_data.last_name'
			},
			{
				name: 'DOB',
				jpath: 'spd_data.dob',
				type: 'age',
			},
			{
				name: 'Email',
				jpath: 'spd_data.email',
				type: 'show_more',
				cut_length: 20,
			},
			{
				name: 'Sex',
				jpath: 'spd_data.sex',
			},
			{
				name: 'Mobile',
				jpath: 'spd_data.mobile',
			},
			{
				name: 'Medicare',
				jpath: 'spd_data.medicare'
			},
			{
				name: 'Conc',
				jpath: 'spd_data.conc_card'
			},

			{
				name: 'user_iding',
				jpath: 'user_iding',
			},
			{
				name: 'ihi_num',
				jpath: 'ihi_num',
			},
			{
				name: 'pts_uid',
				jpath: 'pts_uid',
			},
			{
				name: 'mc_vf',
				jpath: 'meta.medicare_verified',
			},
			{
				name: 'cn_vf',
				jpath: 'meta.concession_verified',
			},
			{name:'mdcr_first_name',jpath: 'spd_data.mdcr_first_name'},
			{name:'mdcr_last_name',jpath: 'spd_data.mdcr_last_name'},

			{ name: 'n__type', jpath: 'n__type',
				type: 'or',	jpath1: 'notes.type' },
			{ name: 'n__lvl', jpath: 'n__lvl',
				type: 'or',	jpath1: 'notes.lvl' },
			{ name: 'n__cont', jpath: 'n__cont',
				type: 'or',	jpath1: 'notes.cont' },
			{ name: 'n__desc', jpath: 'n__desc',
				type: 'or',	jpath1: 'notes.desc' },
			{ name: 'n__cre_by', jpath: 'n__cre_by',
				type: 'or',	jpath1: 'notes.cre_by' },
			{ name: 'n__cre_tm', jpath: 'n__cre_tm',
				type: 'or',	jpath1: 'notes.cre_tm' },
			{ name: 'partn_name', jpath: 'partn.org.name' },
			{ name: 'ur', jpath: 'ur' },
			{ name: 'snum', jpath: 'snum' },
		],
		template : ({First,Last,DOB,Email,Sex,Mobile,Medicare, Conc, pts_view_link, mc_vf, cn_vf,mdcr_first_name,mdcr_last_name, n__type, n__lvl, n__cont, n__desc, n__cre_by, n__cre_tm, user_iding, ihi_num, pts_uid, partn_name, ur, snum })=> <React.Fragment>
							<Link to = {`/patient/dp/${btoa(`d=${user_iding}&i=${ihi_num}&u=${pts_uid}`)}`}>
								<Icon name = {'search'} />
							</Link>
							&nbsp;
							{First} {Last}
							&nbsp;
							{n__type && n__lvl &&
								<Popup
									trigger={ <Icon
													name={n__lvl=='info' && "info circle" || n__lvl=='warn' && "warning circle"  || n__lvl=='consult' && "doctor" || n__lvl=='alert' && "warning sign" || 'flag checkered'}
													color={n__lvl=='info' && "green" || n__lvl=='warn' && "yellow" || n__lvl=='consult' && "blue" || n__lvl=='alert' && "red" || 'grey'}
												/>}
									>
									<Icon
													name={n__lvl=='info' && "info circle" || n__lvl=='warn' && "warning circle"  || n__lvl=='consult' && "doctor" || n__lvl=='alert' && "warning sign" || 'flag checkered'}
													color={n__lvl=='info' && "green" || n__lvl=='warn' && "yellow" || n__lvl=='consult' && "blue" || n__lvl=='alert' && "red" || 'grey'}
												/>
									<b>{n__cont}</b><br />
									{n__desc}<br />
									<i>by {n__cre_by.n} on {moment(n__cre_tm).tz("Australia/Melbourne").format('D/M/YY')}</i>
								</Popup>
							}
							<br/>
							{mdcr_first_name && <React.Fragment>({mdcr_first_name} {mdcr_last_name}) <Popup content='Real name confirmed by Medicare' trigger={ <Icon name="question circle outline"/>} /><br/></React.Fragment>}
							{Sex && <Icon name={Sex=='F' && 'woman' || Sex=='M' && 'man' || 'question'} color={Sex=='F' && 'pink' || Sex=='M' && 'blue' || 'green'} />}
							{DOB}
							<br/>
							{Mobile && <a href={`tel:${Mobile.replace(/\s/g,'')}`}><Icon name='phone' />{Mobile}</a>} <br />
							<br />
							<div className="flex flex-col">
							<Popup
								content={
									<Patient
										embedded
										by_par="ui"
										by_id={user_iding}
										by_uid={pts_uid}
										by_ihi={ihi_num}
										hide_act_btns
									/>
								}
								on='click'
								position="right center"
								trigger={<Button icon='user' size='tiny' label='Patient Details' />}
								style={{maxHeight:'60vh',overflow:'scroll',width:'40vw'}}
							/>
							{ur && <CopyField val={ur} />}
							{snum && <CopyField val={snum} />}
							</div>
							{partn_name && <><br />w/ {partn_name}</>}
						</React.Fragment>
	},

	{
		name: 'Medication',
		type: 'compound',
		_id: 'treatment',
		parts: [
			{
				name: 'Medicine',
				jpath: 'med_db_data.name',
				type: 'show_more',
				cut_length: 50,
			},
			{
				name: 'Size',
				jpath: 'med_db_data.size',
				type: 'show_more',
				cut_length: 15,
			},
			{
				name: 'med_db_data',
				jpath: 'med_db_data',
			},
			{
				name: 'Quantity',
				jpath: 'cosm_det.qua',
				type: 'show_more'
			},
			{
				name: 'Location',
				jpath: 'cosm_det.loc',
				type: 'show_more'
			},
			{
				name: 'Notes',
				jpath: 'cosm_det.note',
				type: 'show_more'
			},

			{
				name: 'send_to',
				jpath: 'med_db_data.send_to',
				type: 'show_more',
				cut_length: 20,
			},
			{
				name: 'med_items',
				jpath: 'med_db_data.items',
				// type: 'show_more',
				// cut_length: 20,
			},
			{
				name: 'med_req_txt',
				jpath: 'med_db_data.req_txt',
				// type: 'show_more',
				// cut_length: 20,
			},
			{
				name: 'others',
				jpath: 'others'
			},
			{
				name: 'last_consult',
				jpath: 'last_consult'
			},
			{
				name: 'form_data',
				jpath: 'form_data'
			},
			{
				name:'note',
				jpath:'note'
			},

			{
				name: 'cons_allergy',
				jpath: 'form_data.cons_allergy',
				type: 'show_more',
				cut_length: 25,
			},
			{
				name: 'cons_meds',
				jpath: 'form_data.cons_meds',
				type: 'show_more',
				cut_length: 25,
			},
			{
				name: 'med_items',
				jpath: 'med_db_data.items',
			},
			{
				name: 'selected_items',
				jpath: 'form_data.selected_items',
			},
		],
		template : ({row, Medicine,Size,Quantity,Location,Notes, send_to, note, med_db_data, form_data, med_req_txt, others, med_items, selected_items })=> {
			return <React.Fragment>
				<p>
					<b>{Medicine}</b> {Size} {med_db_data?.qnty}<br/>
					{Quantity && <React.Fragment>Quantity: {Quantity}<br/></React.Fragment>}
					{Location && <React.Fragment>Location: {Location}<br/></React.Fragment>}
					{Notes && <React.Fragment>Notes: {Notes}</React.Fragment>}
					{send_to && <>send to: {send_to}<br/></> ||''}
					{med_req_txt && <b><br/><li>{med_req_txt}</li></b>||''}
					{form_data?.cons_desc && <>Patient note: <b>{form_data.cons_desc}</b><br/></>}
					{form_data?.cons_medhist && <>Medical history: {form_data.cons_medhist}<br/></>}
					{form_data?.cons_allergy && <>Allergy: {form_data.cons_allergy}<br/></>}
	 				{form_data?.cons_meds && <>Current Meds: {form_data.cons_meds}<br/></>}
					{med_items && <>Selected Tests:<br />{med_items.map( (it, i) => (!selected_items || selected_items[it.key]) && <li key={i}><i><SimpleShowMore cut_length={25} content={it.name || ''} /></i> </li>)}</> ||''}
 				</p>

				{(others || []).map((other) => (
					<div key={other.sid}>
						<hr className="my-2"/>
						{DataShow.show_data_field(other, _LIST_DET_FIELDS.find(x => x._id === 'treatment'))}
					</div>
				))}
				{note && <FieldMetaNotes row={{meta: {note}}}/>}

				{row.form_data.phts && <>
							<Photos
									size={'calc(100% / 4 - 2em)'}
									data = {row.form_data.phts || []}
									mode = 'view_only'
							 />
						</>}
				{Boolean(row.meta?.calls) && <PhoneCalls sid={row.sid} />}
				<ReviewSection row={row} />
			</React.Fragment>
		}
	},
	{
		name: 'Answers',
		type: 'compound',

		parts : [
			{
				name: 'Answers',
				jpath: 'answs',
			},
			{
				name: 'Notes',
				jpath: 'meta.note',
				template: FieldMetaNotes
			},
		],
		template : ({Answers})=> {
				return <React.Fragment>
						<Popup position="bottom right"
							trigger={<Button icon='question' compact/>}
							content={<AnswersList answs={Answers} />}
							wide='very'
							on={['click']}
							/>
					</React.Fragment>
			}
	},
];

function owner() { return {uid:app.user.uid,name:app.user.name} }

export default class instcons_global_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get LIST_DET_FIELDS() { return _LIST_DET_FIELDS; }

	// region: deprecated, use equivalent methods from waiting_room_model
 	static watch_approvals(onData, onError) {
		return this.watch_all_records(onData, {where_key: 'wr', where_val: 'app_scripts', ce: onError});
	}

	static watch_urgent_fu(onData, onError) {
		return this.watch_all_records(onData, {where_key: 'wr', where_val: 'urgent_fu', ce: onError });
	}

	static watch_need_review(onData, onError) {
		return this.watch_all_records(onData, {where_key: 'wr', where_val: 'need_review', ce: onError });
	}

	static watch_escript_review(onData, onError) {
		return this.watch_all_records(onData, {where_key: 'wr', where_val: 'escript_review', ce: onError });
	}

	static awaiting_gp_consult(onData, onError) {
		return this.watch_all_records(
			records => onData(sort_med_scripts_for_wr(records).map(withUr)),
            {where_key: 'wr', where_val: 'await_consult', ce: onError }
        );
	}

	static awaiting_urgent_fu(onData, onError) {
		return this.watch_all_records(
			records => onData(sort_med_scripts_for_wr(records).map(withUr)),
            {where_key: 'wr', where_val: 'urgent_fu', ce: onError }
        );
	}
	// endregion

	static async own(sid, own = true, extra) {
		try {
			const rev_tm = own ? Date.now() : null;
			const srv_tm = own ? serverTimestamp() : null;
			const getOwner = own ? owner() : null;
			await instcons_global_model.update_record(sid, {
				owner: getOwner,
				rev_tm,
				srv_tm,
				...(extra && {extra})
			});
		} catch (error) {
			console.log("got error",error);
		}
		
	}

	static watch_prescribing_lock(sid, min_call_duration_s, onData) {
		// Watch the phone calls for the current script for the current user (doctor), and ensure there is a
		// call within the last 24 hours that is either complete or in progress, and at least X seconds in duration
		// This allows us to enforce minimum call times before allowing the doctor to prescribe or finalise a consult
		// Admin can also manually unlock prescribing for a particular script, so watch that as well to unlock immediately

		let timer;
		let data = {};

		const callEligibility = (call) => {
			// The call is complete, check the duration. Easy.
			if (
				call.ccd?.data?.status === 'completed'
				&& call.ccd?.data?.duration >= min_call_duration_s
				&& moment().subtract(24, 'hours').isBefore(call.ccd.tm)
			) {
				return {
					call_start: moment(call.ccd.data.startTime),
					call_end: moment(call.ccd.data.endTime),
				}
			}

			// The call is in progress. Check the doctor and patient are on the call (in progress) and figure out
			// when 30 seconds will have passed.
			const participants = participantStatus(call);
			const onCall = Object.values(participants).filter(p => p.status === 'in-progress');
			if (onCall.length > 1) {
				const lastEvent = Math.max(...onCall.map(p => p.tm));
				return {
					call_start: lastEvent,
					// "end" here refers to when the call becomes long enough to allow prescribing.
					// The fact that it's in the future (potentially) tells us that
					call_end: moment(lastEvent).add(min_call_duration_s, 'seconds'),
				};
			}

			return null;
		}

		const update = (changes) => {
			const {calls, unlock} = Object.assign(data, changes);
			clearTimeout(timer);

			if (unlock) {
				// Admin have unlocked this, nothing else to do.
				return onData({
					can_prescribe: true,
					lockout_start: null,
					lockout_end: Date.now(),
				});
			}

			const eligibleCalls = Object.values(calls??{})
				.filter(call => call.by.u === app.user.uid)
				.map(call => callEligibility(call, min_call_duration_s))
				.filter(Boolean)
				.sort((x, y) => y.call_end - x.call_end);

			const eligibleCall = eligibleCalls.at(-1);

			if (eligibleCall) {
				const timeLeft = moment(eligibleCall.call_end).diff(moment(), 'ms');
				if (timeLeft > 0) {
					//  Must be an inflight call, set a time to trigger an update at 30 seconds (or whatever)
					timer = setTimeout(() => update({
						can_prescribe: moment().isAfter(eligibleCall.call_end),
					}), timeLeft);
				}

				// Call is complete (or inflight but an appropriate duration already)
				return onData({
					can_prescribe: moment().isAfter(eligibleCall.call_end),
					lockout_start: null,
					lockout_end: eligibleCall.call_end,
				});
			}

			// No call, no admin unlock. Bit of a hack, but set the start and end times to the min call duration so
			// the count timer renders fixed at the minimum duration.
			return onData({
				can_prescribe: false,
				lockout_start: moment(),
				lockout_end: moment().add(min_call_duration_s, 'seconds'),
			});
		}

		const unsub_lock = instcons_global_model.watch_unlock_override(sid, unlock => update({unlock}));
		const unsub_calls = phonecall_model.watch_record('', calls => update({calls}), {where_key: 'sid', where_val: sid});

		return () => {
			clearTimeout(timer);
			unsub_lock();
			unsub_calls();
		};
	}

	static watch_unlock_override(sid, onData) {
		return this.watch_record(`${sid}/presc_unlock`, onData);
	}

	static create_session({sid, test} = {}) {
		return API_service.load_data('instcons/create_session', {sid, test})
	}


	static async update_status(row, status, p) {
		const { sid } = row
		try {
			const data = {
				pending:true,
				owner:owner(),
				doc: app.user.claims.doc_id
			} 
			await instcons_global_model.update_record(
				sid,
				data,
				{add_hist: true}
			);
			const chgStat = () => API_service.load_data( 'chgStat', {sid, stat: status, doc: app.user.claims.doc_id, ...p} )
			let ret
			try {
				ret = await chgStat()
			} catch (error) {
				DEBUG && console.error(error)
				logger.report_error('ERROR in WR update_status', 'error', error);
				try {
					ret = await chgStat()
				} catch (error) {
					DEBUG && console.error(error)
					logger.report_error('ERROR [TWICE] in WR update_status', 'error', error);
					ret = error
					instcons_global_model.update_record( sid,{
						pending:null,
						owner:null,
						doc:null,
						rev_tm: null
					},{add_hist: true})
				}
			}
			return ret
		   
		} catch (error) {
			console.log("got error updating status",error);
			logger.report_except(error,{sid,status,owner:owner()})
			return {res:'err',error}
		}
		
	}

}