import React, { useEffect, useMemo, useState } from "react";
import {Icon, Message, Progress, Statistic} from "semantic-ui-react";
import { useCurrent } from "xAppLib/Hooks/useCurrent";
import { clamp } from "xAppLib/helpers/clamp";
import {useAsync} from "../../xAppLib/Hooks/useAsync";
import instcons_global_model from "../../models/instcons_global_model";
import moment from "moment";
import {UI_DATETIME_FMT} from "../../helpers/datetime";

export const STATUS_COLORS = {
	poor: 'red',
	fair: 'yellow',
	good: 'green'
};

export function inferQuality(stats) {
	function status(value, low, high, lower_is_better = false) {
		if (lower_is_better) {
			if (value < low) return 2;
			if (value < high) return 1;
			return 0;
		} else {
			if (value > high) return 2;
			if (value > low) return 1;
			return 0;
		}
	}

	const audio_bitrate_status = status(stats.audio.bitrate, 6, 10);
	const audio_loss_status = status(stats.audio.loss, 0.5, 3, true);
	const video_bitrate_status = status(stats.video.bitrate, 200, 400);
	const video_loss_status = status(stats.video.loss, 0.5, 3, true);
	const fps_status = status(stats.video.fps, 12, 22);

	const video_overall = clamp(0, 2, Math.floor((video_bitrate_status + video_loss_status + fps_status) / 3));
	const audio_overall = clamp(0, 2, Math.floor((audio_bitrate_status + audio_loss_status) / 2));

	const STATUS_LABELS = ['poor', 'fair', 'good'];

	return {
		...stats,
		video: {
			...stats.video,
			overall_status: STATUS_LABELS[video_overall],
			bitrate_status: STATUS_LABELS[video_bitrate_status],
			loss_status: STATUS_LABELS[video_loss_status],
			fps_status: STATUS_LABELS[fps_status],
		},
		audio: {
			...stats.audio,
			overall_status: STATUS_LABELS[audio_overall],
			bitrate_status: STATUS_LABELS[audio_bitrate_status],
			loss_status: STATUS_LABELS[audio_loss_status],
		}
	}
}

export function makeStats(previous, current) {
	const dir = 'bytesReceived' in previous.video ? 'rx' : 'tx';

	function xfer(source, measure) {
		return source[`${measure}${dir === 'rx' ? 'Received' : 'Sent'}`];
	}

	function kpbs(which = 'video') {
		const msIncreased = current.timestamp - previous.timestamp;
		const secondsElapsed = msIncreased / 1000;
		const bytesIncreased = xfer(current[which], 'bytes') - xfer(previous[which], 'bytes');
		const bitsIncreased = bytesIncreased * 8;
		const kilobitsIncreased = bitsIncreased / 1000;
		return kilobitsIncreased / secondsElapsed;
	}

	function packetLoss(which = 'video') {
		return current[which].packetsLost / xfer(current[which], 'packets') * 100;
	}


	function ensureNumber(val) {
		return isFinite(val) ? Math.floor(val) : 0;
	}

	const audio_bitrate = clamp(0, 50, ensureNumber(kpbs('audio')));
	const audio_loss = clamp(0, 100, ensureNumber(packetLoss('audio')));

	const video_bitrate = clamp(0, 5000, ensureNumber(kpbs('video')));
	const video_loss = clamp(0, 100, ensureNumber(packetLoss('video')));
	const fps = clamp(0, 60, ensureNumber(current.video.frameRate));


	return {
		raw: current,
		video: {
			bitrate: video_bitrate,
			loss: video_loss,
			fps,
		},
		audio: {
			bitrate: audio_bitrate,
			loss: audio_loss,
		}
	}
}

function calculate_test_duration(list) {
	if (list.length < 2) return 0;
	const first = list[0];
	const last = list[list.length - 1];
	return last.timestamp - first.timestamp;
}

export function LoadNetworkTestResults({ sid }) {
	const record = useAsync(async () => await instcons_global_model.get_record(sid));

	if (record.loading) {
		return <Icon loading name='spinner' size='small' />;
	}
	if (record.error) {
		return <><Icon name='exclamation triangle' color='red' size='small' /> {record.error.message ?? record.error.err ?? String(record.error)}</>;
	}

	const stats = record.data?.form_data?.vid_call_network_test;

	if (!stats) {
		return <>No stats</>;
	}

	return <NetworkTestResults stats={stats} />;
}

export function NetworkTestResults({stats}) {
	return <>
		{stats.ts ? <small>Tested at {moment(stats.ts).format(UI_DATETIME_FMT)}</small> : null}
		<div className="mt-4"><Statistic.Group size="mini" widths={4}>
			<Statistic color={STATUS_COLORS[stats.video.overall_status]}>
				<Statistic.Value>{stats.video.overall_status}</Statistic.Value>
				<Statistic.Label>Video</Statistic.Label>
			</Statistic>
			<Statistic color={STATUS_COLORS[stats.video.bitrate_status]}>
				<Statistic.Value>{stats.video.bitrate}</Statistic.Value>
				<Statistic.Label>Speed</Statistic.Label>
			</Statistic>
			<Statistic color={STATUS_COLORS[stats.video.loss_status]}>
				<Statistic.Value>{stats.video.loss}%</Statistic.Value>
				<Statistic.Label>Loss</Statistic.Label>
			</Statistic>
			<Statistic color={STATUS_COLORS[stats.video.fps_status]}>
				<Statistic.Value>{stats.video.fps}</Statistic.Value>
				<Statistic.Label>FPS</Statistic.Label>
			</Statistic>
		</Statistic.Group></div>
		<div className="mt-4"><Statistic.Group size="mini" widths={4}>
			<Statistic color={STATUS_COLORS[stats.audio.overall_status]}>
				<Statistic.Value>{stats.audio.overall_status}</Statistic.Value>
				<Statistic.Label>Audio</Statistic.Label>
			</Statistic>
			<Statistic color={STATUS_COLORS[stats.audio.bitrate_status]}>
				<Statistic.Value>{stats.audio.bitrate}</Statistic.Value>
				<Statistic.Label>Speed</Statistic.Label>
			</Statistic>
			<Statistic color={STATUS_COLORS[stats.audio.loss_status]}>
				<Statistic.Value>{stats.audio.loss}%</Statistic.Value>
				<Statistic.Label>Loss</Statistic.Label>
			</Statistic>
		</Statistic.Group></div>
	</>
}

export function NetworkTest({session, streams, publisher, subscribers, onStatsUpdated, as: StatsComponent = DefaultStatsRenderer}) {
	const [statsList, setStatsList] = useState([]);
	const [error, setError] = useState(null);
	const update = useCurrent(onStatsUpdated);

	const subscription = subscribers?.find(s => s.streamId === publisher?.streamId);
	useEffect(() => {
		if (!subscription) return;

		const id = setInterval(() => {
			subscription.getStats((err, stats) => {
				setError(err);
				if (!stats) return;

				setStatsList(prev => prev.concat(stats));
			});
		}, 500);

		return () => clearInterval(id);
	}, [subscription]);

	const stats = useMemo(() => {
		// however many stats are returned, the first and last are used for comparison (so more samples
		// will give a smoother result eventually, but take more time to accumulate)

		if (statsList.length < 2) return;

		const recent = statsList.slice(-4); // arbitrary, at 500ms interval, this is 2 seconds
		const [previous, current] = [recent[0], recent[recent.length - 1]];
		if (!(previous?.video && previous?.audio && current?.video && current?.audio)) return null;

		return inferQuality(makeStats(previous, current));
	}, [statsList]);

	const duration = calculate_test_duration(statsList);
	const TEST_DURATION = 10000; // 10 seconds
	const percent = clamp(0, 100, (duration / TEST_DURATION) * 100);
	const complete = duration >= TEST_DURATION;
	const video = stats?.video?.overall_status;


	useEffect(() => {
        stats && update.current?.({ ...stats, complete })
    }, [stats, complete]);

	if (error) {
		return (
			<Message error>
				<Message.Header>Network Test Error</Message.Header>
				<Message.Content>{error.message || String(error)}</Message.Content>
			</Message>
		);
	}

	return <StatsComponent {...{stats, complete, video, percent, subscription}}/>
}

function DefaultStatsRenderer({stats, complete, video, percent, subscription}) {
	return (
		<Message>
			<Message.Header>Connection Quality</Message.Header>
			<Message.Content>
				{stats && <NetworkTestResults stats={stats}/>}

			</Message.Content>
			<Message.Content className="pt-4">
				<Progress
					color={complete ? STATUS_COLORS[video] : undefined}
					active={!complete}
					indicating={!complete}
					percent={percent}
					style={{marginTop: 0}}
				>
					{
						complete ? "Complete" :
							stats ? "Running" :
								subscription ? "Waiting for stats..." :
									"Waiting for video..."}
				</Progress>
			</Message.Content>
		</Message>
	);
}