import React, {useEffect, useCallback, useState, useMemo, useRef} from 'react';
import {createPortal} from 'react-dom'
import moment from "moment";
import {Button, Form, Icon, Message} from 'semantic-ui-react';
import {XIcon, ArrowsExpandIcon, ArrowDownIcon} from '@heroicons/react/solid'
import instcons_model from 'models/instcons_model'
import {useNurseDeviceOnline} from "./Hooks";
import {useStaleTime} from "./hooks/useStaleTime";
import UserConsult from './UserConsult';
import {cls} from "views/NUI/utils";
import {SkypeButtonsStandalone} from "../med/Sections/SkypeButtons";
import logger from "../../xAppLib/libs/logger";
import firebase_database from 'xAppLib/providers/firebase_database.js';
import {onDisconnect, update, serverTimestamp} from 'firebase/database';
import {Tel} from "../UIelems/Tel";
import obj_filter_by_key from "../../xAppLib/helpers/obj_filter_by_key";
import {from_melb_ui_tm} from "../../helpers/datetime";
import {group_similar_requests} from "./util";
import AppendIf from "../../xAppLib/UIelems/AppendIf";
import Age from '../patients/Age';

const DEBUG = true

const Portal = ({children,id}) => {
	const target = useRef(null)
	if(!target.current) {
		target.current = document.getElementById(id)
		if (!target.current) {
			target.current = document.createElement('div')
			document.body.append(target.current);
			target.current.setAttribute("id",id)
			target.current.style.position='relative'
			// portalTarget.style.zIndex=10000
		}

	}
	return createPortal(children, target.current);
}

const CollapseIcon = () => (<>
	<span className="sr-only">Minimise</span>
	<ArrowDownIcon className="h-5 w-5 rotate-45" aria-hidden="true"/>
</>);
const ExpandIcon = () => (<>
	<span className="sr-only">Expand</span>
	<ArrowsExpandIcon className="h-5 w-5" aria-hidden="true"/>
</>);
const CloseIcon = () => (<>
	<span className="sr-only">Dismiss</span>
	<XIcon className="h-5 w-5" aria-hidden="true"/>
</>);

function DelayRender({ display_tm, delay_ms, min_delay_ms, onDisplayed, children }) {
	const stale = useStaleTime({
		delay_ms,
		min_delay_ms,
		stale_time: display_tm,
		onStale: onDisplayed,
	});

	return stale ? <>{children}</> : null;
}

const AwaitingConsult = ({row,id,onRemove, switcher = null}) => {
	const [focus,setFocus] = useState(false);
	const [peers, setPeers] = useState([]);
	const [offerSkype, setOfferSkype] = useState(false);
	const final = instcons_model.is_final(row);
	const invalidSession = String(row.sessionId).indexOf('error') === 0;

	const onSkypeCalled = useCallback(({k, name, skype}) => {
		logger.usg_log('AwaitingConsult', 'action', 'SkypedDoctor', {k, name, skype});
		instcons_model.add_record(`${id}/logs`, {label: 'skype_call', k, name, skype, tm: Date.now()});
	}, []);

	const consult = useMemo(() => {
		const onPeersConnected = (peers) => {
			const focused = peers.length > 1
			setFocus(v => {
				if (focused && !v) {
					try {
						const audio = new Audio('https://storage.googleapis.com/instant-med-public/sounds/start-vid.mp3');
						audio.play().catch(() => {
							// ignore
						});
					} catch (error) {
						// do nothing
					}
					return true;
				}
				return v;
			});
			setPeers(peers);
		};
		const onMediaAuthChange = (authorised) => {
			if (row.dvc?.dvcid) {
				instcons_model.update_record(`${id}/dvc`, { mediaAuthorised: authorised });
			}
			if (!authorised) {
				setFocus(true);
			}
		};
		return <UserConsult id={id} row={row} onPeersConnected={onPeersConnected} onMediaAuthChange={onMediaAuthChange} showControls={false}/>;
	}, [id, row])
	const drNotInConsult = peers.length < 2;
	const [add_tm] = useState(() => from_melb_ui_tm(row.add_tm));

	const offerDrSkypeFallback = row.status === 'instcons_taken' && row.doc?.doc_id;
	const canOfferSkype = !offerDrSkypeFallback && ( // if we're offering the consulting dr skype, don't show other doctors' skype
		row.status === 'instcons_await'
	);
	const minimiseVideo = !focus || (offerSkype && canOfferSkype);

	return (
		<NurseInstConsOverlay
			record={row}
			focus={focus}
			heading={<>{!row.doc && 'Awaiting'} Consultation {row.doc && <>with {row.doc.nm}</>}</>}
			closeIcon={final ? <CloseIcon/> : focus ? <CollapseIcon/> : <ExpandIcon/>}
			footer={<>
				{focus && switcher}
				{DEBUG && <small className='text-gray-500'> [{id}]</small>}
			</>}
			onCloseClick={() => {
				if (final) {
					onRemove?.(row)
				}
				setFocus(f => !f)
			}}
		>
			{focus && row.status === 'instcons_await' && !offerSkype && (
				<Message icon>
					<Icon name="call"/>
					<Message.Content>
						<Message.Header>Your call is in the queue</Message.Header>
						A doctor will be with you as soon as they're available.<br/>
						If you need to submit another treatment for this patient, click the {' '}
						<a href="#" className="inline-flex align-middle text-blue-500" onClick={e => {
							e.preventDefault();
							setFocus(false);
						}}><CollapseIcon/></a> button then complete a new treatment request.
					</Message.Content>
				</Message>
			)}
			<div className={cls('mt-2 flex', focus ? 'flex-col' : 'flex-row')}>
				{app.settings.is_COSM_WEBRTC_ENABLED && canOfferSkype && (invalidSession || app.site_status.instcons_skype_fallback_delay > 0) && (
					<DelayRender display_tm={add_tm}
								 delay_ms={app.site_status.instcons_skype_fallback_delay * 1000}
								 min_delay_ms={1000}
								 onDisplayed={() => {
									 if (!offerSkype) {
										 // only bring into focus the first time, otherwise leave minimised
										 setFocus(true);
									 }
									 setOfferSkype(true);
								 }}
					>
						<Message className={focus && offerSkype ? null : 'hidden'}>
							{invalidSession
								? 'There was an error creating the video consult.'
								: 'It is taking longer than expected for a doctor to connect.'}
							<br />
							You can try calling doctors by Skype instead.
							<SkypeButtonsStandalone script_type={'cosm'} show={focus}
													preferred_doc_id={row.doc?.doc_id}
													onDoctorSelected={onSkypeCalled}
													heading={<>
														Doctors are listed in order of Skype Priority.<br/>
														Please Skype the doctor at the top of the list first.
													</>}
							/>
						</Message>
					</DelayRender>
				)}
				{
					(row.status === 'instcons_rejected' || row.status === 'instcons_approved') && <DocDecision row={row} />
					|| app.settings.is_COSM_WEBRTC_ENABLED && !invalidSession && (
						<div className={cls(minimiseVideo ? 'w-1/3' : 'w-full')}>
							{consult}
						</div>
					)
				}
				{app.settings.is_COSM_WEBRTC_ENABLED && offerDrSkypeFallback && app.site_status.instcons_dr_skype_fallback_delay > 0 && (
					<DelayRender delay_ms={app.site_status.instcons_dr_skype_fallback_delay * 1000}>
						<SkypeButtonsStandalone script_type={'cosm'}
												preferred_doc_id={row.doc?.doc_id}
												only_preferred_doc
												show={focus}
												heading={`If you're having trouble with video, you can try Skype:`}
												onDoctorSelected={onSkypeCalled}
						/>
					</DelayRender>
				)}
				{!app.settings.is_COSM_WEBRTC_ENABLED && !final && (
					<Message className={focus ? null : 'hidden'}>
						<SkypeButtonsStandalone script_type={'cosm'}
												preferred_doc_id={row.doc?.doc_id}
												only_preferred_doc={!!row.doc?.doc_id}
												onDoctorSelected={onSkypeCalled}
						/>
					</Message>
				)}


			</div>
			{row.status == 'instcons_await' && !focus &&
				<div className='text-sm'>
					{app.settings.is_COSM_WEBRTC_ENABLED
						? <>
							Doctor will answer shortly. If you're waiting for longer than 5 minutes, please
							call Customer Support on <Tel value={app.site_status.cosm_supp_ph}/>
						</>
						: !app.settings.is_IN_APP_VIDEO_SUPPORTED && (<>
						Your device does not support in-app video, please use Skype instead.
					</>)
					}
					<br/>
					<CancelConsult record={row}
								   label="Cancel consult"
								   message="Are you sure you want to cancel this consultation?"
								   onRemove={onRemove}
					/>
					{(!app.settings.is_COSM_WEBRTC_ENABLED || offerSkype) && (
						<Button size="mini" color="blue" compact onClick={() => setFocus(true)}>
							Call with Skype
						</Button>
					)}

				</div>
			}

			{row.status == 'instcons_cancel' && <div className='text-sm'> Consultation is cancelled </div>}
		</NurseInstConsOverlay>
	)
}

const AwaitingReview = ({row, id, onRemove, switcher = null}) => {
	const [focus, setFocus] = useState(true);
	const final = instcons_model.is_final(row);
	const heading = row.doc ? `Being reviewed by ${row.doc?.nm || 'the doctor'}` : 'Awaiting Review';

	return (
		<NurseInstConsOverlay
			focus={focus}
			record={row}
			heading={heading}
			closeIcon={final ? <CloseIcon/> : focus ? <CollapseIcon/> : <ExpandIcon/>}
			footer={<>
				{focus && switcher}
				{DEBUG && <small className='text-gray-500'> [{id}]</small>}
			</>}
			onCloseClick={() => {
				if (final) {
					onRemove?.(row)
				}
				setFocus(f => !f)
			}}
		>
			{row.status === 'instcons_await' && (focus ? (
				<Message icon>
					<Icon name="wait"/>
					<Message.Content>
						<Message.Header>Your treatment is awaiting review</Message.Header>
						A doctor will review this treatment shortly.<br/>
						If you need to submit another treatment for this patient, click the {' '}
						<a href="#" className="inline-flex align-middle text-blue-500" onClick={e => {
							e.preventDefault();
							setFocus(false);
						}}><CollapseIcon/></a> button then complete a new treatment request.
					</Message.Content>
				</Message>
			) : (
				<div className='text-sm'>
					Doctor will review shortly. If you're waiting for longer than 5 minutes, please
					call Customer Support on <Tel value={app.site_status.cosm_supp_ph}/>
					<br />
					<CancelConsult record={row}
								   label="Cancel review"
								   message="Are you sure you want to cancel this review?"
								   onRemove={onRemove}
					/>
				</div>
			))}
			{row.status === 'instcons_cancel' && (
				<div className='text-sm'> Review is cancelled</div>
			)}
			{row.status === 'instcons_taken' && focus && (
				<Message icon>
					<Icon name="user doctor"/>
					<Message.Content>
						<Message.Header>Your treatment is being reviewed</Message.Header>
						{row.doc?.nm} is currently reviewing your treatment request.<br/>
						If they require more information they will begin a video session with you.
					</Message.Content>
				</Message>
			)}
            <DocDecision row={row} />
		</NurseInstConsOverlay>
	);
}

const AwaitingConsults = () => {
	const [selectedCall, setSelectedCall] = useState(null)
	const [pending, setPending] = useState([]);
	useEffect(() => {
		function onRecords(recs) {
			setPending(prev => prev.concat(recs.filter(newMaybe => !prev.includes(newMaybe))));
			setSelectedCall(null);
		}
		instcons_model.get_pending_calls().then(onRecords);
		app.on(app.events.INSTCONS_WR_PENDING, onRecords, []);
		return () => {
			app.off(app.events.INSTCONS_WR_PENDING);
		};
	}, []);

	const [recs,setRects] = useState({})
	const consults = useMemo(() => {
		const withKey = Object.keys(recs).map(key => ({ ...recs[key], key }));
		return group_similar_requests(withKey);
	}, [recs]);

	const onRecord = useCallback((key,rec)=>{
		setRects(recs=>({...recs,[key]:rec}))
		setSelectedCall(a=> !a && instcons_model.is_pending(rec) ? key : a);
	},[])

	useEffect(()=>{
		const unwatchers = pending.map(key=> instcons_model.watch_record(key,(rec)=>onRecord(key,rec)));
		return () => {
			unwatchers?.map(unwatch=>unwatch())
		};
	}, [pending]);

	const total = consults.length
	if (total === 0)
		return null

	const row = (
		consults.find(r => r.key === selectedCall)
		|| consults.find(r => instcons_model.is_pending(r))
		|| consults.slice(-1)[0]
	);

	if (!row)
		return null

	const activeCall = row.key;

	const switcher = consults.length > 1
		? (
			<div>
				<Form.Dropdown
					fluid
					selection
					className="my-2"
					label="Current Consult"
					onChange={(e, d) => setSelectedCall(d.value)}
					value={activeCall}
					options={consults.map(rec => {
						const needs_consult = instcons_model.needs_consult(rec);
						const consult = needs_consult ? 'consult' : 'review';
						const dr = rec.doc?.nm || 'doctor';
						const status = instcons_model.is_final(rec)
							? rec.status.split('_')[1]
							: rec.status === 'instcons_await'
								? `awaiting ${consult}`
								: `in ${consult} with ${dr}`;

						return {
							key: rec.key,
							value: rec.key,
							text: <>
								{rec.med?.name || rec.med_db_data?.name} {rec.others?.length > 0 && `(+${rec.others.length} more)`} for{' '}
								{rec.spd_data?.first_name} {rec.spd_data?.last_name}{' '}
								({status})
							</>
						}
					})}
				/>
			</div>
		)
		: null;

	const handleRemove = (row) => {
		setSelectedCall(null);
		const to_remove  = [row?.key, ...((row?.others?.flatMap(other => other.key))||[])];
		const still_pending = Object.keys(recs).filter(k => !to_remove.includes(k) && instcons_model.is_pending(recs[k]));
		setPending(prev => prev.filter(key => still_pending.includes(key)));
		setRects(prev => obj_filter_by_key(prev, k => still_pending.includes(k)));
	};

	return instcons_model.needs_consult(row)
		? (
			<AwaitingConsult
				key={activeCall}
				row={{...row}}
				id={activeCall}
				onRemove={handleRemove}
				switcher={switcher}
			/>
		) : (
			<AwaitingReview
				key={activeCall}
				row={{...row}}
				id={activeCall}
				onRemove={handleRemove}
				switcher={switcher}
			/>
		);
}

function displayTreatment(row) {
	const details = row.script_type!='doccons' ? ` (${row.cosm_det?.loc} ${row.cosm_det?.qua})` : ''
	return `${row.med?.name || row.med_db_data?.name} ${row.med?.size || row.med_db_data?.size ||''}${details}`.trim();
}

const CancelConsult = ({record, label, message, onRemove}) => {
	return (
		<Button size="mini" color="red" compact content={label}
				onClick={_ => {
					if (window.confirm(message)) {
						Promise.all([record, ...(record.others || [])].map(
							rec => instcons_model.update_status(rec.key, rec.sid, "instcons_cancel")
						)).then(() => onRemove(record));
					}
				}}/>

	)
}

const NurseInstConsOverlay = ({focus = true, record, heading, footer, onCloseClick, closeIcon, children}) => {
	const color = 'text-green-800';

	const {key: id} = record;

	useNurseDeviceOnline(id, record);

	return (
		<Portal id='consults'>
			<div
				className={cls('fixed z-100', focus ? 'left-1/2 top-32 -translate-x-1/2 w-5/6 max-w-xl' : 'left-0 bottom-0 w-80')}
				style={{zIndex: 1000}}>
				<div className={cls("bg-green-50 px-4 py-2 shadow-lg border-green-400 ", color, {
					'rounded-tr-lg': !focus,
					'rounded-xl': focus,
					'border-2': focus,
					'border-r-2 border-t-2': !focus
				})}>
					<div className="flex">
						<div>
							<strong className={cls("text-base font-bold", color)}>
								{heading}
							</strong>
						</div>
						<div className="ml-auto pl-3">
							<div className="-mx-1.5 -my-1.5">
								<button
									type="button"
									onClick={onCloseClick}
									className={cls("inline-flex rounded-md p-1.5  focus:outline-none focus:ring-2 focus:ring-offset-2", color)}
								>
									{closeIcon}
								</button>
							</div>
						</div>
					</div>
					<div className={cls(focus ? 'w-full' : 'w-2/3 pr-2')}>
						<p className={cls(focus ? 'text-base' : 'text-sm')}>
							For: {[record, ...record.others || []].map(displayTreatment).join(', ')}<br/>
							With: {record.spd_data?.first_name} {record.spd_data?.last_name} <Age dob={record.spd_data?.dob} /><br/>
							<em>{record.cosm_det.note}</em>
						</p>

					</div>
					{children}
					{footer}
				</div>
			</div>
		</Portal>
	)
}

const DocDecision = ({row}) => {
    if (row.status === 'instcons_rejected') {
        return (
            <div className="my-4">
                <div className="text-center text-2xl">
                    <Icon size='large' name='close' color='red'/> Rejected
                </div>
                <AppendIf cont={row.doc?.msg} beforeContent={<strong className="block">Doctor Note:</strong>}/>
            </div>
        );
    }

    if (row.status === 'instcons_approved') {
        return (
            <div className="my-4">
                <div className="text-center text-2xl">
                    <Icon size='large' name='check' color='green'/> Approved
                </div>
            </div>
        );
    }

    return null;
}

export default AwaitingConsults;