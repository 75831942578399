
import React from 'react';
import { Button, Header } from 'semantic-ui-react';
import { cls } from '../../views/NUI/utils';
import SIcon from '../../views/NUI/StreamlineIcons/SIcon';
import { useUser } from '../Hooks';

const LoginBanner = ({clsn, type='banner'}) => {
    const { user_in } = useUser();

    if (user_in) return null;

    const user_acc = (path) => {
       if(app.runtime?.login_type=='popup') {
            app.trigger(app.events[`REQUEST_${path == '/login/' ? 'LOGIN' : 'JOIN'}`])
        } else {
            app.goto_after_login = app.history.location.pathname
            app.history.push(path)
        }
    }

    return type == 'btn' ?
            <div className="flex justify-center gap-4">
                <Button basic className="!w-[180px]" onClick={() => app.trigger(app.events['REQUEST_LOGIN'])}>Yes</Button>
                <Button basic className="!w-[180px]" onClick={() => app.trigger(app.events['REQUEST_JOIN'])}>No</Button>
            </div>
            :
            <div className={cls(clsn, 'login-banner justify-between items-center border rounded-md p-4 m-4 md:mx-auto flex flex-wrap bg-is-gray-10 max-w-[700px]')}>
                <span className="flex items-center gap-4 mb-3">
                    <SIcon name="profile-circle" color="#000" bold cls="h-[40px] w-[40px]"/>
                    <p className="font-bold">Log in or create your account to begin your consultation</p>
                </span>
                <span className="flex no-wrap gap-1 md:gap-2 w-full md:w-auto">
                    <Button className="w-full md:w-fit" onClick={() => user_acc('/signup/')} basic>Create an account</Button>
                    <Button className="w-full md:w-fit" onClick={() => user_acc('/login/')} color="black">Login</Button>
                </span>
            </div>;
}

export default LoginBanner;

