
import { omit } from "@/helpers/json_functions";
import { byChoiceOrder } from "@/views/admin/med/AdminMedQ.Config";
import StyledDrawer from "@/views/NUI/StyledDrawer";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";

export function ChoiceFieldGuardAction({action}) {
	const [selection, setSelection] = useState(action.config.value ?? {});
	const canSave = Object.keys(selection).length > 0;

	return (
		<StyledDrawer
			isOpen={action.active}
			dismissible
			title={action.config.choice_prompt}
			description={action.config.choice_desc}
			onClose={() => action.reject(false)}
			content={(
				<div className="flex flex-col space-y-2 [&>button]:text-left">
					{Object.entries(action.config.choices).sort(byChoiceOrder).map(([id, choice]) => (
						<Button key={id}
								type="button"
								primary={id in selection}
								basic={!(id in selection)}
								onClick={() => {
									setSelection(prev => (id in prev)
										? omit(prev, [id])
										: {...prev, [id]: choice.text},
									);
								}}
						>
							{choice.text}
						</Button>
					))}
				</div>
			)}
			footer={(
				<div className="flex flex-row space-x-3 items-stretch">
					<Button type="button" fluid secondary onClick={() => action.reject(false)}>
						Cancel
					</Button>

					<Button type="button" fluid primary disabled={!canSave} onClick={() => action.resolve(selection)}>
						Save
					</Button>
				</div>
			)}
		/>
	)
}
