import React, { useState, useCallback } from 'react'
import {Button, Checkbox, Form, Loader} from 'semantic-ui-react'
import { SocialIcon } from '../SocialIcon'
import { Divider } from '../Divider'
import { TextField } from '../Form/TextField'
import { NotificationBar } from '../notificationBar/NotificationBar'
import user_model from 'models/user_model'
import SIcon from 'views/NUI/StreamlineIcons/SIcon'
import { validateAuthFields } from 'helpers/signupValidation'
import {getWindowDimensions} from "@/xAppLib/Hooks/useViewport";

/**
 * @param {Object} props
 * @param {(providerInfo: {provider: string, email?: string}) => void} props.onSelectProvider - Called when user selects an auth provider
 * @param {() => void} props.onAlternativeLinkClick - Called when user clicks login or create account link
 * @param {'login' | 'signup'} [props.variant] - Which variant to show
 */

export const AuthenticationOptions = ({
    onSelectProvider,
    onAlternativeLinkClick,
    setSignUpState,
    variant,
    loading,
    error
}) => {
    const isLogin = variant === 'login';

    return (
        <div className="flex flex-col gap-4 md:max-w-[380px]">
            <div className={`${isLogin ? 'order-3' : 'order-1'}`}>
                <SocialButtons
                    onSelectProvider={onSelectProvider}
                />
            </div>

            <div className={`${isLogin ? 'order-2' : 'order-2'}`}>
                <Divider text="text-is2-gray-body" color="gray" clsn="!my-4 lg:!my-9">or</Divider>
            </div>

            {error && (
                <div className="mb-2 order-0">
                    <NotificationBar type="negative" text={error} className={"text-center"} />
                </div>
            )}

            {loading ? (
                <div className="order-0">
                    <Loader inline='centered' active={loading} size="large" />
                </div>
            ) : (
                <>
                    <div className={`${isLogin ? 'order-1' : 'order-3'}`}>
                        <EmailPasswordForm
                            onSelectProvider={onSelectProvider}
                            variant={variant}
                            setSignUpState={setSignUpState}
                        />
                    </div>
                    <div className={`order-4 mt-2`}>
                        {onAlternativeLinkClick && (
                            <AlternativeLink
                                variant={variant}
                                onClick={onAlternativeLinkClick}
                            />
                        )}
                    </div>

                </>
            )}
        </div>
    )
}

const EmailPasswordForm = ({ onSelectProvider, variant, setSignUpState }) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    // Small screen devices should default to "remember me" checked as more likely to be a personal device
    // and less likely to be a shared/ public device
    const [remember, setRemember] = useState(app.settings?.is_ionic || getWindowDimensions().width <= 768)
    const [errors, setErrors] = useState({})
    const [showResetForm, setShowResetForm] = useState(false)
    const [resetErrors, setResetErrors] = useState({})
    const [resetEmail, setResetEmail] = useState('')
    const [isResetting, setIsResetting] = useState(false)
    const [resetMessage, setResetMessage] = useState(null)
    const emailFieldRef = useCallback(node => {
        if (node !== null) {
            node.focus();
        }
    }, []);

    const validateEmail = useCallback((emailToValidate = email, setErrorFn = (msg) => setErrors({ email: msg }), clearErrorFn = () => setErrors({})) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailToValidate || !emailRegex.test(emailToValidate)) {
            setErrorFn('Please enter a valid email address')
            return false
        }
        clearErrorFn()
        return true
    }, [email])

    const handleToggleSignupForm = () => {
        setSignUpState({
            stage: 'signupform',
            providerData: null,
            signupResult: null
        })
    };

    const handleLogin = useCallback(() => {
        if (validateEmail() && password) {
            onSelectProvider({ provider: 'email', email, password, remember })
        } else {
            setErrors(prev => ({
                ...prev,
                password: password ? undefined : 'Password is required'
            }))
        }
    }, [validateEmail, email, password, onSelectProvider, remember])

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (variant === 'login') {
            handleLogin();
        }
    }

    const handleResetPassword = async (e) => {
        e && e.preventDefault();

        if (!validateEmail(resetEmail, 
            (msg) => setResetErrors({ email: msg }), 
            () => setResetErrors({})
        )) {
            return;
        }

        setIsResetting(true);
        setResetMessage(null);

        setTimeout(async () => {
            try {
                const result = await user_model.frb_reset(resetEmail);
                if (result instanceof Error) {
                    setResetMessage({
                        type: 'warning',
                        text: 'Unable to process request. Please try again later.'
                    });
                } else {
                    setResetMessage({
                        type: 'success',
                        text: 'If an account exists with this email, you will receive password reset instructions.'
                    });
                    setTimeout(() => {
                        setShowResetForm(false);
                        setResetMessage(null);
                    }, 3000);
                }
            } finally {
                setIsResetting(false);
            }
        }, 750);
    }

    return (
        <>
            {variant === 'login' && (
                <Form onSubmit={handleFormSubmit} className="flex flex-col gap-2">
                    {showResetForm ? (
                        <div className="flex flex-col gap-2">
                            {resetMessage && (
                                <NotificationBar
                                    type={resetMessage.type}
                                    text={resetMessage.text}
                                    className="text-center"
                                />
                            )}
                            <TextField
                                label="Email"
                                id="resetEmail"
                                type="email"
                                placeholder="Enter your email"
                                aria-label="Reset Password Email"
                                fluid={true}
                                value={resetEmail}
                                onChange={e => setResetEmail(e.target.value)}
                                error={resetErrors.email}
                            />
                            <Button
                                primary
                                fluid
                                onClick={handleResetPassword}
                                loading={isResetting}
                                disabled={isResetting}
                            >
                                Reset Password
                            </Button>
                            <Button
                                basic
                                fluid
                                onClick={() => {
                                    setShowResetForm(false);
                                    setResetErrors({});
                                    setResetMessage(null);
                                }}
                            >
                                Back to Login
                            </Button>
                        </div>
                    ) : (
                        <>
                            <TextField
                                label="Email"
                                id="email"
                                type="email"
                                placeholder="Email"
                                aria-label="Email"
                                fluid={true}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                error={errors.email}
                                inputRef={emailFieldRef}
                                autoFocus={true}
                            />
                            <TextField
                                label="Password"
                                id="password"
                                type="password"
                                placeholder="Enter password"
                                aria-label="Password"
                                fluid={true}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                error={errors.password}
                            />
                            <Checkbox name={'remember-me'} label="Stay signed in" checked={remember} onChange={() => setRemember(!remember)} />
                            <div className="flex flex-col gap-4">
                                <Button
                                    basic
                                    type="submit"
                                    onClick={handleLogin}
                                >
                                    Log in
                                </Button>
                                <button
                                    type="button"
                                    className="text-center text-is2-blue-500 text-sm hover:underline"
                                    onClick={() => setShowResetForm(true)}
                                >
                                    Forgot your password?
                                </button>
                            </div>
                        </>
                    )}
                </Form>
            )}
            {variant === 'signup' && (
                <Form onSubmit={handleFormSubmit} className="flex flex-col gap-2">
                    <div className="w-full">
                        <Button
                            basic
                            type="submit"
                            className="w-full"
                            onClick={handleToggleSignupForm}
                        >
                            Continue with email
                        </Button>
                    </div>
                </Form>
            )}
        </>
    )
}

function SocialButtons({ onSelectProvider }) {
    const [showMore, setShowMore] = useState(false)

    return (
        <div className="flex flex-col gap-4">
            <Button
                basic
                className='!flex !justify-center !items-center gap-3 w-full'
                onClick={(e) => { e?.preventDefault(); onSelectProvider({ provider: 'google' }) }}
            >
                <SocialIcon type="google" />
                <span className="w-[180px] text-left">Continue with Google</span>
            </Button>

            {!showMore ? (
                <Button
                    basic
                    className='!flex !justify-center !items-center gap-3 w-full'
                    onClick={() => setShowMore(true)}
                >
                    <span className="text-left">More:</span>
                    <SocialIcon type="apple" />
                    <SocialIcon type="facebook" />
                    <SocialIcon type="onepass" />
                </Button>
            ) : (
                <>
                    <Button
                        basic
                        className='!flex !justify-center !items-center gap-3 w-full'
                        onClick={(e) => { e?.preventDefault(); onSelectProvider({ provider: 'apple' }) }}
                    >
                        <SocialIcon type="apple" />
                        <span className="w-[190px] text-left">Continue with Apple</span>
                    </Button>
                    <Button
                        basic
                        className='!flex !justify-center !items-center gap-3 w-full'
                        onClick={(e) => { e?.preventDefault(); onSelectProvider({ provider: 'facebook' }) }}
                    >
                        <SocialIcon type="facebook" />
                        <span className="w-[190px] text-left">Continue with Facebook</span>
                    </Button>
                    <div className="flex flex-col gap-3">
                        <Button
                            basic
                            className='!flex !justify-center !items-center gap-3 w-full'
                            onClick={(e) => { e?.preventDefault(); onSelectProvider({ provider: 'onepass' }) }}
                        >
                            <SocialIcon type="onepass" />
                            <span className="w-[190px] text-left">Continue with OnePass</span>
                        </Button>
                        <button
                            type="button"
                            className="flex items-center justify-center gap-1 text-is2-gray-150 hover:text-is2-gray-body mt-2"
                            onClick={() => setShowMore(false)}
                        >
                            <span>View Less</span>
                            <SIcon name="chevron" size="xs" svg_cls='rotate-180 ml-1' color="#000" bold />
                        </button>
                    </div>
                </>
            )}
        </div>
    )
}

const AlternativeLink = ({ variant, onClick }) => {
    if (variant === 'signup') {
        return (
            <p className="flex flex-wrap justify-center gap-2 mt-9 text-center text-is2-gray-150">
                <span>Have an account?</span>
                <span>
                    <a
                        className='text-is2-blue no-underline cursor-pointer hover:text-is2-blue-hover'
                        onClick={onClick}
                    >
                        Log in
                    </a>.
                </span>
            </p>
        )
    }

    if (variant === 'login') {
        return (
            <p className="flex flex-wrap gap-2 mt-9 justify-center text-center text-is2-gray-150">
                <span>Click here to</span>
                <span>
                    <a
                        className='text-is2-blue cursor-pointer hover:text-is2-blue-hover'
                        onClick={onClick}
                    >
                        create an account
                    </a>.
                </span>
            </p>
        )
    }

    return null
}