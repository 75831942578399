import React, { useState, useEffect, useCallback } from "react";
import { Header, Container, Segment, Icon, Button, List, Form, Input, Label, Checkbox} from "semantic-ui-react";
import ProfileSelect from 'xAppLib/Users/ProfileSelect'
import API_service from "xAppLib/providers/API_service";
import user_model from "models/user_model";
import { useUserPrefs, useProfile, useUser, useToggleState, useUserVerified, useIsMounted } from 'xAppLib/Hooks';
import { v4 as generate_id } from 'uuid';
import moment from "moment";
import gtm from "../providers/gtm";
import TermsAndPrivacy from '../UIelems/TermsAndPrivacy'
import { cls } from "../../views/NUI/utils";
import OnboardingEmail from "@/xAppLib/UIelems/OnboardingEmail";

const DEBUG = false
const PREFIX = 'onb_' // for testing over and over and over... use generate_id()
const PREF_NOPROF = PREFIX+'noprof'
const PREF_HIDE = PREFIX+'hide'
const PREF_HIDE_CC = PREFIX+'cro_cond'
const PREF_HIDE_MH = PREFIX+'medical_history'
const PREF_HIDE_T_AND_C = PREFIX+'terms_and_conditions'


const NewUserIlegAgree = () =>{

	const [profile,profiles] = useProfile()

	const ilegtc = <a className='underline' onClick={_=>app.trigger(app.events.SHOW_POPUP, { pt:"cx_txt",txt:'Terms And Conditions',pn:'terms-and-conditions', target:'ileg' }) } target='_blank'>Terms and Conditions</a>
	const ilegEula = <a className='underline' onClick={_=>app.trigger(app.events.SHOW_POPUP, { pt:"cx_txt",txt:'End User Agreement',pn:'ileg-eula' }) } target='_blank'>End User Agreement</a>

	const agreeILeg = () =>{

		// update the profile
		const updProfile = {...profile}

		updProfile.meta = {...updProfile.meta, ileg_agree: 'yes'}

		user_model.save_prof(updProfile, result =>{
			// update old profile
			app.user.prof.meta = {...result.profile.meta}
		})
	}

	return (
		<Form.Checkbox
			onChange={() => agreeILeg()}
			label={<label style={{marginLeft:-9}}><span className='font-semibold'>I have read and accept the {app.runtime.name} {ilegtc} & {ilegEula}.</span> </label>}
		/>
	)
}

const NewUserCmgAgree = () =>{

	const [profile, profiles] = useProfile()
	const [value, setValue] = useState()

	const cmgAgree = (d) => {

		const updProfile = {...profile}

		updProfile.meta = {...updProfile.meta, cmg_consent: d}

		user_model.save_prof(updProfile, result =>{
			app.user.prof.meta = {...result.profile.meta}
		})
	}

	return (

			<List.Item>
				<List.Icon name={"info circle"} color={"grey"} />
				<List.Content>
				<List.Description>
					
				<label className='w-full font-normal pl-5 sm:pl-0 block mb-4'>Haiku.Health would like to keep you updated on important information relating to alternative treatment options. 
				This communication may, from time to time, include, but is not limited to: clinical & observational trial information, relevant legislative updates, Platform owner and product sponsor information & communication, future Platform promotions and offers.</label>
				<label className='mb-2 w-full font-semibold'>Patient acknowledgement: I understand that if I agree below, InstantScripts will disclose my name and contact details to Haiku.Health and Haiku.Health will contact me from time to time (including for marketing purposes). 
				I understand such communication will be provided by Haiku.Health and not InstantScripts or Partner Doctors.</label>
          
				<div className="flex font-semibold mb-2">
				    
					<Form.Field>
						<Checkbox
						label='I agree'
						name='cmg_agree'
						value='yes'
						checked={value === 'yes'}
						onChange={(e, d) => setValue(d.value)}
						/>
					</Form.Field>
					<Form.Field>
						<Checkbox
						label='I do not agree'
						name='cmg_agree'
						value='no'
						checked={value === 'no'}
						onChange={(e, d) => setValue(d.value)}
						/>
					</Form.Field>
				
				</div>
				<Button content="Confirm" color="blue" onClick={() => cmgAgree(value)} /><br/>
				</List.Description>
				</List.Content>
			</List.Item>
	)
}

const UserTermsAndConditions = ({ onApprove, isApproved }) => {
	const [isChecked, setIsChecked] = useState(false);
	const [isLoading, turnIsLoadingOn, turnIsLoadingOff] = useToggleState();
	const isMounted = useIsMounted();

	const profile = useProfile()[0];

	const updateProfile = () => {
		turnIsLoadingOn();
		// update the profile
		const updProfile = { ...profile };

		updProfile.meta = { ...updProfile.meta, consent: true };

		user_model.save_prof(updProfile, (result) => {
			if (isMounted) {
			turnIsLoadingOff();
			}
			// update old profile
			if (result) {
				app.user.prof.meta = { ...result.profile.meta };
				app.trigger(app.events.CONSENT_UPD);
				onApprove?.();
			}
		});
	};

	return (
		<List.Item className="remove-link-padding">
			{!app.settings.iscr2 && <List.Icon name={isApproved ? 'check' : 'info circle'} color={isApproved ? 'green' : 'grey'} />}
			<List.Content>
				<List.Header>Please accept Terms and Conditions to continue</List.Header>
				{!isApproved && (
					<List.Description>
						<div className="flex flex-col items-start gap-4">
							<Checkbox onChange={() => setIsChecked((prev) => !prev)} className="mt-4" label={<TermsAndPrivacy isShowingSubscriber={false} />} />
							<Button content="Confirm" color={app.settings.iscr2 ? "blue" : "green"} onClick={updateProfile} disabled={!isChecked} loading={isLoading} />
						</div>
					</List.Description>
				)}
			</List.Content>
		</List.Item>
	);
};
 
const AccountOnboarding = () => {
	const [isApprovedTAndC, setIsApprovedTAndC] = useState(false)
	const [error,setError] = useState(false)
	const user = useUser()
	const eml_verif = useUserVerified()
	const [prefs,setUserPref] = useUserPrefs()
	const [profile,profiles] = useProfile()
	const noprofile = !!(prefs[PREF_NOPROF] || app.acl.is_work || app.settings.iprep || app.settings.icann || app.settings.ileg)
	const hasFam = profiles.length > 1
	const hidden = prefs[PREF_HIDE]
	const isShowingTermsAndConditions = isApprovedTAndC || !user.prof?.meta?.consent;
	const isTermsAndConditionsDone = user.prof?.meta?.consent;
	const isShowingOnboardingEmail = !(hidden && user.is_email_verified)

	// confirm the site is ileg and user's ileg_agree is false or undefined
	const need_ilegAgree = app.settings.ileg && user.prof?.meta?.ileg_agree !== 'yes';
	const need_cmgAgree = app.settings.is_cmg && !user.prof?.meta?.cmg_consent;

	const isDone = eml_verif && !need_ilegAgree && !need_cmgAgree && isTermsAndConditionsDone && (hasFam||noprofile);

	const [initHidden,setInitHidden] = useState(eml_verif && !need_ilegAgree && !need_cmgAgree && isTermsAndConditionsDone && (prefs[PREF_HIDE] || hasFam || noprofile))

	DEBUG && console.log("AccountOnboarding()",{initHidden,prefs, hidden,need_ilegAgree});
	
	useEffect(_=>{
		// Note: triggers on user auth
		const noprofile = !!(user.prefs[PREF_NOPROF] || app.acl.is_work || app.settings.iprep || app.settings.icann)
		const hasFam = user?.profs?.length > 1
		DEBUG && console.log("AccountOnboarding.useEffect.setInitHidden", user.user_in, eml_verif && (user.prefs[PREF_HIDE] || hasFam || noprofile || !need_ilegAgree), {eml_verif ,hidden:user.prefs[PREF_HIDE], hasFam, noprofile});
		setInitHidden(user.is_email_verified && !need_ilegAgree && !need_cmgAgree && isTermsAndConditionsDone && (user.prefs[PREF_HIDE] || hasFam || noprofile))
	},[user])

	const doHide = useCallback(() => {
		setUserPref(PREF_HIDE,true)
		setInitHidden(true);
		gtm.log('', 'dismiss_prompt', {prompt: 'onboarding'});
	  },[setUserPref]
	)

	const doNoTermsAndConditions = useCallback(() => {
		setUserPref(PREF_HIDE_T_AND_C, true);
		setIsApprovedTAndC(true);
		gtm.log('', 'dismiss_prompt', {prompt: 'terms_and_conditions'});
	  },[setUserPref]
	)

	if (!app.user.user_in ) {
		DEBUG && console.log("No user");
		return null
	}
	DEBUG && console.log("Has User");
	if (initHidden || (eml_verif && hidden && !need_ilegAgree && !need_cmgAgree && isTermsAndConditionsDone) || app.state.in_sgup_st2 || (eml_verif && app.settings.iscr2)) {
		DEBUG && console.log("Hiding");
		return null
	}

	const days_old = moment().diff(moment(app.user.prof.cre_tm),'days')
	const newUser = days_old < 3

	const username = app.user.shortname;

	return (
		<Container className="onboarding">
			<Segment>
				{eml_verif && !need_ilegAgree && !need_cmgAgree && isTermsAndConditionsDone && <Icon link name='x' size='large' onClick={doHide}/>}

				{newUser && !app.settings.iscr2 && (
					<>
						<Header data-testid='header-welcome'>
							Welcome to {app.runtime.name}, {username}{' '}
						</Header>
						{!isDone && <p>Please complete your account configuration.</p>}
					</>
				)}

				{!newUser && !app.settings.iscr2 && <Header>Welcome back to {app.runtime.name}, {username} </Header>}

				{error && <p className='text red'>{error}</p>}
				<List data-testid='list-profile-checks'>

					{ app.settings.ileg && app.acl.is_leg_firm && !app.acl.is_leg_agree && <NewUserIlegAgree /> }

					{app.settings.is_cmg && need_cmgAgree && <NewUserCmgAgree />}

					{isShowingTermsAndConditions && <UserTermsAndConditions isApproved={user.prof?.meta?.consent} onApprove={doNoTermsAndConditions} />}

					{isShowingOnboardingEmail && <OnboardingEmail newUser={newUser} eml_verif={eml_verif} onError={setError} />}

					{isDone && <>
						<List.Item>
							<List.Icon name={"check"} color={"green"} />
							<List.Content>
								<List.Header>All done 🎉</List.Header>
								<List.Description>
									<p>You've completed your profile.</p>
									<Button color="green" onClick={ _=> doHide() }>Close</Button>
								</List.Description>
							</List.Content>
						</List.Item>
					</>}

					
				</List>
			</Segment>
		</Container>
	);
};

export default AccountOnboarding;
