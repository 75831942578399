import React, { useCallback, useState } from "react";
import { Button, Menu, Tab, TabPane } from "semantic-ui-react";
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";
import { toDictionary } from "xAppLib/helpers/toDictionary";

function setFormValues(ufThis, values) {
	for (const [name, value] of Object.entries(values)) {
		ufThis.handleInputChange(null, {name, value})
	}
}

const BookingFields = ({ type, control, fallback, resetFields, ufThis }) => {
	const values = control.args.values;

	const is_enabled = !!app.site_status?.booking?.types?.[type]?.enabled;
	const is_hybrid = !!app.site_status?.booking?.types?.[type]?.hybrid;

	const activeComponent = values?.appointment_type ?? 'appointment';

	const [bookTabDisabled, setBookTabDisabled] = useState(false);

	const onTabChange = useCallback((_e, { activeIndex }) => {

		const appointment_type = activeIndex === 0 ? 'appointment' : 'fallback';

		setFormValues(ufThis, {
			appointment: undefined,
			appointment_type,
			...toDictionary(resetFields, name => name, _ => undefined),
		});
	}, [ufThis]);

	const onInitialAppointmentsEmpty = useCallback(() => {
		if (!is_enabled || !is_hybrid || activeComponent !== 'appointment') {
			return;
		}
		setFormValues(ufThis, {appointment_type: 'fallback '});
		setBookTabDisabled(true);
	}, [activeComponent, ufThis, setBookTabDisabled]);

	const onTabButtonClick = useCallback((event) => {
		const activeIndex = event.target.dataset.tab === 'appointment' ? 0 : 1;
		onTabChange(event, { activeIndex });
	}, [onTabChange, ufThis, control.fields]);

	let control_component = null;
	let fallback_component = null;

	if (is_enabled) {
		const { fields, args } = control;
		const appointment_required = activeComponent === 'appointment';
		control_component = (
			<UniFieldSUI
				fl={{
					...{ ...fields.appointment, valid_not_required: !appointment_required },
					booking_type: type,
					basic: is_hybrid,
					onInitialAppointmentsEmpty,
				}}
				{...{ ...args, valid_not_required: !appointment_required }}
			/>
		);
	}
	if (!is_enabled || is_hybrid) {
		const fallback_required = activeComponent === 'fallback';
		fallback_component = fallback(fallback_required);
	}
	if (is_enabled && is_hybrid) {
		return (
			<div className="pb-4">
				<h3>Consultation time</h3>
				<Tab
					renderActiveOnly={false}
					menu={{ secondary: true, attached: false, }}
					className="bookingfields"
					panes={[
						{
							menuItem: (
								<Menu.Item
									key="appointment-tab"
									fitted
									title={
										bookTabDisabled
											? ('The "Book a time" option is not available right now.'
												+ ' Use the "Next available" option.'
											) : undefined
									}
									disabled={bookTabDisabled}
							>
									<Button
										type="button"
										primary={activeComponent === 'appointment'}
										basic={activeComponent !== 'appointment'}
										onClick={onTabButtonClick}
										data-tab="appointment"
										disabled={bookTabDisabled}
									>
										Book a time
									</Button>
								</Menu.Item>
							),
							pane: (
								<TabPane key="appointment-pane">{control_component}</TabPane>
							),
						},
						{
							menuItem: (
								<Menu.Item key="fallback-tab" fitted>
									<Button
										type="button"
										primary={activeComponent === 'fallback'}
										basic={activeComponent !== 'fallback'}
										onClick={onTabButtonClick}
										data-tab="fallback"
										data-testid="button-next-available"
									>
										Next available
									</Button>
								</Menu.Item>
							),
							pane: (
								<TabPane key="fallback-pane" className="top">{fallback_component}</TabPane>
							),
						},
					]}
					activeIndex={activeComponent === 'appointment' ? 0 : 1}
					onTabChange={onTabChange}
				/>
			</div>
		);
	}
	return (
		<div className="pb-4">
			{control_component}
			{fallback_component}
		</div>
	);
};

export default BookingFields;
