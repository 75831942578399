import React, { useEffect, useState } from 'react'
import { Message } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { waiting_room_model } from "../../models/waiting_room_model";


export default function NeedeScriptBanner() {
	const [scripts, setScripts] = useState([]);
	useEffect(() => {
		return waiting_room_model.watch_escript_review(recs => setScripts(Object.values(recs || {})));

	}, []);

	const count = scripts.filter(s=>['stuck_escript','stuck_escript_noti'].includes(s.status)).length;

	if (count === 0) return null;

	return (
		<Link to="/scripts/list/escript_review" className='flex-auto flex lg:w-[calc(33.333333%_-_2rem)]'>
			<Message
				warning
				 size='small'
				icon="qrcode"
				header={count > 1
					? `${count} eScripts on hold`
					: `${count} eScript on hold`
				}
			/>
		</Link>
	)
}
 
