import user_model from 'models/user_model';

async function loginByEmail(payload) {
    const { email, password } = payload;
    const remember = payload?.remember !== undefined ? payload.remember : false;

    const result = await user_model.frb_login(email, password, remember)

    if (result instanceof Error) {
        throw result;
    }
    await new Promise((resolve) => {setTimeout(resolve, 5000)});
}

async function loginByGoogle() {
    const result = await user_model.g_login()

    if (result instanceof Error) {
        throw new Error(`Error from Google: ${result.message}`);
    }
}

async function loginByApple() {
    const result = await user_model.apple_login()

    if (result instanceof Error) {
        throw new Error(`Error from Apple: ${result.message}`);
    }
}

async function loginByFacebook() {
    const result = await user_model.fb_login()

    if (result instanceof Error) {
        throw new Error(`Error from Facebook: ${result.message}`);
    }
}

async function loginByOnePass() {
    const result = await user_model.onepass_login()

    if (result instanceof Error) {
        throw new Error(`Error from OnePass: ${result.message}`);
    }
}

export const loginHandlers = {
    email: loginByEmail,
    google: loginByGoogle,
    apple: loginByApple,
    facebook: loginByFacebook,
    onepass: loginByOnePass
} 