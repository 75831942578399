/**
 * Like `Promise.all`, but for an object. Will resolve to an object with the same keys, but with the values resolved.
 * If any promise rejects, the entire result rejects
 * @param objOfPromises
 * @return {Promise<{[p: string]: unknown}>}
 */
export async function resolveValues(objOfPromises) {
	const entries = await Promise.all(
		Object.entries(objOfPromises).map(
			async ([key, val]) => [key, await val]
		)
	);

	return Object.fromEntries(entries);
}
