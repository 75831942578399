import React, { useEffect, useState } from 'react'
import { Message } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { waiting_room_model } from "../../models/waiting_room_model";

function doc_filter(rec) {
	if (app.acl.is_supp || app.acl.is_admin) return true;
	if (!app.acl.can_urgent_care) return false;
	if (rec.doc?.doc_id && rec.doc.doc_id === app.user.claims.doc_id) return true;

	return !rec.doc?.doc_id;
}

export default function UrgentFollowupBanner() {
	const [urgent, setUrgent] = useState([]);
	useEffect(() => {
		return waiting_room_model.watch_urgent_fu(recs => {
			const filtered = Object.values(recs || {}).filter(doc_filter);
			setUrgent(filtered);
		});
	}, []);

	const count = urgent.length;
	if (count === 0) return null;

	return (
		<Link to="/scripts/list/urgent" className='flex-auto flex lg:w-[calc(33.333333%_-_2rem)]'>
			<Message
				error
				icon="heartbeat"
				header={count > 1
					? `${count} patients requiring urgent care`
					: `${count} patient requiring urgent care`
				}
			/>
		</Link>
	)
}
 
