import React from "react";

export function EstimatedWaitTime({eta_minutes}) {
	const eta_range = eta_for_display(eta_minutes ?? 20);

	return <strong className="whitespace-nowrap">{eta_range.min}&ndash;{eta_range.max} minutes</strong>;
}

/**
 * Gives an estimated range of times the patient can expect a call. The range will be to the nearest 5 minutes
 * not greater than the actual eta, and 10 minutes plus that time.
 * So an eta of 17 becomes 15-25 minutes
 * @param {number} eta - the actual eta
 * @returns {{ min: number; max: number }}
 */
function eta_for_display(eta) {
	// Might want to pass this in, but just hardcode for now
	const band = 5;
	const min_eta = 10; // pretty sure the wr stat job enforces this too, but just in case

	const min = Math.floor(Math.max(min_eta, eta) / band) * band;
	const max = min + band * 2;

	return {min, max};
}

