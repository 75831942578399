import React from "react";
import { Form } from "semantic-ui-react";
import SIcon from "../../NUI/StreamlineIcons/SIcon";

export function ComplexField(props) {
	const {onEdit, value, ...inputProps} = props;
		
	return (
		<Form.Field>
			<Form.Input {...inputProps} readOnly className="complex-field">
				{/*<input className="hidden"/>*/}
				<div className="complex-input flex items-center gap-2 bg-is2-blue-50 cursor-pointer w-full" onClick={() => onEdit()}>
					<div className="w-full">{value}</div>
					<SIcon name="pencil" bold size="xs" className="mt-auto"/>
				</div>
			</Form.Input>
		</Form.Field>
	);
}
