import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export function ConditionCardItem({ title, imageUrl, iconImage, url ,tileHeight = 212}) {
  return (
    <Link
      to={url || "/"}
      className={`group relative mx-auto rounded-2xl transition-all duration-300 hover:scale-105 cursor-pointer`}
    >
      {iconImage ? (
        <div
          className={`flex rounded-[28px] h-[150px] w-[150px] bg-is2-blue-50 md:h-[90px] md:w-[90px]`}
         
        >
          <img
            src={iconImage}
            alt={title}
            className={`h-[${tileHeight/4}px] m-auto`}
            loading="lazy"
          />
        </div>
      ) : (
        <img
          src={imageUrl}
          alt={title}
          className="aspect-[212/224] object-cover rounded-[28px] max-h-[212px]"
          loading="lazy"
          style={{
            boxShadow:
              "0px 2px 2px 0px rgba(0, 0, 0, 0.04), 0px 4px 6px 0px rgba(0, 0, 0, 0.11)",
          }}
        />
      )}
      <h6 className="mt-3 pb-2 text-center text-is2-gray-800">{title}</h6>
    </Link>
  );
}

ConditionCardItem.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
