import React from 'react'
import {useProfile, useUser} from "@/xAppLib/Hooks";
import {NotificationBar} from "@/views/UIelems/v2/notificationBar/NotificationBar";
import {Button, Modal} from "semantic-ui-react";
import TermsLink from "@/views/UIelems/TermsLinks";
import user_model from "@/models/user_model";
import {useAsyncUpdate} from "@/xAppLib/Hooks/useAsync";
import OnboardingEmail from "@/xAppLib/UIelems/OnboardingEmail";
import {useNavigate} from "react-router-dom";

const OnboardingNeeded = () => {
    const user = useUser()
    const navigate = useNavigate()
    const [onboardingEmailError, setOnboardingEmailError] = React.useState(false)
    const requiredConsent = user.user_in && !user.prof?.meta?.consent
    const requiresEmailVerification = user.user_in && !user?.is_email_verified

    const isBlocked = app.acl.is_blocked

    if(!isBlocked) {
        // Force reload page when not blocked
        navigate('/')
    }

    if(!isBlocked) return null

    const showConsent = requiredConsent
    const showEmailVerification = !showConsent && requiresEmailVerification

    return (
        <>
            <NotificationBar text={'You must complete onboarding before you can use the application.'} type={'warning'} />
            {showConsent && <OnboardingNeeded.ConsentModal isOpen={true} />}
            {showEmailVerification && <OnboardingNeeded.EmailVerification eml_verif={!requiresEmailVerification} errors={onboardingEmailError} onError={setOnboardingEmailError} />}
        </>
    )
}

OnboardingNeeded.Lightbox = ({isOpen, header, content, actions}) => {
    return <Modal open={isOpen} className={'!max-w-[400px]'}>
        <Modal.Header className={'!pt-6 !pb-4'}>{header}</Modal.Header>
        <Modal.Content className={'!bg-is2-blue-50'}>
            {content}
        </Modal.Content>
        <div className={'flex justify-between items-center px-4 pt-4 gap-4 [&>*]:w-full'}>
            {actions}
        </div>
    </Modal>
}

OnboardingNeeded.ConsentModal = ({isOpen}) => {
    const [profile, _profiles] = useProfile()

    const {loading: updatingProfile, fn: handleAccept} = useAsyncUpdate({
        fn: async () => {
            const updProfile = { ...profile, meta: { ...profile.meta, consent: true } };
            await user_model.save_prof(updProfile, (result) => {
                // update old profile
                if (result) {
                    app.user.prof.meta = { ...result.profile.meta };
                    app.trigger(app.events.CONSENT_UPD);
                }
            });
        }
    })

    return <OnboardingNeeded.Lightbox
        isOpen={isOpen}
        header={'Terms and conditions'}
        content={
            <p>You must read and accept the InstantScripts
                <TermsLink type={'privacy-policy'} label={' InstantScripts Privacy Policy, '}/>
                <TermsLink type={'platform-terms-and-conditions'} label={'Platform Terms and Conditions, '}/>
                and{" "}
                <TermsLink type={'customer-terms-and-conditions'} label={'Customer Terms and Conditions'}/>
                {" "} before using the application.
            </p>
        }
        actions={
            <>
                <Button basic onClick={() => user_model.logout()}>Log out</Button>
                <Button primary loading={updatingProfile} onClick={() => handleAccept()}>I accept</Button>
            </>
        }
    />
}

OnboardingNeeded.EmailVerification = ({errors, onError}) => {
    return <div className={'md:mt-9 bg-inherit max-w-screen-lg mx-auto'}>
                <div className={'bg-white p-8 rounded-lg'}>
                    <h2>Email verification required</h2>
                    <OnboardingEmail eml_verif={false} onError={onError} />
                    <div className={'mt-4'} />
                    {!!errors && <NotificationBar text={errors} type={'warning'} />}
                </div>
            </div>
}

export default OnboardingNeeded
