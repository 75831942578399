import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import pages from 'cms/pages/pages.json'
import blog from 'cms/blog/blog.json'
const cms = {
    pages,blog
}
const DEBUG = false

async function add_script(url, target='cms') {
    return new Promise((resolve,reject)=>{
        const script = document.createElement('script');
        script.src=url
        DEBUG && console.log("added",url);
        script.onload = () => {
            DEBUG && console.log('Script loaded successfuly',url);
            resolve()
          };
          
          script.onerror = () => {
            DEBUG && console.log('Error occurred while loading script');
            reject()
          };
          document.getElementById('cms').appendChild(script);
    })
}

function filename(url) {
    return url.replace(/^\//,'').replace(/\/$/,'').replace(/\//g,'-')
}
const CMSPage = ({url, target='pages'}) => {

    DEBUG && console.log({url, target})
    const navigate = useNavigate()

    url = url.replace(/\/$/,'')

    const index = cms[target] || []

    const page = useMemo(()=>index.find(e=>e.loc == url) || {},[url])

    const [data,setData] = useState(window.___?.loc == url && window.___ || null) 
    DEBUG && console.log("CMSPage",url,data)

    useEffect(()=>{
        // return
        import(`cms/${target}/${filename(url)}.json`).then(content=>content?.default).then(data=>{
            DEBUG && console.log("got content",data)
            const { html, css, meta } = data 
            const content = `
            ${css.join("\n")}
            ${html.join("\n")}
            `
            DEBUG && console.log({content});
            setData({...data,content})
        })
    },[url])

   


  

    useEffect(()=>{
        if (!data)
            return;
        Array(...document.querySelectorAll('[uk-grid]')).forEach(el=>{el.classList.add('uk-grid')});

        Array(...document.querySelectorAll('[uk-grid]')).forEach(el=>{el.classList.add('uk-grid')});
       

        add_script("/theme/combined.js?ver=2.7.24")

        function clickHandler(e) {
            DEBUG && console.log("click",e);
            let el = e.target
            while (el && el.tagName != "A" && el.id!='cms') {
                el = el.parentElement
            }
            if (el.classList?.contains('uk-accordion-title')) {
                e.preventDefault();
                return
            }
            if (el.href) {
                const link = el.href.replace(location.origin,'').replace(/https?:\/\/app.instantscripts.com.au/,'').replace(/https?:\/\/www.instantscripts.com.au/,'')
                DEBUG && console.log("link",link,el.href);
                if (link.startsWith('/') && !link.startsWith('/faq') && !link.startsWith('/locations') && !link.startsWith('/blog')) {
                    DEBUG && console.log("Highjack link")
                    e.preventDefault();
                    navigate(link)
                }
            }
        }

        document.getElementById('cms')?.addEventListener('click', clickHandler, false);

        return () => {
            document.getElementById('cms')?.removeEventListener('click',clickHandler)
        }

    },[data,url])

    if (!data)
        return null
      let { html, css, meta, content } = data


    return <div>
                <Helmet>
                    <title>{page.title}</title>
                </Helmet>
            {/* <h1>{page.title.rendered}</h1> */}
        <style scoped>
                @import "/theme/theme.1.css";

        </style>
        <div id='cms' className='cms-page'
            dangerouslySetInnerHTML={{__html:content}}
        />

    </div>;
}
 
export default CMSPage;