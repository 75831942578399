import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useDebouncedValue } from "xAppLib/Hooks";
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";
import Alert from "../../../NUI/Alert";

export function make_bmi_calculator(med_data__xtra = {}) {
	const {min_weight, min_weight_strict, min_bmi, min_bmi_strict, max_bmi, max_bmi_strict} = med_data__xtra;

	return function bmi_calculator(height_cm, weight_kg) {
		const height = height_cm / 100;
		const weight = weight_kg;

		const weight_low = weight && min_weight && weight < min_weight;
		const weight_low_strict = weight_low && min_weight_strict;
		const bmi = height && weight && (weight / (height * height)).toFixed(1) || null;

		const bmi_low = bmi && min_bmi && bmi < min_bmi;
		const bmi_low_strict = bmi_low && !!min_bmi_strict;
		const bmi_high = bmi && max_bmi && bmi > max_bmi;
		const bmi_high_strict = bmi_high && !!max_bmi_strict;

		const bmi_appropriate = !(bmi_low || bmi_high);
		const weight_appropriate = !weight_low;
		const out_of_range = !(bmi_appropriate && weight_appropriate);
		const out_of_range_strict = (bmi_low_strict || bmi_high_strict || weight_low_strict) && out_of_range;

		return {
			height,
			weight,
			weight_low,
			weight_low_strict,
			bmi,
			bmi_low,
			bmi_low_strict,
			bmi_high,
			bmi_high_strict,
			bmi_appropriate,
			weight_appropriate,
			out_of_range,
			out_of_range_strict,
		};
	};
}

export function CheckBmi({fields, med_data, children, ...args}) {
	const calc = make_bmi_calculator(med_data.xtra)(
		+args.values.cons_bmi_height,
		+args.values.cons_bmi_weight,
	);
	const {
		bmi,
		bmi_low,
		out_of_range,
		out_of_range_strict,
		bmi_appropriate,
		weight_appropriate,
	} = calc;

	const debouncedBmi = useDebouncedValue(bmi, 600);

	useEffect(() => {
		args.uf_this.handleInputChange({target: {name: 'cons_bmi_out_of_range', value: null}});
	}, []);

	const level = out_of_range_strict ? 'error' : out_of_range ? 'warning' : 'success';
	const invalid = args.valids?.cons_bmi_out_of_range === false;

	return <>
		{children ? <div className="mb-4">{children}</div> : <>
			<UniFieldSUI fl={fields.cons_bmi_height} {...args} />
			<UniFieldSUI fl={fields.cons_bmi_weight} {...args} />
		</>}
		{bmi && debouncedBmi > 0 && (
			<Alert header={`BMI: ${debouncedBmi}`} {...{[level]: true}}>
				{out_of_range && (<>
					<p>
						{!bmi_appropriate && <>Your BMI is {bmi_low ? 'lower' : 'higher'} than recommended for this treatment.<br/></>}
						{!weight_appropriate && <>Your weight is lower than recommended for this treatment.<br/></>}
					</p>
					{out_of_range_strict ? (
						<Button
							size="big"
							fluid
							color={invalid ? "red" : "green"}
							basic={invalid}
							as={Link}
							to="/m/pharm/DocCons/DocConsCov"
							data-testid="button-doctors-consult"
							content="Click here to request a Telehealth Consultation with one of our doctors."
						/>
					) : (
						<>
							<p>You may continue, however it is at the doctor's discretion as to whether any treatment is suitable for you.</p>
							<UniFieldSUI fl={fields.cons_bmi_out_of_range} {...args} show_label={false}/>
						</>
					)}
				</>)}
			</Alert>
		)}
	</>;
}
