import React from 'react';

import { Form } from 'semantic-ui-react';
import YogpForm from '../../views/med/Sections/Forms/YogpForm';

const YogpField = (props) => {
	const { edit, values, valids } = props;
	const isYOGP = Object.keys(values?.yogp ?? {}).length;
	const isValid = edit && values?.yogp_want && valids && !valids?.yogp && 'The details of your doctor are missing';

	return (
		<Form.Field control={Form} error={isValid}>
			<label>Your doctor details</label>
			<div>
				{!edit && !isYOGP ? 'No doctor selected' : <YogpForm {...props} />}
			</div>
		</Form.Field>
	);
};

export default YogpField;
