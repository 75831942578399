import React from 'react'
import {Button} from "semantic-ui-react";
import {cls} from "../../NUI/utils";

const ProductCardButtons = ({product, productInCart, onProductSelect, onProductRemove, onProductQuantityChange, allowMultiple, confirmOnRemove}) => {
    const qtyInCart = productInCart?.qty
    const disableQtyAdd = productInCart?.qty >= product.max_qty

    const handleProductRemove = async (product) => {
        if(confirmOnRemove) {
            const res = await app.confirm('Are you sure you want to remove this item from your cart?', '')
            if(res !== 'yes') return
        }
        onProductRemove?.(product)
    }


    if(productInCart && allowMultiple){
        return (
            <div className={'bg-blue-100 rounded-full h-10 flex flex-row px-1 justify-between items-center select-none'}>
                <button
                    className={'h-8 w-8 rounded-full bg-white'}
                    onClick={e=> {
                        e.stopPropagation();
                        const new_qty = qtyInCart - 1
                        new_qty === 0 ? handleProductRemove(product) : onProductQuantityChange({...product, qty: qtyInCart - 1});
                    }}
                >
                    -
                </button>
                <p className={'m-0 text-xs'}>Qty: {qtyInCart}</p>
                <button
                    className={cls('h-8 w-8 rounded-full bg-white', disableQtyAdd ? 'invisible' : 'visible')}
                    onClick={e=> {
                        e.stopPropagation();
                        onProductQuantityChange({...product, qty: qtyInCart + 1})}
                    }
                >
                    +
                </button>
            </div>
        )
    } else if (productInCart) {
        return <Button
            size={'tiny'} color={'green'} className={'w-full'}
            onClick={e => {
                e.stopPropagation()
                handleProductRemove(product)
            }}
        >Added</Button>
    } else {
        return <Button
            size={'tiny'}
            className={'w-full'}
            color={'blue'}
            onClick={e => {
                e.stopPropagation()
                onProductSelect({...product, qty: 1})
            }}
        >
            Add Item
        </Button>
    }
}
export default ProductCardButtons