
/**
 * Filters a list of prescriptions to return only active ones.
 * A prescription is considered active if:
 * 1. It has not been ordered through ecommerce (ecomm_ordered !== true)
 * 2. Its state is either 'Active', undefined, or null
 *
 * @param {Array} scrs_list - Array of patient script objects (patient scr_list is structured differently then admin scr_list)
 *
 * @returns {Array} Filtered array containing only active prescriptions.
 */

export const filterActivePrescriptions = (scrs_list = []) => {
    return scrs_list.filter((item) => isActivePrescription(item));
}

/**
 * Checks if a prescription is active.
 * A prescription is considered active if:
 * 1. It has not been ordered through ecommerce (ecomm_ordered !== true)
 * 2. Its state is either 'Active', undefined, or null
 *
 * @param {Object} item - The prescription object to check.
 * @param {boolean} item.ecomm_ordered - Indicates if the prescription has been ordered through ecommerce.
 * @param {Object} item.epresc - The e-prescription details.
 * @param {string} [item.epresc.state] - The state of the e-prescription.
 *
 * @returns {boolean} True if the prescription is active, false otherwise.
 */
export const isActivePrescription = (item) => {
    return (
        // If the scr_obj has been ordered through ecommerce
        // we will consider it as inactive.
        !item.ecomm_ordered === true &&
        // If the scr_obj was a medbuy or medclick variant we will consider it as inactive.
        !['medbuy', 'exprbuy', 'qualbuy', 'medclick', 'exprclick', 'qualclick'].includes(item?.script_type) &&
        // The state of the prescription is Active, or it is undefined or null
        (
            item?.epresc?.state === 'Active' ||
            item?.epresc?.state === undefined ||
            item?.epresc?.state === null
        )
    )
}