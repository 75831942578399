import moment from 'moment';

function from_now(created) {
    return moment(created?.toDate?.() || created).fromNow();
}

function format_date(created) {
    return moment(created?.toDate?.() || created).format('DD/MM/YY - h:mma');
}

export { from_now, format_date };
