import React, { useState } from "react";
import { Form, Segment, Header, Dropdown, Icon } from "semantic-ui-react";
import JsonEditor from 'xAppLib/UIelems/JsonEditor';
import admin_send_notification from "../model/admin_send_notification";
import { PRIORITY, PRIORITY_OPTIONS } from "../constant/priority";

const getDefaultsForType = (type) => {
    switch (type) {
        case 'test':
            return {
                title: 'Test notification',
                body: 'This is a test notification',
                extra_fields: {},
            };
        case 'message':
            return {
                title: 'Message title',
                body: 'This is a message notification',
                icon: 'envelope',
                extra_fields: {},
            };
        default:
            return null;
    }
};

const initialFormData = () => ({
    title: `Test notification ${Date.now().toString(36)}`,
    body: 'This is a test notification',
    type: 'test',
    userId: app?.user?.uid,
    priority: PRIORITY.NORMAL,
    link: '',
    banner_link: '',
    extra_fields: {},
    icon: '',
    overrides: {},
});

const AdminSendNotification = () => {
    const [formData, setFormData] = useState(initialFormData());
    const [isLoading, setIsLoading] = useState(false);

    const [typeOptions, setTypeOptions] = useState(
        [
            "message",
            "test",
        ].map(key => ({
            key: key,
            text: key,
            value: key
        }))
    );

    const handleChange = (e, { name, value }) => {
        setFormData(prev => {
            const updates = { [name]: value };

            if (name === 'type') {
                const defaults = getDefaultsForType(value);
                if (defaults) {
                    updates.title = defaults.title;
                    if (defaults.body) {
                        updates.body = defaults.body;
                    }
                    if (defaults.icon) {
                        updates.icon = defaults.icon;
                    }
                    if (defaults.extra_fields) {
                        updates.extra_fields = defaults.extra_fields;
                    }
                }
            }

            return { ...prev, ...updates };
        });
    };

    const handleextra_fieldsChange = (value) => {
        setFormData(prev => ({ ...prev, extra_fields: value }));
    };

    const handleOverridesChange = (value) => {
        setFormData(prev => ({ ...prev, overrides: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await admin_send_notification(formData);
            setFormData(initialFormData());
        } catch (error) {
            console.error('Failed to send notification:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const priorityOptions = PRIORITY_OPTIONS
        .map(priority => ({
            key: priority,
            text: priority,
            value: priority
        }));

    const handleAddType = (e, { value }) => {
        setTypeOptions(prev => [...prev, { key: value, text: value, value }]);
    };

    const handleAddIcon = (e, { value }) => {
        setIconOptions(prev => [...prev, { key: value, text: value, value, icon: value }]);
    };

    const [iconOptions, setIconOptions] = useState(
        ['bell', 'envelope', 'info circle', 'exclamation', 'calendar', 'user', 'file']
            .map(icon => ({
                key: icon,
                text: icon,
                value: icon,
                icon
            }))
    );

    return (
        <Segment>
            <Header as="h3" color="grey">New Notification</Header>
            <Form onSubmit={handleSubmit} loading={isLoading}>
                <Form.Input
                    label="Title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    maxLength={200}
                    required
                />
                <Form.TextArea
                    label="Body"
                    name="body"
                    value={formData.body}
                    onChange={handleChange}
                    required
                />
                <Form.Field>
                    <label>Type</label>
                    <Dropdown
                        selection
                        search
                        allowAdditions
                        additionLabel={null}
                        options={typeOptions}
                        value={formData.type}
                        onChange={(e, { value }) => handleChange(e, { name: 'type', value })}
                        onAddItem={handleAddType}
                        placeholder="Select or enter type"
                    />
                </Form.Field>
                <Form.Input
                    label="User ID"
                    name="userId"
                    value={formData.userId}
                    onChange={handleChange}
                    required
                />
                <Form.Select
                    label="Priority"
                    name="priority"
                    value={formData.priority}
                    options={priorityOptions}
                    onChange={handleChange}
                    required
                />
                <Form.Input
                    label="Link"
                    name="link"
                    value={formData.link}
                    onChange={handleChange}
                />
                <Form.Input
                    label="Banner Link"
                    name="banner_link"
                    value={formData.banner_link}
                    onChange={handleChange}
                />
                <Form.Field>
                    <label>Icon override</label>
                    <div className="flex items-center gap-2">
                        <Dropdown
                            selection
                            search
                            allowAdditions
                            additionLabel={null}
                            options={iconOptions}
                            value={formData.icon}
                            onChange={(e, { value }) => handleChange(e, { name: 'icon', value })}
                            onAddItem={handleAddIcon}
                            placeholder="Select or enter icon"
                        />
                        <a
                            href="https://react.semantic-ui.com/elements/icon/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="See more icons"
                        >
                            <Icon name="external" />
                        </a>
                    </div>
                    {formData.icon && (
                        <div className="mt-2">
                            <Icon
                                name={formData.icon}
                                size="large"
                            />
                            <span className="ml-2 text-gray-600">Preview</span>
                        </div>
                    )}
                </Form.Field>

                <Form.Field>
                    <label>Extra Fields</label>
                    <JsonEditor
                        value={formData.extra_fields}
                        onChange={handleextra_fieldsChange}
                        mode="tree"
                    />
                </Form.Field>

                <Form.Field>
                    <label>Overrides (toast, banner, pinned, push)</label>
                    <JsonEditor
                        value={formData.overrides}
                        onChange={handleOverridesChange}
                        mode="tree"
                    />
                </Form.Field>

                <div className="flex gap-4">
                    <Form.Button type="submit" primary loading={isLoading}>
                        Send Notification
                    </Form.Button>
                    <Form.Button type="button" onClick={() => setFormData(initialFormData())}>
                        Reset Form
                    </Form.Button>
                </div>
            </Form>
        </Segment>
    );
};

export default AdminSendNotification;