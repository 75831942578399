export const menuItems = [
	{
		children: [
			{
				label: 'News',
				link: 'https://www.instantscripts.com.au/blog',
			},
			{
				label: 'In the media',
				link: '/media_release',
			},
			{
				label: 'Pricing',
				link: '/pricing',
			},
			{
				label: 'Our story',
				link: '/about-us',
			},
			{
				label: 'Privacy T&C',
				link: '/terms-and-conditions',
			},
			{
				label: 'Customer T&C',
				link: '/customer-terms-and-conditions',
			},
			{
				label: 'Platform T&C',
				link: '/platform-terms-and-conditions',
			},
		],
		imageUri: '/images/logos/instant-scripts-logo-IS2-blue.svg',
	},
	{
		children: [
			{
				label: 'OnePass',
				link: '/onepass',
			},
			{
				label: 'Refer a friend',
				link: '/refer-a-friend',
			}
		],
		iconName: 'trophy',
		label: 'Promotions',
	},
	{
		children: [
			{
				label: 'FAQ',
				link: '/faq',
			},
			{
				label: 'Help center',
				link: 'https://help.instantscripts.com.au',
			},
			{
				label: 'Contact us',
				link: '/contact-us',
			}

		],
		iconName: 'help-msg',
		label: 'Help',
	},
	{
		children: [
			{
				label: 'Doctors',
				link: 'https://careers.smartrecruiters.com/InstantScripts',
			},
			{
				label: 'Pharmacies',
				link: '/pharmacy-registration',
			},
		],
		iconName: 'steth-heart',
		label: 'Work with us',
	},
];
