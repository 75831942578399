//	------------------------	------------------------	------------------------
//	Description: Consult wait time for the booking consult page
//  Version: 1.0.0
//  Updates: 
//  Notes: This can be toggled on or off with app.site_status.wr_stat.use_new 
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'

import { Icon, Modal } from 'semantic-ui-react'

import moment from 'moment-timezone'

import firebase_database from 'xAppLib/providers/firebase_database'

import WaitTimeConsult from './consult'
import SIcon from '../../../NUI/StreamlineIcons/SIcon'
import { cls } from '../../../NUI/utils'
import Alert from '../../../NUI/Alert'
import app from 'ampersand-app'
import { Badge } from '../../../UIelems/v2/Badge'

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const mins_max = 600 // This should match the BE update_historic, mins_max
const mins_min = 10  // This should match the BE update_historic, mins_min

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default class WaitTimeConsultBook extends Component {

	constructor(props) {

		super(props)

		this.watched_records = []

		this.state = {

			loading: true,

		}

	}

	//	------------------------	------------------------	------------------------

	async componentDidMount() {

		this.watched_records.push(firebase_database.watch_record('/site_status/wr_stat/prediction/', this.handle_wait_time_prediction_data.bind(this))) // Could use app.site_status

		this.setState({ loading: false })

	}

	//	------------------------	------------------------	------------------------

	async componentWillUnmount() {

		// Unwatch
		this.watched_records.forEach(o => o())

	}

	//	------------------------	------------------------	------------------------
	//	Rendering
	//	------------------------	------------------------	------------------------

	render() {

		return 1 &&
			<React.Fragment>
				{this.state.loading && this.render_loading() || this.render_content()}
			</React.Fragment>

	}

	//	------------------------	------------------------	------------------------

	render_loading() {

		return 1 &&
			<React.Fragment>
				<h3>Loading...</h3>
			</React.Fragment>

	}

	//	------------------------	------------------------	------------------------

	mins_to_hum_time(mins) {

		const hs = Math.floor(mins/60)
		const ms = mins%60;

		return `${hs>1&&`${hs} hours` || hs==1&&'1 hour' || ''} ${ms>1&&`${ms} mins` || ms==1&&'1 minute' || ''}`
		// return `${moment.utc(moment.duration(mins, "minutes").asMilliseconds()).format("H [hours] m [minutes]") }`
		// return `${moment.duration(mins, "minutes").humanize()}`
	}

	//	------------------------	------------------------	------------------------

	render_content() {
		const { basic = false, hideIcon, color, info, badge } = this.props;

		const call_98 = this.state.prediction_data?.data?.pc_98_wait
		const call_98_txt = call_98 && ((call_98 == mins_min && `in less than ${this.mins_to_hum_time(mins_min)}`) || (call_98 == mins_max && `in more than ${this.mins_to_hum_time(mins_max)}`) || `within ${this.mins_to_hum_time(call_98)}`) || 'within 1 hour'

		return 1 &&
			<React.Fragment>

				<div onClick={_ => !basic && this.setState({ show_time_modal: true })} className={!basic && 'cursor-pointer' || ''}>
					{(app.settings.iscr2 && badge) ?
							<div className={'bg-[#CCFCDA] rounded-[4px]'}>{this.avg_wait_txt}</div>
					:
					<Alert  
						bg={color}
						wait={true}
						hideIcon={hideIcon}
						header={this.avg_wait_txt}
						content={(info || !basic) && 
								<> {!basic && (<>
										<p className='text-sm'>98% of calls are made <strong>{call_98_txt}</strong>.
										<a className="flex justify-start underline text-sm cursor-pointer">
										<SIcon name="external" size="xxs" bold /> Learn more about estimated call back times</a></p>
									</>)}
									{info && <p>{info}</p>}
								</>
						}
					/>}
				</div>
				{this.render_time_modal()}

			</React.Fragment>


	}

	get avg_wait_txt() {
		const avg_wait = this.state.prediction_data?.data?.pc_98_wait // Use 98 upper limit

		//const avg_wait_txt = avg_wait && ((avg_wait == mins_min && `less than ${mins_min} minutes`) || (avg_wait == mins_max && `more than ${mins_max} minutes`) || `${avg_wait} minutes`) || 'within 1 hour'
		const avg_wait_txt = avg_wait && ((avg_wait == mins_min && `within ${this.mins_to_hum_time(mins_min)}`) || (avg_wait == mins_max && `more than ${this.mins_to_hum_time(mins_max)}`) || `within ${this.mins_to_hum_time(avg_wait)}`) || 'within 1 hour'

		return app.settings.iscr2
			? <div className={'flex flex-wrap gap-[4px] justify-center items-center text-black py-1.5 px-2 text-[14px] md:py-1 md:px-2 md:text-[16px] '}>
				<span>Current callback time:</span>
				<SIcon name="clock-simple" size={'xs'} bold cls={'!p-0 !h-[14px] !w-[14px] md:!h-[16px] md:!w-[16px]'}/>
				<span className="font-bold">{avg_wait_txt}</span>
				<span>(est.)</span>
			  </div>
			: `Approximate waiting time to speak to a doctor is ${avg_wait_txt}.`;
	}

	//	------------------------	------------------------	------------------------
	//	UI
	//	------------------------	------------------------	------------------------

	render_time_modal() {

		return 1 &&
			<React.Fragment>

				<Modal
					open={this.state.show_time_modal}
					onClose={_ => { this.setState({ show_time_modal: false }) }}
					closeIcon
				>
					<Modal.Content>

						<WaitTimeConsult />

					</Modal.Content>
				</Modal>


			</React.Fragment>
	}

	//	------------------------	------------------------	------------------------
	//	Functions
	//	------------------------	------------------------	------------------------

	async handle_wait_time_prediction_data(data) {

		//this.prediction_data = data

		if (data?.data) data.data.tm = data.data?.tm_aest && moment(data.data.tm_aest) || null

		//data.data.tm = data && data.data && data.data.tm_aest && moment(data.data.tm_aest) || null

		this.setState({prediction_data: data})

	}

}