import React from 'react';
import PropTypes from 'prop-types';
import { ConditionCardItem } from './ConditionCardItem';

/**
 * A grid component for displaying condition cards.
 *
 * It is only visible on devices with a screen size of medium and larger.
 * The grid layout adjusts based on the screen size, displaying 3 columns on
 * medium screens, 4 columns on large screens, and 5 columns on extra-large
 * screens.
 *
 * @param {{title: string, imageUrl: string, url: string}[]} items - An array of condition card items.
 */
const CardGrid = ({ items }) => {
  return (
    <div className="hidden sm:grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
      {items.map((items, index) => (
        <ConditionCardItem key={index} {...items} />
      ))}
    </div>
  );
};

CardGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired
};

export default CardGrid;
