import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import { Grid, Form, Button } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask'
import MedicareToolTip from '../../views/UIelems/MedicareToolTip'

const DEBUG = false

const SIZE_NUMBER = [16,10,10,10,10]
const SIZE_REF = [16,6,6,6,6]

class MedicareCard extends Component {
	
	constructor(props) {
		super(props)
		this.state = {
			medicare_number:'',
			medicare_ref:'',
		}
	}

	
	async componentDidMount() {
		this.getComponents()
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.value != this.props.value) {
			this.getComponents()
		}
	}
	
	
	setComponents({medicare_number:new_medicare_number,medicare_ref:new_medicare_ref}) {
		DEBUG && console.log("setComponents",{new_medicare_number,new_medicare_ref});
		const { onChange, fl } = this.props
		let { medicare_number,medicare_ref } = this.state
		if (new_medicare_number!==undefined) 
			medicare_number = new_medicare_number
		if (new_medicare_ref!==undefined)
			medicare_ref = new_medicare_ref
		medicare_number = medicare_number.replace(/_/g,' ')	
		DEBUG && console.log({medicare_number,medicare_ref});
			
		this.setState({
			medicare_number,
			medicare_ref,
		})
		let combined = medicare_number + (medicare_number.length && medicare_ref.length==1 && ` ${medicare_ref}`||'')
		DEBUG && console.log({combined});
		onChange({target:{name:fl.name, value:combined}})
		
		
	}
	
	getComponents() {
		const { value = '' } = this.props
		DEBUG && console.log("getComponents",value,value.length);
		const medicare_number = value.length == 14 && value.substr(0,value.length-2) || value
		const medicare_ref = value.length == 14 && value.substr(value.length-1) || ''
		
		this.setState({medicare_number,medicare_ref})
	}

	render() {
		const { medicare_number,medicare_ref } = this.state

		const {
			value,
			fl,
			error,
			show_label,
			tooltips = true,
			...restofProps
		} = this.props

		return (
			<div className='medicare-card equal width fields'>
				<div className='field'>
				{show_label && <label className='section-label'>{!app.settings.iscr2 && 'Medicare Card Details'}</label>}
					
				<Grid >
					<Grid.Row>
				      <Grid.Column mobile={SIZE_NUMBER[0]} tablet={SIZE_NUMBER[1]} computer={SIZE_NUMBER[2]} largeScreen={SIZE_NUMBER[3]} widescreen={SIZE_NUMBER[4]} verticalAlign="bottom" className="flex flex-col !mb-4 md:!mb-0">
							  <Form.Input
		  							label={<label><React.Fragment>Card Number {tooltips && <MedicareToolTip type='number' />}</React.Fragment></label>}
									required={!fl.valid_not_required}
									disabled={fl.disabled} 
									fluid
									error = {error} 
									>
		  							<MaskedInput
												defaultValue=""
		  										mask={[ /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ',/\d/]}
		  										placeholder={"____ _____ _"} 
		  										value = {medicare_number}
												onChange={(event) => this.setComponents({medicare_number:event.target.value})}
		  										type = 'text'
												data-testid='input-medicare-number'
												className={value&&"masked-input"}
		  									/>
		  						</Form.Input>
					  </Grid.Column>
				      <Grid.Column mobile={SIZE_REF[0]} tablet={SIZE_REF[1]} computer={SIZE_REF[2]} largeScreen={SIZE_REF[3]} widescreen={SIZE_REF[4]} verticalAlign="bottom">
						  <Form.Input
						  		disabled={fl.disabled} 
								error = {error} 
								fluid
								label={<label><React.Fragment>Ref Number {tooltips && <MedicareToolTip type='ref' />}</React.Fragment></label>}
								>
								<MaskedInput
											defaultValue=""
											mask={[ /\d/ ]}
											placeholder={"_"} 
											value = {medicare_ref}
											type = 'text'
											data-testid = 'input-ref'
											onChange={(event) => this.setComponents({medicare_ref:event.target.value})}
											className={value &&"masked-input"}
										/>
							</Form.Input>
					  </Grid.Column>
					 </Grid.Row>
				</Grid>
				</div>
			</div>
		
		)

	}
}

export default MedicareCard
