import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Checkbox, Container } from "semantic-ui-react";
import NotificationIndicator from "./NotificationIndicator";
import NotificationsHeader from "./NotificationsHeader";
import NotificationsList from "./NotificationsList";
import NotificationBanners from "./NotificationBanners";
import {
    useNotifications,
    useBannerNotifications,
} from "../hooks/useNotifications";
import { useNotificationToasts } from "../hooks/useNotificationToasts";
const NotificationsPage = () => {
    const [indicatorOpen, setIndicatorOpen] = useState(false);
    const {
        notifications,
        loading: notificationsLoading,
        setNotificationRead,
        unreadCount,
        usePlugin,
    } = useNotifications();
    const {
        bannerNotifications,
        loading: bannerNotificationsLoading,
        setNotificationRead: setBannerNotificationRead,
    } = useBannerNotifications();
    useNotificationToasts();

    const handleIndicatorClick = useCallback(() => {
        setIndicatorOpen(b => !b);
    }, []);

    const navigate = useNavigate();
    const handleNotificationClick = (notification) => {
        setNotificationRead(notification.notification_id, true);
        if (notification.link) {
            navigate(notification.link);
        }
    };

    const [hasUnreadButton, setHasUnreadButton] = useState(false);

    return (
        <>
            <Helmet>
                <title>Notifications</title>
            </Helmet>
            <Container>
                <h1 className="pb-8">Notifications</h1>
                <div className="flex flex-col gap-12 pb-20">

                    <div>
                        <h2>Indicator Component</h2>
                        <div className="bg-gray-400 p-4">
                            <NotificationIndicator
                                open={indicatorOpen}
                                onClick={handleIndicatorClick}
                                unreadCount={unreadCount}
                            />
                        </div>
                        <div className="pt-4 text-sm text-gray-500 select-none">
                            {
                                indicatorOpen
                                    ? '<open>'
                                    : '<closed>'
                            }
                        </div>
                    </div>
                    <div>
                        <h2>Header Component</h2>
                        <NotificationsHeader
                            allCount={notifications.length}
                            newCount={unreadCount}
                        />
                    </div>
                    <div>
                        <h2>List Component</h2>
                        <div className="flex items-center gap-4 pb-4">
                            <Checkbox
                                label="Unread button"
                                checked={hasUnreadButton}
                                onChange={() => setHasUnreadButton(b => !b)}
                                toggle
                            />
                        </div>
                        <div className="bg-white pb-4 rounded-lg shadow">
                            <NotificationsList
                                notifications={notifications}
                                loading={notificationsLoading}
                                hasUnreadButton={hasUnreadButton}
                                setNotificationRead={setNotificationRead}
                                usePlugin={usePlugin}
                                onNotificationClick={handleNotificationClick}
                            />
                        </div>
                    </div>
                    <div>
                        <h2>Banners Component</h2>
                        <NotificationBanners
                            notifications={bannerNotifications}
                            loading={bannerNotificationsLoading}
                            setNotificationRead={setBannerNotificationRead}
                            usePlugin={usePlugin}
                        />
                    </div>
                    <div>
                        <h2>Toasts</h2>
                        <div>Loads in page</div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default NotificationsPage;