import React from "react";
import FamilySelector from "@/components/FamilySelector";
import { Header } from "@/views/UIelems/v2/Header/Header";

export const ComponentWrapper = ({children}) => {
    return <div className={'max-w-screen-md mx-auto px-6 py-9 md:px-0'}>
        {children}
    </div>
}

export const CommonHeader = ({title, allProfiles, uid, handleOnSelectProfile, contentWrapper }) => {
    return <Header clsn="!bg-is2-blue-300" contentWrapper={contentWrapper || ComponentWrapper}>
        <div className={'flex flex-col md:flex-row justify-between gap-6'}>
            <Header.Title>{title}</Header.Title>
            {allProfiles.length > 1 && (
                <div className={'md:min-w-[400px]'}>
                    <FamilySelector
                        addAllOption
                        onSelect={handleOnSelectProfile}
                        value={uid}
                        header={""}
                    />
                </div>
            )}
        </div>
    </Header>
}