import React from "react";

export function AppleAppStoreButton() {
	return (
		<a href="https://apps.apple.com/au/app/instantscripts/id1456598384" target="_blank"><img
			className="w-auto h-[2.5rem] mx-auto" src="https://storage.googleapis.com/instant-med-public/app-store.png"
			alt="Download the InstantScripts App on Apple App Store"/></a>
	);
}

export function GooglePlayStoreButton() {
	return (
		<a href="https://play.google.com/store/apps/details?id=au.com.instant" target="_blank"><img
			className="w-auto h-[2.5rem] mx-auto"
			src="https://storage.googleapis.com/instant-med-public/google-play.png"
			alt="Download the InstantScripts App on Google Play"/></a>
	);
}