import React, { useMemo, useState } from "react";
import { useSiteStatus } from "@/xAppLib/Hooks";
import { obj_val } from '@/helpers/json_functions';

const DEBUG = false;

const get = (status, path) => obj_val(status, path.split('/'));

export function SiteToggle({children, watch = false, path, withProps, ...props}) {
	const status = useSiteStatus();
	const [fixed] = useState(() => get(status, path))
	const live = useMemo(() => get(status, path), [status, path]);

	const value = watch ? live : fixed;

	const selected = React.Children.map(children, (element) => {
		DEBUG && console.log("variant", element.props.variant, value, value === element.props.variant);
		if (value === element.props.variant || (value === undefined && element.props.defaultVariant)) {
			return withProps
				? React.cloneElement(element, props)
				: element;
		}
	}).filter(Boolean);

	return (
		<>
			{DEBUG && <>{path}/{value}</>}
			{selected}
		</>
	);
}

const SiteToggleVariant = ({children}) => {
	return children;
};

const SiteToggleVariantFunc = ({children}) => {
	return children?.();
};

SiteToggle.Variant = SiteToggleVariant
SiteToggle.VariantFunc = SiteToggleVariantFunc

export default SiteToggle;
