import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import StyledDrawer from "@/views/NUI/StyledDrawer";
import { useDisclosure } from "@/xAppLib/Hooks/useDisclosure";
import { ComplexField } from "@/views/ordering/components/ComplexField";
import { RadioGroup } from "./UserSignUpAccountDetails";
import { TextField } from "../../../views/UIelems/v2/Form/TextField";
import MaskedInput from "react-text-mask";

export function ConcessionsDrawer({onChange, fl, fields, values, validate, ...props}) {
	
	const disclosure = useDisclosure();
	const value =  fl.name == 'has_dva_card' && values['number'] || values[fields[0]?.name]
	const displayValue = (fl.name == 'has_dva_card') && `${values?.number} ${values?.colour}` || value || ''

	return (
		<>
			{value ?
				<ComplexField {...props} value={displayValue} label={fl?.label||''} onEdit={disclosure.onOpen} />
				:
				<RadioGroup {...fl} value={values[fl.name]} onChange={value => {
					onChange({ name: fl?.name, value })
					value === "yes" && disclosure.onOpen()
				}}/>
			}


			{disclosure.isOpen && (
				<FieldDrawer 
                    values={values}
                    onChange={(val)=> {
						onChange(val)
						!Object.values(val).some(v => v) && onChange({ name: fl.name, value: "no" })
                        disclosure.onClose();
                    }}
                    onCancel={() => {
						!value && onChange({ name: fl.name, value: "no" })
						disclosure.onClose()
					}}
                    label={fl?.label||''}
					fields={fields}
					validate={validate}
				/>
                )
			}
		</>
	);
}

function FieldDrawer({values, label, onChange, onCancel, fields, validate}) {
	const initial = Object.fromEntries(fields.map(f => [f.name, values[f?.name]||'']));
	const [input, setInput] = useState(initial);
	const [errors, setErrors] = useState();

	const handleSave = () => {
		const err = validate(input)
		setErrors(err)
		if(!err||!Object.keys(err).length) {
			onChange(input)
		}
	}

	return (
		<StyledDrawer
			isOpen={true}
			drawerStyle="blue"
			dismissible={true}
			onClose={onCancel}
			title={label}
			content={
				<div className="space-y-6 my-4">
					{fields.map((f) => {
						return f.type === "select" && 
							<Form.Select
								label={f?.label}
								options={f?.options}
								value={input[f?.name]}
								onChange={(e, { value })  => setInput({...input, [f.name]: value})}
								fluid
								error={errors?.[f.name]}
								/>
						|| f.type === "masked" && 
							<Form.Input
								label={f.label}
								error={errors?.[f.name]}
								fluid
								>
								<MaskedInput
									mask={f.mask}
									placeholder={f.placeholder}
									value={input[f?.name]}
									name = {f.name}
									onChange={(e) => { 
										const value = e?.target?.value && e.target.value.toString() || ''
										setInput({...input, [f.name]: value?.replace(/-/g, '')}) }
									}
									type = 'text'
								/>
							</Form.Input>
						|| 
						<TextField
							label={f?.label}
							name={f?.name}
							onChange={(e, { value }) => setInput({...input, [f.name]: value})}
							value={input[f?.name]}
							placeholder={f?.placeholder}
							fluid
							error={errors?.[f.name]}
						/>
					})}
				</div>
			}
			
			footer={(
				<div className="flex flex-row space-x-3 items-stretch">
					<Button type="button" fluid secondary onClick={onCancel}>
						Cancel
					</Button>

					<Button type="button" fluid primary 
					//disabled={!form_valid} 
					onClick={handleSave}>
						Save
					</Button>
				</div>
			)}
		/>
	);
}
