import * as React from "react"
import * as ProgressPrimitive from "@radix-ui/react-progress"

import { cn } from "@/lib/utils"

const Progress = React.forwardRef(({ className, value = 0, fg, bg, showDot = false, style, ...props }, ref) => {
  const dotSize = 10;

  const getDotPosition = () => {
    if (value <= 30) {
      return `${value}%`;
    } else if (value >= 90) {
      return `calc(${value}% - ${dotSize}px)`;
    }
    return `calc(${value}% - ${dotSize/2}px)`;
  };

  return (
    <div className="relative">
      <ProgressPrimitive.Root
        ref={ref}
        className={cn(
          "relative h-6 w-full overflow-hidden rounded-full border-8 bg-progress-background border-progress-background",
          className
        )}
        style={{
          ...style,
          ...(bg && {
            backgroundColor: bg,
            borderColor: bg,
          }),
        }}
        {...props}>
        <ProgressPrimitive.Indicator
          className="h-full w-full flex-1 transition-all rounded-full bg-progress-foreground"
          style={{
            transform: `translateX(-${100 - (value || 0)}%)`,
            ...(fg && {
              backgroundColor: fg,
            }),
          }} />
      </ProgressPrimitive.Root>
      {showDot && (
        <div
          className="absolute rounded-full bg-white border-white border-2 z-10"
          style={{
            width: `${dotSize}px`,
            height: `${dotSize}px`,
            left: getDotPosition(),
            top: `calc(50% - ${dotSize / 2}px)`,
          }}
        />
      )}
    </div>
  )
})
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
