import React, { useState, useMemo, useCallback } from "react";
import { ViewEditDets } from "xAppLib/DataList";
import SelectOption from "views/UIelems/SelectOption";
import script_model from "models/script_model";
import obj_diff from "xAppLib/helpers/obj_diff";
import API_service from "xAppLib/providers/API_service";
import { useAsync } from "xAppLib/Hooks/useAsync";

const MIN_REASON_LENGTH = 4;
const DOC_FORM_SECTION = "doc";

export const EditAssignedDoctor = ({ scriptData, doctorList, onUpdate }) => {
  const assignedDoctorData = useMemo(
    () => ({
      doc: {
        get() {
          return scriptData.doc;
        },
        doc_name: scriptData.doc_db_data__name,
      },
      reason: null,
    }),
    [scriptData]
  );

  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const [reason, setReason] = useState(null);
  const isFormValid = useMemo(
    () =>
      reason &&
      reason?.length >= MIN_REASON_LENGTH &&
      selectedDoctorId &&
      selectedDoctorId !== scriptData.doc
        ? true
        : false,
    [scriptData, selectedDoctorId, reason]
  );

  const onDoctorSelect = useCallback(
    (value) => {
      setSelectedDoctorId(value);
    },
    [setSelectedDoctorId]
  );

  const resetInput = useCallback(() => {
    setSelectedDoctorId(null);
    setReason(null);
  }, [setReason, setSelectedDoctorId]);

  const saveData = useAsync({
    immediate: false,
    fn: async () => {
      const dataDiff = obj_diff(
        { doc: assignedDoctorData.doc.get() },
        { doc: selectedDoctorId }
      );
      if (Object.keys(dataDiff.to || {}).length === 0) return;
      const params = {
        sid: scriptData.sid,
        diff: { ...dataDiff, part: DOC_FORM_SECTION },
        note: reason,
      };
      const updateResponse = await API_service.load_data(
        "scripts/upd_script",
        params
      );
      if (updateResponse.res === "ok") {
        const { doc_db_data } = updateResponse.upd_rec[0];
        const doctorName = doc_db_data.name;
        onUpdate?.({
          ...scriptData,
          doc: selectedDoctorId,
          doc_db_data,
          doc_db_data__name: doctorName,
        });
      } else {
        console.warn("error updating doctor details");
      }
    },
  });

  const onDetailsChange = useCallback(
    (field, fieldValue, _formValues) => {
      if (field === "reason") {
        setReason(fieldValue);
      }
    },
    [setReason]
  );

  return (
    <ViewEditDets
      size="mini"
      itm_data={assignedDoctorData}
      det_fields={script_model.COMM_DOC_FIELDS}
      cust_fields={{
        doc_set: (
          <SelectOption
            value={scriptData.doc}
            placeholder="Select doctor"
            onSelect={onDoctorSelect}
            noConfirm={true}
            options={doctorList}
          />
        ),
      }}
      onChange={onDetailsChange}
      onCancel={resetInput}
      isFormValid={isFormValid}
      allow_fld_edit={false}
      loading={saveData.loading}
      onSave_dets={saveData.fn}
    />
  );
};
