import React, { useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useProfile, useUser } from '../xAppLib/Hooks';
import UserPic from '../xAppLib/Users/UserPic';
import { getUserDisplayName } from 'helpers/getUserDisplayName';

export const ALL_OPTION = { key: 'all', text: 'All', value: 'all' };
export const NEW_OPTION = { key: 'new', text: 'Someone else', value: 'new' };

const FamilySelector = ({ onSelect, value, addAllOption, addNewOption, header = 'Profile' }) => {
	const { prof, user_det, user_in } = useUser();
	const profiles = useProfile()[1];

	const profileOptions = [{ ...prof, ...user_det }, ...profiles.slice(1)];
	const options = useMemo(
		() => [
			...(addAllOption ? [ALL_OPTION] : []),
			...profileOptions.map((p) => ({
				key: p.uid,
				value: p.uid,
				content: (
					<div className="flex gap-4">
						<UserPic photoURL={p.photoURL} email={p.email} name={`${p.first_name} ${p.last_name}`} className="!rounded-full" />
						<p>{getUserDisplayName({ prof: p }, { format: 'full', defaultValue: 'User' })}</p>
					</div>
				),
			})),
			...(addNewOption ? [NEW_OPTION] : []),
		],
		[addAllOption, profileOptions]
	);

	const selectedOption = profileOptions.find((p) => p.uid === value);
	const selectedName = selectedOption ? getUserDisplayName({ prof: selectedOption }, { format: 'full', defaultValue: 'Select a profile' }) : "Select a profile";

	const handleChange = (_, data) => {
		const selectedProfileObj = profileOptions.find((p) => p.uid === data.value);
		onSelect?.(selectedProfileObj);
	};

	if (!user_in || !prof || !profiles || !prof || !profiles) {
		return null;
	}

	return (
		<div>
			{header && <h3>{header}</h3>}
			<Dropdown
				fluid
				selection
				options={options}
				onChange={handleChange}
				value={value}
				trigger={
					<div className="flex gap-4">
						{selectedOption && <UserPic photoURL={selectedOption.photoURL} email={selectedOption.email} name={selectedName} className="!rounded-full" />}
						<p>{selectedName}</p>
					</div>
				}
			/>
		</div>
	);
};

export default React.memo(FamilySelector);