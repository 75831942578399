import { validateDVANumber, validateDVAColour } from './dvaValidation';
import moment from 'moment';

export const validateAuthFields = (formData) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const hasNumber = /\d/.test(formData.password);
    const hasLetter = /[a-zA-Z]/.test(formData.password);

    if (!formData.email || !emailRegex.test(formData.email)) {
        errors.email = 'Please enter a valid email address';
    }

    if (!formData.password || formData.password.length < 8 || !hasNumber || !hasLetter) {
        errors.password = 'Password must be at least 8 characters and contain a number and letter';
    }

    if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
    }

    if (!formData.firstName?.trim()) {
        errors.firstName = 'Please enter your first name';
    }
    if (!formData.lastName?.trim()) {
        errors.lastName = 'Please enter your last name';
    }

    if (!formData.consent) {
        errors.consent = 'You must agree to the terms to create an account';
    }

    return errors;
};

export const validateDOBField = (val) => {
   return   val && /\d{4}\-\d{2}\-\d{2}/.test(val) && moment(val).isValid() 
            && moment().diff(val, 'years') >= 0 && moment().diff(val, 'years') <= 120
}

export const validateAccountDetails = (formData) => {
    const errors = {};
    const hasDVANumber = Boolean(formData.dvaNumber?.number?.trim());
    const hasDVAColour = Boolean(formData.dvaNumber?.colour);
    const hasDate = Boolean(formData.dateOfBirth);

    if (hasDVANumber || hasDVAColour) {
        if (!hasDVANumber) {
            errors.number = 'Please enter a DVA number';
        } else if (!validateDVANumber(formData.dvaNumber.number)) {
            errors.number = 'Please enter a valid DVA number';
        }

        if (!hasDVAColour) {
            errors.colour = 'Please select a card colour';
        } else if (!validateDVAColour(formData.dvaNumber.colour)) {
            errors.colour = 'Please select a valid card colour';
        }
    }

    if(hasDate && !validateDOBField(formData.dateOfBirth)){
        errors.dateOfBirth = 'Please enter a valid date of birth'
    }

    return errors;
};