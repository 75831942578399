import React, { useEffect, useState } from 'react'
import { Message } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { waiting_room_model } from "../../models/waiting_room_model";

export default function NeedReviewBanner() {
	const [scripts, setScripts] = useState([]);
	useEffect(() => {
		return waiting_room_model.watch_need_review(recs => setScripts(Object.values(recs || {})));
	}, []);

	const count = scripts.length;
	if (count === 0) return null;

	return (
		<Link to="/scripts/list/need_review" className='flex-auto flex lg:w-[calc(33.333333%_-_2rem)]'>
			<Message
				warning
				icon="map signs"
				header={count > 1
					? `${count} scripts requiring manual review`
					: `${count} script requiring manual review`
				}
			/>
		</Link>
	)
}
 
