import React from "react";
import { Link } from "react-router-dom";
import { Container, Menu, Grid, Icon } from "semantic-ui-react";
import { SiteVariationSelector } from "../../xAppLib/UIelems/SiteVariationSelector";

import DeviceOrgSelector from "./DeviceOrgSelector";

const menu = [
	{
		title: "About Us",
		links: [
			/*{
				name: "Our Story",
				link: "/about-instantcosmetics",
			},
			{
				name: "Our Team",
				link: "/instantcosmetics-team",
			},*/
			{
				name: "Privacy Policy",
				link: "#",
			},
			{
				name: "Customer T&Cs",
				link: "#",
			},
			{
				name: "Platform T&Cs",
				link: "#",
			}
		]
	},
	{
		title: "Customers",
		links: [
			{
				name: "Contact Us",
				link: "/contact",
			},
			{
				name: "FAQs",
				link: "/instantcosmetics-faqs",
			},
		]

	},
	{
		title: "Follow Us",
		links: [
			{
				name: "Instagram",
				link: "https://www.instagram.com/instantcosmeticsau/",
			},
			{
				name: "Facebook",
				link: "https://www.facebook.com/InstantCosmetics/",
			},
			{
				name: "TikTok",
				link: "https://www.tiktok.com/@instantcosmetics?_t=8pQdzMXrAtc&_r=1",
			},
			{
				name: "LinkedIn",
				link: "https://www.linkedin.com/company/instant-cosmetics/",
			}
		]
	}
];

const Footer = () => {


  return (
    <div className='py-4 sm:py-14 ' id="icosm-site-footer">
        <Container>
			<a href='/'><img className='logo mb-[36px]' src={app.runtime.headerLogo} alt={app.runtime.name} /></a>

			<Grid divided={false} inverted stackable>
				
				<Grid.Row className="!justify-between">
					<Grid.Column mobile={12} computer={7} className="footer-lg flex-col p-0">
						<p className="!text-sm">We respectfully acknowledge the Traditional Custodians of the land on which we work and gather. We recognise their connections to Country, and we pay our respects to Aboriginal and Torres Strait Islander cultures, and to Elders past and present.</p>
						<p className="!text-sm mt-4">Level 8 / 637 Flinders St.,<br />Docklands VIC 3008</p>
						<p className="!text-sm">Copyright © {new Date().getFullYear()} {app.runtime.name}</p>
					</Grid.Column>

					<Grid.Column mobile={16} computer={9}>
						<div className='menu-items-wrap top  md:text-left'>
							<Menu.Menu className='footer-menu menu-items top flex flex-col md:flex-row  flex-wrap w-full m-auto'>
								<div className="grid grid-cols-3 md:grid-cols-3 gap-1 md:gap-6">
									{menu.map(({ title, links }, i) => {
										return <div className="grid-col-1 flex flex-col" key={i}>
												<p className="uppercase !font-semibold mb-0">{title}</p>
												{links.map(({ name, link }, i) => {
													switch (name) {
														case 'Privacy Policy':
															return (
															<Link className="item main" key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Privacy', pn:'privacy-policy'})} to={link}>{name}</Link>
															);
														case 'Customer T&Cs':
															return (
															<Link className="item main" key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Customer Terms and Conditions', pn:'customer-terms-and-conditions'})} to={link}>{name}</Link>
															);
														case 'Platform T&Cs':
															return (
															<Link className="item main leading-none" key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Platform Terms and Conditions', pn:'platform-terms-and-conditions'})} to={link}>{name}</Link>
															);
														default:
															return (
															<Link className="item main" key={name + i} to={link}>{name}</Link>
															);
													}
												})}
											</div>
									})}
		
								</div>
							</Menu.Menu>
						</div>
					</Grid.Column>

					

				</Grid.Row>
				{/* <Grid.Row className="block lg:hidden">
					<Grid.Column mobile={12} >{desc}</Grid.Column>
				</Grid.Row> */}
				{(true || app.acl.is_nurse || app.acl.is_admin || app.acl.is_supp || app.acl.is_doc) && <Grid.Row>
					<Grid.Column className="text-center md:text-left">
						<DeviceOrgSelector />
						{app.runtime.org?.oid!='app'&&<i>{app.runtime.org?.name}<br/></i> || null}
						<p>Build: {__GIT_HASH__} - {__BUILD__}
						{app.dev_env &&  ' - dev' || app.test_env &&  ' - test' || ''}
						&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
						<Icon
							compact="true"
							name="refresh"
							title="Refresh"
							circular
							onClick={_ => location.reload(true)}
							style={{cursor: 'pointer'}}
						/></p>
						<SiteVariationSelector/>
					</Grid.Column>
				</Grid.Row>}
			</Grid>
        </Container>
	</div>
  );
};

export default Footer;
