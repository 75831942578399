import React from 'react';
import { useCopyToClipboard } from "@/xAppLib/Hooks/useCopyToClipboard";

const CHECK = <>&#x2713;</>;

const Copy = ({children, value = children}) =>  {
	const {copied, copyToClipboard} = useCopyToClipboard();

	return (
		<span className="cursor-pointer" onClick={() => copyToClipboard(value)}>
			{children} {!!copied && CHECK}
		</span>
	);
}

export function BuildInfo() {

	const hash = __GIT_HASH__;
	const version = __BUILD__;
	const branch =  app.settings.dev_env && typeof __GIT_BRANCH__ === 'string' ? __GIT_BRANCH__ : null;
	const suffix = (
		app.dev_env && 'dev'
		|| app.test_env && 'test'
		|| ''
	);
	const dash = (branch || suffix) ? '-' : '';

	return <>Build: <Copy>{hash}</Copy> - <Copy>{version}</Copy> {dash} {branch ? <Copy>{branch}</Copy> : suffix}</>
}