import React, { useCallback, useEffect } from "react";
import UniFormMed from "views/med/UniFormMed";
import { Button } from "semantic-ui-react";
import Alert from "../../../NUI/Alert";

const FIELDS = [
	{
		label: 'Priority Queue',
		name: "skip_the_queue",
		type: "boolean",
		valid_not_required: true,
	}
];

const SkipTheQueue = (props) => {
	const { Section, cart, enabled } = props

	useEffect(() => {
		if (!enabled) {
			props.onUpdate({skip_the_queue: undefined});
		}
	}, [enabled]);

	const mins_to_hum_time = useCallback(
		(mins) => {

		const hs = Math.floor(mins/60)
		const ms = mins%60;

		return `${hs>1&&`${hs} hours` || hs==1&&'1 hour' || ''} ${ms>1&&`${ms} minutes` || ms==1&&'1 minute' || ''}`
	}, [])

	if (!enabled) return null;

	return (
		<UniFormMed {...props} section="skip_the_queue" fields={FIELDS}>
			{(values, valids, uf_this) => {
				return <Section>
							<Section.Header>
								Priority Queue
							</Section.Header>

							<Section.Content>
								<Alert
									header={`Need a speak to a doctor sooner than the current ${mins_to_hum_time(app.site_status?.wr_stat?.prediction?.data?.pc_98_wait)} wait time?`}
								/>
								<p>We are experiencing longer than usual wait times due to a high volume of patients. 
									For your convenience, we offer an option to priotitise your consultation.
								</p>

								<Section.Content>
									<div className="flex items-center justify-between">
										<label htmlFor="skipTheQueue" className="font-semibold">Priority Queue fee: <span className="font-normal">${cart.SKIP_QUEUE_PRICE}</span></label>
										<Button
											id="skipTheQueue"
											onClick={e => {
												return uf_this.handleInputChange(e, {
												name: 'skip_the_queue',
												value: !values.skip_the_queue,
											})}}
											type="button"
											basic
											color={values.skip_the_queue ? "red" : undefined}
											className="px-4 py-2 rounded-lg font-medium text-white transition"
										>
											{values.skip_the_queue ? "Remove" : "Add"}
										</Button>
									</div>
								</Section.Content>

							</Section.Content>
						</Section>;
			}}
		</UniFormMed>
	);
};

export default SkipTheQueue;


