/**
 * 
 * Creates an object composed of keys generated from the results of running each element of collection thru iteratee. 
 * The order of grouped values is determined by the order they occur in collection. 
 * The corresponding value of each key is an array of elements responsible for generating the key. 
 * The iteratee is invoked with one argument: (value).
 * 
 * @param {T[]} arr
 * @param {string | Function} keyOrFn
 * @returns {Object<string, T[]>}
 * @template T
 */
const groupBy = function (arr, keyOrFn) {
    const keyFn = typeof keyOrFn === 'function' ? keyOrFn : obj => obj[keyOrFn];
    return arr.reduce((result, obj, i, arr) => {
        const groupKey = keyFn(obj, i, arr);
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(obj);
        return result;
    }, {});
}

export default groupBy