const containsOneUniqueValue = (arr) => {
    return [...new Set(arr)].length === 1
}

const orderAndDeliveryStatus = (evermed) => {
    const orderLineItems = evermed?.order?.lines || []
    const allStatusesPresent = orderLineItems.every(line => line?.lineStatus && line?.packageStatus)

    if (allStatusesPresent) {
        const lineStatuses = orderLineItems.map(line => line.lineStatus)
        const packageStatuses = orderLineItems.map(line => line.packageStatus)
        return {
            lineStatus: containsOneUniqueValue(lineStatuses) ? lineStatuses[0] : 'Multiple Statuses - Please check',
            packageStatus: containsOneUniqueValue(packageStatuses) ? packageStatuses[0] : 'Multiple Statuses - Please check'
        }
    }

    return {
        lineStatus: evermed?.orderStatus || 'Status not available',
        packageStatus: evermed?.deliveryStatus || 'Status not available'
    }
}

const getSelectedDeliveryMethod = (evermed, deliveryCode) => {
    const flat_del_ops = evermed.do?.map((method) => method.options).flat() || [];
    return flat_del_ops.find((opt) => opt.code === deliveryCode?.replace('EM_', ''));
}

const computeDeliveryMethodsToShow = (evermed) => {
    if(!evermed?.do?.length) return [];
    const deliveryCategoriesToOptions = evermed?.do?.reduce((acc, method) => {
        acc[method.displayName] = method.options;
        return acc;
    }, {});
    const doorDashOptions = deliveryCategoriesToOptions['DoorDash'] || [];
    const doorDashHasATL = doorDashOptions.some((opt) => !!opt.hasAuthorityToLeave);
    const presentableDoorDashOptions = doorDashOptions.slice(0, doorDashHasATL ? 1 : 3);
    delete deliveryCategoriesToOptions['DoorDash'];
    const presentableOptions = Object.values(deliveryCategoriesToOptions).flat().concat(presentableDoorDashOptions);
    return presentableOptions.sort((a, b) => new Date(a.estimatedLatestDeliveryDate) - new Date(b.estimatedLatestDeliveryDate));
}

const _DELIVERY_PROVIDERS_SP_SURCHARGE = ['StarTrack', 'Australia Post']
const _SURCHARGE_PER_PRODUCT_QTY = 1;

const sumSPQty = (suggested_products = []) => {
    return suggested_products?.length && suggested_products.reduce((acc, p) => acc += p.qty, 0) || 0;
}

const calculateSPDeliverySurcharge = (code, provider, qtyOfSuggestedProducts, hasOnepassDiscount) => {
    if(!code || !provider) return 0;
    const onepass_subsidised = code === 'StandardPost' && hasOnepassDiscount;
    const surcharge_applicable = _DELIVERY_PROVIDERS_SP_SURCHARGE.includes(provider) && !onepass_subsidised;
    return surcharge_applicable ? (qtyOfSuggestedProducts * _SURCHARGE_PER_PRODUCT_QTY) : 0;
}

const getMedicationPriceByLevel = (pricingLevel, prices) => {
    if (pricingLevel === 'concession' && prices.concession) return prices.concession;
    if (pricingLevel === 'general' && prices.general) return prices.general;
    return prices.original;
};

const constructMedicationItemForCart = ({medication_data, selected_medication}) => {
    return {
        ...selected_medication,
        id: medication_data.scid,
        price: selected_medication.config.charged_price,
        type: 'medication',
        qty: 1,
        max_qty: 1,
        medication_data
    }
}

const constructDummyOrderUpdatePayload = (order) => {
    if(!order) return {err: 'No order'};
    const orderNumber = order.orderNumber;
    const partnerOrderReference = order.partnerOrderReference;
    const lines = order.lines.map((line) => {
        return {
            prescriptionToken: line?.prescriptionToken,
            catalogProductId: line?.catalogProductId,
            lineStatus: line?.lineStatus || 'NEW',
            packageStatus: line?.packageStatus || 'NEW',
        };
    });
    return { orderNumber, partnerOrderReference, lines, isDelayedActionNotification: false };
}

export {
    orderAndDeliveryStatus,
    getSelectedDeliveryMethod, 
    computeDeliveryMethodsToShow,
    sumSPQty,
    calculateSPDeliverySurcharge,
    constructMedicationItemForCart,
    getMedicationPriceByLevel,
    constructDummyOrderUpdatePayload
}