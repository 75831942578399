import { useEffect } from "react";
import { useNotifications } from "../notifications/hooks/useNotifications";

export const useRegisterNotificationPlugins = () => {
    const { registerPlugin } = useNotifications();

    useEffect(() => {
        registerPlugin(
            'patient_appointment',
            () => import('./patient_appointment/patient_appointment_plugin'),
        );
        registerPlugin(
            'could_not_call_consult',
            () => import('./could_not_call_consults/could_not_call_consult_plugin'),
        );
    }, []);
}