
import React, { useRef } from 'react';

import { BottomMenuSubMenuItem } from './BottomMenuSubMenuItem';

export const BottomMenuSubMenu = ({ closeMenu, expanded = false, menuItems = [] }) => {
	const containerRef = useRef();
	const height = expanded ? containerRef?.current?.scrollHeight : 0;

	return (
		<div
			className="bg-is2-gray-10 duration-300 overflow-hidden transition-height"
			ref={containerRef}
			style={{ height }}
		>
			<div className="px-4 py-3.5">
				{menuItems.map((menuItem, index) => (
					<BottomMenuSubMenuItem closeMenu={closeMenu} key={index} label={menuItem.label} link={menuItem.link} />
				))}
			</div>
		</div>
	);
};
