import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import {
    getFirestore,
    collection,
    query,
    where,
    onSnapshot,
    orderBy,
    doc,
    updateDoc,
    writeBatch,
} from 'firebase/firestore';
import firebaseApp from '../../xAppLib/providers/firebase';

export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
    const [notificationsPanel, setNotificationsPanel] = useState({ open: false, autoFocus: false });
    const [notifications, setNotifications] = useState([]);
    const [dynamicNotifications, setDynamicNotifications] = useState(new Map());
    const [pluginRegistry, setPluginRegistry] = useState(new Map());
    const [loadedPlugins, setLoadedPlugins] = useState(new Map());
    const [firestoreUnreadCount, setFirestoreUnreadCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const handleNotificationsOpen =
        useCallback(
            (event) => {
                const isMouseOrTouch =
                    event.pointerType === 'mouse' ||
                    event.pointerType === 'touch' ||
                    event.detail > 0;
                setNotificationsPanel(prev => ({
                    open: !prev.open,
                    autoFocus: !isMouseOrTouch,
                }));
            }, []
        );

    useEffect(() => {
        // Check if any notifications belong to a different user
        const hasOtherUserNotifications = Array.from(dynamicNotifications.values())
            .some(notification => notification.uid && notification.uid !== app?.user?.uid);
        if (hasOtherUserNotifications) {
            setDynamicNotifications(() => new Map());
        }
    }, [app.user?.uid]);

    const dynamicUnreadCount = useMemo(() =>
        Array.from(dynamicNotifications.values()).filter(n => !n.read).length,
        [dynamicNotifications]
    );

    const unreadCount = useMemo(() =>
        firestoreUnreadCount + dynamicUnreadCount,
        [firestoreUnreadCount, dynamicUnreadCount]
    );

    const sortedNotifications =
        notifications.slice().sort((a, b) => {
            if (a.overrides?.persistent && !b.overrides?.persistent) return -1;
            if (!a.overrides?.persistent && b.overrides?.persistent) return 1;

            const isPinned = (notification) =>
                !notification.read
                && (
                    notification.priority === 'urgent' ||
                    notification.priority === 'high' ||
                    notification.overrides?.pinned
                );

            if (isPinned(a) && !isPinned(b)) return -1;
            if (!isPinned(a) && isPinned(b)) return 1;
            return b.created.seconds - a.created.seconds;
        });

    const allNotifications = [
        ...Array.from(dynamicNotifications.values()).sort((a, b) => {
            if (a.overrides?.persistent && !b.overrides?.persistent) return -1;
            if (!a.overrides?.persistent && b.overrides?.persistent) return 1;
            return b.created.getTime() - a.created.getTime()
        }
        ),
        ...sortedNotifications,
    ];

    const setDynamicNotification = useCallback((key, notification) => {
        setDynamicNotifications(prev => {
            const existing = prev.get(key) || {};
            const newNotification = {
                ...(existing || {}),
                read: null,
                ...notification,
                notification_id: `dynamic-${key}`,
                created: existing?.created || new Date(),
                isDynamic: true,
                userId: app.user?.uid,
            };
            return new Map(prev).set(key, newNotification);
        });
    }, [app.user?.uid]);

    const removeDynamicNotification = useCallback((key) => {
        setDynamicNotifications(prev => {
            const updated = new Map(prev);
            updated.delete(key);
            return updated;
        });
    }, []);

    const registerPlugin = useCallback((type, importFn) => {
        if (pluginRegistry.has(type)) return;

        setPluginRegistry(prev => new Map(prev).set(type, {
            load: async () => {
                const module = await importFn();
                return module.default || module;
            }
        }));
    }, [pluginRegistry]);

    const usePlugin = useCallback((type) => {
        if (!type || !pluginRegistry.has(type)) return null;

        if (loadedPlugins.has(type)) {
            return loadedPlugins.get(type);
        }

        pluginRegistry.get(type).load()
            .then(plugin => {
                setLoadedPlugins(prev => new Map(prev).set(type, plugin));
            })
            .catch(error => {
                console.error(`Error loading plugin for type ${type}:`, error);
            });

        return null;
    }, [pluginRegistry, loadedPlugins]);

    useEffect(() => {
        if (!app?.user?.uid) return;

        const db = getFirestore(firebaseApp, 'main');
        const notificationsRef = collection(db, 'notifications');

        const notificationsQuery = query(
            notificationsRef,
            where("userId", "==", app.user.uid),
            orderBy("created", "desc")
        );

        const unreadQuery = query(
            notificationsRef,
            where("userId", "==", app.user.uid),
            where("read", "==", null)
        );

        setLoading(true);
        const notificationsUnsubscribe = onSnapshot(
            notificationsQuery,
            snapshot => {
                const notificationsData = snapshot.docs
                    .map(doc => ({
                        ...doc.data(),
                        notification_id: doc.id
                    }));
                setNotifications(notificationsData);
                setLoading(false);
            },
            error => {
                console.error('Error with notifications subscription:', error);
                setLoading(false);
            }
        );

        const unreadUnsubscribe = onSnapshot(
            unreadQuery,
            snapshot => {
                setFirestoreUnreadCount(snapshot.size);
            },
            error => {
                console.error('Error with unread count subscription:', error);
            }
        );

        return () => {
            notificationsUnsubscribe();
            unreadUnsubscribe();
        };
    }, [app?.user?.uid]);

    const setNotificationRead = useCallback(async (notificationId, isRead) => {
        const db = getFirestore(firebaseApp, 'main');
        const notificationRef = doc(db, 'notifications', notificationId);

        const notification = notifications.find(n => n.notification_id === notificationId);

        if (notification) {
            try {
                await updateDoc(notificationRef, {
                    read: isRead ? Date.now() : null
                });
            } catch (error) {
                console.error('Error updating notification read status:', error);
            }
            return;
        }
        const dynamicKey = notificationId.replace('dynamic-', '');
        const dynamicNotification = dynamicNotifications.get(dynamicKey);
        if (!dynamicNotification) return;

        setDynamicNotifications(prev => {
            const existing = prev.get(dynamicKey) || {};
            if (!existing) return prev;
            const newNotification = {
                ...existing,
                read: isRead ? Date.now() : null,
            };
            return new Map(prev).set(dynamicKey, newNotification);
        });
    }, [notifications, dynamicNotifications, setDynamicNotifications]);

    const setNotificationsRead = useCallback(async (notificationIds, isRead) => {
        const db = getFirestore(firebaseApp, 'main');
        const writeBatchRef = writeBatch(db);

        const updates = notificationIds.map(notificationId => {
            if (notificationId.startsWith('dynamic-')) {
                const dynamicKey = notificationId.replace('dynamic-', '');
                return { isDynamic: true, key: dynamicKey };
            }
            const notificationRef = doc(db, 'notifications', notificationId);
            writeBatchRef.update(notificationRef, { read: isRead ? Date.now() : null });
            return { isDynamic: false };
        });

        const dynamicUpdates = updates.filter(u => u.isDynamic);
        if (dynamicUpdates.length > 0) {
            setDynamicNotifications(prev => {
                const updated = new Map(prev);
                dynamicUpdates.forEach(({ key }) => {
                    const existing = updated.get(key);
                    if (existing) {
                        updated.set(key, { ...existing, read: isRead ? Date.now() : null });
                    }
                });
                return updated;
            });
        }

        try {
            await writeBatchRef.commit();
        } catch (error) {
            console.error('Error batch updating notification read status:', error);
        }
    }, [setDynamicNotifications]);

    const setNotificationToastRead = useCallback(async (notificationId, isRead) => {
        const db = getFirestore(firebaseApp, 'main');
        const notificationRef = doc(db, 'notifications', notificationId);

        const notification = notifications.find(n => n.notification_id === notificationId);

        if (!notification) return;

        try {
            await updateDoc(notificationRef, {
                toastRead: isRead ? Date.now() : null
            });
        } catch (error) {
            console.error('Error updating notification read status:', error);
        }
        return;
    }, [notifications]);

    return (
        <NotificationsContext.Provider value={{
            notifications: allNotifications,
            loading,
            unreadCount,
            setNotificationRead,
            setNotificationsRead,
            setNotificationToastRead,
            setDynamicNotification,
            removeDynamicNotification,
            registerPlugin,
            usePlugin,
            notificationsPanel,
            setNotificationsPanel,
            handleNotificationsOpen,
        }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotificationPlugin = (pluginType) => {
    const { usePlugin } = React.useContext(NotificationsContext);
    const [plugin, setPlugin] = React.useState(null);

    React.useEffect(() => {
        const result = usePlugin(pluginType);
        setPlugin(result);
    }, [usePlugin, pluginType]);

    return plugin;
}; 