import { useCallback, useState } from "react";
import { loginHandlers } from "@/views/UIelems/v2/single_use/loginHandlers";
import { useAsyncUpdate } from "@/xAppLib/Hooks/useAsync";

export function useAuthenticationOptionsProps({ initialVariant = 'login'} = {}) {
	const [variant, setVariant] = useState(initialVariant);
	const [errorMessage, setErrorMessage] = useState(null);
	
	const onAlternativeLinkClick = useCallback(() => {
		return setVariant(v => v === 'login' ? 'signup' : 'login');
	}, []);

	const {error, loading, fn: onSelectProvider} = useAsyncUpdate(async ({ provider, ...payload }) => {
		setErrorMessage(null);
		
		try {
			await loginHandlers[provider](payload)
		} catch (error) {
			if (['auth/popup-closed-by-user', 'auth/cancelled-popup-request', 'auth/popup-blocked', '1001', '-5', '12501'].includes(error.code)) {
				return;
			}

			let message = error.message;
			if (error.code === 'auth/internal-error') {
				message = 'Password cannot be empty.';
			}

			if (error.code === 'auth/invalid-login-credentials') {
				message = 'Invalid email or password.';
			}

			setErrorMessage(message.replace('Firebase:', '').split('(')[0].trim());
			
			return Promise.reject(message.replace('Firebase:', '').split('(')[0].trim());
		}
	});

	const [signUpState, setSignUpState] = useState({
		stage: 'auth',
		providerData: null,
		signupResult: null
	})

	return {
		variant,
		onSelectProvider,
		onAlternativeLinkClick,
		loading,
		error: errorMessage || error?.message,
		setSignUpState,
		signUpState,
	}
}