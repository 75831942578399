import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { useAsync, useAsyncUpdate } from "../Hooks/useAsync";
import firebase_auth from "../providers/firebase_auth";
import { ErrorMessage, handle_error } from "./MFA";

const DEBUG = false;
const debug = (...args) => DEBUG && console.debug('[HandleMultiFactorAuthentication]', ...args);

const INITIAL_STATE = {
	resolver: null,
	onMfaResult: null,
	verifier: null,
};

function CodeInput({onSubmit, error, loading}) {
	const [verificationCode, setVerificationCode] = useState('');
	const submit = ev => {
		ev.preventDefault();
		return onSubmit(verificationCode);
	};
	const primary = verificationCode.length > 1;

	return (
		<Form loading={loading} error={Boolean(error)} onSubmit={submit}>
			<div className="flex flex-row items-stretch">
				<div className="flex-grow">
					<Form.Input
						autoComplete="off"
						required={true}
						name="verificationCode"
						value={verificationCode}
						onChange={(e) => setVerificationCode(e.target.value)}
						placeholder={"Enter verification code"}
						fluid
						control={Input}
						autoFocus
					/>
				</div>
				<div className="flex items-stretch pl-2">
					<Button type="submit" primary={primary} disabled={verificationCode.length < 1} loading={loading}>
						Verify
					</Button>
				</div>
			</div>
			<ErrorMessage error={error}/>
		</Form>
	);
}

export function HandleMultiFactorAuthentication() {
	const [state, setState] = useState(INITIAL_STATE);

	useEffect(() => {
		function onHandleFactor(resolver, onMfaResult) {
			setState({resolver, onMfaResult, verifier: null});
		}

		app.on(app.events.MFA_CONFIRM, onHandleFactor);

		return () => {
			app.off(app.events.MFA_CONFIRM, onHandleFactor);
		};
	}, []);

	const verification = useAsync({
		immediate: !!state.verifier,
		fn: async () => {
			const {resolver, verifier} = state;
			debug('verification', state);

			try {
				const phoneInfoOptions = {
					multiFactorHint: resolver.hints[0],
					session: resolver.session
				};

				const verificationId = await firebase_auth.verifyPhone(phoneInfoOptions, verifier)

				return verificationId;
			} catch (error) {
				if (!handle_error(error)) {
					throw error;
				}
			}
		},
	});

	const submit = useAsyncUpdate(async (verificationCode) => {
		debug('onSubmit', verificationCode);
		try {
			await firebase_auth.verifyPhoneCode(state.resolver, verification.data, verificationCode);
			state.onMfaResult?.resolve();
			setState(INITIAL_STATE);
		} catch (error) {
			if (!handle_error(error)) {
				return Promise.reject(error);
			}
		}
	});

	const createCaptcha = useCallback(node => {
		debug('createCaptcha', node);
		if (!node) {
			return;
		}

		const parent = document.querySelector('.auth-modal')?.parentElement;
		if (parent) {
			parent.style.zIndex = 10000;
		}

		const verifier = firebase_auth.get_captcha(node, {
			'size': 'invisible',
			'callback': (_response) => {
			},
			'expired-callback': (_e) => {
			},
		});

		setState(prev => ({...prev, verifier}));

		return () => {
			debug('clear verifier');
			setState(prev => ({...prev, verifier: null}));
			verifier.clear();
		}
	}, []);

	if (!state.resolver) {
		return null;
	}

	const hint = state.resolver?.hints[0];

	return (
		<>
			<div ref={createCaptcha}/>

			<Modal open
				   closeIcon
				   onClose={() => {
					   setState(INITIAL_STATE);
					   state.onMfaResult.reject(new Error('User cancelled'));
				   }}
				   className="auth-modal"
			>
				<Modal.Header>Verify with mobile number</Modal.Header>
				<Modal.Content>
					{verification.data && <>
						<p>Code has been sent to your nominated mobile {hint.phoneNumber}.</p>
						<p>Didn't get it? <span className="underline cursor-pointer" onClick={verification.fn}>Click here</span> to resend.</p>
					</>}
					<CodeInput loading={submit.loading || verification.loading}
							   onSubmit={submit.fn}
							   error={verification.error || submit.error}
					/>
				</Modal.Content>
			</Modal>
		</>
	);
}
