
import { Link } from 'react-router-dom';
import React from 'react';

export const BottomMenuSubMenuItem = ({ closeMenu, label, link }) => {
	const isAbsoluteUrl = () => {
		return link.startsWith('https://');
	};

	const linkClassName = "group-hover:text-is2-blue hover:!text-is2-blue text-is2-gray-700 text-is2_sm flex w-full !my-0 !py-0";

	return (
		<div className="group cursor-pointer py-1.5" onClick={() => closeMenu()}>
			{isAbsoluteUrl() ? (
				<a className={linkClassName} href={link} rel="noopener noreferrer" target="_blank">{label}</a>
			) : (
				<Link className={linkClassName} to={link}>{label}</Link>
			)}
		</div>
	);
};
