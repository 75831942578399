
import logger from 'xAppLib/libs/logger'

import user_model from 'models/user_model'
import presence_model from 'models/presence_model'
import messaging_model from 'models/messaging_model'
import device_model from 'models/device_model'
import org_model from 'models/org_model'
import orgs_model from 'models/orgs_model'

import merge_multilev_obj from 'xAppLib/helpers/merge_multilev_obj'

import "amp-app.js";
import AppUser from 'models/app_user'
import utm_model from 'models/utm_model'

import inactivity_watch from 'xAppLib/helpers/inactivity_watch'
import waitFor from 'xAppLib/libs/waitFor'
import localstorage_database from 'xAppLib/providers/localstorage_database'

let ran = false

function apply_mode(config) {
	try {
		document.body.classList.add(config.app)
		if (config.theme) {
			document.body.classList.add(config.theme)
		}
		app.settings.icann && document.body.classList.add(app.runtime.mode)
		if (config.css) {
			var head  = document.getElementsByTagName('head')[0];
			for (let i = 0; i < config.css.length; i++) {
				const link  = document.createElement('link');
				link.rel  = 'stylesheet';
				link.type = 'text/css';
				link.href = config.css[i];
				link.media = 'all';
				head.appendChild(link);
			}
		}
		if (config.scripts) {
			for (let i = 0; i < config.scripts.length; i++) {
				document.body.appendChild(document.createElement('script')).src = config.scripts[i];
			}
		}
	} catch (e) {
		console.log(e);
	} 
	
}

export const update_runtime = async () =>  {
	let org_data = await orgs_model.get_record(null, null, {act:'getusrorg', oid:app.runtime.partn_oid || app.runtime.partn_pharm_oid || 'app'});
	if (!org_data || (org_data && org_data.res != 'ok')) {
		org_data = await orgs_model.get_record(null, null, {act:'getusrorg', oid:app.runtime.partn_oid || app.runtime.partn_pharm_oid || 'app'});
	}
	app.runtime.org = org_data?.res == 'ok' && org_data || {}
	app.runtime.uip = org_data?.res == 'ok' && org_data.uip || null

	app.runtime?.start_page && app.history.push(app.runtime.start_page);
}

export const prep_env = async (call_after) => {
	
	if (ran) {
		return
	}
	ran = true
	
	apply_mode(app.runtime)

	localstorage_database.init('device_conf')

	app.timing = {init_start: Date.now()};
	
	// TODO remove extra step in code and use ACL
	app.dev_env_local = app.settings.dev_env_local
	app.dev_env_remote = app.settings.dev_env_remote
	app.dev_env = app.settings.dev_env
	app.test_env = app.settings.test_env
	app.app_data.ws_conf = __RUN_MODE__=='loc' && merge_multilev_obj(require('conf/ws_conf.json'), require('conf/loc_conf.json'))
							|| require('conf/ws_conf.json');
	if (__LOCAL_PROD__) {
		app.app_data.ws_conf.loc.API__SCRIPT_BASE__url = app.app_data.ws_conf.loc.API__SCRIPT_BASE__url.replace('8080','8081')
	}
	logger.init();

	app.settings.guest_name = 'Guest'+Math.round(Math.random()*1000)

	app.user = new AppUser()

	if (__IONIC__) {
		const FirebasePlugin = await waitFor(window,'FirebasePlugin')
		window.__NATIVE_AUTH__ = !!FirebasePlugin?.verifySecondAuthFactor
		window.__NATIVE_OAUTH__ = !!FirebasePlugin?.authenticateUserWithOAuth
	}
	
	const user = user_model.check_user()

	const dvc = (new Promise(resolve => new device_model().init(resolve,user))); 

	const rnt =  update_runtime()

	await Promise.all([user,dvc,rnt])


	app.dev_env && console.log('app.runtime', app.runtime);

	// app.runtime?.start_page && app.history.push(app.runtime.start_page);

	app.dvc.org?.type == 'pharm' && window.location.pathname == '/' && app.history.push(app.runtime?.start_page || '/m/pharm/online/prescription');

	app.dvc.org?.type=='cosm' && window.location.pathname=='/' && (
		app.user.user_in
		&& app.user?.claims?.nurse
		&& app.user.claims.oid
		&& org_model.org_conf_itm(app.user.org, 'meds')
		&& org_model.org_conf_itm(app.dvc.org, 'meds')
		&& org_model.org_conf_itm(app.user.org, 'meds')==org_model.org_conf_itm(app.dvc.org, 'meds')
		&& app.history.push(org_model.new_script_route())
		|| !app.user.user_in && app.history.push('/login')
	);

	// console.log('loaded site configuration and data', app.app_data.ws_conf.site.year)

	call_after();

	app.timing.init_fin = Date.now();
	app.timing.ld_tm = app.timing.init_start - window.time_ld_start;
	app.timing.init_tm = app.timing.init_fin - app.timing.init_start;
	app.runtime.perf = {ld_tm: app.timing.ld_tm/1000, init_tm: app.timing.init_tm/1000};

	logger.site_start();

	messaging_model.init();

	utm_model.init();

	await (new Promise(resolve => setTimeout(resolve, 3*1000))); 

	await presence_model.connect();

	inactivity_watch(2*60, _=>app.trigger( app.events.IDLE_START ), _=>app.trigger( app.events.IDLE_END ), _=>app.trigger( app.events.IDLE_RESET ))

	app.trigger(app.events.SITE_READY);

	!app.user?.user_in && window.Intercom('shutdown');
	window.Intercom('boot', { app_id: 'ksq5hplh', hide_default_launcher: !app.site_status?.use_intercom || app.settings.is_cbls || app.settings.is_cmg, });

}

