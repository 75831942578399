import React, { useEffect, useState } from "react";
import { Segment, Button } from "semantic-ui-react";
import { fetchOptions } from "../api";
import { OPTION_KEYS } from "../consts";
import { getSelectedOptionText } from "../functions";
import { reRunDocpayRule } from "../api";

export const DocPayEditScript = ({ script }) => {
  const [options, setOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [docpay, setDocpay] = useState(script.paym?.docpay?.rule || null);
  const [params, setParams] = useState(script.paym?.docpay?.params || null);
  const [value, setValue] = useState(script.paym?.docpay?.value || null);
  const [differences, setDifferences] = useState(null);

  useEffect(() => {
    const fetchDocpayOptions = async () => {
      setIsLoading(true);
      const loadedOptions = await fetchOptions();
      setOptions(loadedOptions);
      setIsLoading(false);
    };
    if (!options && !isLoading) {
      fetchDocpayOptions();
    }
  }, [setOptions]);

  const findDifferences = (currentParams, newParams) => {
    const diffs = {};
    for (const key in newParams) {
      if (Array.isArray(newParams[key]) && Array.isArray(currentParams[key])) {
        const areArraysEqual =
          newParams[key].length === currentParams[key].length &&
          newParams[key].every(
            (item, index) => item === currentParams[key][index]
          );
        if (!areArraysEqual) {
          diffs[key] = { old: currentParams[key], new: newParams[key] };
        }
      } else if (newParams[key] !== currentParams[key]) {
        diffs[key] = { old: currentParams[key], new: newParams[key] };
      }
    }
    return Object.keys(diffs).length > 0 ? diffs : null;
  };

  const onRerunClick = async () => {
    try {
      setIsLoading(true);
      const result = await reRunDocpayRule(script.sid);
      if (result.ok) {
        const newParams = result.docpay.params;
        const diffs = {
          ...findDifferences(params, newParams),
          ...findDifferences(docpay, result.docpay.rule),
        };
        setDifferences(Object.keys(diffs).length > 0 ? diffs : null);
        setParams(newParams);
        setDocpay(result.docpay.rule);
        setValue(result.docpay.value);
        script.paym = {
          ...script.paym,
          docpay: {
            rule: result.docpay.rule,
            params: newParams,
            value: result.docpay.value,
          },
        };
      }

      setIsLoading(false);
    } catch (e) {
      console.error("Error re-running script", e);
    }
  };

  if (isLoading) {
    return <Segment loading>Loading...</Segment>;
  }

  return (
    <div>
      {docpay && options && params && (
        <>
          {" "}
          <Segment>
            {" "}
            <p>Rule ID: {docpay.rule_id}</p>
            <p>
              Consult Type :{" "}
              {getSelectedOptionText(
                options,
                OPTION_KEYS.CONSULT_OPTIONS,
                params?.consult_type
              )}
            </p>
            <p>
              Med ID :{" "}
              {getSelectedOptionText(
                options,
                OPTION_KEYS.SELECTED_CONSULTS,
                params.med_id
              )}
            </p>
            <HighlightedOption
              options={options}
              optionKey={OPTION_KEYS.SENIORITY}
              label={"Seniority"}
              ruleValues={docpay.seniority}
              selectedValue={params.doc_level}
            />
            {docpay.selected_cons.length ? (
              <p>
                Selected Consults :{" "}
                {getSelectedOptionText(
                  options,
                  OPTION_KEYS.SELECTED_CONSULTS,
                  params.med_id
                )}
              </p>
            ) : null}
            {docpay.target_date && <p>Target Date : {docpay.target_date}</p>}
            <HighlightedOption
              options={options}
              optionKey={OPTION_KEYS.TIME_OPTIONS}
              label={"Time of Day"}
              ruleValues={docpay.time_of_day}
              selectedValue={params.time_of_day}
            />
            <HighlightedOption
              options={options}
              optionKey={OPTION_KEYS.DAY_OPTIONS}
              label={"Day of Week"}
              ruleValues={docpay.days_of_week}
              selectedValue={params.days_of_week}
            />
            <p>Fixed Rate: ${docpay.fixed_rate}</p>
            <p>Percentage Rate: {docpay.perc_rate}%</p>
            <p>Value ${value.toFixed(2)}</p>
          </Segment>
          {differences && (
            <Segment>
              <h4>Differences:</h4>
              <ul>
                {Object.entries(differences).map(
                  ([key, { old, new: newValue }]) => (
                    <li key={key}>
                      <strong>{prettyPrintKey(key)}:</strong> Changed from "
                      {old}" to "{newValue}"
                    </li>
                  )
                )}
              </ul>
            </Segment>
          )}
        </>
      )}

      <Button onClick={onRerunClick} primary>
        Re Run
      </Button>
    </div>
  );
};

const prettyPrintKey = (key) => {
  switch (key) {
    case "med_id":
      return "Med ID";
    case "consult_type":
      return "Consult Type";
    case "doc_level":
      return "Doc Level";
    case "target_date":
      return "Target Date";
    case "time_of_day":
      return "Time of Day";
    case "days_of_week":
      return "Days of Week";
    case "rule_id":
      return "Rule ID";
    case "seniority":
      return "Seniority";
    case "fixed_rate":
      return "Fixed Rate";
    case "perc_rate":
      return "Percentage Rate";
    default:
      return key;
  }
};

const HighlightedOption = ({
  options,
  optionKey,
  label,
  ruleValues,
  selectedValue,
}) => {
  return (
    <p>
      {label} :{" "}
      {ruleValues?.map((value, index) => {
        const isBold = value === selectedValue;
        const text = getSelectedOptionText(options, optionKey, value);
        return (
          <span key={index}>
            <span
              key={value}
              className={isBold ? "bg-blue-200 p-2 rounded" : ""}
            >
              {text}
            </span>
            <span> </span>
          </span>
        );
      })}
    </p>
  );
};
