import React from "react";
import { Modal } from "semantic-ui-react";
import { QRCodeSVG } from 'qrcode.react';
import BarCode from "views/UIelems/BarCode";

export default function EscriptModal({ open, onClose, scriptId, profileName }) {

    const getInitials = (name) => {
        if (!name) return "";
        const words = name.trim().split(/\s+/);
        if (words.length === 1) return words[0][0].toUpperCase();
        return (words[0][0] + words[words.length - 1][0]).toUpperCase();
    };

    return (
        <Modal closeIcon open={open} onClose={onClose} style={{ maxWidth: '400px', padding: '0%' }}>
            <Modal.Header>Scan eScript</Modal.Header>
            <Modal.Content data-testid="escript-qr">
                {scriptId ? (
                    <>
                        <div>
                            <QRCodeSVG value={scriptId} renderAs="svg" width="100%" height="100%" level="H" />
                        </div>
                        <div style={{ margin: "2em 0" }}>
                            <BarCode value={scriptId} />
                        </div>
                        <div>
                            {profileName &&
                                <svg width="100%" height="100%" viewBox="0 0 20 1">
                                    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="1" fontFamily="monospace">
                                        Patient: {getInitials(profileName)}
                                    </text>
                                </svg>
                            }
                            <svg width="100%" height="100%" viewBox="0 0 20 1">
                                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="1" fontFamily="monospace">
                                    DSPID: {scriptId}
                                </text>
                            </svg>
                        </div>
                    </>
                ) : (
                    <>
                        <h2>Unknown Script ID</h2>
                        <p>Could not determine Script ID (SCID).</p>
                        <p>Please contact InstantScripts support</p>
                    </>
                )}
            </Modal.Content>
        </Modal>
    );
}