import React, {useEffect, useState} from "react";
import {Button, Icon, Input, Label, List} from "semantic-ui-react";
import API_service from "@/xAppLib/providers/API_service";
import user_model from "@/models/user_model";
import {cls} from "@/views/NUI/utils";

const DEBUG = false

const OnboardingEmail = ({eml_verif, onError}) => {
    const [loading,setLoading] = useState(false)
    const [sent,setSent] = useState(false)
    const [updateEmail, setUpdateEmail] = useState(false)
    const [updateEmailSent, setUpdateEmailSent] = useState(false)
    const [error, setError] = useState()
    const [email, setEmail] = useState(app.user.email)
    const days_left = app.settings.verification_days
    const is2 = app.settings.iscr2

    const brand = app.settings.is_cmg && 'Haiku Health' || app.settings.is_cbls && 'BLS Clinics' || app.settings.icann && 'Benicura' || app.settings.iprep && 'HIVprevent' || 'InstantScripts';

    useEffect(() => {
        if (email)
            setError('')
        else
            setError('Email cannot be empty')
    }, [email])

    return <List.Item data-testid='item-verify-email'>
        {!is2 && <List.Icon name={eml_verif?"check":"mail"} color={eml_verif?"green":"grey"} />}
        <List.Content>
            <List.Header>
                {(is2  && !eml_verif) &&  <>Your email is not verified</> || !is2&& `Your email ${app.user.email} is ${eml_verif ? 'verified' : 'not verified'}.`}
            </List.Header>
            <List.Description>
                {!eml_verif && (
                    <>
                        {updateEmail ? (
                            <p>Please provide the new email address you would like to use. You will be prompted to login again</p>
                        ) : (
                            <p>{is2 ? `Please check your email address: ${app.user.email} to verify your account.`: `Please check your inbox for the verification email sent. You must verify your account to continue using ${brand}`}</p>
                        )}

                        <p>
                            {days_left > 0 ? (
                                <>
                                    You have {days_left} day{days_left != 1 && 's'} left to verify{!app.settings.iscr2 ? " your account." : "."}
                                </>
                            ) : (
                                <> You must verify your account to continue using InstantScripts.</>
                            )}
                        </p>

                        {!updateEmail && (
                            <div className="flex mt-2 gap-2">
                                <Button
                                    icon
                                    basic={is2}
                                    loading={loading}
                                    color={!is2 && (sent ? 'green' : (app.settings.icosm ? 'green' : 'blue'))}
                                    data-testid='button-resend-email'
                                    onClick={async (_) => {
                                        setSent(false);
                                        onError(false);
                                        setLoading(true);
                                        const { res, err, token } = await API_service.load_data('User/verif', {});
                                        if (token) await user_model.signInWithCustomToken(token);
                                        setLoading(false);
                                        if (err) {
                                            onError(err);
                                            return;
                                        }
                                        setSent(true);
                                    }}
                                >
                                    {sent && <Icon name="check circle outline" />} {sent ? 'Email Sent. Check your inbox.' : 'Resend Email'}
                                </Button>
                                <Button className={cls(is2 ? "secondary !text-is2-gray-700" : "basic")} data-testid='button-update-email' onClick={() => setUpdateEmail(true)}>
                                    Update Email
                                </Button>
                            </div>
                        )}

                        {updateEmail && (
                            <div className="flex flex-wrap mt-4 gap-2 max-w-lg">
                                <Input placeholder="Email" onChange={(e, { value }) => setEmail(value)} required className="grow " type="text" />
                                <div className="flex gap-2">
                                    <Button
                                        loading={updateEmailSent}
                                        disabled={updateEmailSent || email === app.user.email || !email}
                                        color={'blue'}
                                        onClick={async () => {
                                            try {
                                                setUpdateEmailSent(true);
                                                await user_model.set_user_newemail(app.user, email, (result) => {
                                                    DEBUG && console.log('result = %o', result);
                                                    if (result.res === 'ok') {
                                                        setUpdateEmail(false);
                                                        setTimeout(() => app.history.push('/login'), 200);
                                                    } else {
                                                        setError(result.err_msg);
                                                    }
                                                });
                                                setUpdateEmailSent(false);
                                            } catch (e) {
                                                console.log(e);
                                            }
                                        }}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        basic
                                        onClick={() => {
                                            setUpdateEmail(!updateEmail);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                {error && (
                                    <Label basic color="red" pointing>
                                        {error}
                                    </Label>
                                )}
                            </div>
                        )}
                    </>
                )}
            </List.Description>
        </List.Content>
    </List.Item>;
}

export default OnboardingEmail;