import React, { useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import scripts_list_model from '../../models/scripts_list_model';
import { DataConnector } from '../../xAppLib/DataTable';
import PrescriptionCard from './PrescriptionCard';
import {filterActivePrescriptions} from "@/helpers/scripts";

export default function ActivePrescriptionsDashboard({ cardQty }) {
	const { sid } = useParams();
	const [uid] = useState(null);
	const [pageSize] = useState(50);
	const [page] = useState(1);
	const [refresh] = useState(new Date().getTime());

	return (
		<section className="">
			<DataConnector
				refresh={refresh}
				pageSize={pageSize}
				page={page}
				data={() => {
					return new Promise((resolve, reject) => {
						scripts_list_model.load_scripts_list(
							{
								type: 'pts',
								total_count: true,
								get_stats: true,
								limit: pageSize,
								show_list: "medications",
								offset: (page - 1) * pageSize,
								filters: {
									sid,
									uid,
									escr_only: true,
								},
							},
							(results) => {
								if (results.res == 'ok') {
									resolve({ data: results.listScripts, total: results.total });
								}
							}
						);
					});
				}}
			>
				{(data, loading, total) => {
					if (loading) return <Loader active inline="centered" className="after:!border-t-gray-500" />;
					if (total == 0)
						return (
							<div className="mt-5 text-center !bg-white !rounded-lg !shadow !border-b !border-gray-100 py-10 ">
								<p>You dont have any active prescriptions</p>
							</div>
						);

					const renderActiveData = filterActivePrescriptions(data);

					const limitedData = Array.isArray(renderActiveData) && renderActiveData.length > 0
					? renderActiveData.slice(0, cardQty)
					: renderActiveData;

					return (
						<>
							{Array.isArray(limitedData) && limitedData.length > 0 && (
								<section className="hidden md:block bg-white px-6 py-9 rounded bg-white">
									<h2 className="mb-0">Active prescriptions:</h2>
									<div className="flex flex-col relative gap-6 pt-14 pb-5 md:py-0 md:mt-6 md:mb-4 lg:rounded-[28px] xl:w-[630px] md:min-h-[272px] mx-auto bg-white lg:bg-transparent text-black">
										<section className={`!gap-4 grid grid-cols-1 sm:grid-cols-2`}>
											{limitedData?.map((item, index) => (
												<PrescriptionCard key={index} item={item} showProfileNames={true} />
											))}
										</section>
									</div>
								</ section>
							)}
						</>
					);
				}}
			</DataConnector>
		</section>
	);
}
