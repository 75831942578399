import React, { useState, useMemo } from "react";
import SectionHeader from 'views/med/SectionHeader';
import UniForm from "xAppLib/UniForm/UniForm";
import script_model from "models/script_model";
import { Form } from "semantic-ui-react";
import SearchPharm from "views/UIelems/SearchPharm";
import UniFormMed from "views/med/UniFormMed";

const PharmacySelect = (props) => {
	const { med_data, script_type, req_type, has_escript, PARTN_PHARM, store, Section, inline=false } = props
	const fields = useMemo(() => [
		{
			label: "Select a pharmacy",
			name: "sendto_oid",
			type: "text",
			value: app.dvc?.org?.type=='pharm'&&app.dvc.org.oid || PARTN_PHARM || app.user.claims?.pts_oid || store?.sendto_oid,
			valid_not_required: !(app.acl.app_use && ['medcons', 'subcons', 'exprcons', 'qualcons', 'doccons', 'docscr'].includes(script_type) && req_type!='medcert'),
			
		},
		{
			label: "Pharmacy",
			name: "sendto_pha",
			valid_not_required: true,
			
		},
	], [med_data, script_type, req_type, store?.sendto_oid]);

	const is_click_and_collect = store.fulfilment === 'click_collect'
			
	const label = !is_click_and_collect && <span className="text-is-black-80 font-bold">{PARTN_PHARM && 'We will send your script to the pharmacy within minutes.'
        || 'If the doctor prescribes medication but it cannot be issued as an eScript, the medication can be picked up from the following pharmacy:'}</span>

	return (
		<UniFormMed
			{...props}
			section="pharm"
			fields={fields}
		>
			{(values, valids, uf_this, fields) => {
				return <Section>
						{script_type=='doccons' && <br/>}
						<Section.Content end={script_type=='doccons' ? false : true} className={inline && 'm-0 mb-3'}>
						{script_type!='doccons' && !inline && <hr className="h-px bg-is-gray-50 border-0 -mt-4 mb-4"></hr>}
						
							<Form.Field
								error={valids && !valids.sendto_oid}
								label = {label}
							/>
							{!PARTN_PHARM &&
								<SearchPharm 
									error={valids && !valids.sendto_oid}
									placeholder='Set device org'
									onSelect={ (oid, pn, pa, sa, org) => {
										if (oid!=store.sendto_oid) {
											uf_this.handleInputChange(null, {name:'sendto_oid',value:oid}); 
											uf_this.handleInputChange(null, {name:'send_to_pha',value:{nm:pn, adr:pa, srch_adr:sa}}); 
										}
										 /*console.log('SearchPharm :: onSelect ::', org);*/
										} 
									}
									oid={store.sendto_oid}
									noChange={PARTN_PHARM}
									init_addr={store.address ?? props.formData?.full_address?.formatted}
									defaultPharm
								/>
							}
							{PARTN_PHARM &&
								<>Selected pharmacy - <b>{store.send_to_pha?.nm||''}</b>, {store.send_to_pha?.adr ||''}</>
							}
					</Section.Content>
				</Section>;
			}}
		</UniFormMed>
	);


};

export default PharmacySelect;
