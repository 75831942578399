import React, { useState } from "react";
import moment from "moment";
import { Item } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SIcon from "../../views/NUI/StreamlineIcons/SIcon";
import BuyMedicationButton from "./BuyMedicationButton";
import { capitalizeFirstLetter, trimMedicationName } from "../../views/pages/Medications";
import { useProfile } from "../../xAppLib/Hooks";
import EscriptModal from "../EscriptModal";
import {isActivePrescription} from "@/helpers/scripts";

export default function PrescriptionCard({ item, showProfileNames }) {
    const [prof] = useProfile();
    const [viewEscript, setViewEscript] = useState();

    const now = moment();
    const add_tm = moment(item.add_tm, 'D/M/YY HH:mm:ss');
    const daysPassed = moment().diff(add_tm.isValid() ? add_tm.startOf('day') : moment().startOf('day'), 'days');

    const hasNewItem = add_tm.diff(now, 'days') >= -1 && add_tm.diff(now, 'days') <= 0;

    const hasEscript = item?.epresc__scid;
    const isMedicationDispensed = !isActivePrescription(item);
    const dispensedDate = item?.epresc?.dispensed_date;
    const formattedDispensedDate = moment(dispensedDate).format('D/M/YY HH:mm:ss');
    const disableViewScript = !hasEscript;

    const daysLimit = item?.med_db_data__days_limit;
    const hasDayLimit = daysLimit != null;
    const isPastLimit = (hasDayLimit && daysPassed > (daysLimit - 2));
    const noDaysLimit = !hasDayLimit;

    const isEnrolledMed = prof?.qualcons?.some(qualcon => qualcon.enrolled_med === item?.med?.id || qualcon.enrolled_med === item?.med?.m) || false;
    const canOrderMed = !isEnrolledMed && (noDaysLimit || isPastLimit);
    const canReorderMed = isEnrolledMed && (!hasDayLimit || isPastLimit);

    const brandName = trimMedicationName(capitalizeFirstLetter(item?.med_db_data?.name), item.epresc?.item?.ItemStrength) || '';
    const genericName = trimMedicationName(item.epresc?.item?.ItemGenericName, item.epresc?.item?.ItemStrength);
    const profileName = `${item?.spd_data?.first_name} ${item?.spd_data?.last_name}`;

    return (
        <Item key={item.sid} className={`hist-item bg-white border border-is2-gray-100 rounded-lg p-4 !pt-6 w-full relative`}>
            {hasNewItem && (
                <span className="text-sm bg-is2-green px-2 py-1 rounded-tr-lg rounded-bl-lg mb-4 w-fit absolute top-0 right-0">
                    New
                </span>
            )}

            <div className="grid grid-cols-1 h-full">
                <Item.Content className="description min-h-32 md:min-h-28 flex-grow">
                    <Item.Meta >
                        <div className="mb-2">
                            <p className="font-medium text-base text-gray-900 sm:justify-center !my-auto">{brandName || 'Product name not found'} <span className="text-gray-500 text-base font-normal">{genericName ? `(${genericName})` : ''}</span></p>
                            <p className="text-gray-600 text-sm">{item.med_db_data.size} | {item.med_db_data.qnty}</p>
                        </div>
                        <div className="flex flex-row gap-2 justify-between text-sm text-gray-600 mb-2">
                            <div className="flex flex-col">
                                <p className="text-sm !mb-0"><span className="text-sm">
                                    {`${!dispensedDate ? 'Prescribed:' : 'Dispensed:'}`}</span> {`${!dispensedDate ? moment(item.add_tm, 'D/M/YY HH:mm:ss').format('DD/MM/YY') :
                                        moment(formattedDispensedDate, 'D/M/YY HH:mm:ss').format('DD/MM/YY')}`
                                    }
                                </p>
                                {showProfileNames && (
                                    <p className="text-sm">Patient: {profileName}</p>
                                )}
                            </div>
                            <p className="text-sm"><span className="text-sm">Repeats:</span> {item.epresc?.item?.NumberOfRepeatsAuthorised ?? "0"}</p>
                        </div>

                    </Item.Meta>
                </Item.Content>

                <Item.Content className="actions mt-auto">
                    {!isMedicationDispensed && <BuyMedicationButton item={item} />}
                    <div className={`grid ${isMedicationDispensed ? 'grid-cols-2' : 'grid-cols-3'} gap-2 md:gap-0`}>
                        {!isMedicationDispensed && hasEscript &&
                            <Link
                                onClick={(e) => {
                                    if (disableViewScript) {
                                        e.preventDefault();
                                        return;
                                    }
                                    setViewEscript(hasEscript);
                                }}
                                content="View eScript"
                                disabled={disableViewScript}
                                className={`flex w-full items-center justify-center !rounded-lg font-medium px-1 py-1 
									${disableViewScript
                                        ? '!bg-gray-200 !text-gray-400 cursor-not-allowed'
                                        : '!bg-white !text-is2-gray-150 !hover:bg-is2-gray-100'
                                    }`}
                            >
                                <div className="flex flex-col items-center self-start mx-auto">
                                    <SIcon name="doc-stamp" size="xs" bold cls="mb-2 mt-1" />
                                    <span className="text-sm block sm:inline w-[50px] sm:w-auto text-center">View eScript</span>
                                </div>
                            </Link>
                        }
                        <Link
                            className="flex w-full items-center justify-center !rounded-lg !text-is2-gray-150 !bg-white !hover:bg-is2-gray-100 font-medium rounded-lg px-1 py-1"
                            to={'/reminders/add'}
                            state={item}
                        >
                            <div className="flex flex-col items-center self-start mx-auto">
                                <SIcon name="clock" size="xs" bold cls="mb-2 mt-1" />
                                <span className="text-sm block sm:inline w-[50px] sm:w-auto text-center">Set reminder</span>
                            </div>

                        </Link>
                        <Link
                            to={canReorderMed ? '/remed/' + item.med_db_data__pth + '/' + item.med_db_data__id + '/' + item.sid : '/m/pharm/online/prescription'}
                            onClick={(e) => {
                                if (!canReorderMed && !canOrderMed) {
                                    e.preventDefault()
                                    let days = Math.abs(moment().diff(add_tm.startOf('day'), 'days') - (daysLimit * 1 - 2)) + 1
                                    const msg = `Script for ${item.med_db_data.name} was issued to you on ${add_tm.format('DD/MM/YY')}. We can't prescribe the same medication again for ${days} day${days != 1 ? 's' : ''}. Please try again in ${days} day${days != 1 ? 's' : ''}.`
                                    app.confirm(canReorderMed ? "Reorder limit" : "Order limit", msg, { basic: true })
                                }
                            }}
                            className={`flex w-full items-center justify-center !rounded-lg font-medium rounded-lg px-0 py-1 !text-is2-gray-150 !hover:bg-is2-gray-100 ${hasDayLimit && !isPastLimit ? '!bg-gray-200 !text-gray-400 cursor-not-allowed' : '!bg-white'}`}
                        >
                            <div className="flex flex-col items-center self-start mx-auto">
                                <SIcon name="refresh-arrow" size="xs" bold cls="mb-2 mt-1" />
                                <span className="text-sm block sm:inline w-[70px] sm:w-auto text-center">{isEnrolledMed ? "Reorder Script" : "Order Script"}</span>
                            </div>
                        </Link>
                    </div>
                </Item.Content>
            </div>
            {viewEscript && (
                <EscriptModal
                    open={!!viewEscript}
                    onClose={() => setViewEscript(null)}
                    scriptId={viewEscript}
                    profileName={profileName}
                />
            )}
        </Item>
    );
}