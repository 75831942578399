import React, { Suspense } from 'react';

import { Container, Loader } from 'semantic-ui-react'

import AwaitingConsults from "@/views/instcons/AwaitingConsults";
import AccountOnboarding from "xAppLib/UIelems/AccountOnboarding";
import MFA from "xAppLib/UIelems/MFA";
import MessageBar from "xAppLib/UIelems/MessageBar";
import { CouldNotCallConsults } from "../../views/instcons/CouldNotCallConsults";
import PatientAppointments from "../../views/booking/PatientAppointments";
import Prescs from "../../views/prescs/Prescs";
import WatchApprovals from "../../views/instcons/WatchApprovals";
import { InProgressVideoConsults } from "../../views/instcons/InProgressVideoConsults";
import { AssertiveRtdbBounce } from "../../views/instcons/AssertiveRtdbBounce";
import WatchUnseenResults from "../../views/instcons/WatchUnseenResults"
import WatchUnseenResultsExpress from "../../views/instcons/WatchUnseenResultsExpress"
import { InstConsWrNotifier } from "../../views/instcons/InstConsWrNotifier";

const CommonAlerts = () => {
    return (
        <>
            <MessageBar hide_on={[app.runtime?.login_type == 'popup' && 'user_auth_msg']} />
            {!app.settings.iscr2 && <AccountOnboarding />}
            <MFA bannerMode />
            {!app.acl.is_blocked && <>
                {app.acl.is_nurse && (
                    <Suspense fallback={<div className="suspense-style"><Loader active inline /></div>}>
                        <AwaitingConsults />
                    </Suspense>
                )}
                <WatchApprovals />
                <WatchUnseenResults />
                <WatchUnseenResultsExpress />
                <InstConsWrNotifier />
                <Container className="!p-0 space-y-4 my-4 empty:my-0">
                    {(app?.acl?.is_usr && !app?.settings?.icosm && !app.site_status?.notifications?.enable_banners?.includes?.(app.runtime?.app)) ? (
                        <>
                            <CouldNotCallConsults />
                            <PatientAppointments />
                            <Prescs />
                        </>
                    ) : null}
                    {!app.site_status?.notifications?.enable_banners?.includes?.(app.runtime?.app) && (
                        <InProgressVideoConsults />
                    )}
                </Container>
                <AssertiveRtdbBounce />
            </>}
        </>
    );
};

export default CommonAlerts;