import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SIcon from "../../../NUI/StreamlineIcons/SIcon";

/**
 * @typedef {"neutral" | "positive" | "negative" | "warning"} MessageType
 */

/**
 * A component that displays a notification with a dynamic type.
 * - The type prop controls the background color and icon of the notification.
 * - The text prop sets the main text of the notification.
 * - The icon prop determines whether an icon is displayed on the left of the notification.
 * 
 * @param {{ 
 *   text: string, 
 *   type: MessageType, 
 *   icon: boolean 
 * }} props
 * @returns {React.ReactElement}
 */
export function NotificationBar ({ text, type, icon, className }) {
  const typeStyles = {
    neutral: {
      bgClass: "bg-is2-blue-50",
      icon: 'check-circle-bold',
    },
    positive: {
      bgClass: "bg-is2-green-100",
      icon: 'check-circle-bold',
    },
    negative: {
      bgClass: "bg-is2-red-100",
      icon: 'clock',
    },
    warning: {
      bgClass: "bg-is2-yellow-50",
      icon: 'road-sign-warning-bold',
    },
  };

  const { bgClass, icon: iconSrc } = typeStyles[type] || typeStyles.neutral;   // Fallback to the "neutral"

  return (
    <div
      className={classNames(
        "flex items-center justify-center gap-2 px-6 py-3 border border-transparent w-full text-is2-gray-350",
        bgClass
      )}
    >
      {icon && iconSrc && (
        <SIcon name={iconSrc} size="xs" cls='' bold/>
      )}
      <span className={`text-sm font-semibold leading-tight ${className}`}>{text}</span>
    </div>
  );
}

NotificationBar.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["neutral", "positive", "negative", "warning"]),
  icon: PropTypes.bool,
};

NotificationBar.defaultProps = {
  type: "neutral",
  icon: false,
};