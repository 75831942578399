import React from 'react';

export default function SplitPage({ calloutSection, children, fullHeight = false }) {

    return (
        <div className={
            `split-page
            flex flex-col
            md:flex-row md:h-full
            ${fullHeight ? 'h-[100vh]' : ''}
            `
        }>
            <div className={
                `split-page__callout
                bg-is2-blue-300 md:bg-is2-blue-700 text-white
                md:w-1/2
                md:flex md:flex-col md:items-end
                ${fullHeight ? 'h-auto lg:min-h-[25vh]' : ''}
                `
            }>
                <div className={
                    `split-page__callout-inner
                    pl-6
                    pr-6
                    pt-6
                    pb-9
                    md:py-[10rem]
                    md:pl-[4rem]
                    md:pr-[4rem]
                    lg:pr-[8rem]
                    md:max-w-[540px]
                    `
                }>
                    {calloutSection}
                </div>
            </div>
            <div className={`split-page__content bg-white md:w-1/2 ${fullHeight ? 'min-h-[70vh] sm:h-auto sm:min-h-[90vh]' : ''}`}>
                <div className={
                    `split-page__content-inner
                    py-[4rem]
                    pl-6
                    pr-6
                    md:py-[10rem]
                    md:pr-[4rem]
                    md:pl-[4rem]
                    lg:pl-[8rem]
                    `
                }>
                    {children}
                </div>
            </div>
        </div>
    )
}
