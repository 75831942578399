import API_service from "xAppLib/providers/API_service";
import cat_model from "models/cat_model";

const toSnakeCase = (str) =>
  str
    .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
    .replace(/^_/, "");

const objectToSnakeCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => objectToSnakeCase(item));
  } else if (obj !== null && typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        toSnakeCase(key),
        objectToSnakeCase(value),
      ])
    );
  }
  return obj; // Return values as is (e.g., numbers, strings, booleans)
};

const formatRuleForBackend = (rule) => {
  const convertedRule = objectToSnakeCase(rule);
  if (convertedRule.target_date === null) {
    delete convertedRule["target_date"];
  }
  if (
    convertedRule.fixed_rate !== undefined &&
    convertedRule.fixed_rate !== null
  ) {
    convertedRule.fixed_rate = parseFloat(convertedRule.fixed_rate);
  }
  return convertedRule;
};

export const toggleActive = async (ruleId, checked) => {
  try {
    const response = await API_service.api_promise("admin/docpay/toggle", {
      rule_id: ruleId,
      active: checked,
    });
    if (response === 1) {
      return { ok: true };
    } else {
      return { ok: false, errorMessage: "Error toggling active status" };
    }
  } catch (e) {
    return { ok: false, errorMessage: e };
  }
};

export const updateRule = async (editedRule) => {
  try {
    const formattedRule = formatRuleForBackend(editedRule);
    const response = await API_service.api_promise(
      "admin/docpay/update",
      formattedRule
    );
    if (response === 1) {
      return { ok: true };
    }
  } catch (e) {
    return { ok: false, errorMessage: e };
  }
};

export const addNewRule = async (newRule) => {
  try {
    const formattedRule = formatRuleForBackend(newRule);
    const response = await API_service.api_promise(
      "admin/docpay/create",
      formattedRule
    );
    return { ok: true, ruleId: response.rule_id };
  } catch (e) {
    return { ok: false, errorMessage: e };
  }
};

function snakeToCamelCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map(snakeToCamelCase); // Recursively convert array elements
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = key.replace(/_([a-z])/g, (match, letter) =>
        letter.toUpperCase()
      );
      acc[camelKey] = snakeToCamelCase(obj[key]); // Recursively convert object values
      return acc;
    }, {});
  }
  return obj; // If it's not an object or array, return the value as is
}

const formatResponse = (rules) =>
  rules.map((rule) => {
    const convertedRule = snakeToCamelCase(rule);
    return { ...convertedRule, fixedRate: parseFloat(convertedRule.fixedRate) };
  });

export const fetchRules = async () => {
  try {
    const response = await API_service.load_data("admin/docpay");
    if (response.res === "ok") {
      return formatResponse(response.data);
    }
  } catch {
    console.error("error retrieving rules");
    return [];
  }
  return [];
};

const fetchConsultationCategories = async () => {
  const categoryList = await cat_model.get_cat_list({ cat_nm: "pharm" });
  if (categoryList) {
    // TODO - double check that the category fetched is correct
    // const { cat_avail_list } = categoryList;
    // const activeAvailableCategories = Object.values(cat_avail_list).flatMap(
    //   (category) => {
    //     if (!category.active || category.t !== "c" || !category.c) {
    //       return [];
    //     }
    //     return { text: category.n, value: category.c };
    //   }
    // );

    const { cat_tree_list } = categoryList;

    const activeAvailableCategories = Object.values(
      cat_tree_list?.DocCons?.i
    ).flatMap((category) => {
      if (!category.c) {
        return [];
      }

      return { text: `${category.n} (${category.c})`, value: category.c };
    });
    return activeAvailableCategories;
  }
  return [];
};

export const fetchOptions = async () => {
  try {
    const options = await API_service.load_data(
      "admin/docpay/options"
    );
    const activeConsultOptions = await fetchConsultationCategories();
    return { ...options, selectedConsults: activeConsultOptions };
  } catch (e) {
    console.error("error retrieving options", e);
    return {};
  }
};


export const reRunDocpayRule = async (sid) => {
  try {
    const response = await API_service.api_promise(
      "admin/docpay/re-run",
      {sid  }
    );
    return { ok: true, docpay: response.docpay };
  } catch (e) {
    return { ok: false, errorMessage: e };
  }
}
