import firebase_database from "../../xAppLib/providers/firebase_database";
import currency from "currency.js";

// Can change this per app when PL or other apps utilize ecommerce.
const defaultLoc = 'ecomm_carts_iscr'

export const ECOMM_CART_LOC = () =>`${defaultLoc}/${app.user?.prof?.uid}`;
export const DEFAULT_CART = { items: [], fees_and_charges: [] };

export function get_cart_total(cart) {
    cart = {...DEFAULT_CART, ...cart}
    const chargeable_items = [...cart.items, ...cart.fees_and_charges]
    return currency(chargeable_items.reduce((acc, item) => acc + currency(item.price).multiply(item.qty).value, 0)).value
}

export function get_cart_item_total(cart) {
    const items = cart?.items || []
    return currency(items.reduce((acc, item) => acc + currency(item.price).multiply(item.qty).value, 0)).value
}

export function get_cart_item_count(cart = DEFAULT_CART) {
    return cart.items.reduce((acc, item) => acc + item.qty, 0)
}

export function get_product_total(product) {
    return currency(product.price).multiply(product.qty).value
}

export function get_chargeable_items(cart) {
    const items = cart?.items || []
    const fees_and_charges = cart?.fees_and_charges || []
    return [...items, ...fees_and_charges]
}

export function add_item_to_cart(cart, new_item) {
    const cart_items = cart?.items || []
    return {...cart, items: [...cart_items, new_item]}
}

export function update_item_in_cart(cart, updated_item) {
    return {...cart, items: cart.items.map(item => item.id === updated_item.id ? updated_item : item)}
}

export function remove_item_from_cart(cart, item_to_remove) {
    return {...cart, items: cart.items.filter(item => item.id !== item_to_remove.id)}
}

export function add_fee_or_charge_to_cart(cart, fee_or_charge) {
    return {...cart, fees_and_charges: [...(cart?.fees_and_charges || []), fee_or_charge]}
}

export function update_fee_or_charge_in_cart(cart, updated_fee_or_charge, id_function) {
    return {
        ...cart,
        fees_and_charges: cart.fees_and_charges.map(fee => id_function(fee, updated_fee_or_charge)
            ? updated_fee_or_charge
            : fee
        )}
}

export function remove_fee_or_charge_from_cart(cart, fee_or_charge, id_function) {
    const fees_and_charges = cart.fees_and_charges || []
    return {...cart, fees_and_charges: fees_and_charges.filter(fee => !id_function(fee, fee_or_charge))}
}

export function clear_fees_and_charges(cart) {
    return {...cart, fees_and_charges: []}
}

export function clear_products(cart) {
    const items = cart?.items || []
    return {...cart, items: items.filter(i => i.type !== 'product')}
}

export function save_cart(recordExist, cart = {}) {
    const action = recordExist ? 'update_record' : 'set_record';
    return firebase_database[action](ECOMM_CART_LOC(), {...cart, updated_at: new Date().toISOString()});
}

export function get_medications_in_cart(cart) {
    const items = cart?.items || []
    return items.filter(i => i.type === 'medication')
}

export function get_products_in_cart(cart) {
    const items = cart?.items || []
    return items.filter(i => i.type === 'product')
}

export function clear_except_medications(cart) {
    let newCart = JSON.parse(JSON.stringify(cart))
    newCart = clear_fees_and_charges(newCart)
    newCart = clear_products(newCart)
    return newCart
}

export function is_cart_empty(cart) {
    return !!(cart === null || !cart?.items?.length)
}

export function cart_has_medications(cart) {
    const items = cart?.items || []
    return items.some(i => i.type === 'medication')
}

export function prep_ecommerce_payload_for_submission ({values, cart, paypalOrderDetails, is_local_cart = false}) {
    const formData = JSON.parse(JSON.stringify(values))

    formData.cart = cart
    formData.cart_total = get_cart_total(cart)
    formData.cart.items.forEach(i => i.total = get_product_total(i))

    if(formData.payment === 'paypal' && paypalOrderDetails?.current?.orderID) {
        formData.payment = {
            paymentMethod: formData.payment,
            approval: paypalOrderDetails.current
        }
    } else {
        formData.payment = {
            paymentMethod: 'ccard',
            ...formData.payment
        }
    }

    formData.runt = app.runtime
    formData.is_local_cart = is_local_cart

    return formData
}