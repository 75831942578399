export const getSelectedOptionText = (options, optionKey, selectedValue) =>
    options[optionKey]?.find(({ value }) => value === selectedValue)?.text ?? selectedValue;

export const getSelectedOptionsText = (options, optionKey, selectedValues) => {
    if (!Array.isArray(selectedValues)) {
        return selectedValues;
    }
    return selectedValues
        .map((value) => getSelectedOptionText(options, optionKey, value))
        .join(", ");
}