import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { lp_template } from './lp_template.js';
import logger from 'xAppLib/libs/logger'
import { useNavigate } from 'react-router-dom';

const DEBUG = false

const LandingPage = ({url}) => {

    const navigate = useNavigate();

    const [data,setData] = useState(window.___?.loc == url && window.___ || null)

    DEBUG && console.log("LandingPage",url,data)

    useEffect(()=>{
        import(`landing_pages/${url}.json`).then(content=>content?.default).then(data=>{
            const content = lp_template(data)
            setData({ ...data, content })
        })
       
    },[url])

    useEffect(()=>{
        if (!data)
            return;
        
        const lp = document.getElementById('lp')

        function clickHandler(e) {
            DEBUG && console.log("click",e);
            let el = e.target

            let link = el.tagName=='A' ? el : el.parentElement?.tagName == 'A' ? el.parentElement : null
            if (link) {
                const href = link.getAttribute("href")
                const blog = link.classList.contains('blog')

                if (href!='#' && !blog) {
                    e.preventDefault()
                    navigate(href)
                }
                const d = {
                    label:link.innerText,
                    cta:link.classList.contains('cta'),
                    search:link.classList.contains('search'),
                    blog
                }
                logger.usg_log('LP.click', 'url.'+url, null, d)
            }
        }

        lp?.addEventListener('click', clickHandler, false);

        return () => {
            lp?.removeEventListener('click',clickHandler)
        }

    },[data,url])

    if (!data)
        return null
      let { meta, content, title } = data

    return <div>
                <Helmet>
                    <title>{title}</title>
                </Helmet>

        <div id='lp' className='landing-pages'
            dangerouslySetInnerHTML={{__html:content}}
        />

    </div>;
}
 
export default LandingPage;