import React from 'react';
import { NotificationsCountBanner } from '../../../views/UIelems/v2/NotificationsCountBanner';
import { useNotifications } from '../../hooks/useNotifications';

const NotificationsCountBannerApp = () => {
    const {
        unreadCount,
        handleNotificationsOpen,
    } = useNotifications();
    if (!unreadCount) return null;

    return <NotificationsCountBanner
        count={unreadCount}
        onClick={handleNotificationsOpen}
    />;
};

export default NotificationsCountBannerApp;