import React from 'react';
import { Header, List, Table } from "semantic-ui-react";
import Alert from "../NUI/Alert";
import { FieldRawPopup } from "./fields/FieldRawPopup";

const STAT_NAME = {
	rtt: 'Round-trip-time',
	jitter: 'Jitter',
	packetLoss: 'Packet loss',
};

export function TwilioNetworkTestResults({results: data}) {

	if (!data) return <>No pre-flight test</>;

	if (data.name) {
		return <Alert error header={`${data.name} (${data.code})`} content={data.message}/>;
	}

	return (
		<div>
			<div className="flex justify-between items-start">
				<List>
					<List.Item>
						<List.Content>
							<List.Header>Test Duration</List.Header>
							<List.Description>{data.testTiming.duration}ms</List.Description>
						</List.Content>
					</List.Item>
				</List>
				<FieldRawPopup row={data}/>
			</div>

			<IceCandidates data={data}/>

			<div className="flex flex-row space-x-2">
				<div><PreflightStats data={data}/></div>
				<div className="grow"><ProgressEvents progressEvents={data.progressEvents}/></div>
				<div className="grow"><NetworkTiming networkTiming={data.networkTiming}/></div>
			</div>
		</div>
	);
}

function IceCandidates({data}) {
	return (
		<>
			<Header as="h3">ICE Candidates</Header>
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Selected</Table.HeaderCell>
						<Table.HeaderCell>Address</Table.HeaderCell>
						<Table.HeaderCell>Type</Table.HeaderCell>
						<Table.HeaderCell>Port</Table.HeaderCell>
						<Table.HeaderCell>Priority</Table.HeaderCell>
						<Table.HeaderCell>Protocol</Table.HeaderCell>
						<Table.HeaderCell>TransportId</Table.HeaderCell>
						<Table.HeaderCell>URL</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{data.iceCandidateStats.map(candidate => (
						<Table.Row key={candidate.candidateType + candidate.port + candidate.priority}>
							<Table.Cell>
								{matchCandidate(data.selectedIceCandidatePairStats.localCandidate, candidate) && 'Local'}
								{matchCandidate(data.selectedIceCandidatePairStats.remoteCandidate, candidate) && 'Remote'}
							</Table.Cell>
							<Table.Cell>{candidate.address}</Table.Cell>
							<Table.Cell>{candidate.candidateType}</Table.Cell>
							<Table.Cell>{candidate.port}</Table.Cell>
							<Table.Cell>{candidate.priority}</Table.Cell>
							<Table.Cell>{candidate.protocol}</Table.Cell>
							<Table.Cell>{candidate.transportId}</Table.Cell>
							<Table.Cell>{candidate.url}</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</>
	);
}

function matchCandidate(left, right) {
	return (
		left.protocol === right.protocol
		&& left.port === right.port
		&& left.candidateType === right.candidateType
	);
}

export function PreflightStats({data}) {
	if (!data.stats) return <>No stats</>;

	return (
		<>
			<Header as="h3">Stats</Header>
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Stat</Table.HeaderCell>
						<Table.HeaderCell textAlign="right">Min</Table.HeaderCell>
						<Table.HeaderCell textAlign="right">Avg</Table.HeaderCell>
						<Table.HeaderCell textAlign="right">Max</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{Object.entries(data.stats).map(([key, stats]) => (
						<Table.Row key={key}>
							<Table.Cell>{STAT_NAME[key] ?? key}</Table.Cell>
							<Table.Cell textAlign="right">{stats.min.toFixed(2)}</Table.Cell>
							<Table.Cell textAlign="right">{stats.average.toFixed(2)}</Table.Cell>
							<Table.Cell textAlign="right">{stats.max.toFixed(2)}</Table.Cell>
						</Table.Row>
					))}
					<Table.Row>
						<Table.Cell>Mean-opinion score</Table.Cell>
						<Table.Cell textAlign="right"><MeanOpinionScore value={data.mos.min}/></Table.Cell>
						<Table.Cell textAlign="right"><MeanOpinionScore value={data.mos.average}/></Table.Cell>
						<Table.Cell textAlign="right"><MeanOpinionScore value={data.mos.max}/></Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		</>
	);
}

function MeanOpinionScore({value}) {
	const colour = (() => {
		if (value > 4.2) return 'bg-green-400';
		if (value >= 4.1) return 'bg-blue-400';
		if (value >= 3.7) return 'bg-yellow-400';
		if (value >= 3.1) return 'bg-orange-400';
		if (value >= 3.1) return 'bg-red-400';
		return 'bg-red-900';
	})();
	// Excellent - If the average mos is over 4.2
	// Great - If the average mos is between 4.1 and 4.2 both inclusive
	// Good - If the average mos is between 3.7 and 4.0 both inclusive
	// Fair - If the average mos is between 3.1 and 3.6 both inclusive
	// Degraded - If the average mos is 3.0 or below

	return <span className={`${colour} px-2 py-0.5 rounded`}>{value.toFixed(1)}</span>;
}

function NetworkTiming({networkTiming}) {
	const values = Object.values(networkTiming);
	const min_start = Math.min(...values.map(x => x.start));
	const max_end = Math.max(...values.map(x => x.end));
	const duration = max_end - min_start;

	const getSpan = (start, end) => ({
		left: `${(start - min_start) / duration * 100}%`,
		right: `${(max_end - end) / duration * 100}%`,
	});
	const events = Object.entries(networkTiming).sort(([_x, x], [_y, y]) => x.start - y.start);

	return (
		<>
			<Header as="h3">Network Timing</Header>
			<Table selectable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={3}>Event</Table.HeaderCell>
						<Table.HeaderCell>Graph</Table.HeaderCell>
						<Table.HeaderCell width={2} textAlign="right">Dur (ms)</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{events.map(([name, timing]) => (
						<Table.Row key={name}>
							<Table.Cell>{name}</Table.Cell>
							<Table.Cell className="relative flex">
							<span className={"absolute bg-blue-300"} style={{
								height: 10,
								...getSpan(timing.start, timing.end),
							}}/>
							</Table.Cell>
							<Table.Cell textAlign="right">{timing.duration}</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</>
	);
}


function ProgressEvents({progressEvents}) {
	const values = Object.values(progressEvents);
	const min_start = 0;
	const max_end = values.reduce((sum, x) => sum + x.duration, 0);

	const duration = max_end - min_start;

	const getSpan = (start, end) => ({
		left: `${(start - min_start) / duration * 100}%`,
		right: `${(max_end - end) / duration * 100}%`,
	});

	const events = progressEvents.reduce((acc, event) => {
		const start = acc.length === 0
			? 0
			: acc.at(-1).end;

		return acc.concat({...event, start, end: start + event.duration});
	}, []);

	return (
		<>
			<Header as="h3">Progress Events</Header>
			<Table selectable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={3}>Event</Table.HeaderCell>
						<Table.HeaderCell>Graph</Table.HeaderCell>
						<Table.HeaderCell width={2} textAlign="right">Dur (ms)</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{events.map(event => (
						<Table.Row key={event.name}>
							<Table.Cell>{event.name}</Table.Cell>
							<Table.Cell className="relative flex">
							<span className={"absolute bg-blue-300"} style={{
								height: 10,
								...getSpan(event.start, event.end),
							}}/>
							</Table.Cell>
							<Table.Cell textAlign="right">{event.duration}</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</>
	);
}
