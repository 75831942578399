import React, { useState } from 'react';
import SIcon from '../../NUI/StreamlineIcons/SIcon';
import { useProfile } from '../../../xAppLib/Hooks';
import moment from 'moment/moment';
import { cls } from '../../NUI/utils';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PreviewDocument, viewScriptUrl } from '../PreviewDocument';
import pdf_types from "../../../models/types/pdf_types";
import user_model from '../../../models/user_model';
import { ViewEscriptModal } from '../../scripts_history/ViewEscriptModal';

export function LastOrder({clsn=""}){

    const [prof] = useProfile()
	const [showInfo, setShowInfo] = useState(false);

    if(!prof.hist.length) return;

    const row = prof?.hist.find(order => order.status !== 'in_doccall') || prof?.hist[0]; // excluding consults in progress
    const escript = !!row?.epresc__escr;
    const awaiting = !row.is_script && !['qualcons', 'qualbuy', 'qualclick'].includes(row.script_type)

	const menu = [
		{
			title: 'Re-order',
			link:   '/remed/' + row.med_db_data__pth + '/' + row.med_db_data__id + '/' + row.sid,
            show:   ['medcons', 'medbuy', 'medclick', 'exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick'].includes(row.script_type) &&
                    row.status === 'doc_approved' &&
                    (user_model.check_access('show_medcat', { k: row.med_db_data__id, cat_nm: 'pharm' }) ||
                        user_model.check_access('show_medcat', { k: row.med_db_data__id, cat_nm: 'patho' })) &&
                    (!row.med_db_data__days_limit * 1 ||
                        moment().diff(moment(row.add_tm, 'D/M/YY HH:mm:ss').startOf('day'), 'days') > row.med_db_data__days_limit * 1 - 2) 
		},
		{
			title: 'View order',
			link: `/history/${row.sid}`,
            show: ['pathoreq', 'medcert', 'docrefr', 'doclett', 'sleeprefr'].includes(row.script_type) && row.patho_results && row.status == 'patho_res'
		},
        {
			title: 'View details',
			link: `/order/${row?.sid}`,
            show: ['doccons', 'qualcons', 'qualbuy', 'qualclick'].includes(row.script_type) && !['done_doccall', 'doc_approved'].includes(row.status)
		},
		{
			title: 'View prescription',
            button: <ViewEscriptModal scid={row.sid} trigger={(<Button basic content="View eScript" />)} />,
            show: escript && ['medcons', 'exprcons', 'qualcons', 'docscr'].includes(row.script_type) && !awaiting
		},
        {
			title: 'View results',
			button:  <PreviewDocument doc_name={pdf_types[row.script_type] || pdf_types[row.req_type] || 'Document'} url={viewScriptUrl(row.sid)}>
                        View {pdf_types[row.script_type] || pdf_types[row.req_type] || 'Document'}
                    </PreviewDocument>,
            show: (['pathoreq', 'medcert', 'docrefr', 'doclett', 'sleeprefr', 'medimgreq', 'mentplan', 'mentrev'].includes(row.script_type) || ['medcert'].includes(row.req_type)) && !row.patho_results && !awaiting
		},

	]

    const buttons = menu.filter((el) => el.show)

	return  <div className={cls("last-order block", clsn)}>

                <div className="bg-white lg:rounded lg:px-6 lg:py-9">
                    <h2 className="mb-4">Your last order:</h2>
                    
                    <div className="border border-is2-gray-100 rounded-md flex justify-between items-center px-6 lg:px-4 lg:py-6 py-2">
                        <div className="flex flex-col text-sm text-is2-gray-body">
                            <h4 className="mb-2 font-normal text-is2-gray-700">{row?.med_db_data.name}</h4>
                            <p className="text-sm m-0">{row.add_tm.split(" ")[0]}</p>
                        </div>
                        <div className={cls("rounded-full w-[40px] h-[40px] flex items-center justify-center cursor-pointer transition duration-300", showInfo && "rotate-180")} onClick={() => setShowInfo(!showInfo)}><SIcon name="chevron" size="xxs" bold/></div>
                    </div>
                </div>

                <div className={cls(showInfo ? "flex flex-wrap gap-4 justify-between w-full" : "hidden opacity-0", "bg-is2-gray-50 p-6 animate-fade")}>
                    {buttons.length > 0 ? 
                        buttons.map((el, i) => {
                            if (el.button) {
                                return <React.Fragment key={`${el.title}-${i}`}>{el.button}</React.Fragment>;
                            }
                            return (
                                <Button 
                                    key={`${el.title}-${i}`} 
                                    basic 
                                    as={Link} 
                                    to={el.link}
                                >
                                    {el.title}
                                </Button>
                            );
                        })
                        : <Button basic as={Link} to="/history">View details</Button>
                    }
                </div>
            </div>
    }

