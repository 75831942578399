/**
 * Convert a list to a lookup object. The keyFn is used to determine the key for each item, and the valFn is used to
 * determine the value for each item. If valFn is not provided, the item itself is used.
 * This is for a single record per key. Throws an error if duplicate keys.
 * @param list
 * @param keyFn
 * @param valFn
 * @return {Object}
 */
export function toDictionary(list, keyFn, valFn = item => item) {
	const lookup = {};

	for (const item of list) {
		const key = keyFn(item);
		if (lookup[key]) {
			throw new Error(`Duplicate key: ${key}`);
		}
		lookup[key] = valFn(item);
	}

	return lookup;
}
