import React, { useEffect, useRef, useState } from "react";
import menu_model from "../../../models/menu_model";
import { Button, Dropdown } from "semantic-ui-react";
import { useUser } from "../../../xAppLib/Hooks";
import SIcon from "../../NUI/StreamlineIcons/SIcon";
import { Link } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { cls } from "../../NUI/utils";
import user_model from "../../../models/user_model";
import { Header } from "./Header/Header";
import UserPic from "../../../xAppLib/Users/UserPic";
import { getUserDisplayName } from "helpers/getUserDisplayName";

function MenuItem({ icon, label, onClick, link }) {
	const itemProps = onClick ? {
		onClick
	} : {
		as: Link,
		to: link,
	};

	return (
		<Dropdown.Item {...itemProps} content={
			<span className="menu-item text-is2-gray-450 flex items-center">
				<SIcon name={icon} size="s" cls="text-is2-gray-body" bold /> {label}
			</span>
		} />
	);
}

export function UserMenuItem({ name, trigger, icon, toggle }) {

	const [openDialog, setOpen] = useState(false);
	const acc_menu = menu_model.get_accountmenu();
	const user = useUser()
	const btnRef = useRef(null);
	const [align, setAlign] = useState(0);

	const handleClick = () => {
		setOpen(!openDialog)
		toggle(false)
		handleAlign()
	}

	const handleAlign = () => {
		const curr = btnRef?.current?.getBoundingClientRect();
		if(curr) {
			const w = window.innerWidth;
			const r = (w - curr.left) < 360
			const aligned = w > 992 ? (r && curr.left - 80 + "px" || curr.left + "px") : 0;
			setAlign(aligned);
		}
	}

	useEffect(() => {
		window.addEventListener("resize", handleAlign, false);
		openDialog ? document.body.classList.add('menu-open') : document.body.classList.remove('menu-open')
	}, [openDialog]);

	const content =
		name == "Account" && <>
			<div className="flex items-center gap-2 pb-[4px] mb-4">
				<div className="!border !border-white !rounded-full">
					<UserPic
						photoURL={user?.user_det?.photoURL}
						email={user?.user_det?.email}
						name={getUserDisplayName(user, {
							format: 'full',
							defaultValue: 'User'
						})}
						size={36}
						className="!rounded-full !border !border-white !bg-cover !bg-no-repeat"
					/>
				</div>
				<h3 className="text-[20px] leading-[28px] font-medium !mt-0">
					{getUserDisplayName(user, {
						format: 'full',
						defaultValue: 'User'
					})}</h3>
			</div>

			<div className="menu-items" onClick={() => handleClick()}>
				{acc_menu.slice(0, -4).map((el, i) => <MenuItem key={i} icon={el.icon} link={el.link} label={el.name} />)}

				{app.acl.is_mang && app.acl.is_cosm && <Dropdown.Item icon='building' as={Link} to='/org/my-clinic-profile' content="Clinic Profile" className="menu-item" />}
				{app.acl.is_nurse && <Dropdown.Item icon='certificate' as={Link} to='/cosm/audit/list/practitioner' content="Audit history" className="menu-item" />}
				{(app.acl.is_admin || app.acl.is_nurse || app.acl.is_doc) && (
					<Dropdown.Item icon='video' as={Link} to='/vidcall-troubleshooting' content="Test VidCall" className="menu-item" />
				)}

				<h3 className="font-medium text-[20px] leading-[28px] mb-4 mt-6">Account</h3>
				{acc_menu.slice(-4).map((el, i) => <MenuItem key={i} icon={el.icon} link={el.link} label={el.name} />)}

				<MenuItem label="Sign out" icon="logout" onClick={() => user_model.logout()} />
			</div>
		</>
		|| `placeholder ${name}`

	const defaultName = getUserDisplayName(user, {
		format: 'first',
		defaultValue: 'User'
	})

	return <>
		<span className={cls("item dropdown", openDialog && "active")} data-testid={`dropdown-${name}`} ref={btnRef} onClick={_ => handleClick()}><span className="hidden lg:block">{trigger === "undefined u" ? defaultName : trigger || trigger === "undefined" ? defaultName : trigger || name}</span><SIcon name={icon} cls="p-[9px]" bold svg_cls="h-[18px] w-[18px]" /></span>

		<Dialog
			open={openDialog}
			onClose={() => { setOpen(false) }}
			className="relative"
		>

			<div className="fixed inset-0 w-screen flex w-full lg:w-[332px] lg:h-fit items-center justify-center mt-[56px] lg:mt-[80px] z-20 lg:shadow-lg lg:rounded-b-[28px]" style={{ left: align }}>
				<Dialog.Panel className="relative transform overflow-y-scroll lg:max-h-[88vh] no-scrollbar bg-white w-full h-full lg:rounded-b-[28px] lg:h-[660px]">
					<div className="user-menu-container">
						<Header title={name} clsn="!flex-row justify-between lg:hidden" children={<Button color="blue" icon="close" className="rounded-full w-fit" onClick={() => setOpen(false)} />}></Header>

						<div className="px-6 pt-6 lg:pt-0 pb-3">
							{content}
						</div>
					</div>
				</Dialog.Panel>
			</div>
		</Dialog>
	</>
}

