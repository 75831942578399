import { useEffect, useState } from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import config from '@/../tailwind.config';

const fullConfig = resolveConfig(config);
const breakpoints = fullConfig.theme.screens;

const available = 'matchMedia' in window;

function query(breakpoint) {
	if (!available) return false;

	const result = window.matchMedia(`(min-width: ${breakpoints[breakpoint]})`);

	return result.matches;
}

/**
 * A React hook that listens to window size changes and determines whether the window matches a specified breakpoint.
 * See https://stackoverflow.com/a/71098593
 *
 * @param {string} breakpoint The CSS media query string representing the breakpoint to check against.
 * @return {boolean} A boolean value indicating if the window is at least the given breakpoint.
 */
export function useBreakpoint(breakpoint) {
	const [matches, setMatches] = useState(() => query(breakpoint));

	useEffect(() => {
		if (!available) return;

		const update = () => setMatches(query(breakpoint));
		window.addEventListener('resize', update);

		return () => {
			window.removeEventListener('resize', update);
		};
	}, []);

	return matches;
}