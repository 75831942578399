import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import UserSignUpAuthentication from '../../xAppLib/Users/Account/UserSignUpAuthentication';
import UserSignUpAccountDetails from '../../xAppLib/Users/Account/UserSignUpAccountDetails';
import user_model from 'models/user_model';
import { useNavigate } from 'react-router-dom';
import { Header } from 'views/UIelems/v2/Header/Header';
import { useUser } from 'xAppLib/Hooks';
import { loginHandlers } from 'views/UIelems/v2/single_use/loginHandlers';
import UserSignUpAccount from 'xAppLib/Users/Account/UserSignUpAccount';

const transformProfileData = (formData) => {
    const dvaData = formData.dvaNumber.number && formData.dvaNumber.colour ? {
        number: formData.dvaNumber.number,
        colour: formData.dvaNumber.colour
    } : null;

    return {
        first_name: formData.firstName,
        last_name: formData.lastName,
        medicare: formData.medicare,
        atsi: formData.indigenous,
        dob: formData.dateOfBirth,
        mobile: formData.mobilePhone,
        sex: formData.sex === 'male' ? 'M' :
            formData.sex === 'female' ? 'F' : null,
        full_address: formData.full_address,
        conc_card: formData.concessionCard,
        dva: dvaData,
        em_con_name: '',
        em_con_mobile: '',
        em_con_rel: '',
    };
};

const SignUpPage = ({onAlternativeLinkClick}) => {
    const [signUpState, setSignUpState] = useState({
        stage: 'auth',
        providerData: null,
        signupResult: null
    })
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const { user_in } = useUser();
    const containerRef = useRef(null)

    useEffect(() => {
        if (user_in && !signUpState.signupResult) {
            navigate('/')
        }
    }, [user_in])

    const handleProviderSelect = async ({ provider, ...payload }) => {
        if (isLoading) return;
        setError(null);

        try {
            if (provider === 'email') {
                setIsLoading(true);
                const { firstName, lastName, email, password, consent } = payload;

                const signupResult = await user_model.usr_signup({
                    email,
                    password,
                    verify_password: password,
                    first_name: firstName,
                    last_name: lastName,
                    consent
                });

                if (signupResult.res === 'err') {
                    setError({
                        header: 'Sign Up Error',
                        message: signupResult.err_msg,
                        code: signupResult.err_code
                    });
                    return;
                }

                await user_model.frb_login(email, password, consent)

                setSignUpState({
                    ...signUpState,
                    stage: 'details',
                    providerData: payload,
                    signupResult: signupResult
                });
            } else {
                await loginHandlers[provider](payload);
            }
        } catch (error) {
            setError({
                header: 'Error',
                message: 'An unexpected error occurred. Please try again.',
            });
        } finally {
            setIsLoading(false);
        }
    }

    const handleProfileSubmit = async (formData) => {
        if (isLoading) return;
        setIsLoading(true);

        try {
            const profilePayload = transformProfileData(formData);
            const userId = signUpState.signupResult?.uid;

            if (!userId) {
                throw new Error('No user ID available');
            }

            const response = await upd_prof({
                uid: userId,
                ...profilePayload
            });

            if (response) {
                if(app.goto_after_login){
                    navigate(app.goto_after_login);
                    app.goto_after_login = null;
                } else {
                    navigate('/');
                }
            }
        } catch (error) {
            setError({
                header: 'Profile Update Error',
                message: error.message || 'There was an error updating your profile. Please try again.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const upd_prof = async (d) => {
        const r = await user_model.save_prof(d, async r => {
            if (r.res === 'ok') {
                await app.user.reload_profile()
            }
            return r
        })
        return r
    }

    const stageComponents = {
        auth: (
            <UserSignUpAuthentication
                onSelectProvider={handleProviderSelect}
                setSignUpState={setSignUpState}
                error={error}
                loading={isLoading}
                onAlternativeLinkClick={onAlternativeLinkClick}
            />
        ),
        signupform: (
            <>
                <Header title="Create account" />
                <UserSignUpAccount
                    providerData={signUpState.providerData} 
                    onSubmit={handleProviderSelect} 
                    authError={error} 
                    loading={isLoading}
                />
            </>
        ),
        details: (
            <>
                <Header title="Create account" />
                <UserSignUpAccountDetails
                    providerData={signUpState.providerData}
                    onSubmit={handleProfileSubmit}
                    authError={error}
                    loading={isLoading}
                />
            </>
        ),
    };

    return <div ref={containerRef}>
        <Helmet>
            <title>Create an account</title>
        </Helmet>
        {stageComponents[signUpState.stage] || null}
    </div>
}

export default SignUpPage;