import React from 'react';
import SIcon from '../../NUI/StreamlineIcons/SIcon';
import UserPic from '../../../xAppLib/Users/UserPic';
import { cls } from '../../NUI/utils';

export function ImageCard(props){
	const {img, label, edit, highlight, add_new, user, onEdit, handleClick } = props

	return (
		<div className={cls("flex items-center gap-3 rounded-lg border border-[#D4D5D6] py-2 px-4 h-fit relative mb-4 cursor-pointer hover:bg-is2-blue-50 group transition-all duration-300 group", highlight && "border-2")} onClick={onEdit}>
            {add_new ?  <div className="flex gap-3 items-center w-full" onClick={handleClick}>
							<div className="rounded-full h-[60px] w-[60px] shrink-0 bg-[#ECF4FE]"><img src="https://storage.googleapis.com/instant-med-public/v2/new-account.svg" className="h-[60px]"/> </div>      
							<p>{label}</p>
						</div>
			: 	<>
					<UserPic
						email={user?.email}
						name={label}
						size={60}
						className="!rounded-full !bg-contain !bg-no-repeat"
					/>
					<p className="flex items-center mb-0">{label}{highlight && <img src="https://storage.googleapis.com/instant-med-public/v2/icon-star-rating.svg" className="h-4 ml-2"/>}</p>
				</>
			}
			{edit && <div className="cursor-pointer absolute right-4 h-5"><SIcon name="pencil" size="xxs" bold cls="cursor-pointer text-is2-gray-body group-hover:text-is2-blue"/></div>}
		
		</div>
	);
}

