import React from "react";
import { DescriptionAndInfo } from "./DescriptionAndInfo";

export default function SingleInfoCard({
  icon,
  headingIntro,
  title,
  img,
  imageComponent,
  descriptions,
  postDescriptionComponent,
  postDescription,
  descriptionAndInfoComponent,
  headerComponent,
  overlayProfession,
  overlayName,
  overlayCompany,
  overlayLinkUrl,
  overlayLinkText,
  overlayComponent,
  isEven,
  cta,
}) {
  return (
    <article
      className={`flex flex-col gap-2 sm:gap-4 ${
        isEven ? "sm:flex-row-reverse" : "sm:flex-row"
      }`}
    >
      <div className="relative hidden md:block md:flex-[6] md:basis-6/12 mx-auto">
        <ImageAndOverlay
          img={img}
          title={title}
          imageComponent={imageComponent}
          overlayComponent={overlayComponent}
          overlayName={overlayName}
          overlayProfession={overlayProfession}
          overlayCompany={overlayCompany}
        />
      </div>
      <div className="md:flex-[6] md:basis-6/12 flex flex-col justify-center text-center">
        <InfoCardHeader
          headerComponent={headerComponent}
          headingIntro={headingIntro}
          title={title}
        />
        <div className="relative block md:hidden mb-9">
          <ImageAndOverlay
            img={img}
            title={title}
            imageComponent={imageComponent}
            overlayComponent={overlayComponent}
            overlayName={overlayName}
            overlayProfession={overlayProfession}
            overlayCompany={overlayCompany}
          />
        </div>
        <DescriptionAndInfo
          descriptionAndInfoComponent={descriptionAndInfoComponent}
          descriptions={descriptions}
          overlayLinkUrl={overlayLinkUrl}
          overlayLinkText={overlayLinkText}
          postDescriptionComponent={postDescriptionComponent}
          postDescription={postDescription}
          cta={cta}
          icon={icon}
        />
      </div>
    </article>
  );
}

const InfoCardHeader = ({ headerComponent, headingIntro , title}) => {
  return (
    <>
      {headerComponent ? (
        headerComponent
      ) : (
        <>
          <span className="text-is2_md text-is2-gray-350">{headingIntro}</span>
          <h2 className="text-[#313334] mt-2 mb-9 mx-10 md:mx2">{title}</h2>
        </>
      )}
    </>
  );
};


const ImageAndOverlay = ({
  img,
  title,
  imageComponent,
  overlayComponent,
  overlayName,
  overlayProfession,
  overlayCompany,
}) => {
  return (
    <>
      {imageComponent ? (
        imageComponent
      ) : (
        <img
          src={img}
          alt={title}
          className="object-cover w-full h-full block rounded-[50px] mb-9"
          style={{
            aspectRatio: "3 / 3.5",
          }}
          loading="lazy"
        />
      )}
      {overlayComponent ? (
        overlayComponent
      ) : (
        <>
          {overlayName && (
            <div className="absolute bottom-4 left-4 p-4 text-white rounded-bl-[40px] rounded-br-[40px]">
              <p className="text-sm text-left">
                {overlayName} <br />
                {overlayProfession} <br />
                {overlayCompany} <br />
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
};
