export const TABLE_COMPONENT_TYPES = {
  CHECKBOX: "checkbox",
  TEXT: "text",
  TOGGLE: "toggle",
  DROPDOWN: "dropdown",
  MULTI_SELECT: "multiselect",
  RATE_TYPE: "rateType",
  DATE_PICKER: "datePicker",
  CONSULT_OPTIONS: "consultOptions",
};

export const RATE_TYPES = {
  FIXED: "fixedRate",
  PERCENTAGE: "percRate",
};
export const OPTION_KEYS = {
  CONSULT_OPTIONS: "CONSULT_OPTIONS",
  SELECTED_CONSULTS: "selectedConsults",
  SENIORITY: "SENIORITY",
  TIME_OPTIONS: "TIME_OPTIONS",
  DAY_OPTIONS: "DAY_OPTIONS",
};

export const DOC_PAY_TABLE_SCHEMA = [
  { label: "Id", key: "ruleId" },
  {
    label: "Active",
    key: "active",
    componentType: TABLE_COMPONENT_TYPES.TOGGLE,
    required: true,
  },
  {
    label: "Consult Type",
    key: "consultType",
    componentType: TABLE_COMPONENT_TYPES.DROPDOWN,
    optionKey: OPTION_KEYS.CONSULT_OPTIONS,
    required: true,
    tooltipInfo:
      "Selected consultations will require you to select minimum one consult type",
  },
  {
    label: "Selected Consults",
    key: "selectedCons",
    componentType: TABLE_COMPONENT_TYPES.MULTI_SELECT,
    required: true,
    optionKey: OPTION_KEYS.SELECTED_CONSULTS,
    tooltipInfo:
      "Selected consults take precedence over 'All Consults', 'Async' and 'Cosm DTA'",
    hasSearch: true,
    columnWidth: "20%",
  },
  {
    label: "Seniority",
    key: "seniority",
    componentType: TABLE_COMPONENT_TYPES.MULTI_SELECT,
    optionKey: OPTION_KEYS.SENIORITY,
    required: true,
    hasSelectAll: true,
    displayText : "Levels Selected",
    columnWidth: "10%",
  },
  {
    label: "Time Of Day",
    key: "timeOfDay",
    componentType: TABLE_COMPONENT_TYPES.MULTI_SELECT,
    optionKey: OPTION_KEYS.TIME_OPTIONS,
    required: true,
    hasSelectAll: true,
    displayText : "Times Selected",
    columnWidth: "10%",
  },
  {
    label: "Day of Week",
    key: "daysOfWeek",
    componentType: TABLE_COMPONENT_TYPES.MULTI_SELECT,
    optionKey: OPTION_KEYS.DAY_OPTIONS,
    required: true,
    hasSelectAll: true,
    displayText : "Days of week selected",
  },
  {
    label: "Rate Type",
    key: "rateType",
    componentType: TABLE_COMPONENT_TYPES.RATE_TYPE,
    required: true,
    tooltipInfo: "Either a fixed or percentage rate type is required",
  },
  {
    label: "Target Date",
    key: "targetDate",
    componentType: TABLE_COMPONENT_TYPES.DATE_PICKER,
    tooltipInfo:
      "Rules with a target date will take precedence on the specified day to matching rules.",
    columnWidth: "10%",
  },
  {
    label: "Actions",
    key: "actions",
    componentType: TABLE_COMPONENT_TYPES.ACTIONS,
    columnWidth: "12%",
  },
];
export const EMPTY_VALUE = "━━━";
export const COMPONENT_ONLY_COLUMNS = [
  "select",
  "active",
  "actions",
  "rateType",
  "endDate",
  "startDate",
];
export const READ_ONLY_COLUMNS = [""];
export const OMITTED_FIELDS = ["select", "ruleId", "active", "actions"];
export const EMPTY_DOC_PAY_RULE = {
  timeOfDay: [],
  daysOfWeek: [],
  consultType: "",
  selectedCons: [],
  seniority: [],
  fixedRate: null,
  percRate: 0,
  startDate: null,
  endDate: null,
  targetDate: null,
  last_updated_date: null,
  created_date: null,
  active: false,

  hist: [],
};

export const SAVE_ACTIONS = {
  UPDATE: "update",
  ADD: "add",
  TOGGLE_ACTIVE: "toggleActive",
};

export const SELECT_ALL = {
  key: "select all ",
  text: "Select All",
  value: "select all",
};
export const CLEAR_ALL = {
  key: "clear all ",
  text: "Clear All",
  value: "clear all",
};

export const SELECTED_CONS_DISPLAY_MAX = 1;
export const MAX_SELECTED_ITEMS_DISPLAYED = 2