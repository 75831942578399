/**
 * Get user name information with flexible formatting options
 * @param {Object} user - User object containing profile and details
 * @param {Object} options - Display options
 * @param {('full'|'first'|'last')} [options.format='full'] - Name format to return
 * @param {string} [options.defaultValue=''] - Default value if no name found
 * @returns {string} Formatted display name
 */
export const getUserDisplayName = (user, options = {}) => {
    const {
        format = 'full',
        defaultValue = ''
    } = options;

    const isEmpty = (str) => (
        !str ||
        str.trim() === '' ||
        str === 'undefined' ||
        str === 'undefined undefined'
    );

    const firstName = (user?.prof?.first_name === 'undefined' ? '' : user?.prof?.first_name) ||
        (user?.first_name === 'undefined' ? '' : user?.first_name) ||
        '';
    const lastName = (user?.prof?.last_name === 'undefined' ? '' : user?.prof?.last_name) ||
        (user?.last_name === 'undefined' ? '' : user?.last_name) ||
        '';

    if (isEmpty(firstName) && isEmpty(lastName)) {
        return defaultValue;
    }

    if (format === 'first') {
        return isEmpty(firstName) ? defaultValue : firstName;
    }

    if (format === 'last') {
        return isEmpty(lastName) ? defaultValue : lastName;
    }

    const fullName = [firstName, lastName].filter(name => !isEmpty(name)).join(' ');

    if (format === 'full') {
        return fullName || defaultValue
    }

    const displayName = user?.user_det?.displayName;
    if (displayName &&
        !isEmpty(displayName)) {
        return user.user_det.displayName;
    }

   
    return fullName || defaultValue;
};