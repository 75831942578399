import React, {Component} from 'react'
import { Icon, Container } from 'semantic-ui-react'
// import jquery from 'jquery'
import Alert from "views/NUI/Alert";
import {ErrorBoundary} from "./ErrorBoundary";
const Message = Alert
import { toastMessage, ToastType } from 'xAppLib/helpers/toast_message';
import user_model from '../../models/user_model';
import { v4 as generate_id } from 'uuid'
import { shouldShowInNewSystem } from '../../notifications/view/app/LegacyMessageAdapter';
export default class MessageBar extends Component {

	constructor (props) {
	    super(props)

		this.state = {
			msgs: []
		};

		// {h:'hd', m:'msg'}, {h:'hhdd', m:'mmsgg'}

	}

	componentDidMount() {
		app.on(app.events.SHOW_MESSAGE, this.show_message)
		app.on(app.events.CLEAR_MESSAGE, this.clear_message)
	}

	componentWillUnmount() {
		app.off(app.events.SHOW_MESSAGE, this.show_message)
		app.off(app.events.CLEAR_MESSAGE, this.clear_message)
	}

	render () {

		let { msgs } = this.state
		const { hide_on, show_on } = this.props

		if (hide_on)			msgs = msgs.filter( m => !hide_on?.includes?.(m.key) )
		if (show_on)			msgs = msgs.filter( m => !!show_on?.includes?.(m.key) )

		return (
			// (!app.runtime?.login_type=='popup' && msgs.length>0 ||
			//  (app.runtime?.login_type=='popup' && this.props.login == true && msgs.length>0)) &&

			msgs.length>0 &&
				<div className="mx-4 mt-4 md:w-full md:max-w-3xl md:mx-auto message-bar">
						{
							msgs.map( (m, i) => <Message
																accent
																header={m.h}
																content={<ErrorBoundary><>{m.m}</></ErrorBoundary>}
																onDismiss={_=>this.closeWindow(m.id)}
																key={'msg'+i}
																success={m.l == 'positive'}
																error={m.l == 'negative'}
																warning={m.l == 'info'}
																className={m.l}
																testId='error-message'
																{...{ [m.l]:true }}
															/>
							)
						}
				  {/*<Message onDismiss={this.closeWindow.bind(this)}>

						{
							msgs.map( (m, i) => <Message.Content key={'msg'+i}>
																{m.h.length>1 && <Message.Header>{m.h}</Message.Header>}
																{ m.m }
															</Message.Content>
							)
						}
				  </Message>*/}
			</div>

		)
	}

	//	------------	------------	------------	------------

	show_message = (h, m, l, key) => {
		// Extract the message key for checking
		const messageKey = typeof h === 'object' ? h.key : key;
		
		if (shouldShowInNewSystem(messageKey)) {
			return;
		}

		if (typeof h == 'object') {
			l = h.type,
			m = h.content
			key = h.key
			h = h.header
		}
		let msgs = [...this.state.msgs]
		if (key) {
			msgs = this.clear_message(key)
		} 

		const _m = {h:unescape(h), m:m, l, key, id:generate_id()}
		msgs.push(_m)

		this.setState({
			msgs
		})

		if (user_model.check_access('🍞')) {
			const t = _m.l=='negative'?ToastType.ERROR:_m.l == 'info'?ToastType.INFO:ToastType.SUCCESS
			toastMessage(<><p><strong>{_m.h}</strong></p><p><ErrorBoundary><>{_m.m}</></ErrorBoundary></p></>,t,{onClose: () => this.closeWindow(_m.id)})
		}
	}

	//	------------	------------	------------	------------

	clear_message = (key)=> {
		// Only clear messages that should be shown in the old system
		if (!shouldShowInNewSystem(key)) {
			return [];
		}
		
		let msgs = key ? [...this.state.msgs].filter(m=>m.key!=key) : []
		
		this.setState({
			msgs
		})
		
		return msgs
	}

	//	------------	------------	------------	------------

	closeWindow (id) {
		this.setState({
			msgs:this.state.msgs.filter(m=>m.id!=id)
		})
	}

	test_message () {
		app.trigger(app.events.SHOW_MESSAGE, 'header', 'this is message')
	}
}
