import { capitalize } from "@/helpers/prettify";
import React from 'react';
import { Icon } from "semantic-ui-react";

function SexIcon({value}) {
	const ICON_MAPPING = {
		male: {name: 'man', color: 'blue'},
		female: {name: 'woman', color: 'pink'},
		other: {name: 'genderless', color: 'blue'},
	};

	const props = ICON_MAPPING[value] ?? ICON_MAPPING.other;

	return <Icon {...props}/>;
}

export function DoctorGenderPreference({value, className}) {
	if (!value) {
		return null;
	}

	return (
		<div className={className}>
			Preferred Dr Sex: {capitalize(value)} <SexIcon value={value}/>
		</div>
	);
}