const colors = require('tailwindcss/colors');
/* eslint-env node */

module.exports = {
  content: ["./src/**/*.{js,jsx}"],
  safelist: [{pattern: /(gap-|grid-cols-|col-span-).+/, variants: ['md']}],
  theme: {
    fontFamily: {
      sans: '"gotham-rounded", "Helvetica Neue", Arial, Helvetica, sans-serif',
    },
    extend: {
      gridColumn: {
        "span-16": "span 16 / span 16",
      },
      gridTemplateColumns: {
        // Simple 16 column grid
        16: "repeat(16, minmax(0, 1fr))",

        // e.g. Complex site-specific column configuration
        //'footer': '200px minmax(900px, 1fr) 100px',
      },
      animation: {
        fade: 'fadeIn .4s ease-in'
      },
      keyframes: theme => ({
        fadeIn: {
          '0%': { opacity: 0.4 },
          '100%': { opacity: 1 },
        },

      }),
      colors: {
        "is": {
          "blue": {
            20: '#E2F3FF',
            50: '#C5E5FF',
            // 100: '#1377F7',
            150: '#A8D7FE',
            DEFAULT: '#0065F2',
            100: '#0071f5',
            200: '#6FB6FD',
            900: '#00286B'
          },
          "yellow": '#FFF4E3',
          "blue2": "#006DE9",
          "gray": {
            DEFAULT: '#f6f6f7',
            10: '#F2F2F2',
            20: '#FAFAFA',
            25: '#7B7B7B',
            30: '#E5E7EB',
            50: '#D5D5D5',
            80: '#BEBEBE',
            85: "#AAAAA7",
            90: '#666666',
            100: '#1F2937'
          },
          "black": {
          50: '#515151',
          80: '#272727',
          DEFAULT: "#2D3748",
          },
          "red": "#DF3200",
          "green": {
            20: '#EBFFEB',
            DEFAULT: '#00873F',
          },
        },
        green: colors.emerald,
        purple: colors.violet,
        yellow: colors.amber,
        tertiary: "hsl(var(--site-tertiary))",
        'site-yellow': "hsl(var(--site-yellow))",
        'lightest-grey': "hsl(var(--site-lightest-grey))",
        'site-brand': "hsl(var(--site-brand))",

        "is2": {
          "blue": {
            900 : "#001430",
            850: "#001E49",
            650: "#0047A9",
            700: "#003D91",
            600: "#002861",
            550: "#001D4E",
            DEFAULT: "#0065F2",
            500: "#0051C2",
            300: "#005BDA",
            250: "#1A74F3",
            200: "#1377F7",
            150: "#B3D1FB",
            100: "#CCE0FC",
            50: "#ECF4FE",
          },
          "gray": {
            body: "#696c70",
            800: "#101214",
            700: "#3E4247",
            500: "#292D33",
            450: "#25292E",
            350: "#494E53",
            300: "#47494B",
            200: "#A9ABAD",
            150: "#696C70",
            100: "#D4D5D6",
            50: "#EFEFF0",
            10: "#F0F4F5",
          },
          "black": {
            DEFAULT: "#000",
            "20": "#6E7377",
          },
          "green": {
            DEFAULT: "#E6FEEC",
            750: "#007923",
            650: "#00A930",
            100: "#E6F8EC",
          },
          "yellow": {
            500: "#F28D00",
            200: "#FAD199",
            100: "#FEF4E6",
            50: "#FDF4E5"
          },
          "red": {
            100: "#FDEAEA",
          },
          "orange": {
            DEFAULT: "#FF5A00"
          }
        },
       
        "bls": {
          "gray": '#fafafa',
          "blue": "#0d5786",
          "light-blue": '#7a9fb7',
          "dark-blue": '#09384c',
        },
        "cmg": {
          10: "#f5f5f0",
          20:"#d3d4cf",
          25: "#d2d3ce",
          30: "#e7e7e0",
          50: "#ced0c2",
          100: "#9da085",
          150: "#7c7d6d",
          200: "#50523d",
          250: "#282a1d",
          300: "#231f20"
        },
        "icosm": {
          "gold": {
            50: '#FEF7EE',
            DEFAULT: '#a39383',
            200: '#95785E',
          },
          "blue" : {
            default: "#D1E0EE",
            250: "#2D3748",
          },
          "gray" : "#6D7171",
          "primary": '#000',
          "secondary": '#fff',
          "tertiary": '#AB0439',
          "quaternary": '#EFB69C',
        },

        "op-purple" : "#9900F1",
        "op-gray" : "#EEEEE9",

        border: "hsl(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        background: "hsl(var(--background))",
        foreground: "hsl(var(--foreground))",
        primary: {
          DEFAULT: "hsl(var(--primary))",
          foreground: "hsl(var(--primary-foreground))",
        },
        secondary: {
          DEFAULT: "hsl(var(--secondary))",
          foreground: "hsl(var(--secondary-foreground))",
        },
        destructive: {
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        card: {
          DEFAULT: "hsl(var(--card))",
          foreground: "hsl(var(--card-foreground))",
        },
        progress: {
          foreground: "hsl(var(--progress-foreground))",
          background: "hsl(var(--progress-background))",
          activeForeground: "hsl(var(--progress-active-consultation-foreground))",
          activeBackground: "hsl(var(--progress-active-consultation-background))",
        },
        notifications: {
          indicatorIcon: {
            DEFAULT: "hsl(var(--notifications-indicator-icon))",
            active: "hsl(var(--notifications-indicator-icon-active))",
          },
          list: {
            header: {
              DEFAULT: "hsl(var(--notifications-list-header))",
            },
          },
        },
      },
      fontSize: {
        xs: ['0.750rem', '1.25rem'],
        sm: ['0.875rem', '1.25rem'],
        base: ['1rem', '1.5rem'],
        md: ['1.25rem', '1.5rem'],
        lg: ['1.5rem', '2rem'],
        xl: ['2rem', '2.5rem'],
        '2xl': ['2.375rem', '3rem'],
        '3xl': ['2.625rem', '3.5rem'],
        '4xl': ['3.25rem', '4.75rem'],

        'lg-sm': ['1.35rem', '1.8rem'],
        'xl-sm': ['1.55rem', '2.25rem'],
        '2xl-sm': ['2rem', '2.7rem'],
        '3xl-sm': ['2.36rem', '3.15rem'],

        is2_md: ['1.125rem', '1.52rem'],
        is2_lg: ['1.25rem', '1.875rem'],
        is2_xl: ['1.5rem', '2.25rem'],
        'is2_2xl': ['2rem', '2.5rem'],
        'is2_3xl': ['2.5rem', '2.75rem'],
        'is2_4xl': ['2.75rem', '3.25rem'],
        'is2_5xl': ['3.5rem', '4.25rem'],

        icosm_sm: '0.8rem',
        icosm_md: ['1rem', '1.375rem'],
        icosm_base: ['1.1875rem', '1.438rem'],
        icosm_xl: '1.36rem',
        icann_md: ['1.125rem', '1.75rem']
      },
      screens: {
        'xs': '375px',
        'sm': '576px',
        'md': '768px',
        'lg': '992px',
        'xl': '1200px',
        'menu-sm': '768px',
        'menu-lg': '1165px',
        'menu-xl': '1440px',
        'cmg-md': '1132px'
      },
      spacing: {
        sm: '8px',
        md: '16px',
        lg: '24px',
        xl: '48px',
      },
      backgroundImage: {
        'hero-lg': "url('/www/images/homepage/24-7-home_header_desktop.jpg')",
        'hero-day-lg': "url('/www/images/homepage/24-7-home_header_desktop_day.jpg')",
        'hero-sm': "url('/www/images/homepage/24-7-home_header_mobile.jpg')",
        'hero-day-sm': "url('/www/images/homepage/24-7-home_header_mobile_day.jpg')",
        'hero-return-lg': "url('/www/images/homepage/return-banner-lg.jpg')",
        'hero-return-sm': "url('/www/images/homepage/return-banner-sm.jpg')",
      },


      borderRadius: {
        lg: `var(--radius)`,
        md: `calc(var(--radius) - 2px)`,
        sm: "calc(var(--radius) - 4px)",
      },

      textColor: {
        subdued: {
          DEFAULT: 'hsl(var(--subdued))',
          inverted: 'hsl(var(--subdued-inverted))',
        },
        tertiary: {
          DEFAULT: 'hsl(var(--site-tertiary))',
          inverted: 'hsl(var(--site-tertiary-inverted))',
        },
      },
    },
  },
  plugins: [require("@tailwindcss/forms"), require("tailwindcss-animate")],
};
