import React, { Component } from 'react';

import { 	Container,
			Input,
			Button,
			Table,
			Icon,
			Message,
			Segment,
			Header,
			Loader,
			Popup,
			Menu,
			Dimmer,
			Modal,
			Form,
			Checkbox,
		} from 'semantic-ui-react'

import API_service from 'xAppLib/providers/API_service'
import obj_diff from 'xAppLib/helpers/obj_diff'

import patho_model from 'models/patho_model'
import user_model from 'models/user_model'
import Files from 'xAppLib/Files/Files';
import moment from 'moment-timezone'
import SendAttachments from 'xAppLib/Files/SendAttachments'
import NotifyPatho from 'xAppLib/Files/NotifyPatho'
import { v4 as uuidv4 } from 'uuid';

export default class AddResult extends Component {

	constructor (props) {
		super(props)

		user_model.limit_access('cust_supp');

		this.state = {
			upl_files: props.scr_obj.attch || [],
			loading:false,
			has_followup: !!props.scr_obj.meta?.followup_sid,
			has_prescs: props.scr_obj.prescs,
			sup_ticket: props.scr_obj.meta?.sup_followup_ticket,
			result_note: 'Results are ready. Please followup with a patient.',
			result_msg: 'We got your test results. Please request a followup consultation with your doctor.',
		}
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (this.props.scr_obj != prevProps.scr_obj) {
			this.setState({upl_files: this.props.scr_obj.attch || []})
		}
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	async attach_results (files) {

		const { scr_obj } = this.props
		
		// if (!files?.length)
		// 	return

		const data_diff = obj_diff(scr_obj.attch, files)

		// console.log('sendNote', {part:'attch'}, data_diff, Object.keys(data_diff).length)


		if (!data_diff.to || !Object.keys(data_diff).length) 			return


		let params = {sid:scr_obj.sid, attch:files, diff:{...data_diff, part:'attch'}}

		const upd_res = await API_service.load_data(
									'scripts/upd_script', 
									params,
									// r => ca && ca(r)
								) 

		console.log('upd_res', upd_res);

		app.trigger(app.events.SHOW_MESSAGE, 
						'Result added to record', 
						upd_res.res=='ok' && `Success adding result` || `ERROR adding result ${upd_res.err}`, 
						upd_res.res=='ok' ? 'positive' : 'negative',
						`add_results_${uuidv4()}`
					)
		
		return upd_res.res=='ok'

	}

	// 		--------------------------------		--------------------------------		---------

	async req_results_followup () {

		const { scr_obj } = this.props
		const { result_note, result_msg } = this.state
		
		this.setState({loading:true})

		let params = {
						sid: scr_obj.sid, 
						type: 'res', 
						note: result_note,
						msg: result_msg,
						res_type: 'abnormal'
					}

		const upd_res = await API_service.load_data(
									'scripts/req_followup_cons_res', 
									params,
									// r => ca && ca(r)
								) 

		console.log('upd_res', upd_res);

		app.trigger(app.events.SHOW_MESSAGE, 
						'Follow Up Booking Request', 
						upd_res.res=='ok' && `Record successfully added.` || `ERROR adding record ${upd_res.err}`, 
						upd_res.res=='ok' ? 'positive' : 'negative',
						`add_results_${uuidv4()}`
					)
					
		this.setState({loading:false,showFollowReq:false,result_note:'Results are ready. Please followup with a patient.',has_followup:upd_res.res=='ok'})
		
	}
	
	// 		--------------------------------		--------------------------------		---------

	async cre_results_followup () {

		const { scr_obj } = this.props
		const { result_note } = this.state
		
		this.setState({loading:true})

		let params = {sid:scr_obj.sid, type:'res', note:result_note}

		const upd_res = await API_service.load_data(
									'scripts/cre_cons_followup_fro', 
									params,
									// r => ca && ca(r)
								) 

		console.log('upd_res', upd_res);

		app.trigger(app.events.SHOW_MESSAGE, 
						'Follow Up Request', 
						upd_res.res=='ok' && `Record successfully added.` || `ERROR adding record ${upd_res.err}`, 
						upd_res.res=='ok' ? 'positive' : 'negative',
						`add_results_${uuidv4()}`
					)
					
		this.setState({loading:false,showFollowUp:false,result_note:'Results are ready. Please followup with a patient.',has_followup:upd_res.res=='ok'})
		
	}
	
	
	async notify_patho () {
		
		
		const { scr_obj } = this.props
		
		this.setState({loading:true})

		const upd_res = await API_service.load_data(
									'scripts/notify_patho_patient', 
									{sid:scr_obj.sid, type:'pathores'}
								) 

		console.log('upd_res', upd_res);

		app.trigger(app.events.SHOW_MESSAGE, 
						'Pathology', 
						upd_res.res=='ok' && `Notification Sent.` || `ERROR sending notification ${upd_res.err}`, 
						upd_res.res=='ok' ? 'positive' : 'negative',
						`add_results_notify_patho_${uuidv4()}`
					)
					
		this.setState({loading:false,showPathoNotify:false,has_notified:upd_res.res=='ok'})
		
	}

	// 		--------------------------------		--------------------------------		---------
	onAddFile = async (f, index) => {
		const files = [...this.state.upl_files]
		if (index != undefined) 
			files[index] = f
		else
			files.push(f)
		this.setState({upl_files:files})
		await this.attach_results(files)
		this.setState({refresh:new Date().getTime(), new_result:true})
	}

	// 		--------------------------------		--------------------------------		---------
	onRemoveFile = async (index) => {
		const files = [...this.state.upl_files]
		files.splice(index,1)
		this.setState({upl_files:files})
		await this.attach_results(files)
		this.setState({refresh:new Date().getTime(), new_result:true})
	}
	
	onInputChange = (e, { name, value }) => this.setState({ [name]: value })

	onResultsDevUpload = async (scr_obj) => {
		var res = {res: 'err', msg: 'Something went wrong'}
		this.setState({loading: true})
		try {
			const resp = await patho_model.send_results_to_bucket(scr_obj.sid)
			res = { ...resp }
			this.setState({loading: false})
			
		} catch ({message}) {
			res = {res: 'err', msg: message}
			this.setState({loading: false})
		}
		app.trigger(app.events.SHOW_MESSAGE, 
			'Action Result', 
			res.res === 'ok' ? `Uploaded to Dev${res.msg ? ' - ' + res.msg : ''}` : `Failed to upload - ${res.msg}`,
			res.res === 'ok' && 'positive' || 'negative',
			`add_results_dev_upload_${uuidv4()}`
		)
	}

	
	// 		--------------------------------		--------------------------------		---------

	render() {

		const { show_drop, upl_files, showFollowUp, showFollowReq, loading, result_note, result_msg, has_followup, sup_ticket, has_notified, new_result, showPathoNotify, has_prescs } = this.state
		const { scr_obj } = this.props
		
		// const menuClick = (e, { name }) => this.setState({ activeStats: name })
		const followup_tm = has_prescs && has_prescs.cre_tm && moment(has_prescs.cre_tm)
		
		const patho_result = upl_files.find(f=>f.type=="patho")
		const patho_complete = patho_model.results_complete(patho_result?.value)
		const doc_patho = scr_obj.med_db_data.id==="patho-req"
		return <React.Fragment>
			<Files
					target="is-res-docs"
					inline
					data = {upl_files}
					onAddFile={this.onAddFile}
					onRemoveFile={this.onRemoveFile}
					onChange = { ps => this.setState({upl_files:ps}) }
					addPatho={scr_obj.script_type=="pathoreq" && !doc_patho}
					scr_obj={scr_obj}
				 >
				<div className="ui buttons" style={{marginTop:5}}>
						{(upl_files.filter(f=>f.type!="patho").length>0 || ['docconswl', 'docconswli', 'docconswlo'].includes(scr_obj.req_type)) && (!has_followup||new_result) && <Button
								content = {`Req results flup booking`}
								onClick = { _=> this.setState({showFollowReq:true}) }
								size = 'mini'
								compact
								color = 'yellow'
								disabled={upl_files.length==0}
							/>}
						{upl_files.filter(f=>f.type!="patho").length>0 && (!has_followup||new_result) && <Button
								content = {`Create results flup consult`}
								onClick = { _=> this.setState({showFollowUp:true}) }
								size = 'mini'
								compact basic
								color = 'brown'
								disabled={upl_files.length==0}
							/>}
						{patho_result && !has_notified && !['docconswl', 'docconswli', 'docconswlo'].includes(scr_obj.req_type) && <Button
								content = {`Notify Pathology Results`}
								onClick = { _=> {
									if (patho_complete || confirm('Pathology results are incomplete. Are you sure?'))
										this.setState({showPathoNotify:true})} 
								}
								size = 'mini'
								compact
								color = 'orange'
								// disabled={!patho_complete}
							/>}
				</div>
				<div className="ui buttons" style={{marginTop:5}}>
				<SendAttachments
							show_send_all={true}
							show_send_individual={true}
							show_submit_path_followup_ticket={true}
							scr_obj={scr_obj}
							hide_privy={false}
				/>
				</div>
				<div className="ui buttons" style={{marginTop:5}}>
					<NotifyPatho
						scr_obj={scr_obj}
						on_update={this?.props?.onRefreshList}
					/>
				</div>
				<div className='mt-2 w-full'>
					{scr_obj.attch?.length > 0 && 
						<Button 
							color='purple'
							className='w-full'
							loading={loading}
							onClick={async () => await this.onResultsDevUpload(scr_obj)}>
								Upload Anon Results to Dev
						</Button>
					}
				</div>
				{has_followup && <div><Icon color="green" name='check'/> Followup Submitted</div>}
				{(has_notified || scr_obj.status=='patho_res') && <div><Icon color="green" name='check'/> Patient Notified of Results</div>}
				{sup_ticket && <div><Icon color="orange" name='ticket'/> Support followup submitted ({sup_ticket.name || '?'} at {sup_ticket.tm && moment(sup_ticket.tm).format('YYYY-MM-DD HH:mm') || '?'})</div>}
				{has_prescs && <div><Icon color="green" name='check'/> Followup booking requested ({followup_tm && followup_tm.isValid() && followup_tm.format('YYYY-MM-DD HH:mm') || '?'})</div>}
			 </Files>
			 
				<Modal open={!!showFollowReq} 
 					onClose={_=>this.setState({showFollowReq:false})}
 					 closeIcon>
 					<Modal.Header>Create request results followup booking</Modal.Header>
 					<Modal.Content>
 						<Form>
 							<Form.TextArea label="Note to Doctor"
 								value={result_note} 
 								 name='result_note'
 								 onChange={this.onInputChange} 
 								 style={{minHeight:'200px'}}
 								/>

 							<Form.TextArea label="Message to Patient"
 								value={result_msg} 
 								 name='result_msg'
 								 onChange={this.onInputChange} 
 								 style={{minHeight:'200px'}}
 								/>
 							
 							<Button type="button" color="green" loading={loading} fluid onClick={_=>this.req_results_followup()}>Submit</Button>
 						</Form>
 					</Modal.Content>
 				</Modal>

				<Modal open={!!showFollowUp} 
 					onClose={_=>this.setState({showFollowUp:false})}
 					 closeIcon>
 					<Modal.Header>Create results followup</Modal.Header>
 					<Modal.Content>
 						<Form>
 							<Form.TextArea label="Note"
 								value={result_note} 
 								 name='result_note'
 								 onChange={this.onInputChange} 
 								 style={{minHeight:'200px'}}
 								/>
 							
 							<Button type="button" color="green" loading={loading} fluid onClick={_=>this.cre_results_followup()}>Submit</Button>
 						</Form>
 					</Modal.Content>
 				</Modal>

				<Modal open={!!showPathoNotify} 
					onClose={_=>this.setState({showPathoNotify:false})}
					 closeIcon>
					<Modal.Header>Send Pathology Results</Modal.Header>
					<Modal.Content>
						<Form>
							<p>Patient will notified and allowed to view the Pathology results.</p>
							<Button type="button" color="green" loading={loading} fluid onClick={_=>this.notify_patho()}>Submit</Button>
						</Form>
					</Modal.Content>
				</Modal>

			</React.Fragment>

		
	}

}
