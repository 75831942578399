import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import { useNotifications } from './useNotifications';
import getNotificationIcon from '../view/getNotificationIcon';

export const useNotificationToasts = () => {
    const {
        notifications,
        loading,
        setNotificationToastRead,
    } = useNotifications();
    const processedNotifications = useRef(new Set());
    useEffect(() => {
        if (!app.site_status?.notifications?.enable_toasts?.includes?.(app.runtime?.app)) {
            return;
        }
        if (loading || !notifications) return;

        const tenMinutesAgo = new Date(Date.now() - 1000 * 60 * 10);

        const newNotifications = notifications.filter(notification => (
            !processedNotifications.current.has(notification.notification_id)
            && isToastNotification(notification, tenMinutesAgo)
        ));

        newNotifications.forEach(notification => {
            toast(
                (
                    <ToastContent
                        notification={notification}
                        setNotificationToastRead={setNotificationToastRead}
                    />
                ),
                {
                    containerId: 'default',
                    position: 'bottom-center',
                    hideProgressBar: true,
                    draggable: false,
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: false,
                    onClick: () => undefined,
                    className: 'cursor-default !min-h-auto !p-0 [&>*]:!min-h-auto [&>*]:!p-0',
                });
            processedNotifications.current.add(notification.notification_id);
            setNotificationToastRead(notification.notification_id, true);
        });
    }, [notifications, loading, setNotificationToastRead]);
};

const ToastContent = ({ notification, setNotificationToastRead }) => {
    const icon = getNotificationIcon(notification);
    const navigate = useNavigate();
    const handleClick = () => {
        if (notification.link || notification.banner_link) {
            navigate(notification.link || notification.banner_link);
        }
        setNotificationToastRead(notification.notification_id, true);
    };

    if (notification.link || notification.banner_link) {
        return (
            <button
                className="
                    w-full flex items-start gap-2 text-left px-4
                    hover:bg-gray-100
                "
                onClick={handleClick}
            >
                <Icon name={icon.name} color={icon.color} />
                <div className="flex-grow">
                    <div className="font-bold">{notification.title}</div>
                    {notification.overrides?.toast_body ? <div>{notification.body}</div> : null}
                </div>
                {(notification.link || notification.banner_link) && (
                    <Icon name="chevron right" className="self-center" />
                )}
            </button>
        );
    }
    return (
        <div
            className="w-full flex items-start gap-2 text-left px-4"
        >
            <Icon name={icon.name} color={icon.color} />
            <div className="flex-grow">
                <div className="font-bold">{notification.title}</div>
                {notification.overrides?.toast_body ? <div>{notification.body}</div> : null}
            </div>
        </div>
    );
};

function isToastNotification(notification, timeFrame) {
    const notificationDate = notification.created?.toDate?.() || notification.created;
    return (
        !notification.read
        && !notification.toastRead
        && (
            (['high', 'urgent'].includes(notification.priority) && notification.overrides?.toast !== false)
            || notification.overrides?.toast === true
        )
        && notificationDate
        && notificationDate > timeFrame
    )
}