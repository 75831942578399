import React, { useState, useEffect } from 'react';
import {Search, Grid, Header, Segment, Form, Loader, Icon, Popup, Dropdown } from 'semantic-ui-react'
import med_model from 'models/med_model'
import obj_diff from 'xAppLib/helpers/obj_diff'
import { cls as clsn } from '../../views/NUI/utils';

const MedSelect = React.memo(({ onChange, value, name, fl, fluid = true, cat_filter, multiple = true, clearable = true, placeholder = 'Med', disc_allow_meds, read_only = false, cls='' }) => {
	const [options,setOptions]=useState([])

	useEffect(() => {
		if (cat_filter) {
			const tmp = Object.keys(cat_filter)
					.sort( (a, b) => cat_filter[a]?.par_nm?.localeCompare(cat_filter[b].par_nm) )  
					.filter(k=>cat_filter[k].t=='m')
					.map( (k, i) => ({ key: 'md'+k+i, value: k, text: `${cat_filter[k].par_nm} >> ${cat_filter[k].active==false && '!' || ''} ${cat_filter[k].n || ''}` })) || [] 
            setOptions(tmp)
        } else {
            const getData = async () => {
                const all_meds = await med_model.get_record( '/', null, {refresh:true} )
                let keys = all_meds && Object.keys(all_meds)
                // if (cat_filter) 
                // 	keys = keys.filter( k=> cat_filter.hasOwnProperty(k))
                if (disc_allow_meds?.length) 
                    keys = keys.filter( k=> disc_allow_meds.includes(k))
                const tmp = keys
                    .sort( (a, b) => all_meds[a]?.name?.localeCompare(all_meds[b].name) )
                    .map( (k, i) => ({ key: 'md'+k+i, value: k, text: `${all_meds[k].name} ${all_meds[k].size || ''} ${all_meds[k].qnty && `(${all_meds[k].qnty})` || ''}` })) || []
                setOptions(tmp)
            }
            getData();
		}

	},[cat_filter, disc_allow_meds])
	
	// console.log('MedSelect', value);
	return read_only ? <>{options?.filter(d => d.value === value)?.[0]?.text}</> : <Dropdown
	    placeholder={placeholder}
	    fluid={fluid}
	    multiple={multiple}
	    search
	    selection
		clearable={clearable}
	    options={options}
		value={multiple&&!value ? [] : value}
		onChange={(e, { value }) => onChange({target:{name:name || fl && fl.name, value}})}
		className={clsn(clearable && "!pr-10", cls)}
	  />
},(prevProps, nextProps) => {
	const diff = obj_diff(prevProps.cat_filter,nextProps.cat_filter)
	return prevProps.value===nextProps.value && Object.keys(diff.to).length == 0
});

export default MedSelect
