import React from "react";
import { Link } from "react-router-dom";
import { Container, Icon } from "semantic-ui-react";

const menu = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "For Patients",
    link: "/forpatients",
  },
  {
    name: "For Lawyers",
    link: "/forlawyers",
  },
  {
    name: "Privacy",
    link: "#",
  },
  {
    name: "Terms & Conditions",
    link: "#",
  },
  {
    name: "Request A Demo ",
    link: "/request-demo",
  },
];

const Footer = () => {

  // console.log(app.events)

  return (
    <div className="text-white bg-footer-blue py-8">
      <Container>
      <div className="grid md:grid-cols-2 grid-cols-1 grid-rows-1 auto-rows-max text-center md:text-left">
        <div className="">
          <ul className="space-y-2">
            {menu.map(({ name, link }, i) => {
              if (name === 'Privacy') return (
                <li key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Privacy', pn:'privacy-policy'})}>
                  <Link to={link}>{name}</Link>
                </li>);
              if (name === 'Terms & Conditions') return (
                <li key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Terms and Conditions', pn:'terms-and-conditions', target:"ileg"})}>
                <Link to={link}>{name}</Link>
              </li>)

              return (
                <li key={name + i}>
                <Link to={link}>{name}</Link>
              </li>)
            }
            )}
          </ul>
        </div>
        <div className='flex md:justify-end justify-center pt-8 md:pt-0'>
          <div className="w-48 px-4">
            <img src={app.runtime.sidemenuLogo}/>
            <p className="mb-4 mt-4 text-center text-sm text-white px-4 py-1 ">
              Powered by
            </p>
            <img
              src="/images/logos/instant-scripts-logo-IS2-white.svg"
              alt="InstantScripts"
              className="h-14 m-auto"
            />
          </div>
        </div>
        <div className="pt-8">
          <div>
            © {new Date().getFullYear()} {app.runtime.name}®
          </div>
          <div className="pt-4 text-sm">
            {(app.dvc.org && <b>{app.dvc.org.name}</b>) || null}
            {(app.runtime.org && <i>{app.runtime.org.name}</i>) || null}
            Build: {__GIT_HASH__} - {__BUILD__}
            {(app.dev_env && " - dev") || (app.test_env && " - test") || ""}
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
            <Icon
              compact="true"
              color="green"
              name="refresh"
              size="small"
              circular
              onClick={(_) => location.reload(true)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
      </Container>
    </div>
  );
};

export default Footer;
