// Context for Ecommerce cart

import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {
    ECOMM_CART_LOC,
    save_cart,
    add_item_to_cart,
    update_item_in_cart,
    remove_item_from_cart,
    add_fee_or_charge_to_cart,
    clear_fees_and_charges,
    remove_fee_or_charge_from_cart,
    update_fee_or_charge_in_cart,
    clear_products,
    clear_except_medications
} from "@/views/ecommerce/EcommUtils";
import { useProfile } from "../Hooks";
import firebase_database from "../providers/firebase_database";
import {useCurrent} from "../Hooks/useCurrent";
import {useDisclosure} from "@/xAppLib/Hooks/useDisclosure";
import StyledDrawer from "@/views/NUI/StyledDrawer";

const EcommerceDataContext = React.createContext(undefined);

const EcommerceDataProvider = ({ children }) => {
    const [profile] = useProfile();
    const unsubscribe = useRef(null);

    const [cart, setCart] = React.useState(null);
    const [addingMedication, setAddingMedication] = React.useState(false);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [drawerConfig, setDrawerConfig] = React.useState({});

    const uid = useMemo(() => profile?.uid, [profile?.uid]);

    useEffect(() => {
        if(!uid){
            setCart(null);
            unsubscribe.current?.();
            unsubscribe.current = null;
        } else {
            unsubscribe.current = firebase_database.watch_record(ECOMM_CART_LOC(uid), setCart);
        }
    }, [uid]);

    const cartRef = useCurrent(cart);

    const add = useCallback((item) => save_cart(cartRef.current, add_item_to_cart(cartRef.current, item)), [])
    const update = useCallback((item) => save_cart(cartRef.current, update_item_in_cart(cartRef.current, item)), [])
    const remove = useCallback((item) => save_cart(cartRef.current, remove_item_from_cart(cartRef.current, item)), [])
    const add_foc = useCallback((foc) => save_cart(cartRef.current, add_fee_or_charge_to_cart(cartRef.current, foc)), [])
    const update_foc = useCallback((foc, iding_func) => save_cart(cartRef.current, update_fee_or_charge_in_cart(cartRef.current, foc, iding_func)), [])
    const remove_foc = useCallback((foc, iding_func) => save_cart(cartRef.current, remove_fee_or_charge_from_cart(cartRef.current, foc, iding_func)), [])
    const clear_focs = useCallback(() => save_cart(cartRef.current, clear_fees_and_charges(cartRef.current)), [])
    const clear_ex_meds = useCallback(() => save_cart(cartRef.current, clear_except_medications(cartRef.current)), [])
    const clear_prods = useCallback(() => save_cart(cartRef.current, clear_products(cartRef.current)), [])

    const switch_on_adding = useCallback(() => setAddingMedication(true), []);
    const switch_off_adding = useCallback(() => setAddingMedication(false), []);

    const open_drawer = useCallback(($drawerConfig) => {
        setDrawerConfig($drawerConfig);
        onOpen();
    }, [])

    const close_drawer = useCallback(() => onClose(), [])

    const value = useMemo(() => ({
        cart,
        add_item: add,
        update_item: update,
        remove_item: remove,
        add_foc,
        update_foc,
        remove_foc,
        clear_focs,
        clear_prods,
        clear_ex_meds,

        open_drawer,
        close_drawer,

        is_adding_medication: addingMedication,
        switch_on_adding,
        switch_off_adding
    }), [cart, addingMedication]);

    return <EcommerceDataContext.Provider value={value}>
        <>
            {children}
            <StyledDrawer {...{
                isOpen,
                onOpen,
                onClose,
                ...drawerConfig
            }} />
        </>
    </EcommerceDataContext.Provider>;
};

function EcommerceDataConsumer({ children }) {
    return (
        <EcommerceDataContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error(
                        "EcommerceDataConsumer must be used within a EcommerceDataProvider"
                    );
                }
                return children(context);
            }}
        </EcommerceDataContext.Consumer>
    );
}

export { EcommerceDataContext, EcommerceDataProvider, EcommerceDataConsumer };
