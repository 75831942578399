import React, { useState } from 'react';
import moment from "moment";
import { cls } from "@/views/NUI/utils";
import { defer } from "@/xAppLib/helpers/defer";
import { Button, Card, CardGroup, Icon, Image, Modal } from "semantic-ui-react";
import { useAsyncUpdate } from "xAppLib/Hooks/useAsync";
import API_service from "xAppLib/providers/API_service";
import SimpleShowMore from "xAppLib/UIelems/SimpleShowMore";

function iframeUrl(file) {
	return API_service.API_BASE_URL + `file/doc/view/${file.bct}/${encodeURIComponent(file.img_fn)}`;
}

/** @typedef {Object} UploadedFile
 * @property {string} res
 * @property {string} bct
 * @property {string} url
 * @property {string} img_fn
 * @property {string} tm
 * @property {string} comn
 * @property {boolean} is_attachment
 * @property {string} image_source
 */

/**
 *
 * @param {Object[]} files
 * @param {(remainingFiles: UploadedFile[], removedFiles: UploadedFile[]) => Promise<void> | void} onChange
 * @param {boolean=false} readOnly
 * @param {number=} itemsPerRow
 * @param {((file: UploadedFile)=>void)=} onFileClickHandler
 * @param {boolean=false} basic
 * @param {boolean} confirmAsOverlay
 * @param {React.JSX.Element=} extra
 * @return {React.JSX.Element|null}
 * @constructor
 */
export function FileGallery({files, onChange, readOnly, itemsPerRow = 4, onFileClickHandler, basic = false, extra, confirmAsOverlay}) {
	const [show, setShow] = useState(null);
	const closeModal = () => setShow(null);

	const [deleting, setDeleting] = useState(null);
	const deletePhoto = useAsyncUpdate(async file => {
		const promise = (async () => {
			if (confirmAsOverlay) {
				const deferred = defer();
				setDeleting({...deferred, file});
				return deferred.promise.finally(() => {
					setDeleting(null);
				});
			}
			return ('yes' === await app.confirm(`Delete ${file.comn || 'Document'}`, `Are you sure?`));
		})();

		if (await promise) {
			return await onChange(files.filter(f => f !== file), [file]);
		}
	});

	if (!files) {
		return null;
	}

	const confirmationOverlay = file => deleting?.file === file && (
		<div className="absolute inset-0 flex items-center justify-center">
			<div className="flex flex-col items-center justify-center space-y-2 rounded-md bg-white opacity-90 shadow-2xl p-2 md:px-4 md:py-6">
				<p className="mb-0 md:mb-auto">Are you sure?</p>
				<div className="space-y-1 md:space-y-0 md:space-x-4 flex flex-col md:flex-row">
					<Button type="button" secondary onClick={() => deleting.resolve(false)}>Cancel</Button>
					<Button type="button" color="red" onClick={() => deleting.resolve(true)}>Remove</Button>
				</div>
			</div>
		</div>
	);

	const preview = file => (
		<div className={cls(deleting?.file === file && "blur-md")}>
			<FilePreview file={file}/>
		</div>
	);

	if (basic) {
		return (
			<div className={`grid grid-cols-${itemsPerRow} gap-4`}>
				{files.map(file => (
					<div className="relative overflow-hidden" key={file.file_fn}>
						{preview(file)}
						{!readOnly && !file?.read_only && (
							<Button type="button"
									loading={!deleting && deletePhoto.loading}
									disabled={deleting || deletePhoto.loading}
									icon="trash alternate outline"
									basic
									className="absolute right-0 bottom-0 scale-75 origin-bottom-right"
									data-testid="button-delete-file"
									onClick={() => deletePhoto.fn(file)}/>
						)}
						{confirmationOverlay(file)}
					</div>
				))}
				{extra}
			</div>
		);
	}

	return (
		<>
			<CardGroup stackable doubling itemsPerRow={itemsPerRow}>
				{files.map(file => (
					<Card key={file.img_fn}>
						<Card.Content className="flex items-center justify-center cursor-pointer min-h-28 overflow-hidden"
									  data-testid="image-preview"
									  onClick={() => onFileClickHandler?.(file) || setShow(file)}
						>
							{preview(file)}
						</Card.Content>

						<Card.Content extra>
							<div className="flex-horizontal items-center justify-between">
								<div className="flex-vertical text-sm">
									<span><SimpleShowMore content={file.comn} cut_length={50}/></span>
									<span>{formatDate(file.tm)}</span>
								</div>
								{!readOnly && !file?.read_only && (
									<Button type="button"
											loading={deletePhoto.loading}
											icon="trash alternate outline"
											basic
											className="self-end"
											data-testid="button-delete-file"
											onClick={() => deletePhoto.fn(file)}/>
								)}
								{confirmationOverlay(file)}
							</div>
						</Card.Content>
					</Card>
				))}
				{extra}
			</CardGroup>

			<Modal open={!!show} onClose={closeModal} closeIcon>
				<Modal.Header>
					{show?.comn}
				</Modal.Header>
				<Modal.Content>
					{isAttachment(show)
						? <iframe src={iframeUrl(show)} style={{width: '100%', height: '70vh'}}/>
						: <FilePreview file={show}/>
					}
				</Modal.Content>
				<Modal.Actions>
					<Button basic content="Close" onClick={closeModal}/>
				</Modal.Actions>
			</Modal>
		</>
	);
}

function FilePreview({file}) {
	if (isAttachment(file)) {
		return <Icon name="file pdf outline" size="huge"/>;
	}

	return <Image src={file.url} className="mx-auto"/>;
}

function isAttachment(file) {
	return file?.tag === "attachment" || file?.is_attachment;
}

function formatDate(file_tm) {
	const time = /^\d{12}$/.test(file_tm)
		? moment(file_tm, "YYMMDDHHmmss")
		: moment(file_tm);

	if (!time.isValid()) {
		return null;
	}

	return time.format("D/M/YY");
}