import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Button } from "semantic-ui-react";
import AdminSendNotification from "./AdminSendNotification";

const AdminNotificationsPage = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Helmet>
                <title>Admin Notifications</title>
            </Helmet>
            <Container>
                <h1>Admin Notifications</h1>
                <Button
                    onClick={() => setIsOpen(!isOpen)}
                    icon={isOpen ? 'angle up' : 'angle down'}
                    content={`Send a Notification`}
                />
                <div className="py-4">
                    {isOpen && (
                        <AdminSendNotification />
                    )}
                </div>
            </Container>
        </>
    );
};

export default AdminNotificationsPage;