import { useContext } from 'react';
import { NotificationsContext } from '../context/NotificationsContext';

export const useNotifications = () => {
    const context = useContext(NotificationsContext);
    if (!context) {
        throw new Error('useNotifications must be used within a NotificationsProvider');
    }
    return context;
};

export const useUnreadCount = () => {
    const { unreadCount } = useContext(NotificationsContext);
    if (typeof unreadCount === 'undefined') {
        throw new Error('useUnreadCount must be used within a NotificationsProvider');
    }
    return unreadCount;
};

export const useUnreadByType = (notificationType) => {
    const { notifications } = useContext(NotificationsContext);
    return notifications.filter(notification => !notification.read && notification.type === notificationType);
};

export const useSetNotificationRead = () => {
    const { setNotificationRead } = useContext(NotificationsContext);
    if (!setNotificationRead) {
        throw new Error('useSetNotificationRead must be used within a NotificationsProvider');
    }
    return setNotificationRead;
};

export const useSetNotificationsRead = () => {
    const { setNotificationsRead } = useContext(NotificationsContext);
    if (!setNotificationsRead) {
        throw new Error('useSetNotificationsRead must be used within a NotificationsProvider');
    }
    return setNotificationsRead;
};

export const useBannerNotifications = () => {
    const { notifications, loading, setNotificationRead, usePlugin } = useContext(NotificationsContext);

    if (!notifications) {
        throw new Error('useBannerNotifications must be used within a NotificationsProvider');
    }

    const now = new Date();

    const bannerNotifications =
        notifications
            .filter(notification =>
                isBannerNotification(notification)
                && isConsideredUnread(notification)
                && !isExpired(notification)
            ).sort(sortBannerNotifications);

    return { bannerNotifications, loading, setNotificationRead, usePlugin };
};

function isBannerNotification(notification) {
    return (
        (
            (notification.priority === 'urgent' && notification?.overrides?.banner !== false) || notification?.overrides?.banner === true
        )
    );
}

function isExpired(notification) {
    return (
        notification.banner_expiry && notification.banner_expiry.toDate() < new Date()
    );
}

function isConsideredUnread(notification) {
    return (
        !notification.read || notification.overrides?.persistent
    );
}

function sortBannerNotifications(a, b) {
    return (b.overrides?.persistent ? 1 : 0) - (a.overrides?.persistent ? 1 : 0);
}