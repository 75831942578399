import React from 'react';
import { Form } from 'semantic-ui-react';

export function TextField({helper, ...props}){

	return (
        <div>
            <Form.Input {...props} />
           
            <p className="helper text-xs text-is2-gray-150 !-mt-2">{helper}</p>
        </div>
	);
}

TextField.displayName = 'TextField';
