import React, {useCallback, useEffect, useMemo, useState} from "react";
import Search from "../search/Search.jsx";
import cat_model from "../../../../models/cat_model.js";
import getNestedObject from "../../../../xAppLib/helpers/getNestedObject.js";
import user_model from "../../../../models/user_model.js";
import { excludedCats } from "../../../../xAppLib/Cat/constants.js";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

export const conditions = {
    common: [
        {
            n: 'Cold, flu and COVID-19',
            k: '-N9YuK8ZZsDznRtdISNF',
            footerOrder : 3
        },
        {
            n: 'Mental Health',
            name: 'Mental health',
            k: '-LMrOQSVQMBVgUqe3y2A',
            footerOrder : 2
        },
        {
            n: 'Urinary Tract Infections',
            name: 'Urinary tract infection',
            k: 'uti',
            footerOrder : 1
        },
        {
            n: 'Erectile Dysfunction',
            name: 'Erectile dysfunction',
            k: 'erectile-dysfunction',
            footerOrder : 6
        },
        {
            n: 'Premature Ejaculation',
            name: 'Premature ejaculation',
            k: 'premature-ejaculation',
            footerOrder : 6
        },
        {
            n: 'Asthma',
            k: '-LJVyR5Pdls_ydzW8sRk',
            footerOrder : 4
        },
        {
            n: 'Weight Loss',
            name: 'Weight loss',
            k: '-N1fTGgW2ONhP0Zurrnn',
        },
        {
            n: 'Nausea & Morning Sickness',
            name: 'Nausea or vomiting',
            k: 'nausea',
            footerOrder : 5
        },
        {
            n: 'Migraine And Headaches',
            name: 'Migraines and headaches',
            k: '-LMfGlpF3oP3xgL52d7w',
        },
    ],

};

const ConditionsMenu = ({ onMenuChange, showMenu }) => {
    const [data, setData] = useState({
        common: null,
        other: null,
        allConditions: null,
        conditionsByKey: {},
        loading: true
    })
    const [searchResult, setSearchResult] = useState([]);
    const [searching, setSearching] = useState(false);

    const fetchData = useCallback(async () => {
        const { cat_tree_list } = await cat_model.get_cat_list({ cat_nm: 'pharm' }, true);

        const catData = getNestedObject(cat_tree_list, '');
        const mappedData = Object.keys(catData).filter(k => user_model.check_access('show_medcat', { k, cat_nm: 'pharm' }) && ![...excludedCats, 'mens', 'womens'].includes(k))
            .map(k => ({ k, ...catData[k] }));
        const cmn = conditions.common.filter((n) => !(["COVID-19"]).includes(n.n));

        const [commonConditions, otherConditions] = mappedData.reduce((res, el) => {
            const id = cmn.findIndex(f => f.n === el.n);
            res[id >= 0 ? 0 : 1].push({ ...el, img: cmn[id]?.img, o: cmn[id]?.order });
            return res;
        }, [[], []]);

        const allConditions = [...[...commonConditions, ...cmn].sort((a, b) => a.n - b.n), ...otherConditions];

        const uniqueConditions = Object.values(
            allConditions.reduce((conditionMap, condition) => {
                const existingCondition = conditionMap[condition.n];

                if (!existingCondition || (!existingCondition.active && condition.active)) {
                    conditionMap[condition.n] = condition;
                }

                return conditionMap;
            }, {})
        );

        const conditionsByKey = {};
        uniqueConditions.forEach((condition) => {
            const key = condition.n.charAt(0).toUpperCase();
            if (!conditionsByKey[key]) {
                conditionsByKey[key] = [];
            }
            conditionsByKey[key].push(condition);
        });

        setData({
            ...data,
            common: commonConditions.sort((a, b) => a.o - b.o),
            other: otherConditions,
            conditionsByKey,
            uniqueConditions,
            loading: false
        })
    }, [])

    const handleOnSearch = useCallback((value) => {
        setSearching(true);

        if (!value.length) {
            setSearching(false);
            setSearchResult([]);
            return;
        }

        const matchedConditions = data?.uniqueConditions?.filter((c) => c.n.toLowerCase().includes(value.toLowerCase())) || [];

        setSearchResult(matchedConditions);
    }, [data]);

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        if (!showMenu) {
            setSearchResult([]);
            setSearching(false);
        }
    }, [showMenu]);

    const alphabeticalConditions = useMemo(() =>
        Object.entries(data.conditionsByKey || {})
            .sort((a, b) => a[0].localeCompare(b[0]))
        , [data.conditionsByKey]);

    return (
        <div className="conditions-menu space-y-8 pt-10">
            <section>
                {showMenu &&
                    <Search
                        variant="secondary"
                        onSearch={handleOnSearch}
                        onClear={() => setSearchResult([])}
                        placeholder="Search for condition"
                        icon="https://storage.googleapis.com/instant-med-public/v2/search.svg"
                    />
                }

            </section>

            {/* search results */}
            <section>
                {searchResult.length ? (
                    <>
                        <div>
                            <ul className="flex flex-col gap-2 space-y-2 border-t border-t-is2-blue-50">
                                {searchResult.map((condition, index) => (
                                    <li key={index} className="text-is2_md text-is2-gray-800 hover:text-is2-gray-600 cursor-pointer border-b border-b-is2-blue-50 !py-3 !mt-0">
                                        <Link
                                            to={`${app.consts.routes.cat_route}/pharm/${condition.k}`}
                                            onClick={_ => {
                                                onMenuChange(false);
                                            }}
                                        >
                                            {condition.n}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                ) : null}

                {searching ? (
                    <div className="flex flex-col bg-is2-blue-50 items-center justify-center py-5 mt-6 rounded-md">
                        <p className="font-semibold">Can't find your condition?</p>
                        <Button
                            href={`${app.consts.routes.cat_route}/pharm/DocCons`}
                            secondary
                            onClick={() => { onMenuChange(false); }}
                        >
                            Speak to a doctor - ${app.site_status?.prs_mc['dc_std']}
                        </Button>
                    </div>
                ) : null}
            </section>

            {!searchResult.length &&
                <>
                    <section className="common-conditions">
                        <h3 className="text-lg font-semibold text-is2-gray-700 mb-4">Common conditions</h3>
                        <div className="flex flex-col space-y-2 border-t border-t-is2-blue-50">
                            {conditions.common.map((condition, index) => (
                                <Link
                                    to={`${app.consts.routes.cat_route}/pharm/${condition.k}`}
                                    onClick={e => { onMenuChange(false); }}
                                    key={index}
                                    className="text-is2_md text-is2-gray-800 hover:text-is2-gray-600 cursor-pointer border-b border-b-is2-blue-50 !py-3 !mt-0"
                                >
                                    {condition.n}
                                </Link>
                            ))}
                        </div>
                    </section>

                    <section className="alphabetical-conditions">
                        <h3 className="text-lg font-semibold text-gray-800 mb-4 text-center">A-Z</h3>
                        <div className="space-y-6">
                            {alphabeticalConditions.map(([letter, conditions]) => (
                                <div key={letter} className="condition-group">
                                    <h4 className="text-base font-bold text-is2-gray-700 mb-2 pl-2">{letter}</h4>
                                    <div className="flex flex-col space-y-1 border-t border-t-is2-blue-50">
                                        {conditions.map((condition, index) => (
                                            <Link
                                                to={`${app.consts.routes.cat_route}/pharm/${condition.k}`}
                                                onClick={() => { onMenuChange(false); }}
                                                key={index}
                                                className="text-is2_md text-is2-gray-800 hover:text-is2-gray-600 cursor-pointer border-b border-b-is2-blue-50 !py-3 !mt-0"
                                            >
                                                {condition.n}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                </>
            }
        </div>
    );
};

export default ConditionsMenu;