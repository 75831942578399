import { useCallback, useEffect } from 'react';
import { useNotifications } from '../../hooks/useNotifications';
import { v4 as generate_id } from 'uuid';

// Define message types that should always use the old system
const ALWAYS_USE_OLD_SYSTEM = [
  'user_auth_msg',
];

export const shouldShowInNewSystem = (messageKey) => {
  if (!messageKey) return false;

  if (!app.site_status?.notifications?.enable_banners?.includes?.(app.runtime?.app)) {
    return false;
  }
  
  if (ALWAYS_USE_OLD_SYSTEM.includes(messageKey)) {
    return false;
  }
  
  return true;
};

const LegacyMessageAdapter = () => {
  const { setDynamicNotification, removeDynamicNotification } = useNotifications();
  
  const isShouldShowInNewSystem = useCallback(
    shouldShowInNewSystem,
    [app.site_status?.notifications?.enable_banners, app.runtime?.app]
  );
  
  const handleShowMessage = useCallback((h, m, l, key, notificationOpts) => {
    if (typeof h === 'object') {
      l = h.type;
      m = h.content;
      key = h.key;
      h = h.header;
    }
    
    // Check if this message should be shown in the new system
    if (!isShouldShowInNewSystem(key)) {
      return;
    }
    
    const notificationKey = key;
    
    // Map legacy types to notification variants
    const variantMap = {
      'positive': 'positive',
      'negative': 'negative',
      'info': 'info',
      'warning': 'warning'
    };

    const notificationType = notificationOpts?.type || 'legacy-message';
    const notificationBody = notificationOpts?.body || m;
    
    // Create notification object for the new system
    setDynamicNotification(notificationKey, {
      title: h ? decodeURIComponent(h.replace(/\+/g, ' ')) : '',
      body: notificationBody,
      type: notificationType,
      created: new Date(),
      overrides: {
        banner: true,
        banner_variant: variantMap[l] || 'info',
        toast: !!app?.acl?.is_work,
        toast_body: !!app?.acl?.is_work,
        ...(notificationOpts?.overrides || {}),
      }
    });
  }, [isShouldShowInNewSystem, app?.acl?.is_work]);
  
  const handleClearMessage = useCallback((key) => {
    if (!key) return;
    if (!isShouldShowInNewSystem(key)) {
      return;
    }
    removeDynamicNotification(key);
  }, [isShouldShowInNewSystem]);
  
  useEffect(() => {
    if (app.site_status?.notifications?.enable_banners?.includes?.(app.runtime?.app)) {
      app.on(app.events.SHOW_MESSAGE, handleShowMessage);
      app.on(app.events.CLEAR_MESSAGE, handleClearMessage);
      
      return () => {
        app.off(app.events.SHOW_MESSAGE, handleShowMessage);
        app.off(app.events.CLEAR_MESSAGE, handleClearMessage);
      };
    }
  }, [app.site_status?.notifications?.enable_banners, app.runtime?.app]);
  
  // This component doesn't render anything
  return null;
};

export default LegacyMessageAdapter; 