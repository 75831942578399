import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { useNotifications } from '../../notifications/hooks/useNotifications';
import { useProfile } from 'xAppLib/Hooks';
import instcons_global_model from '../../models/instcons_global_model';
import { from_melb_ui_tm } from '../../helpers/datetime';

const NOTIFICATION_KEY = 'could_not_call_consult';

const createNotification = (rec) => ({
    type: NOTIFICATION_KEY,
    title: 'Consultation Booking',
    body: "We're sorry we haven't been able to get in touch with you. Please choose from the options below to let us know if you'd still like one of our doctors to call you.",
    icon: 'phone',
    link: `/docconsreq/${rec.sid}/${rec.spd_hash__mobile || '_'}`,
    overrides: {
        banner: true,
        banner_variant: 'info',
    },
    extra_fields: { rec },
});

export const useCouldNotCallConsults = () => {
    const { pathname } = useLocation();
    const { setDynamicNotification, removeDynamicNotification } = useNotifications();
    const [prof] = useProfile();

    useEffect(() => {
        if (!prof?.hist) return;

        const couldNotCallConsults = prof.hist.filter(rec => 
            rec.status === 'could_not_call' && 
            from_melb_ui_tm(rec.add_tm).isAfter(moment().subtract(1, 'month'))
        );

        if (pathname.startsWith('/docconsreq')) {
            couldNotCallConsults.forEach(rec => 
                removeDynamicNotification(`${NOTIFICATION_KEY}_${rec.sid}`)
            );
            return;
        }

        const cleanups = couldNotCallConsults.map(rec => {
            const notificationKey = `${NOTIFICATION_KEY}_${rec.sid}`;
            
            const cleanup = instcons_global_model.watch_record(rec.sid, data => {
                if (data?.status !== 'could_not_call') {
                    removeDynamicNotification(notificationKey);
                    return;
                }
                setDynamicNotification(notificationKey, createNotification(rec));
            });

            setDynamicNotification(notificationKey, createNotification(rec));
            return cleanup;
        });

        return () => {
            cleanups.forEach(cleanup => cleanup?.());
            couldNotCallConsults.forEach(rec => 
                removeDynamicNotification(`${NOTIFICATION_KEY}_${rec.sid}`)
            );
        };
    }, [pathname, prof?.hist, setDynamicNotification, removeDynamicNotification]);
};