import React, { useMemo } from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const BUTTON_TYPES = {
  ROUNDED: "rounded",
  WHITE: "white-button",
};

const VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};
export const CallToAction = ({
  type,
  title,
  url,
  priceTag,
  variant = "primary",
}) => {
  const ctaStyling = useMemo(() => {
    switch (type) {
      case BUTTON_TYPES.ROUNDED:
        return ` rounded bordered`;
      case BUTTON_TYPES.WHITE:
        return "!bg-white !text-black";
      default:
        return "";
    }
  }, [type]);

  return (
    <Button
      className={ctaStyling}
      primary={variant === VARIANTS.PRIMARY}
      secondary={variant === VARIANTS.SECONDARY}
    >
      <Link to={url}>
        <div
          className={`flex ${priceTag ? "justify-between" : "justify-center"}`}
        >
          <span>{title}</span>
          {priceTag && <span>${priceTag}</span>}
        </div>
      </Link>
    </Button>
  );
};
