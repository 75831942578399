import React, { useCallback, useContext, useState, useEffect } from 'react'
import { Button, Message, Container } from 'semantic-ui-react';
import { Link, createSearchParams } from 'react-router-dom';
import { useUserPrefs, useProfile, useUser } from 'xAppLib/Hooks';
import cat_model from 'models/cat_model'

const PREF_HIDE = 'presc_hide'

const PrescItem = ({children, header, onHide}) => {
	return <Message
				header={header}
				content={children}
				info
				onDismiss={onHide}

			/>;
}
 

const Prescs = ({canHide = true}) => {

	const user = useUser()
	const [prof] = useProfile()
	const [prefs,setUserPref] = useUserPrefs()
	const [loaded,setLoaded] = useState()

	const prescs = prof?.pts_prescs?.filter( p => true || ["req_doccons_flup", "req_doccons_res","pre_prep",'presc_icann','req_icanncons_flup'].includes(p.type) )
									.filter( p => !canHide || !prefs[PREF_HIDE+p.prid]);

	const cat_nm = app.runtime.mode!='iscr'&&app.runtime.mode||'pharm';

	// useEffect( _=> !!prescs?.length && cat_model.get_cat_list({all:true, cat_nm:cat_nm, mb_list:cat_nm}, false).then( _=> setLoaded(true) ), 
	// 		[!!prescs?.length]);

	const hide = useCallback( prid =>{
		setUserPref(PREF_HIDE+prid,true,false)
	});

	if (!prescs?.length)
		return null;

	return <>
				{prescs.length > 1 && app.settings.is_cmg && <h3>Please confirm each medication order and delivery address individually</h3>}
				{ prescs
					.map( p => (p.type=="req_doccons_flup" || p.type=='req_icanncons_flup') && 
							<PrescItem
								key={p.prid}
								header='Your doctor has asked that you request a follow up consultation.'
								onHide={canHide && (_=>hide(p.prid)) || undefined}>
								<div className="mt-2">
									{p.dets.msg && <p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>}
									<p>If you would like a follow up consultation please click here and the doctor will contact you in the next 24 hours.</p>
									<Button size={'small'} as={Link}
										content='Book now' primary
										to={{
											pathname: '/m/pharm/' + p.med.mid,
											// search: "?sort=name",
											// hash: "#the-hash",
											state: { presc: true, prid: p.prid }
										}}
										onClick={canHide && (_ => hide(p.prid)) || undefined}
									/>
									{/* <pre>{JSON.stringify(p, null, 4)}</pre> */}
								</div>
							</PrescItem>
						|| p.type=="req_doccons_res" && 
							<PrescItem
								key={p.prid}
								header={!p.dets?.optional ? 'Your doctor has received your test results and would like to have a chat.' : 'Your doctor has received your test results'}
								onHide={canHide && (_=>hide(p.prid)) || undefined}>
								<div className="mt-4">
									<p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>
									<p>{!p.dets?.optional ? 'Please request now and our doctors will contact you within the next 24 hours.' : 'If you would still like to discuss your results, you can request a follow up appointment using the button below.'}</p>
									<div className='flex justify-between'>
										<Button size={'small'} as={Link} 
												content='Book now'  primary 
												to={{
													pathname: '/m/pharm/'+p.med.mid,
													// search: "?sort=name",
													// hash: "#the-hash",
													state: { presc: true, prid: p.prid, pts_uid : p.uid }
												}}
												onClick={canHide && (_=>hide(p.prid)) || undefined}
										/>
										{!p.dets.optional && p.ref?.apmd === 'iscr' && <Button size={'small'} as={Link} 
												content='Cancel' color='red' 
												to={{
													pathname: `/patient/cancel_followup_presc`,
													search: createSearchParams({
														prid: p.prid
													}).toString()
												}}
												onClick={canHide && (_=>hide(p.prid)) || undefined}
										/>}
									</div>
									{/* <pre>{JSON.stringify(p, null, 4)}</pre> */}
								</div>
								</PrescItem>
								
						|| p.type=="pre_prep" && 
							<PrescItem
								key={p.prid}
								header='PrEP Prescription – Based on your test results you can now request a script for PrEP'
								onHide={canHide && (_=>hide(p.prid)) || undefined}>
								<div className="mt-2">
									{p.dets?.msg && <p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>}
									{/*<p>Request script now.</p>*/}
									<Button size={'large'} as={Link} 
											content='Request script now'  primary 
											to={{
												pathname: '/m/pharm/'+p.med.mid,
												// search: "?sort=name",
												// hash: "#the-hash",
												state: { presc: true, prid: p.prid ,  pts_uid : p.uid}
											}}
											onClick={canHide && (_=>hide(p.prid)) || undefined}
									/>
									{/* <pre>{JSON.stringify(p, null, 4)}</pre> */}
								</div>
							</PrescItem>
						|| p.type=="presc_icann" && 
							<PrescItem
								key={p.prid}
								header="It's time to order your medication – Your treatment has been approved, please click the button below to order your medication delivery"
								onHide={canHide && (_=>hide(p.prid)) || undefined}>
								<div className="mt-2">
									{p.dets?.msg && <><p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>
										<div>
											<p className="font-semibold">{app.app_data.ws_data?.cat_data?.[cat_nm]?.cat_avail_list?.[p.med?.mid]?.n}</p>
											<i>{app.app_data.ws_data?.cat_data?.[cat_nm]?.cat_avail_list?.[p.med?.mid]?.d}</i>
										</div>
										{/*<div style={{margin:'1em 0'}}>{p.dets.note}</div>*/}
									</> || null}
									{/*<p>Request script now.</p>*/}
									<Button size={'large'} as={Link} 
											content='Order Now'  primary
											to={{
												pathname: '/m/pharm/'+p.med.mid,
												// search: "?sort=name",
												// hash: "#the-hash",
												state: { presc: true, prid: p.prid ,  pts_uid : p.uid}
											}}
											onClick={canHide && (_=>hide(p.prid)) || undefined}
									/>
									{/*<pre>{JSON.stringify(p, null, 4)}</pre>*/}
								</div>
							</PrescItem>

						|| p.type=="req_iwlcons_flup" && 
							<PrescItem
								key={p.prid}
								header="Your Weight Loss program - we got your blood test results, please request an appointment with your doctor"
								onHide={canHide && (_=>hide(p.prid)) || undefined}>
								<div className="mt-2">
									{p.dets?.msg && <>
										<p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>
										{/*<div style={{margin:'1em 0'}}>{p.dets.note}</div>*/}
										 </> || null}
									{/*<p>Request script now.</p>*/}
									<Button size={'large'} as={Link} 
											content='Order Now'  primary
											to={{
												pathname: '/m/pharm/'+p.med.mid,
												// search: "?sort=name",
												// hash: "#the-hash",
												state: { presc: true, prid: p.prid ,  pts_uid : p.uid}
											}}
											onClick={canHide && (_=>hide(p.prid)) || undefined}
									/>
									{/* <pre>{JSON.stringify(p, null, 4)}</pre> */}
								</div>
							</PrescItem>


						|| p.type.startsWith("presc_medsub") &&
							<PrescItem
								key={p.prid}
								header="Your Treatment Plan - please review and approve"
								onHide={canHide && (_=>hide(p.prid)) || undefined}>
								<div className="mt-2">
									{p.dets?.msg && <>
										<p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>
										{/*<div style={{margin:'1em 0'}}>{p.dets.note}</div>*/}
										 </> || null}
									{/*<p>Request script now.</p>*/}
									<Button size={'large'} as={Link}
											content='Order Now'  primary
											to={{
												pathname: '/m/pharm/'+p.med.mid,
												state: { presc: true, prid: p.prid ,  pts_uid : p.uid}
											}}
											onClick={canHide && (_=>hide(p.prid)) || undefined}
									/>
									{/* <pre>{JSON.stringify(p, null, 4)}</pre> */}
								</div>
							</PrescItem>

						|| <PrescItem
								key={p.prid}
								header="It's time to order your medication – Your treatment has been approved, please click the button below to order your medication"
								onHide={canHide && (_=>hide(p.prid)) || undefined}>
							    <div className="mt-2">
									{p.dets?.msg && <>
										<p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>
										<div>
											<p className="font-semibold">{app.app_data.ws_data?.cat_data?.[cat_nm]?.cat_avail_list?.[p.med?.mid]?.n}</p>
											<i>{app.app_data.ws_data?.cat_data?.[cat_nm]?.cat_avail_list?.[p.med?.mid]?.d}</i>
										</div>
										{/*<div style={{margin:'1em 0'}}>{p.dets.note}</div>*/}
									 </> || null}
									{/*<p>Request script now.</p>*/}
									<Button size={'large'} as={Link} 
											content='Order Now'  primary
											to={{
												pathname: '/m/pharm/'+p.med.mid,
												// search: "?sort=name",
												// hash: "#the-hash",
												state: { presc: true, prid: p.prid ,  pts_uid : p.uid}
											}}
											onClick={canHide && (_=>hide(p.prid)) || undefined}
									/>
									{/* <pre>{JSON.stringify(p, null, 4)}</pre> */}
								</div>
							</PrescItem>
						)
			}
		</>
}
 
export default Prescs;