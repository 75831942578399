import React from "react";
import { StepsTabsCard } from "./StepsTabsCard";

export function SpeakToADoctorCopy() {
    return (
        <StepsTabsCard
            steps={[
                "Let our doctors know why you’re requesting the call, whether you would like a male or female doctor and upload any photos you would like them to see.",
                "A doctor will call you by phone. You will be able to see our wait times before payment.",
                "The doctor will provide any necessary prescriptions, referrals, or medical certificates if deemed clinically appropriate."
            ]}
            buttonText="Speak to a Doctor"
            buttonHref="/c/pharm/DocCons"
        />
    );
}

export function PrescriptionsCopy() {
    return (
        <StepsTabsCard
            steps={[
                "Find your medication and place your request.",
                "A doctor will review your request for a prescription and may call you if necessary.",
                "Receive an eScript via SMS and email.",
                "Purchase your medication online with delivery or collect it yourself from a pharmacy."
            ]}
            buttonText="Request a prescription"
            buttonHref="/m/pharm/online/prescription"
        />
    );
}

export function MedicalCerfiticateCopy() {
    return (
        <StepsTabsCard
            steps={[
                "Select the days you require a medical certificate for.",
                "A doctor will review your request and may call you if necessary.",
                "Receive your medical certificate by email.",
            ]}
            buttonText="Request a medical certificate"
            buttonHref="/c/pharm/certs"
        />
    );
}

export function PathologyCopy() {
    return (
        <StepsTabsCard
            steps={[
                "Select the the pathology request you require.",
                "A doctor will review your request and may call you if necessary.",
                "Receive your pathology request by email.",
                "Take your request to any pathology collection center."
            ]}
            buttonText="Request a pathology referral"
            buttonHref="/c/pharm/patho"
        />
    );
}

export function SpecialistCopy() {
    return (
        <StepsTabsCard
            steps={[
                "Select the the specialist you would like to be referred to.",
                "A doctor will review your request and may call you if necessary.",
                "Receive your specialist referral by email.",
                "Forward your referral to your specialist."
            ]}
            buttonText="Request a specialist referral"
            buttonHref="/c/pharm/express-specialist-referrals"
        />
    );
}