import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import moment from "moment";

import { useApp } from 'xAppLib/Hooks';
import { useNotifications } from '../../notifications/hooks/useNotifications';
import { from_melb_ui_tm } from "../../helpers/datetime";
import { useProfile } from "../../xAppLib/Hooks";
import instcons_global_model from "../../models/instcons_global_model";
import scripts_list_model from "../../models/scripts_list_model";

const NOTIFICATION_KEY = 'in_progress_video_consult';

export const useInProgressVideoConsults = () => {
    const { pathname } = useLocation();
    const { setDynamicNotification, removeDynamicNotification } = useNotifications();
    const app = useApp();
    const [prof] = useProfile();

    useEffect(() => {
        const inProgressConsults = prof?.hist
            ?.filter(rec => (
                ['qualcons', 'qualbuy', 'qualclick', 'exprcons', 'exprbuy', 'exprclick', 'doccons'].includes(rec.script_type)
                && ['await_doccall', 'in_doccall', 'noans_doccall', 'could_not_call', 'wrong_number'].includes(rec.status)
                && from_melb_ui_tm(rec.add_tm).isAfter(moment().subtract(1, 'month'))
            )) || [];

        const watchUnsubscribes = inProgressConsults.map(rec => {
            const { sid, spd_data, req_type } = rec;
            
            return instcons_global_model.watch_record(sid, fbdbRec => {
                const prefix = `/doccons_view`;
                
                if (pathname.startsWith(prefix)) {
                    removeDynamicNotification(`${NOTIFICATION_KEY}_${sid}`);
                    return;
                }

                if (!fbdbRec || fbdbRec.status !== 'in_doccall') {
                    removeDynamicNotification(`${NOTIFICATION_KEY}_${sid}`);
                    return;
                }

                const { vidcall_notify, sessionId } = fbdbRec;
                const has_recent_invite = vidcall_notify && moment().subtract(1, 'hour').isBefore(vidcall_notify);
                const is_mhcp = req_type === 'mhcp';
                
                if (!(has_recent_invite || is_mhcp)) {
                    removeDynamicNotification(`${NOTIFICATION_KEY}_${sid}`);
                    return;
                }

                const name = rec.med_db_data?.name || "Your Video Consult";
                const status_label = scripts_list_model.stats_dict(fbdbRec.status);
                const spd_hash__mobile = rec.spd_hash__mobile || '_';
                const href = `${prefix}/${sid}/${spd_hash__mobile}`;
                
                const show_pt_name = app?.user?.profs?.length > 1 && spd_data?.first_name && spd_data?.last_name;
                const subtitle = show_pt_name 
                    ? `${spd_data?.first_name} ${spd_data?.last_name} - ${from_melb_ui_tm(rec.add_tm).format('MMM D, YYYY')}`
                    : from_melb_ui_tm(rec.add_tm).format('MMM D, YYYY');
                
                setDynamicNotification(`${NOTIFICATION_KEY}_${sid}`, {
                    type: NOTIFICATION_KEY,
                    title: `${name} - ${status_label}`,
                    subtitle,
                    content: sessionId
                        ? "Your doctor has started the video consultation and ready for you to join."
                        : "Your doctor will notify you shortly when they're ready to start the video consultation.",
                    icon: 'video',
                    link: href,
                    buttonText: sessionId ? 'Join video consult' : 'Wait for doctor',
                    overrides: {
                        banner: true,
                        banner_variant: 'info',
                    },
                });
            });
        });

        return () => {
            watchUnsubscribes.forEach(unsubscribe => unsubscribe && unsubscribe());
            inProgressConsults.forEach(rec => {
                removeDynamicNotification(`${NOTIFICATION_KEY}_${rec.sid}`);
            });
        };
    }, [pathname, prof, app, setDynamicNotification, removeDynamicNotification]);
};