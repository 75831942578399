import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Image, Message } from "semantic-ui-react";

import instcons_global_model from "../../models/instcons_global_model";
import { AsyncButton } from "../UIelems/AsyncButton";
import ConferenceTok from "../UIelems/ConferenceTok";
import { useAsync } from "../../xAppLib/Hooks/useAsync";
import webrtc_logs_model from "../../models/webrtc_logs_model";
import { cls } from "../NUI/utils";

const WrapComponent = ({embedded, title, children}) => embedded
	? <div>{children}</div>
	: <Container className='wide-content' fluid>
		<Helmet>
			<title>{title}</title>
		</Helmet>

		<h1>Test My Video</h1>

		{children}
	</Container>

function defaultTrigger({start, stop, isRunning}) {
	return isRunning
		? <AsyncButton color="red" onClick={stop}>End Test</AsyncButton>
		: <AsyncButton color="green" onClick={start}>Start Test</AsyncButton>
}

function camAndMicPermissionsImage() {
	const ua = navigator.userAgent.toLowerCase();
	return [
		{
			src: 'android-app.png',
			show: app.settings.is_ionic_android,
		},
		{
			src: 'android-browser.jpg',
			show: app.settings.is_mob && !app.settings.is_ionic && !app.settings.is_ios
		},
		{
			src: 'desktop-edge.png',
			show: !app.settings.is_mob && ua.match(/edge?\/(\d+)(?:\.\d+)+$/)
		},
		{
			src: 'desktop-chrome.png',
			show: !app.settings.is_mob && !app.settings.is_ionic && ua.indexOf('chrome') >= 0
		},
		{
			src: 'desktop-firefox.png',
			show: !app.settings.is_mob && ua.indexOf('firefox') >= 0
		},
		{
			src: 'desktop-safari.png',
			show: !app.settings.is_mob && navigator.vendor?.toLowerCase().indexOf('apple') >= 0
		},
		{
			src: 'ios-app.png',
			show: app.settings.is_ionic_ios || app.settings.is_ios_homescreen_app
		},
		{
			src: 'ios-browser.png',
			show: app.settings.is_ios
		},
		{
			src: 'desktop-chrome.png',
			show: true // fallback
		},
	].filter(i => i.show)[0]?.src;
}

export default function TestMyVideo({
										embedded,
										autoStart,
										onLog,
										onStart,
										onEnd,
										showPermissionsInstruction = false,
										trigger = defaultTrigger,
										hideControls = false,
										statsComponent,
									}) {
	const permissionsImage = camAndMicPermissionsImage();
	const {data, error, fn: run_test} = useAsync({
		fn: async (start = true) => {
			if (!start) {
				onEnd?.();
				return {};
			}

			const sessionId = await instcons_global_model.create_session({test: true}).then(d => d?.sessionId);
			const fbid = app.user.uid && await webrtc_logs_model.create_session_log(sessionId);

			onStart?.();

			return {sessionId, fbid};
		},
		immediate: autoStart
	});

	const {sessionId, fbid} = data || {};
	const isRunning = !!sessionId;
	const showPerms =
		showPermissionsInstruction === "running" ? isRunning :
		showPermissionsInstruction === "idle" ? !isRunning
		: !!showPermissionsInstruction;

	return (
		<WrapComponent title="Test My Video" embedded={embedded}>
			{showPerms && (<div className="mb-4">
				<p>If prompted, please allow access to your camera and microphone:</p>
				<Image className="mx-4"
					   src={`/images/permissions/${permissionsImage}`}
					   size={app.settings.is_mob ? "small" : "medium"}
					   centered={app.settings.is_mob}/>
			</div>)}

			{trigger({start: () => run_test(true), stop: () => run_test(false), isRunning})}

			<div className={cls("max-w-xl mt-2", !embedded && "mb-28")}>
				{error && <Message negative>{error.message || String(error)}</Message>}
				{sessionId && <ConferenceTok
					showControls={!hideControls}
					statsComponent={statsComponent}
					sessionId={sessionId}
					autoStart
					name={app.user.name}
					networkTest
					onSubmitLog={(label, data = {}) => {
						if (!fbid) return;
						if (label === 'stats') {
							if (data.stats) webrtc_logs_model.update_session_stats(fbid, data.stats);
						} else {
							webrtc_logs_model.update_session_log(fbid, {...data, label});
						}
						onLog?.(label, data);
					}}
				/>}
			</div>
		</WrapComponent>
	);
}