import React, { useEffect, useState } from 'react';
import myhr_modal from '../../models/myhr_model';
import MyHRDocumentView from './MyHRDocumentView';
import ErrorScreen from './ErrorScreen';
import LoadingScreen from './LoadingScreen';

const GetViewScreen = ({ uid, filters, changeView}) => {
	const [loading, setLoading] = useState(false);
	const [documentData, setDocumentData] = useState(false);

	useEffect(() => {
		setLoading(true);
        const getData = async () => {
            const { view_type, start_date, end_date } = filters;
            const res = await myhr_modal.get_view({ uid, view_type, start_date, end_date });
            setDocumentData(res);
            setLoading(false);
        }
        getData();
	}, [JSON.stringify(filters)]);

	useEffect(() => {
		return () => {
			setLoading(false);
			setDocumentData(false);
		};
	}, []);

	const error = documentData?.err ? `${documentData?.err} (${documentData?.code})` : false;

	if (loading) {

		return <LoadingScreen loadingWhat={filters.view_type} />;
	}

	if (error) {
		return <>
			<div className='mt-6 h-[60vh] flex items-center justify-center'>
				<ErrorScreen message={error} />
			</div>
		</>;
	}

	if (documentData.document) {
		return (
			<>
				<div className='mt-2'>
					<MyHRDocumentView documentArr={documentData.document} changeView={changeView} />
				</div>
			</>
		);
	}

	return <></>
};
export default GetViewScreen;
