import React, { useEffect } from 'react';
import { useNotifications } from '../../notifications/hooks/useNotifications';
import { useProfile } from 'xAppLib/Hooks';

const NOTIFICATION_KEY = 'prescs';

export const usePrescs = () => {
    const { setDynamicNotification } = useNotifications();
    const [prof] = useProfile();

    const cat_nm = app.runtime.mode != 'iscr' && app.runtime.mode || 'pharm';

    useEffect(() => {
        if (!prof?.pts_prescs?.length) return;

        const prescs = prof.pts_prescs;

        prescs.forEach(p => {
            let title = '';
            let body = '';
            let link = null;
            let link_options = null;
            if (p.type === "req_doccons_flup" || p.type === 'req_icanncons_flup') {
                title = 'Your doctor has asked that you request a follow up consultation.';
                body = <>
                    {p.dets.msg && <p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>}
                    <p>If you would like a follow up consultation please click here and the doctor will contact you in the next 24 hours.</p>
                </>;
                link = '/m/pharm/' + p.med.mid;
                link_options = {
                    state: { presc: true, prid: p.prid }
                };

            } else if (p.type === 'req_doccons_res') {
                if (!p.dets?.optional) {
                    title = 'Your doctor has received your test results and would like to have a chat.';
                } else {
                    title = 'Your doctor has received your test results';
                }
                body = <>
                    {p.dets.msg && <p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>}
                    <p>{!p.dets?.optional ? 'Please request now and our doctors will contact you within the next 24 hours.' : 'If you would still like to discuss your results, you can request a follow up appointment please click here.'}</p>
                </>;
                link = '/m/pharm/' + p.med.mid;
                link_options = {
                    state: { presc: true, prid: p.prid, pts_uid: p.uid }
                };

            } else if (p.type === 'pre_prep') {
                title = 'PrEP Prescription – Based on your test results you can now request a script for PrEP';
                body = <>
                    {p.dets.msg && <p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>}
                    <p>Request script now.</p>
                </>;
                link = '/m/pharm/' + p.med.mid;
                link_options = {
                    state: { presc: true, prid: p.prid, pts_uid: p.uid }
                };

            } else if (p.type === 'presc_icann') {
                title = 'It\'s time to order your medication – Your treatment has been approved, please click  to order your medication delivery';
                body = <>
                    {p.dets?.msg && <><p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>
                        <div>
                            <p className="font-semibold">{app.app_data.ws_data?.cat_data?.[cat_nm]?.cat_avail_list?.[p.med?.mid]?.n}</p>
                            <i>{app.app_data.ws_data?.cat_data?.[cat_nm]?.cat_avail_list?.[p.med?.mid]?.d}</i>
                        </div>
                    </> || null}
                </>
                link = '/m/pharm/' + p.med.mid;
                link_options = {
                    state: { presc: true, prid: p.prid, pts_uid: p.uid }
                };

            } else if (p.type === 'req_iwlcons_flup') {
                title = 'Your Weight Loss program - we got your blood test results, please request an appointment with your doctor';
                body = <>
                    {p.dets?.msg && <>
                        <p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>
                    </> || null}
                </>
                link = '/m/pharm/' + p.med.mid;
                link_options = {
                    state: { presc: true, prid: p.prid, pts_uid: p.uid }
                };

            } else if (p.type.startsWith("presc_medsub")) {
                title = 'Your treatment plan - please review and approve';
                body = <>
                    {p.dets?.msg && <>
                        <p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>
                    </> || null}
                </>;
                link = '/m/pharm/' + p.med.mid;
                link_options = {
                    state: { presc: true, prid: p.prid, pts_uid: p.uid }
                };

            } else {
                title = "It's time to order your medication – Your treatment has been approved, please click to order your medication";
                body = <>
                    {p.dets?.msg && <>
                        <p><span className="font-semibold">Doctor's message:</span> {p.dets.msg}</p>
                        <div>
                            <p className="font-semibold">{app.app_data.ws_data?.cat_data?.[cat_nm]?.cat_avail_list?.[p.med?.mid]?.n}</p>
                            <i>{app.app_data.ws_data?.cat_data?.[cat_nm]?.cat_avail_list?.[p.med?.mid]?.d}</i>
                        </div>
                    </> || null}
                </>;
                link = '/m/pharm/' + p.med.mid;
                link_options = {
                    state: { presc: true, prid: p.prid, pts_uid: p.uid }
                };
            }

            setDynamicNotification(`${NOTIFICATION_KEY}-${p.prid}`, {
                type: NOTIFICATION_KEY,
                title,
                body,
                link,
                link_options,
                overrides: {
                    banner: true,
                    banner_variant: 'info',
                },
            });

        });
    }, [prof?.pts_prescs, cat_nm]);
}
