import React, { useState } from 'react';
import API_service from "../../xAppLib/providers/API_service";
import { supportsPDFs } from "../../xAppLib/helpers/pdf";
import { Button, Modal } from "semantic-ui-react";
import ShareDownloadButton from "../../xAppLib/UIelems/ShareDownloadButton";

export function viewScriptUrl(sid) {
	return `${API_service.API_BASE_URL}viewScript?s=${sid}`;
}

export function PreviewDocument(props) {
	const {
		url,
		children,
		doc_name = 'Document',
		onClosed,
		trigger,
		print = true,
		viewOnly = false,
		...rest
	} = props

	const [show, setShow] = useState(false);
	const onOpen = () => setShow(true);
	const onClose = () => {
		setShow(false);
		onClosed?.();
	};

	const url_print = `${url}&view=print`

	const view_url = !supportsPDFs() && `/pdf-view/viewer.html?file=` + encodeURIComponent(url) || url

	return (<>
		{!trigger && <a href="#" className='ui blue button' target='_blank' data-testid='link-download' {...rest} onClick={e => {
			e.preventDefault();
			setShow(true)
		}}>
			{children || `Download ${doc_name}`}
		</a>}
		<Modal open={show} onOpen={onOpen} onClose={onClose} closeIcon trigger={trigger}>
			<Modal.Header style={{display: 'flex'}}>
				<div>{doc_name}</div>
				{(!viewOnly || !supportsPDFs()) && <Button size='small'
						as='a'
						style={{marginLeft: 'auto'}}
						href={url}
						icon="external alternate"
						target='_blank'/>
				}
			</Modal.Header>
			<Modal.Content>
				<iframe src={view_url} style={{width: '100%', height: '70vh'}} frameBorder="0"></iframe>
			</Modal.Content>
			<Modal.Actions>
				{!viewOnly && (
					<ShareDownloadButton url={url} filename={`InstantScripts ${doc_name}.pdf`}/>
				)}
				{print && !viewOnly && (
					<ShareDownloadButton url={url_print} label={'Print friendly'}
										 filename={`InstantScripts ${doc_name} (print).pdf`}
					/>
				)}
			</Modal.Actions>
		</Modal>
	</>);
}