import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import StyledDrawer from "@/views/NUI/StyledDrawer";
import { useDisclosure } from "@/xAppLib/Hooks/useDisclosure";
import { ComplexField } from "@/views/ordering/components/ComplexField";

export function Allergies({onChange, ...props}) {
	const disclosure = useDisclosure();

	const value = props.value;
	const isNotApplicable = value === 'no';
	const displayValue = isNotApplicable
		? 'None'
		: value;

	return (
		<>
			<ComplexField {...props} value={displayValue} onEdit={disclosure.onOpen}/>
			<AllergiesDrawer value={isNotApplicable ? '' : props.value}
							 open={disclosure.isOpen}
							 isNotApplicable={isNotApplicable}
							 onChange={value => {
								 onChange(value);
								 disclosure.onClose();
							 }}
							 onCancel={disclosure.onClose}
							 label={props.label}
							 allowNone
			/>
		</>
	);
}

export function AllergiesGuardAction({action}) {
	return (
		<AllergiesDrawer value={action.config.value}
						 open={action.active}
						 onChange={action.resolve}
						 onCancel={() => action.reject(false)}
						 label={action.config.label}
		/>
	);
}

function AllergiesDrawer({value, open, allowNone, isNotApplicable, label, title = label, onChange, onCancel}) {
	const [input, setInput] = useState(value ?? '');
	const [notApplicable, setNotApplicable] = useState(isNotApplicable);
	const form_valid = notApplicable || input.trim().length > 0;

	const onSave = () => onChange(notApplicable ? 'no' : input);

	return (
		<StyledDrawer
			isOpen={open}
			drawerStyle="blue"
			dismissible
			onClose={onCancel}
			title={title}
			content={
				<div className="form ui">
					<p>Please list any significant health issues or concerns the doctor should know about.</p>
					<Form.TextArea
						label={label}
						disabled={notApplicable}
						onChange={e => setInput(e.target.value)}
						fluid
					>
						{input}
					</Form.TextArea>
					{allowNone && (
						<Form.Checkbox label="No allergies"
									   className="border rounded-lg w-full [&_*]:w-full [&>.ui.checkbox]:p-4"
									   checked={notApplicable}
									   onChange={(_e, {checked}) => setNotApplicable(checked)}
						/>
					)}
				</div>
			}
			footer={(
				<div className="flex flex-row space-x-3 items-stretch">
					<Button type="button" fluid secondary onClick={onCancel}>
						Cancel
					</Button>

					<Button type="button" fluid primary disabled={!form_valid} onClick={onSave}>
						Save
					</Button>
				</div>
			)}
		/>
	);
}