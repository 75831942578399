import React, { useCallback, useEffect, useRef, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import { Button, Card, Dimmer, Icon, Loader } from 'semantic-ui-react';

import moment from "moment";

import { get_appointment_times } from 'models/booking/booking_patient';

import { DATE_TIME_ZONE } from '../formatting';

import { getClientBookingTimezone } from '../shared';

import './AppointmentTimes.css';

const renderEventContent = (eventInfo) => {
    return (
        <div className="event-content">
            <div><strong>{eventInfo.timeText}</strong></div>
            <div className="event-title">{eventInfo.event.title}&nbsp;</div>
        </div>
    )
};

const AppointmentCard = ({ event, tz, onChangeTime, basic, bookingType }) => {
    useEffect(() => {
        if (!onChangeTime) {
            return;
        }

        let polling = true;

        const intervalID = setInterval(async () => {
            polling = true;

            const appointment_times =
                await get_appointment_times({
                    start: moment().format(DATE_TIME_ZONE),
                    booking_type: bookingType,
                });

            const event_start = moment(event.start);
            const has_event = appointment_times?.data?.events?.some(
                ({ start }) => event_start.isSame(start)
            );

            if (!has_event && polling) {
                onChangeTime();
                app.trigger(
                    app.events.SHOW_POPUP,
                    { txt: 'Your selected appointment time is no longer available. Please select another time.' }
                );
            }
        },
            1000 * 30 // 30 seconds
        );

        return (() => {
            polling = false;
            clearInterval(intervalID);
        });
    }, [onChangeTime])

    return (
        <Card
            fluid
            raised={!basic}
            className={basic ? '!shadow-none' : ''}>
            <Card.Content>
                <Card.Header>Appointment</Card.Header>
                <Card.Description>
                    <div className='pb-2'>
                        <Icon name='clock outline' />
                        <span className='pr-2'>{event.day_str},</span>
                        <span className='pr-2'>{event.startStr}</span>
                        -
                        <span className='pl-2'>{event.endStr}</span>
                    </div>
                    {tz} time
                </Card.Description>
            </Card.Content>
            {onChangeTime && (
                <Card.Content extra>
                    <Button
                        size='tiny'
                        onClick={onChangeTime}
                    ><Icon name='cancel' /> Change appointment time</Button>
                </Card.Content>
            )}
        </Card>
    )
};

const TimesView = (props) => {

    const {
        clientTimeZone,
        dateProfile: {
            renderRange: { start: rangeStart, end: rangeEnd }
        },
        eventsData,
    } = props;

    const mo_range_start =
        moment.tz(
            moment(rangeStart).tz(clientTimeZone).format('YYYY-MM-DD'),
            clientTimeZone,
        );

    const mo_range_end =
        moment.tz(
            moment(rangeEnd).tz(clientTimeZone).format('YYYY-MM-DD'),
            clientTimeZone,
        );

    const day_obj = {};
    eventsData.forEach(
        ({ start, end }) => {
            const mo_start = moment(start).tz(clientTimeZone);

            if (mo_start.isBefore(mo_range_start) || mo_start.isSameOrAfter(mo_range_end)) {
                return;
            }

            const mo_end = moment(end).tz(clientTimeZone);
            const day_key = mo_start.format('YYYY-MM-DD');

            const day_str = mo_start.format('ddd DD MMMM');

            if (!day_obj[day_key]) {
                day_obj[day_key] = {
                    day_key,
                    title: day_str,
                    events: []
                };
            }

            const startStr = mo_start.format("h:mm a");
            const endStr = mo_end.format("h:mm a");
            const title = `${day_str} ${startStr} - ${endStr}`;
            const id = mo_start.format("YYYYMMDDTHHmm");

            day_obj[day_key].events.push({
                start: mo_start.format(DATE_TIME_ZONE),
                end: mo_end.format(DATE_TIME_ZONE),
                day_str,
                startStr,
                endStr,
                title,
                id,
            });
        }
    );

    const days = Object.entries(day_obj)
        .sort(([a], [b]) => {
            if (a > b) {
                return 1;
            } else if (a < b) {
                return -1;
            }
            return 0;
        }).map(([_, day]) => day);

    if (days.length === 0) {
        return (
            <div className='px-4 py-16'>
                {!props.loading && (<span>
                    There are no appointment times for this date period.
                </span>)}
            </div>
        );
    }

    return (
        <div>
            {days.map(({ day_key, title, events }) => {
                return (
                    <div key={day_key}>
                        <h3 className='text-lg'>{title}</h3>
                        <div className='pb-1 flex flex-wrap'>
                            {events.map((event) => {
                                const {
                                    startStr,
                                    title,
                                    id,
                                } = event;
                                return (
                                    <Button
                                        id={id}
                                        key={id}
                                        title={title}
                                        primary
                                        inverted
                                        size='tiny'
                                        onClick={() => {
                                            props.onEventClick(event);
                                        }}
                                        className='min-w-[110px] box-border !mr-[0.75rem] !mb-[0.75rem] text-center'
                                    >
                                        <time dateTime={id}>{startStr}</time>
                                    </Button>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const AppointmentTimes = ({
    booking_type,
    onChangeTime = () => undefined,
    defaultEvent = null,
    basic = false,
    onInitialAppointmentsEmpty,
}) => {
    const calendarRef = useRef();

    const [eventsData, setEventsData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(defaultEvent);
    const loadedInitialEvents = useRef(false);

    const clientTimeZone = getClientBookingTimezone();

    const onEventClick = useCallback((event) => {
        const { start, end } = event;
        onChangeTime({
            start,
            end,
            clientTimeZone,
        });
        setSelectedEvent(event);
    }, [setSelectedEvent, onChangeTime]);

    const onLoading = useCallback((isLoading) => {
        setLoading(isLoading);
    }, []);

    const onAppointmentCardChangeTime = useCallback(() => {
        onChangeTime({ start: null, end: null });
        setSelectedEvent(null);
    }, [onChangeTime, setSelectedEvent]);

    const getEvents = useCallback(async (_fetchInfo, successCallback, failureCallback) => {
        let events_empty = false;
        try {
            const start = moment().format(DATE_TIME_ZONE);

            const appointment_times =
                await get_appointment_times({
                    start,
                    booking_type,
                });
            if (appointment_times.res === 'ok' && appointment_times.data) {
                const events =
                    appointment_times
                        ?.data
                        ?.events || [];

                if (events.length === 0) {
                    events_empty = true;
                }
                setEventsData(events);
                successCallback([]);
            } else {
                failureCallback(appointment_times);
                events_empty = true;
            }
        } catch (err) {
            failureCallback(err);
            events_empty = true;
        }

        setTimeout(() => {
            if (!loadedInitialEvents.current) {
                loadedInitialEvents.current = true;
                if (events_empty && onInitialAppointmentsEmpty) {
                    onInitialAppointmentsEmpty();
                }
            }
        }, 0);
    }, [
        booking_type,
        setEventsData,
        loadedInitialEvents,
        onInitialAppointmentsEmpty,
    ])

    return (
        <>
            {selectedEvent && (
                <AppointmentCard
                    event={selectedEvent}
                    tz={clientTimeZone}
                    onChangeTime={onAppointmentCardChangeTime}
                    basic={basic}
                    bookingType={booking_type}
                />
            )}
            {!selectedEvent && (
                <>
                    <h2 className='text-base'>Select an appointment time <small>({clientTimeZone} time)</small></h2>
                    <Dimmer.Dimmable dimmed={true}>
                        <Dimmer active={loading} inverted>
                            <Loader>Loading</Loader>
                        </Dimmer>
                        <FullCalendar
                            eventContent={renderEventContent}
                            eventInteractive={true}
                            events={getEvents}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: null,
                                right: 'title',
                            }}
                            height='auto'
                            initialView='times'
                            loading={onLoading}
                            plugins={[dayGridPlugin]}
                            ref={calendarRef}
                            validRange={{
                                start: moment().format('YYYY-MM-DD'),
                            }}
                            views={{
                                times: {
                                    duration: { weeks: 2 },
                                    content: function (props, f) {
                                        return (
                                            <TimesView
                                                onEventClick={onEventClick}
                                                loading={loading}
                                                {...props}
                                                eventsData={eventsData}
                                                clientTimeZone={clientTimeZone}
                                            />
                                        );
                                    }
                                }
                            }}
                        />
                    </Dimmer.Dimmable>
                </>
            )}
        </>
    )
}

export default AppointmentTimes;
