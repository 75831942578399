import debounce from "xAppLib/libs/debounce"
import logger from "xAppLib/libs/logger"
import API_service from "xAppLib/providers/API_service"
import localstorage_database from "xAppLib/providers/localstorage_database"
import moment from "moment-timezone"
import { flagFormData } from "../../xAppLib/Users/AnswerFlag";

export const QUAL_STATUS = {
	CURRENT: 'current',
	MED_ONLY: 'med_only',
	EXPIRED: 'expired',
	CONS_ONLY: 'cons_only',
}

export function calculate_types({values = {}, med_code, med_data, cat_nm, cat_route, profile,prop_req_type,prop_script_type,PARTN_PHARM, home_delivery_available, medbuy_only, presc, qual_status }) {

	if (!med_data)
		return [null,null]

	const needs_qual = !presc && (med_data.itm_tp ?? 'scr') === 'scr' && (med_data?.need_qual !== false) && qual_status !== QUAL_STATUS.CURRENT;
	const buy_type = needs_qual ? 'qualbuy' : values.exprcons_want ? 'exprbuy' : 'medbuy';
	const click_type = needs_qual ? 'qualclick' : values.exprcons_want ? 'exprclick' : 'medclick'; // click & collect
	const cons_type = needs_qual ? 'qualcons' : values.exprcons_want ? 'exprcons' : 'medcons';

	const script_type =
			presc?.type.startsWith('presc_medsub') && 'medsub'
			|| (values.delivery == 'home' || values.delivery=='c2u' || values.fulfilment === 'delivery') && buy_type
			|| values.fulfilment === 'click_collect' && click_type
			|| med_data.itm_tp=='doccons' && 'doccons'
            || medbuy_only && home_delivery_available && buy_type
			|| med_data.itm_tp=='scr' && cons_type
			|| med_data.itm_tp=='patho' && 'pathoreq'
			|| med_data.itm_tp=='cert' && app.dvc.org?.type=='pharm' && 'medcert'
			|| med_data.itm_tp=='refr' && 'docrefr'
			|| med_code=='pthl-needlestick' && 'pthl-needlestick' 
			|| med_data.itm_tp=='subcons' && 'subcons'
			|| cat_nm=='patho' && 'pathoreq'
			|| cat_nm=='iLeg_cat' && 'doccons'
			|| ['DocConsCov', 'DocConsFU', 'DocConsWL', 'DocConsRef'].includes(med_code) && 'doccons' 
			|| !['pharm', 'patho'].includes(cat_nm) && app.acl.is_doc_presc && 'docscr' 
			|| !['pharm', 'patho'].includes(cat_nm) && (cat_route=='-MTnamdhrOqUxWr1AxBy' ? cons_type : 'cosm')  // TODO that seems messy
			|| app.dvc.org?.type=='pharm' && 'pharm' 
			|| prop_script_type
			|| cons_type

	const req_type =
			med_data.req_tp
			|| med_data.itm_tp=='cert' && script_type==cons_type && 'medcert'
			|| cat_nm!='pharm' && cat_nm!='patho' && (app.dvc.org?.type=='cosm'||app.user?.claims?.admin) && 'cosm' 
			|| ['-MdM99oAFZEtgGgABh2n', '-Mgk8Hap2WJtiE_fhxu6'].includes(med_code) && 'hivprevent'
			|| (['DocConsHairIni', 'DocConsHairFU'].includes(med_code) || profile?.pts_prescs?.filter( p => p.med?.mid==med_code )?.[0]?.type=='presc_ihair') && 'ihair'
			|| (['DocConsICann', 'DocConsICannFU'].includes(med_code) || app.runtime.app == 'icann' || profile?.pts_prescs?.filter( p => p.med?.mid==med_code )?.[0]?.type=='presc_icann') &&
				!['DocConsCov', 'DocConsFU', 'DocConsWL', 'DocConsRef'].includes(med_code) && 'icann'
			|| med_code=='DocConsFU' && 
				( location.state?.presc && location.state?.prid || profile?.pts_prescs?.filter( p => p.med?.mid==med_code ).length ) 
				&& 'docord' 
			|| ['DocConsFU', 'SubConsFU'].includes(med_code) && 'doccons_fu'
			|| med_code=='DocConsCov' && 'docconscov' 
			|| med_code=='DocConsSC' && 'docconssc'
			|| med_code=='DocConsSL' && 'docconssl'
			|| med_code=='DocConsSunDamage' && 'dc_skin'
			|| med_code=='DocConsAcne' && 'dc_acne'
			|| script_type==='subcons' && med_code.toLowerCase()
			|| presc?.type.startsWith('presc_medsub') && presc.type.replace(/^presc_/, '')
			|| ['DocConsAntiVir', 'DocConsAntiVirBB'].includes(med_code) && 'dc_av'
			|| ['-NQi3kEk3nXo4hjWK3-V', '-NRecaAbZYaXNw3N0EKb'].includes(med_code) && 'dc_rh'
			|| ['DocConsMHCP','DocConsMHCPCopay'].includes(med_code) && 'mhcp'
			|| ['DocConsMHCPReview'].includes(med_code) && 'mhcprev'
			|| ['DocConsWL', 'DocConsWLFU'].includes(med_code) && 'docconswl'
			|| ((presc_req_type = '') => {
				// e.g. presc_docconswli => docconswli
				return /^presc_docconswl/.test(presc_req_type) && presc_req_type.replace(/^presc_/, '');
			})(profile?.pts_prescs?.filter( p => p.med?.mid==med_code )?.[0]?.type)
			|| med_code=='DocConsRef' && 'docconsref' 
			|| app.dvc.org?.type=='pharm' && 'pharm' 
			|| PARTN_PHARM && 'forpha' 
			|| prop_req_type 
			|| cat_nm=='iLeg_cat' && 'ileg'
			|| !['pharm', 'patho'].includes(cat_nm) && app.acl.is_doc_presc && 'paas' 
			|| 'premed'


	return [req_type,script_type]
}

export function get_ref_fields(location, profile, med_code) {
	const ref_fields = {};

	// TODO check this
	if ( location?.state?.presc && location?.state?.prid || profile?.pts_prescs?.filter( p => p.med?.mid==med_code )?.length ) {
		ref_fields.ref_prid = location?.state?.prid || profile?.pts_prescs?.filter( p => p.med?.mid==med_code )[0].prid
		ref_fields.presc_partn_oid = location?.state?.prid || profile?.pts_prescs?.filter( p => p.med?.mid==med_code )[0].ref?.partn_oid
		ref_fields.presc_partn_ptid = location?.state?.prid || profile?.pts_prescs?.filter( p => p.med?.mid==med_code )[0].ref?.partn_ptid
	} else {
		const presc = get_related_presc({profile, med_code });
		if (presc) {
			ref_fields.ref_prid = presc.prid;
		}
	}

	if (!ref_fields.ref_prid) {
		const consults = consults_for_followup(med_code);
		const sid = location?.state?.sid;
		const consult = sid && consults.find(c => c.sid === sid) || consults[0];

		if (consult) {
			ref_fields.ref_sid = consult.sid;
		}
	}

	return ref_fields;
}

export function get_form_data({med_code, values, cart, profile, paymToken, has_form_errs, paypalOrderDetails, location, is_spon, qualcons, onepass}) {
	const form_data = {...values}
	const cart_content = cart.content()
	
	if (cart_content.subtotal.value > 0) {
				
		// console.log('Med :: med_form_check - med price - ', paym);

		form_data.paym = {	
			price: cart_content.total.value,
			subtotal: cart_content.subtotal.value,
			tax: cart_content.tax.value,
			items: cart_content.items,
			discount : cart_content.items.filter(i=>i.id=='DISCOUNT').pop(),
			
		}

		if (is_spon) {
			form_data.paym = {
				...form_data.paym,
				paymentMethod: "partnpay"
			}
		} else if (paymToken?.current && !paypalOrderDetails?.current){
			form_data.paym = {	
				...form_data.paym,
				CC_tok:paymToken.current?.CC_tok,
				name:paymToken.current?.name, 
				paymentMethod: "ccard"
							}
		} else if (!paymToken?.current && form_data.paym.price > 0) {
			form_data.paym = {
				...form_data.paym,
				paymentMethod: "paypal"
			}
		}

		if(form_data.paym.paymentMethod === 'paypal' && paypalOrderDetails?.current?.orderID){
			form_data.paym = {
				...form_data.paym,
				approval: paypalOrderDetails.current,
			}
		}
		
	}
	
	if (values['cado_want']) {
		const { cado_phone } = values
		form_data.cado = {want:true, phone:cado_phone, name:paymToken.current?.name||`${values['first_name']} ${values['last_name']}`, price:cart.CADO_PRICE}
	}
	

	if (!cart.has_branded && cart.has_generic) {
		form_data.want_gen = true
	}

	// TODO might need to tweak this
	if (form_data.sendto_pha?.srch_adr)
	form_data.sendto_srch_adr = form_data.sendto_pha?.srch_adr

	if (form_data.paym) {
		const cart_content = cart.content(has_form_errs)
		form_data.paym.price = cart_content.total.value
		form_data.paym.subtotal = cart_content.subtotal.value
		form_data.paym.tax = cart_content.tax.value
		form_data.paym.items = cart_content.items,
		form_data.paym.discount = cart_content.items.filter(i=>i.id=='DISCOUNT').pop()
	}

	Object.assign(form_data, get_ref_fields(location, profile, med_code));

	if (!needs_shipping(form_data.delivery, form_data.fulfilment)) {
		delete form_data.shipping_address_components
		delete form_data.shipping_address
	}

	form_data.cons_req_med = location?.state?.cons_req_med;

	form_data.qualcons = qualcons;
	onepass && (form_data.onepass = onepass);

	return form_data
}

export function get_related_presc({ profile, med_code }) {
	return profile?.pts_prescs?.find(p => p.med?.mid == med_code);
}

export function get_qs(med_data, selected_items = {}, params = {}) {

	if (!med_data)
		return {}
		
    const groups = []
    
    // todo better implementation?
    const is_prep = !!(app.settings.iprep && med_data.key=='-Mgk8Hap2WJtiE_fhxu6')

    if (is_prep)
        return {}

    if (med_data.q)
        groups.push({name:med_data.name,qs:med_data.q,key:med_data.key, primary:true})
    
    if (med_data.items)
        for (let {name,key,q : qs} of med_data.items) {
            if (qs) {
                groups.push({name,qs,key})
            }
        }
    
    const qs_obj = groups.filter(({key,primary})=>primary || !selected_items || selected_items[key]).reduce((o,g)=>({...o,...g.qs}),{})

    Object.keys(qs_obj).map( k => {
    							const { age_from, age_to } = qs_obj[k]?.config||{}

								const age = params?.dob && moment().diff(params?.dob, 'years');
								const ageFrom = age_from && Number(age_from);
								const ageTo = age_to && Number(age_to);

								if (age && (ageFrom || ageTo) && (age < (ageFrom??0) || age > (ageTo??120))) 
									delete qs_obj[k];
							} )

    return qs_obj
    
}

export function make_answs(form_data, qs) {
	let answs = {};

	qs && Object.keys(qs).forEach((k) => {
		const { txt, a, details, ord } = qs[k];
		answs[k] = {
			q: txt,
			a: a?.[form_data[k]]?.txt,
			r: a?.[form_data[k]]?.res,
			d: details,
			f: a?.[form_data[k]]?.flg && flagFormData(a?.[form_data[k]]?.flg),
			o: ord
		};
		const text_id = `${k}_text`;
		if (qs[k].config?.text && (!qs[k].config?.text_answer || qs[k].config?.text_answer === form_data[k]) && form_data[text_id]) {
			answs[k].x = form_data[text_id];
		}
		const choice_id = `${k}_choice`;
		if (
			qs[k].config?.choice
			&& (!qs[k].config?.choice_answer || qs[k].config?.choice_answer === form_data[k])
			&& form_data[choice_id]
			&& Object.values(form_data[choice_id]).length > 0
		) {
			answs[k].c = Object.values(form_data[choice_id]);
		}
	});

	return answs;
}

export function get_answer_flags(formData, qs) {
	return flagged_answers(make_answs(formData, qs));
}

export function flagged_answers(answs) {
	return Object.values(answs || {}).filter(q => q.f).map(q => q.f.f);
}

export function ans_flag_block(answer_flags) {
	return answer_flags.includes('self_harm');
}

export function sort_qs(qs) {
    return Object.keys(qs).sort( (a, b) => {
		// qs[a].ord for qs, qs[a].o for answs
		const ord_a = qs[a].ord || qs[a].o;
		const ord_b = qs[b].ord || qs[b].o;
        return 	a == 'extra_Qs-understand' ? 1 
                : b == 'extra_Qs-understand' ? -1 
                : (ord_a == null || ord_b == null ) ? 0 : parseInt(ord_a) - parseInt(ord_b)
    }).map(k=>({k,...qs[k]}))

}

export function data_from_profile(profile) {
    return profile && {
        first_name: profile['first_name'] || '',
        last_name: profile['last_name'] || '',
        email: profile['email'] || '',
        dob: profile['dob'] || '',
        dva: profile['dva'] || '',
        medicare: profile['medicare'] || '',
        mobile: profile['mobile'] || '',
        address: profile['address'] || '',
        full_address: profile['full_address'] || profile['address'] || '',
        //shipping_address: profile['full_address'] && profile['full_address'].formatted || '',
        //shipping_address_components: profile['full_address'] || '',
        conc_card: profile['conc_card'] || '',
		em_con_name: profile['em_con_name'] || '',
		em_con_mobile: profile['em_con_mobile'] || '',
		em_con_rel: profile['em_con_rel'] || '',
        sex: profile['sex'] || null,
				atsi: profile.atsi,
				chro_cond: profile.chro_cond,
				cons_allergy: profile.cons_allergy,
				cons_has_allergies: profile.cons_has_allergies,
				cons_medhist: profile.cons_medhist,
				cons_meds: profile.cons_meds,
				yogp: profile.yogp,
				yogp_want: !!Object.keys(profile.yogp ?? {}).length,
    } || {}
}

export const PROFILE_FIELDS = [
	'first_name',
	'last_name',
	'email',
	'dob',
	'mobile',
	'full_address',
	'sex',
]

export function missing_profile_fields(profile, need_mdc) {
	if (!profile)
		return [...PROFILE_FIELDS]

		const need_cons_allergy = profile.cons_has_allergies != 'no' && !profile.cons_allergy;
		const need_cons_medhist = !profile.cons_medhist;
		const need_cons_meds = !profile.cons_meds;
		const need_cons_chro_cond = !profile.chro_cond;

    const missingProfileFields = [
			...PROFILE_FIELDS,
			...((need_mdc && ['medicare']) || []),
			...((need_cons_allergy && ['cons_allergy']) || []),
			...((need_cons_medhist && ['cons_medhist']) || []),
			...((need_cons_meds && ['cons_meds']) || []),
			...(need_cons_chro_cond&&['chro_cond']||[]),
		].reduce((missing, field) => {
		if (!profile[field] || (field=="full_address" && !profile.full_address?.formatted)) {
			missing.push(field)
		}
		return missing
	},[])
	return missingProfileFields
}

export function profile_ihi_valid(profile) {
	const profile_ihi = {
		number: profile.ihi_ihinumber,
		status: profile.ihi_ihistatus,
	};

	if (ihi_valid(profile_ihi)) {
		return profile_ihi;
	}

	return false;
}

export function ihi_valid(ihi) {
	return ihi?.number && ihi?.status == "Active"
}

export function update_cart(cart, {values, script_type, req_type, use_profile, free_consult, evermed, credit} ) {
	const no_medicare = values.no_medicare
	cart.free_consult = free_consult
	cart.script_type = script_type
	cart.req_type = req_type
	cart.want_cado = !!values.cado_want
	cart.want_exprcons = !!values.exprcons_want
	cart.form_vals = values
	cart.want_generic = !!values.want_gen;
	cart.evermed_enabled = evermed.enabled
	cart.credit = credit

	const concession = values.conc_card 
	cart.has_concession = !!(concession && concession != '')

	const dva_number = values.dva?.number
	const dva_colour = values.dva?.colour
	cart.has_dva_concession = !!((dva_number && dva_colour) && (dva_colour !== 'W' || values.dva_conc_eligible === 'yes'))
	
	const medicare = values.medicare
	if (use_profile) {
		cart.has_medicare = !!(medicare && medicare != '')
	} else if (app.acl.app_use) {
		cart.has_medicare = !no_medicare
	} else {
		cart.has_medicare = !!(medicare && medicare != '')
	}


}

export function requestAppReview() {
	if (!app.settings.is_ionic || !('AppRate' in window)) 
		return
	try {
		const review_asked = localstorage_database.get_record('__appreview_asked') || 0
		if (review_asked==2 || review_asked==4 || review_asked==7) {
			setTimeout(_=>{
				window.AppRate.promptForRating(true)
			},1000)
		}
		localstorage_database.update_record('__appreview_asked', review_asked+1)
	} catch (e) {
		logger.report_error('ERROR prompting for app review', 'error', e);
	} 	
}




export function get_gtm_product({script_type, req_type, med_data, cart,srch_str,cat_nm, cat_route, cat_avail_list}) {
	const cat = get_gtm_cat({cat_route, med_data, cat_avail_list})
	const cart_content = cart.content()

	const data = cart_content.items.reduce((d,p)=>{
		switch(p.id) {
			case 'DOCCONS':
			case 'MEDCONS':
				return {
					...d,
					price:p.price.value
				}
			case 'MEDBUY':
				return {
					...d,
					metric2:p.price.value,
					
				}
			case 'SHIPPING':
				return {
					...d,
					metric3:p.price.value
				}
		}
		return d
	},{})
	return {
		price:0,
		id: med_data?.m,
		quantity: 1,
		tax: 0,
		shipping: 0,
		name: cat + ' : ' + (med_data?.name||'unknown med'),
		dimension1: med_data?.size,
		dimension2: script_type,
		dimension3: req_type,
		dimension4: cat_nm,
		category: cat,
		list: srch_str ? `srch: ${srch_str}` : cat,
		brand: `${script_type}-${req_type}`,
		coupon: cart.discount?.code,
		metric1:Math.abs(cart_content.discount?.value || 0),
		...data

	}
}

export function get_gtm_cat({cat_route, med_data,cat_avail_list}) {
	return med_data?.m == 'DocConsCov' ? 'Doctor Consultations': cat_route?.split('/').map(c=>cat_avail_list[c]?.n).join('/') || ''
	
}

export function log_scr_req({sid, err, cart, script_type, req_type, med_data, req_data}) {

	try {
		const cart_content = cart.content()
		logger.usg_log('MedScrReq', req_type+'-'+script_type, err||'pre_appr', {val:cart_content.total.value, mednm:med_data?.name, req_data})

		
	} catch (e) {
		console.log('Med :: log_scr_req :: ERROR :: ',e);
	}
	
}

export  async function _get_c2u(params = {}, ca) {
	const { postcode, full_address = {} } = params
	let ret
	try {
		if (full_address?.address && typeof full_address?.address == 'string' && full_address?.address.match(/po\s*?box/gi)) ret = {res:'err', err: 'PO Box not supported'}
		else ret = await API_service.load_data( 'c2u/service', {postcode} )
	} catch (error) {
		ret = {res:'err', err: error.message}
	}
	if (ret?.response_data?.data?.courrierDetails) ret.response_data.data.courierDetails = ret.response_data.data.courrierDetails // Fix C2U typo
	const courierDetails = ret?.response_data?.success && ret?.response_data?.data?.courierDetails
	if (courierDetails) {
		let { dropoff, pickup, timeZoneId, day } = courierDetails.nextShift
		dropoff = moment.tz(dropoff, timeZoneId);
		pickup = moment.tz(pickup, timeZoneId);
		const is_today = moment().isSame(pickup,'day')
		const formatted = `${is_today ? "Today" : day} between ${pickup.format("h:mm A")} and ${dropoff.format("h:mm A")}`
		ca({ok:true,formatted,courierDetails})
		app.tmp_c2u_serv_resp_formatted = formatted 	//	TEMP
	} else 
		ca({})
	

}

export const get_c2u = debounce(_get_c2u,500)

// ---- --------------------------------------------  --------------------------------------------

const state_delivery_data = { // See https://auspost.com.au/service-updates/domestic-delivery-times - express post times
	vic: { names: ['victoria', 'vic'], time: '1 - 2 business days' },
	nsw: { names: ['newsouthwales', 'nsw'], time: '2 - 4 business days' },
	tas: { names: ['tasmania', 'tas'], time: '2 - 3 business days' },
	qld: { names: ['queensland', 'qld'], time: '2 - 4 business days' },
	sa: { names: ['southaustralia', 'sa'], time: '2 - 3 business days' },
	act: { names: ['australiancapitalterritory', 'act'], time: '2 - 4 business days' },
	nt: { names: ['northernterritory', 'nt'], time: '4 - 6 business days' },
	wa: { names: ['westernaustralia', 'wa'], time: '3 - 4 business days' },
	default: { time: '2 - 5 business days' } // If state is unknown, go be worst case scenario
}

// ---- --------------------------------------------  --------------------------------------------

export function aus_post_estimate(params = {}) {

	let result = { res: 'ok', time: state_delivery_data.default.time }

	try {

		const { state } = params

		const state_lower = `${state}`.replace(/[^A-Za-z]/gi, '').toLowerCase()

		const keys = Object.keys(state_delivery_data) // One call faster

		for (let i = 0; i < keys.length; i++) { // Can't break forEach
			const c_data = state_delivery_data[keys[i]] // One call faster
			if (c_data.names.includes(state_lower)) {
				result.time = c_data.time
				break
			}
		}

		//if (typeof result.time === 'undefined') throw `Unknown state: ${state}`

	} catch (e) {

		result = { ...result, res: 'err', err: `${e}` }

	}

	app.tmp_aus_post_time = result.time // This is very bad

	return result

}

export function needs_shipping(delivery, fulfilment) {
	return delivery=='home' || delivery=='c2u' || delivery?.includes("EM_") || fulfilment=='delivery' || fulfilment === 'click_collect'
}

export function consults_for_followup(fu_mid) {
	return app.user?.prof?.hist?.filter?.(hs => hs?.med_db_data?.FU_mid == fu_mid && can_followup(hs)) || [];
}

export function can_followup(hs) {
	return !!(hs?.med_db_data?.FU_mid && hs?.med_db_data?.FU_until > Date.now());
}