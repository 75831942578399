import React from 'react';

export function NotificationsCountBanner({ count, onClick }) {
	return (
		<button
			className="w-fit flex items-center justify-between bg-is2-yellow-100 lg:bg-is2-green py-3 px-4 gap-3 lg:gap-[32px] rounded-[4px] lg:rounded-[8px] mb-9 cursor-pointer"
			onClick={onClick}
		>
			<p className="!mb-0 font-normal text-left lg:text-center !text-is2-gray-700">You have {count} new notification{count !== 1 ? 's' : ''}</p>
			<span className="ui button basic blue !bg-white hover:lg:!bg-is2-blue-50 !text-is2-gray-700 hover:lg:!outline-is2-gray-100 lg:!outline-is2-gray-100">View</span>
		</button>
	);
}

