import React, { Component } from 'react';
import {
		Dropdown,
		Icon,
		Pagination,
		Segment
	} from 'semantic-ui-react'

const defaultPageSizeOptions = [10,30,50,100,200,1000,10000]

export function WrappedListPagination({children, top = true, bottom = true, ...props}) {
	return (<>
		{top && <ListPagination {...props} />}
		{children}
		{bottom && <ListPagination {...props}/>}
	</>);
}

class ListPagination extends Component {

	constructor(props) {
		super(props);

	}
	
	setPage = (e, { activePage }) => {
		console.log("setPage(%o)",activePage)
		const { onPageChange } = this.props
		onPageChange && onPageChange(activePage)
	}


	render() {
		const { data, page, pages = 1, total, 
				pageSize, showPageSizeSelector = false, 
				pageSizeOptions = defaultPageSizeOptions,
				boundaryRange = 5 } = this.props
		
		if (!data)
			return null

		const commonProps = {
			totalPages: pages,
			activePage: page,
			onPageChange: this.setPage,
			ellipsisItem: { content: <Icon name='ellipsis horizontal' />, icon: true },
			firstItem: page > 2 ? { content: <Icon name='angle double left' />, icon: true } : null,
			prevItem: page > 1 ? { content: <Icon name='angle left' />, icon: true } : null,
			nextItem: page < pages ? { content: <Icon name='angle right' />, icon: true } : null,
			lastItem: page < pages-1 ? { content: <Icon name='angle double right' />, icon: true } : null,
			className: 'right floated clearing'
		};
			
		return (
			<Segment basic clearing style={{padding:0}}>
				<div className="font-bold list-pagination flex flex-col lg:flex-row items-start lg:items-center justify-between gap-4 lg:gap-0 pb-10">
					{/* Mobile */}
					<div className="w-full lg:hidden flex justify-center scale-90 transform-origin-center">
						{pages > 0 && <Pagination 
							{...commonProps} 
							size="mini"
							siblingRange={1}
							boundaryRange={1}
						/>}
					</div>

					<div className="flex flex-col sm:flex-row text-sm sm:mx-auto lg:mx-0 gap-1 my-auto sm:justify-center sm:items-center">
						Total records: {total} &nbsp; &nbsp;
						<span/>
						{total && <>Showing records {(page -1 ) * pageSize + 1}-{Math.min((page) * pageSize, total)}</> || null}  &nbsp; &nbsp;
						<span/>

						{showPageSizeSelector && 
							<Dropdown 
								value={pageSize}
								text={'Page Size ' + pageSize}
								options={pageSizeOptions.map((o,i)=>({ text: o.toString(), value: o }))}
								onChange={(event, data) => {this.props.onPageSizeChange && this.props.onPageSizeChange(parseInt(data.value))}}
								icon="dropdown"
								labeled
								button
								className={`icon !px-2 !py-0.5 !text-sm !font-semibold !text-black ${app.settings.iscr2 ? '!bg-is2-gray-50 hover:!bg-is2-blue-50' : ''}`}
							/>
						}
					</div>

					{/* Desktop */}
					<div className="hidden lg:block">
						{pages > 0 && <Pagination 
							{...commonProps}
							size="small"
							siblingRange={3}
							boundaryRange={boundaryRange}
						/>}
					</div>
				</div>
			</Segment>
		);
	}


}


export default ListPagination;
