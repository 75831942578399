import React from 'react';
import { Icon } from 'semantic-ui-react';
import getNotificationIcon from './getNotificationIcon';
import { format_date } from './format_date';

const NotificationItem = ({
    notification,
    onClick,
    hasUnreadButton,
    setNotificationRead,
    usePlugin,
    onClose,
}) => {
    const plugin = usePlugin?.(notification.type);

    const unread_dynamic_colors = {
        background: 'bg-blue-50',
        background_active: 'hover:bg-blue-100',
        plugins_background: 'bg-blue-50/50',
        envelope_icon: 'text-blue-500',
        pin_icon: 'text-blue-500',
    };

    const unread_regular_colors = {
        background: 'bg-white',
        background_active: 'hover:bg-gray-100',
        plugins_background: 'bg-gray-50',
        envelope_icon: 'text-gray-500',
        pin_icon: 'text-gray-500',
    };

    const read_colors = {
        background: 'bg-[#EFEFF0]',
        background_active: 'hover:bg-[#E5E5E6]',
        plugins_background: 'bg-gray-100',
        envelope_icon: 'text-gray-500',
        pin_icon: 'text-gray-500',
    };

    const background_class =
        isUnread(notification)
            ? (notification.isDynamic)
                ? unread_dynamic_colors.background
                : unread_regular_colors.background
            : read_colors.background;

    const background_active_class =
        isUnread(notification)
            ? (notification.isDynamic)
                ? unread_dynamic_colors.background_active
                : unread_regular_colors.background_active
            : read_colors.background_active;

    const plugins_background_class =
        isUnread(notification)
            ? (notification.isDynamic)
                ? unread_dynamic_colors.plugins_background
                : unread_regular_colors.plugins_background
            : read_colors.plugins_background;

    const envelope_icon_class =
        isUnread(notification)
            ? notification.isDynamic
                ? unread_dynamic_colors.envelope_icon
                : unread_regular_colors.envelope_icon
            : read_colors.envelope_icon;

    const pin_icon_class =
        isUnread(notification)
            ? notification.isDynamic
                ? unread_dynamic_colors.pin_icon
                : unread_regular_colors.pin_icon
            : read_colors.pin_icon;

    return (
        <div className="notification-item relative">
            <div className={`
                notification-item-main
                w-full flex items-center
            `}>
                <NotificationItemButton
                    active={onClick && notification.link}
                    notification={notification}
                    onClick={onClick}
                    hasUnreadButton={hasUnreadButton}
                    background_class={background_class}
                    background_active_class={background_active_class}
                >
                    <div className="notification-item-content flex flex-col gap-2">
                        <div className="notification-header flex items-center gap-2">
                            <div className="notification-icon">
                                <Icon
                                    {...getNotificationIcon(notification)}
                                    aria-hidden="true"
                                    className="text-gray-500"
                                    size="large"
                                />
                            </div>
                            <div className="notification-header-content flex flex-col gap-1">
                                <div className="notification-header-title font-bold text-sm text-gray-900">
                                    {notification.title}
                                    {notification.link && ' ›'}
                                </div>
                                {!notification.isDynamic && (
                                    <div className="notification-header-date text-sm text-gray-500">
                                        {format_date(notification.created)}
                                    </div>
                                )}
                            </div>
                        </div>
                        {notification.body && (
                            <div className="notification-body text-base text-black/85">
                                {notification.body}
                            </div>
                        )}
                    </div>
                </NotificationItemButton>

                {hasUnreadButton && !notification.overrides?.persistent && (
                    <NotifcationEnvelopButton
                        read={notification.read}
                        onClick={() => setNotificationRead && setNotificationRead(notification.notification_id, !notification.read)}
                        background_class={background_class}
                        background_active_class={background_active_class}
                        envelope_icon_class={envelope_icon_class}
                    />
                )}
            </div>
            {showPinIcon(notification)
                ? <Icon
                    name="pin"
                    className={`absolute isolate z-10 top-2.5 right-1 ${pin_icon_class}`}
                    size="small"
                    aria-hidden="true"
                />
                : null
            }
            {plugin?.itemExtra && (
                <div
                    className={`notification-item-plugins p-4 flex flex-wrap gap-2 items-center ${plugins_background_class
                        }`}>
                    <plugin.itemExtra
                        notification={notification}
                        onClose={onClose ? onClose : () => undefined}
                    />
                </div>
            )}
        </div>
    );
};

function NotificationItemButton({
    active,
    notification,
    children,
    onClick,
    hasUnreadButton,
    background_class,
    background_active_class,
}) {
    if (active) {
        return (
            <button
                className={`
                    notification-item-card-button
                    flex-1 text-left
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500
                    sm:[li:first-child_&]:rounded-tl-lg
                    [li:last-child_&]:rounded-bl-lg
                    ${(!hasUnreadButton || notification.overrides?.persistent) ?
                        `sm:[li:first-child_&]:rounded-tr-lg
                        [li:last-child_&]:rounded-br-lg` : ''
                    }
                    transition-colors duration-150
                    px-4 py-6
                    focus:relative
                    focus:isolation-isolate
                    hover:isolation-isolate
                    focus:z-10
                    ${background_class}
                    ${background_active_class}
                `}
                onClick={() => onClick(notification)}
                title="Go to page"
            >
                {children}
            </button>
        );
    }
    return (
        <div
            className={`
                notification-item-card
                flex-1 text-left
                sm:[li:first-child_&]:rounded-tl-lg
                [li:last-child_&]:rounded-bl-lg
                ${!hasUnreadButton || notification.overrides?.persistent ?
                    `sm:[li:first-child_&]:rounded-tr-lg
                    [li:last-child_&]:rounded-br-lg` : ''
                }
                transition-colors duration-150
                px-4 py-6
                ${background_class}
            `}
        >
            {children}
        </div>
    );
}

function NotifcationEnvelopButton({
    read,
    onClick,
    background_class,
    background_active_class,
    envelope_icon_class,
}) {
    return (
        <button
            className={`
                    notification-item-read-button
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500
                    sm:[li:first-child_&]:rounded-tr-lg
                    [li:last-child_&]:rounded-br-lg
                    p-4
                    self-stretch
                    relative
                    focus:isolation-isolate
                    hover:isolation-isolate
                    focus:z-10
                    ${background_class}
                    ${background_active_class}
                `}
            onClick={onClick}
            aria-label={`Mark notification as ${read ? 'unread' : 'read'}`}
            title={`Mark notification as ${read ? 'unread' : 'read'}`}
        >
            <Icon
                name={read ? 'envelope open' : 'envelope'}
                className={`${envelope_icon_class} transform translate-x-0.5`}
                size="large"
                aria-hidden="true"
            />
        </button>
    );
}

function showPinIcon(notification) {
    if (notification.isDynamic) {
        return true;
    }
    return (
        isUnread(notification)
        && (
            notification.priority === 'urgent' ||
            notification.priority === 'high' ||
            notification.overrides?.pinned
        )
    );
}

function isUnread(notification) {
    return !notification.read;
}

export default NotificationItem; 