import React, {useMemo} from 'react'
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
} from "@/components/ui/drawer";
import {cls} from "./utils";

/**
 * StyledDrawer component built on top of vaul shadcn drawer component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Indicates if the Drawer or Modal is open.
 * @param {Function} props.onClose - Function to handle toggling the Drawer or Modal.
 * @param {React.ReactNode} props.title - The header content for the Drawer or Modal.
 * @param {React.ReactNode} props.description - The header content for the Drawer or Modal.
 * @param {React.ReactNode} props.footer - The footer content for the Drawer or Modal.
 * @param {React.ReactNode} props.content - The main content for the Drawer or Modal.
 * @param {string} props.drawerStyle - The type of Drawer or Modal to render.
 * @returns {JSX.Element} The rendered Drawer or Modal component.
 */


const StyledDrawer = ({isOpen, onClose, title, description, footer, content, drawerStyle, dismissible}) => {
    const args = {isOpen, onClose, title, description, footer, content, drawerStyle, dismissible};


    const {bg, text, border, handle} = useMemo(() => {
        switch (drawerStyle) {
            // Blue header
            case 'blue':
                return {
                    bg: 'bg-is-blue',
                    text: '!text-white',
                    border: '!border-is-blue border-t',
                    handle: 'w-[90px] bg-is2-blue-500 mt-6 h-[4px]',
                };
            // White header
            default:
                return {
                    bg: 'bg-white',
                    text: 'text-black',
                    handle: 'w-[90px] bg-muted mt-6 h-[4px]',
                };
        }
    }, [drawerStyle]);

    return (
        <Drawer open={isOpen} onOpenChange={onClose} dismissible={dismissible} repositionInputs={false}>
            <DrawerContent className={cls(['max-w-2xl mx-auto border-none z-[2147483005] max-h-screen', bg])}
                           styles={{handle: cls(handle, dismissible === false && '!h-[0px]')}}
                           handle={true}
            >
                <DrawerHeader className={cls(['p-0 m-0 bg-white'])}>
                    <div className={cls(['pt-4 pb-6 px-6 text-left', bg, text])}>
                        <DrawerTitle className={cls(text, border, 'm-0')}>{title}</DrawerTitle>
                        {description && <DrawerDescription className={cls(text)}>
                            <ConditionalComponent {...args}>
                                {description}
                            </ConditionalComponent>
                        </DrawerDescription>}
                    </div>
                </DrawerHeader>
                <div className={'bg-white p-6 overflow-y-auto'}>
                    <ConditionalComponent {...args}>
                        {content}
                    </ConditionalComponent>
                </div>
                {footer && <DrawerFooter className={'bg-white'}>
                    <ConditionalComponent {...args}>
                        {footer}
                    </ConditionalComponent>
                </DrawerFooter>}
                <div className={'h-[44px] md:h-[62px] !bg-gray-50'}/>
            </DrawerContent>
        </Drawer>
    )
}

/**
 * A conditional component that renders children based on their type.
 * Could extend this functionality and place into a separate file.
 **/

const ConditionalComponent = ({children, ...props}) => {
    if (!children) {
        return null;
    } else if (typeof children === 'string') {
        return <span>{children}</span>;
    } else if (React.isValidElement(children)) {
        return React.cloneElement(children, props);
    } else if (typeof children === 'function') {
        return children(props);

    } else {
        return children;
    }
};


export default StyledDrawer
