import React from "react";
import { Link } from "react-router-dom";
import { Container, Icon } from "semantic-ui-react";

const menu = [
  {
    name: "For Patients",
    link: "/forpatients",
    enabled: () => !!app.settings.icann_avail,
  },
  {
    name: "About Us",
    link: "/aboutus",
      enabled: () => !!app.settings.icann_avail,
  },
  {
    name: "Contact Us",
    link: "#",
  },
  {
    name: "Privacy",
    link: "#",
  },
  ...(app.settings.is_cmg || app.settings.is_cbls) &&
			[
        {
          name: "Terms & Conditions",
          link: "#",
        },
			] || [
        {
          name: "Customer Terms & Conditions",
          link: "#",
        },
        {
          name: "Platform Terms & Conditions",
          link: "#",
        },
      ],
  ...(!app.settings.is_cbls ?
  [{
    name: "FAQ",
    link: "/faqs"
  }] : []),
];

const Footer = () => {
    const target = app.settings.is_cbls && 'cbls' || app.settings.is_cmg && 'cmg' || 'iscr';

    return (
        <div className={`footer py-8 ${app.settings.is_cbls && 'text-white bg-bls-dark-blue' || 'text-white bg-footer-color'}`}>
        <Container fluid={app.settings.is_cmg ? true: false}>
        <div className={`${app.settings.is_cmg ? "text-left" : "text-center"} grid md:grid-cols-2 grid-cols-1 grid-rows-1 auto-rows-max md:text-left`}>
            <div>
            <ul className="space-y-2">
                {menu.filter(link => link.enabled?.() !== false).map(({ name, link }, i) => {
                
                switch (name) {
                  case 'Privacy':
                    return (
                      <li key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Privacy', pn:'privacy-policy'})}>
                      <Link to={link}>{name}</Link>
                      </li>);
                  case 'Terms & Conditions':
                  return (
                    <li key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Terms and Conditions', pn:'terms-and-conditions', target: target})}>
                    <Link to={link}>{name}</Link>
                    </li>);
                  case 'Customer Terms & Conditions':
                    return (
                      <li key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Customer Terms and Conditions', pn:'customer-terms-and-conditions'})}>
                      <Link to={link}>{name}</Link>
                      </li>);
                  case 'Platform Terms & Conditions':
                  return (
                    <li key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Platform Terms and Conditions', pn:'platform-terms-and-conditions'})}>
                    <Link to={link}>{name}</Link>
                    </li>);
                  case 'Contact Us':
                    return (
                      <li key={name + i} onClick={()=>app.trigger(app.events.SHOW_POPUP, {
                        "tp": "pu",
                        "pt": "contact",
                        "txt": "Contact Us",
                        "u": "contact-us",
                        "pn": "contact-us",
                        "target": target
                      })}>
                      <Link to={link}>{name}</Link>
                      </li>);
                  default:
                    return (
                      <li key={name + i}>
                      <Link to={link}>{name}</Link>
                      </li>);
                }})}
            </ul>
            </div>
            <div className='flex md:justify-end justify-center py-auto'>
            <div className={`${app.settings.is_cmg ? 'md:w-4/5' : 'w-48'} my-0 pt-4 md:pt-0`}>
                <img src={app.runtime.sidemenuLogo}/>
               {!app.settings.is_cbls && !app.settings.is_cmg && <img
                src="/images/logos/instant-scripts-logo-IS2-white.svg"
                alt="InstantScripts"
                className="h-16 mx-auto my-4"
                />}
                {!app.settings.is_cmg &&<p className="pt-4 w-max mx-auto">
                © {new Date().getFullYear()} {app.runtime.name}
                </p>}
                {app.settings.is_cmg && <><p className="pt-4 sm:w-[50%] md:w-full" style={{fontSize: '.8rem'}}>
                 Haiku Health respectfully acknowledges the Traditional
                 Custodians of the land on which we work. We pay our respects
                 to the local people across our operations which include the
                 Wadawurrung and the Wurundjeri people of the Kulin nations and
                 to their elders past, present and future—and extend that respect
                 to all First Nations people today. We recognise their continuing
                 connection to the land, waters and culture and community
                 and thank them for protecting this nation and its ecosystems
                 since time immemorial.
                </p>
              </>}
            </div>
            </div>
            <div className="pt-8">
            <div className={`${app.settings.is_cmg && "uppercase md:pt-20" } "pt-4 text-sm`}>
                {app.settings.is_cmg &&<>© {new Date().getFullYear()} </>}
                {(app.dvc.org && <b>{app.dvc.org.name}<br/></b>) || null}
                {(app.runtime.org && <i>{app.runtime.org.name}<br/></i>) || null}
                Build: {__GIT_HASH__} - {__BUILD__}
                {(app.dev_env && " - dev") || (app.test_env && " - test") || ""}
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                <Icon
                compact="true"
                name="refresh"
                size="small"
                circular
                onClick={(_) => location.reload(true)}
                style={{ cursor: "pointer" }}
                />
            </div>
            </div>
        </div>
        </Container>
        </div>
    );
};

export default Footer;
