import React from "react";
import { Button } from "semantic-ui-react";
import StyledDrawer from "@/views/NUI/StyledDrawer";
import { useDisclosure } from "@/xAppLib/Hooks/useDisclosure";
import { ComplexField } from "@/views/ordering/components/ComplexField";
import UniFieldSUI from "../../../../xAppLib/UniForm/UniField-SUI";
import { RadioGroup } from "../../../../xAppLib/Users/Account/UserSignUpAccountDetails";

export function ProfileFieldDrawer({onChange, fl, fields, values, valids, edit, uf_this}) {

	const disclosure = useDisclosure();
	const details = fields.filter((f) => values[f.name]?.length)
	const displayValue = details && ((fl.name === "has_dva_card" && values.dva?.number ) && `${values.dva.number} ${values.dva.colour}` || fl.name === "has_em_con" && <>{values?.em_con_name ||''}<br/> {values?.em_con_mobile||''}</> || values[details[0]?.name]) ||'';

	return (
		<>
            {details.length ?
				<ComplexField {...fields} value={displayValue} label={fl?.label} onEdit={disclosure.onOpen}/>  
				:
				<RadioGroup {...fl} value={values[fl.name]} onChange={value => {
					uf_this.handleInputChange({ target: { name: fl?.name, value }})
					value === "yes" && disclosure.onOpen()
				}}/>
			}

			{disclosure.isOpen && (
				<FieldDrawer 
                    values={values}
                    onChange={()=> {
                        disclosure.onClose();
                    }}
                    onCancel={() => {
						!values[fields[0].name] && uf_this.handleInputChange({ target: { name: fl.name, value: "no" } })
						disclosure.onClose()
					}}
                    label={fl?.label}
                    uf_this={uf_this}
					fl={fields}
					valids={valids}
					edit={edit}
				/>
                )
			}
		</>
	);
}

function FieldDrawer({values, label, title = label, fl, onChange, onCancel, edit, uf_this, valids}) {
    const args = {edit, uf_this, values, valids}

	return (
		<StyledDrawer
			isOpen={true}
			drawerStyle="blue"
			dismissible={false}
			title={title}
			description=""
			content={
					<div className="space-y-6 my-4">
						{fl.map(f => <UniFieldSUI display_errors fl={f} {...args} value={values[f.name]}/>)}
					</div>
			}
			footer={(
				<div className="flex flex-row space-x-3 items-stretch">
					<Button type="button" fluid basic onClick={onCancel}>
						Cancel
					</Button>

					<Button type="button" fluid primary disabled={false} onClick={onChange}>
						Save
					</Button>
				</div>
			)}
		/>
	);
}