import React, {useCallback, useReducer, useState} from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import { TextField } from '../../../views/UIelems/v2/Form/TextField'
import { validateAuthFields } from 'helpers/signupValidation';
import SIcon from 'views/NUI/StreamlineIcons/SIcon';
import { NotificationBar } from 'views/UIelems/v2/notificationBar/NotificationBar';
import TermsLink from "@/views/UIelems/TermsLinks";

const UserSignUpAccount = ({ onSubmit, loading, authError }) => {
    const [state, dispatch] = useReducer(reducer, initialState());
    const [errors, setErrors] = useState({});
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const scrollToError = (errors) => {
        const firstErrorField = Object.keys(errors)[0];
        const errorElement = document.querySelector(`[name="${firstErrorField}"]`);

        if (errorElement) {
            const offset = 100;
            const elementPosition = errorElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });

            errorElement.focus();
        }
    };

    const clearError = (fieldName) => {
        setErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors[fieldName];
            return newErrors;
        });
    };

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const formErrors = validateAuthFields(state);
        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            if (typeof onSubmit === 'function') {
                onSubmit({
                    provider: 'email',
                    ...state,
                    consent: state.consent
                });
            }
        } else {
            scrollToError(formErrors);
        }
    };

    return (
        <Form
            className="w-full flex flex-col gap-2 md:gap-4 lg:mt-9 lg:mb-[116px] lg:rounded"
            onSubmit={handleOnSubmit}
            noValidate
        >
            <div className="w-full max-w-screen-md mx-auto sm:px-4">
                <div className="bg-white pt-10 pb-6 px-6 max-w-screen-md lg:rounded">
                    <CreateAccount state={state}
                        dispatch={dispatch}
                        errors={errors}
                        clearError={clearError}
                        showPasswordFields={showPasswordFields}
                        setShowPasswordFields={setShowPasswordFields}
                        passwordVisible={passwordVisible}
                        setPasswordVisible={setPasswordVisible}
                        confirmPasswordVisible={confirmPasswordVisible}
                        setConfirmPasswordVisible={setConfirmPasswordVisible}
                    />
                </div>
                {authError && (
                    <div className="mb-2">
                        <NotificationBar type="negative" text={authError.message} className={"text-center"} />
                    </div>
                )}
                <div className="bg-white py-8 px-6 max-w-screen-md">
                    <Button primary fluid type="submit" loading={loading} disabled={loading}>
                        Continue
                    </Button>
                </div>
            </div>
        </Form>
    )
};

export default UserSignUpAccount;

function CreateAccount({ state, dispatch, errors, clearError, showPasswordFields, setShowPasswordFields, passwordVisible, setPasswordVisible, confirmPasswordVisible, setConfirmPasswordVisible }) {
    return (
        <div className="flex flex-col gap-3">
            <h2 className="m-0 font-bold pb-2">Account Details</h2>
            <div>
                <label className="!mb-4">Log in details:</label>
                <Form.Field className="!mb-2 !pb-0.5">
                    <TextField
                        label="Email "
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        aria-label="Email"
                        fluid={true}
                        value={state.email}
                        required
                        onChange={e => {
                            dispatch({ type: 'SET_EMAIL', payload: e.target.value });
                            if (e.target.value.trim()) clearError('email');
                        }}
                        autoFocus
                        error={errors.email}
                    />
                </Form.Field>
                <Form.Field className="!mb-5">
                    <TextField
                        label="Password "
                        id="password"
                        name="password"
                        type={passwordVisible ? "text" : "password"}
                        placeholder="Enter password"
                        aria-label="Password"
                        fluid={true}
                        value={state.password}
                        required
                        onChange={e => {
                            const password = e.target.value;
                            dispatch({ type: 'SET_PASSWORD', payload: password });
                            if (password.trim()) {
                                clearError('password');
                                setShowPasswordFields(true);
                            } else {
                                setShowPasswordFields(false);
                            }
                        }}
                        error={errors.password}
                    />
                    <button
                        onClick={(event) => {
                            event.preventDefault();
                            setPasswordVisible(!passwordVisible);
                        }}
                        className="absolute right-4 top-2.5 cursor-pointer z-10"
                    >
                        <SIcon name={passwordVisible ? "eye" : "eye"} size="sm" svg_cls='' cls="text-is2-gray-150 pr-3 mr-2" bold />
                    </button>
                </Form.Field>
                {showPasswordFields && (
                    <>
                        <div className="text-is2-gray-150 text-sm m-0 p-0 mb-4">
                            Password must be at least 8 characters, contain a number and letter.
                        </div>
                        <Form.Field className="!mb-5">
                            <TextField
                                label="Confirm Password "
                                id="confirmPassword"
                                name="confirmPassword"
                                type={confirmPasswordVisible ? "text" : "password"}
                                placeholder="Confirm Password"
                                aria-label="Confirm Password"
                                fluid={true}
                                value={state.confirmPassword}
                                required
                                onChange={e => {
                                    dispatch({ type: 'SET_CONFIRM_PASSWORD', payload: e.target.value });
                                    if (e.target.value.trim()) clearError('confirmPassword');
                                }}
                                error={errors.confirmPassword}
                            />
                            <button
                                onClick={(event) => {
                                    event.preventDefault();
                                    setConfirmPasswordVisible(!confirmPasswordVisible);
                                }}
                                className="absolute right-4 top-2.5 cursor-pointer z-10"
                            >
                                <SIcon name={confirmPasswordVisible ? "eye" : "eye"} size="sm" svg_cls='' cls="text-is2-gray-150 pr-3 mr-2" bold />
                            </button>
                        </Form.Field>
                    </>
                )}
            </div>
            <div>
                <label className="!mb-4">Your details:</label>
                <Form.Field className="!mb-2 !pb-0.5">
                    <TextField
                        label="First Name "
                        name="firstName"
                        placeholder=""
                        onChange={e => {
                            dispatch({ type: 'SET_FIRST_NAME', payload: e.target.value });
                            if (e.target.value.trim()) clearError('firstName');
                        }}
                        required
                        fluid
                        value={state.firstName}
                        error={errors.firstName}
                    />
                </Form.Field>
                <Form.Field className="!mb-5">
                    <TextField
                        label="Last Name "
                        name="lastName"
                        onChange={e => {
                            dispatch({ type: 'SET_LAST_NAME', payload: e.target.value });
                            if (e.target.value.trim()) clearError('lastName');
                        }}
                        required
                        value={state.lastName}
                        fluid
                        error={errors.lastName}
                    />
                </Form.Field>
            </div>
            <label className="!-mb-2">Privacy</label>
            <div className="flex flex-row items-center gap-1 p-1 h-[40px]">
                <Checkbox
                    id="consent"
                    name="consent"
                    type="checkbox"
                    className="!inline-block"
                    aria-label="Consent Checkbox"
                    checked={state.consent}
                    onChange={e => {
                        const isChecked = e.target.checked;
                        dispatch({ type: 'SET_CONSENT_STATUS', payload: isChecked });
                        clearError('consent');
                    }}
                    required
                />
                {/*This margin offset isn't ideal but will have to do for now*/}
                <label htmlFor="consent" className="text-xs xl:text-sm pl-5 pt-3 !mt-14 sm:!mt-11 md:!mt-4 xl:!mt-8">
                    I have read and accept the
                    <TermsLink type={'privacy-policy'} label={' InstantScripts Privacy Policy, '}/>
                    <TermsLink type={'platform-terms-and-conditions'} label={'Platform Terms and Conditions, '}/>
                    <TermsLink type={'customer-terms-and-conditions'} label={'Customer Terms and Conditions.'}/>
                    You will be added to our subscriber list, you can unsubscribe at any time.
                </label>
            </div>
            <div className="text-red-600 text-xs xl:text-sm">
                {errors.consent}
            </div>
        </div>
    )
}

function initialState() {
    return {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        consent: false
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIRST_NAME':
            return { ...state, firstName: action.payload };
        case 'SET_LAST_NAME':
            return { ...state, lastName: action.payload };
        case 'SET_EMAIL':
            return { ...state, email: action.payload };
        case 'SET_PASSWORD':
            return { ...state, password: action.payload };
        case 'SET_CONFIRM_PASSWORD':
            return { ...state, confirmPassword: action.payload };
        case 'SET_CONSENT_STATUS':
            return { ...state, consent: action.payload };
        default:
            return state;
    }
}