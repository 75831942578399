import { useSubscription } from "xAppLib/Hooks/useSubscription";
import instcons_global_model from "models/instcons_global_model";
import { from_melb_ui_tm } from "@/helpers/datetime";
import moment from "moment";
import scripts_list_model from "@/models/scripts_list_model";
import { useMemo } from "react";

export function useOrderProgress(sid) {
    const { loading, error, data } = useSubscription({
        fn: (ca, ce) => instcons_global_model.watch_record(sid, ca, { ce }),
    }, [sid]);

    const processedData = useMemo(() => {
        if (!data) return null;

        const start = from_melb_ui_tm(data.add_tm) || moment();
        const eta_minutes = data.eta ?? app.site_status?.wr_stat?.prediction?.data?.pc_98_wait;
        const elapsed = moment().diff(start, 'minutes');
        const rawProgress = (elapsed / eta_minutes) * 100;
        
        const progress = elapsed < 0 ? 30 
            : elapsed >= eta_minutes ? 90 
            : Math.min(90, Math.max(30, rawProgress));

        return {
            ...data,
            progress_eta: {
                min: Math.max(0, eta_minutes ? eta_minutes - 10 : 20),
                max: eta_minutes,
                time: moment().add(eta_minutes, 'minutes').format('h:mma')
            },
            status_header: scripts_list_model.stats_dict(data.status),
            progress
        };
    }, [data]);

    return { loading, error, data: processedData };
} 