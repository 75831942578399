import React, { useEffect, useState } from "react";
import { TabComponent } from "../tabs/TabComponent";
import ServicesMenu from "./ServicesMenu";
import ConditionsMenu from "./ConditionsMenu";

export default function ServiceAndConditionsMenu({ onMenuChange, showMenu }) {
  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    { id: 1, label: "Services" },
    { id: 2, label: "Conditions" },
  ];

  useEffect(() => {
    app.on(app.events.SIDEMENU, ({view}) => setActiveTab(view === 'Conditions' ? 2 : 1));
	}, [])

  return (
    <section className="bg-white pt-6 pb-[60px] px-6 lg:py-9 lg:px-16">
      <TabComponent tabs={tabs} onTabChange={setActiveTab} active={activeTab} />

      {activeTab === 1 && <ServicesMenu onMenuChange={onMenuChange} />}
      {activeTab === 2 && <ConditionsMenu onMenuChange={onMenuChange} showMenu={showMenu} />}
    </section>
  );
}
