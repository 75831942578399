import React, {Fragment, useState} from 'react';
import { Message, Form, Grid, Button, Divider } from 'semantic-ui-react'
import user_model from 'models/user_model'
import UniForm from 'xAppLib/UniForm/UniForm'
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";
import ChroCondField from "xAppLib/UniForm/ChroCondField";
import CheckboxField from '../UniForm/CheckboxField';
import YogpField from '../UniForm/YogpField';
import Alert from "views/NUI/Alert";
import MedicareToolTip from '../../views/UIelems/MedicareToolTip';
import obj_diff from '../helpers/obj_diff';
import { Allergies } from '../../views/ordering/components/Allergies';
import { ChronicConditions } from '../../views/ordering/components/ChronicConditions';
import { MedicalHistory } from '../../views/ordering/components/MedicalHistory';
import { ProfileFieldDrawer } from '../../views/UIelems/v2/Form/ProfileFieldDrawer';
import { NotificationBar } from '../../views/UIelems/v2/notificationBar/NotificationBar';

function MaybeEdit({fl, values, valids, uf_this, edit}) {
	const args = {values, valids, uf_this }

	if (!fl)
		return null

	let value = values[fl.name]
	if (fl.name == "full_address") {
		if (value && value.formatted)
			value = value.formatted
		else
			value = ""
	}

	const missing = valids && !valids?.[fl.name] 

	if (edit)
		return <UniFieldSUI display_errors fl={fl} {...args} values={values} />

	if (fl.name == "dva") {
		value = value?.number && `${value.number} ${value.colour}`
	}

	if (fl.type=='select')
		value = fl.options.find(o=>o.value==value)?.text || value

	return <Form.Field error={missing}>
		<label>{fl.label}</label>
		<p className={missing?'text error':'text grey'} data-testid={`input-${fl.name}`}>{value || ''}</p>
	</Form.Field> 

}


const ProfileEdit = ({data = {}, edit = true, shouldInlineEdit = false, onUpdate, onCancel, step, fields, nextStep, btnSave = 'Save', btnSaveAs : BtnSaveAs = Button, btnCancel = 'Cancel', requireAddress=true, showErrors = false, need_ihi, profile}) => {
	edit = edit || shouldInlineEdit;
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	const [values, setValues] = useState(null);

	const flds = (fields ||
					(data.parent
						? user_model.PROF_FIELDS_MY_CHILD
						: user_model.PROF_FIELDS_MY)).map(f=>({...f}))
	if (!fields) {
		const address = flds.find(f=>f.name=="full_address")
		if (address)
			address.valid_not_required = !requireAddress
	
		if (need_ihi) {
			flds.find(f=>f.name=="medicare").valid_not_required = false
			flds.find(f=>f.name=="dob").valid_not_required = false
		}
	}

	return (
		<UniForm
			key={data.uid}
			name="ProfileEdit"
			onActForm={async (p) => {
				if (app.runtime?.login_type=='popup' && step == 'signup') {
					// setValues(p);
					// nextStep?.();
				}
				else 	setError(null);

				setSaving(true);
				// const res = await onUpdate?.( app.runtime?.login_type=='popup' && step == 'signup_step_2' ? values : p )
				const res = await onUpdate?.( p )
				setSaving(false);
				if (res?.err_msg) setError(res.err_msg);
			}}
			defaultValues={data}
			render_children={true}
			fields={flds}
			showErrors={showErrors}
			onChange={(name, value) => {

				if (name === 'cons_has_allergies' && value === 'no') {
					return { cons_allergy: '' };
				}
				if (name === 'cons_has_meds') {
					return { cons_meds: value || '' };
				}
				if (name === 'cons_has_medhist') {
					return { cons_medhist: value || '' };
				}
			}}
		>
			{(values, valids, uf_this, fields) => {

				const field_args = {values,valids, uf_this,edit}
				// const data_diff = obj_diff(data, values)
					
				return (

					(app.settings.iscr2 && profile) ?
					<>
						{Object.values(fields).map(f => <Fragment key={f.name}>
							{f?.header &&<>
								<Divider />
								<h2>{f.header}</h2>
							</>}
							
							<div className="wrapper mb-9">
								{f.name === "cons_allergy" && <Allergies {...f} value={values.cons_allergy} onChange={val => uf_this.handleInputChange({target: {name: f.name, value: val}}) }/>
								|| f.name === "chro_cond" && <ChronicConditions {...f} value={values.chro_cond} onChange={val => uf_this.handleInputChange({target: {name: f.name, value: val}}) }/>
								|| f.name === "cons_medhist" && <MedicalHistory {...f} value={values.cons_medhist} onChange={val => {uf_this.handleInputChange({target: {name: f.name, value: val}})}}/>
								|| f.name === "has_em_con" && <ProfileFieldDrawer fl={fields.has_em_con} fields={[fields.em_con_name, fields.em_con_mobile, fields.em_con_rel]} {...field_args} /> 
								|| f.name === "has_conc_card" && <ProfileFieldDrawer fl={fields.has_conc_card} fields={[fields.conc_card]} {...field_args} />
								|| f.name === "has_dva_card" && <ProfileFieldDrawer fl={fields.has_dva_card} fields={[fields.dva]} {...field_args} />
								|| !f.showif && <MaybeEdit fl={f} {...field_args} />
								}
							</div>
						</Fragment>)}

						<Divider />
						<div className="flex gap-2 mb-6">
							<Button
								loading={saving}
								disabled={saving}
								primary
								type="submit"
								fluid
								onClick={ _=> uf_this.actForm()}
							>
								{btnSave}
							</Button>
							<Button
								fluid
								basic
								disabled={saving}
								type="reset"
								onClick={_=> {
									uf_this.reset(data)
									onCancel()
								}
							}
							>
								Cancel
							</Button>
						</div>

						{((valids && !Object.values(valids).every(Boolean)) || error) && <NotificationBar type="negative" text={error ? "We couldn't save this profile." : "Some information is missing or invalid. Please check the highlighted fields."} className="text-center" />}
					</>
					:
					<>
						{error && (
							<Message negative>
								<Message.Header>
									We couldn't save this profile.
								</Message.Header>
								<p>{error}</p>
							</Message>
						)}
						{/*!edit && values.desc && (
							<Form.Field>
								<br />
								<label>
									<span className="text green">
										Profile: {values.desc}
									</span>
								</label>
							</Form.Field>
						)*/}

						<Grid verticalAlign="top" stackable className='profile'>
							{edit && data.parent && (
								<Grid.Row>
									<Grid.Column width={16}>
										<MaybeEdit
											fl={fields.desc}
											{...field_args}
										/>
									</Grid.Column>
								</Grid.Row>
							)}

							{edit && !fields.email?.read_only && (
								<Grid.Row>
									<Grid.Column width={16}>
										<MaybeEdit
											fl={fields.email}
											{...field_args}
										/>
									</Grid.Column>
								</Grid.Row>
							)}

							<Grid.Row>
								{edit && <Grid.Column width={16}>
									<Alert warning>
										<p>
											Please enter your details exactly as they appear on your Medicare Card.
											<MedicareToolTip type='name' position='top right' />
										</p>
									</Alert>
								</Grid.Column>}
								<Grid.Column width={edit ? 8 : 6}>
									<MaybeEdit
										fl={fields.first_name}
										{...field_args}
									/>
								</Grid.Column>
								<Grid.Column width={edit ? 8 : 6}>
									<MaybeEdit
										fl={fields.last_name}
										{...field_args}
									/>
								</Grid.Column>
								{!edit && <Grid.Column width={4}>
									<MaybeEdit
										fl={fields.dob}
										{...field_args}
									/>
								</Grid.Column>}
							</Grid.Row>
							<Grid.Row>
								{edit && <Grid.Column width={8}>
									<MaybeEdit
										fl={fields.dob}
										{...field_args}
									/>
								</Grid.Column>}
								<Grid.Column width={edit ? 8 : 6}>
									<MaybeEdit
										fl={fields.mobile}
										{...field_args}
									/>
								</Grid.Column>
								{!edit && <Grid.Column width={6}>
									<MaybeEdit
										fl={fields.sex}
										{...field_args}
									/>
								</Grid.Column>}
							</Grid.Row>

							{/* <Grid.Row>
								<Grid.Column width={16}>
									<MaybeEdit fl={fields.email} values={values} valids={valids} uf_this={uf_this} edit={edit}/>
								</Grid.Column>
							</Grid.Row> */}
							{!app.runtime?.login_type=='popup' ? 
								<>
									{edit && <Grid.Row>	
											<Grid.Column width={9}>
												<MaybeEdit
													fl={fields.sex}
													{...field_args}
												/>
											</Grid.Column>
										</Grid.Row>
									}
									<Grid.Row>
										<Grid.Column width={9}>
											<MaybeEdit
												fl={fields.atsi}
												{...field_args}
											/>
										</Grid.Column>
									</Grid.Row>
								</>
								:
								<Grid.Row>
									{edit && <Grid.Column width={8}>
											<MaybeEdit
												fl={fields.sex}
												{...field_args}
											/>
										</Grid.Column>
									}
									<Grid.Column width={8}>
										<MaybeEdit
											fl={fields.atsi}
											{...field_args}
										/>
									</Grid.Column>
								</Grid.Row>
							}

							<Grid.Row>
								<Grid.Column width={edit ? 16 : 10}>
									<MaybeEdit
										fl={fields.full_address}
										{...field_args}
									/>
								</Grid.Column>
							</Grid.Row>

							{edit && <Divider hidden={!app.settings.iscr2} />}

							<Grid.Row>
								<Grid.Column width={edit ? 16 : 6}>
									<MaybeEdit
										fl={fields.medicare}
										{...field_args}
									/>
								</Grid.Column>
								{!edit && <Grid.Column width={6}>
									<MaybeEdit
										fl={fields.conc_card}
										{...field_args}
									/>
								</Grid.Column>}
							</Grid.Row>

							{edit && <Divider hidden={!app.settings.iscr2} />}

							{edit && <Grid.Row>
								{app.settings.iscr2 && edit && <><Grid.Column width={16}>
									<label className="section-label">{fields.conc_card?.header}</label>
								</Grid.Column></>}
								<Grid.Column width={16}>
									<MaybeEdit
										fl={fields.conc_card}
										{...field_args}
									/>
								</Grid.Column>
							</Grid.Row>}
							<Grid.Row>
								<Grid.Column width={16}>
									<MaybeEdit
										fl={fields.dva}
										{...field_args}
									/>
								</Grid.Column>
							</Grid.Row>

							{(fields.em_con_name || fields.em_con_mobile || fields.em_con_rel) && <>
								{edit && <Divider hidden={!app.settings.iscr2} />}
								<Grid.Row>
									{app.settings.iscr2 && edit && <Grid.Column width={16}>
										<label className="section-label">{fields.em_con_name?.header}</label>
									</Grid.Column>}
								
									<Grid.Column width={8}>
										<MaybeEdit
												fl={fields.em_con_name}
												{...field_args}
											/>
									</Grid.Column>
									<Grid.Column width={8}>
										<MaybeEdit
												fl={fields.em_con_mobile}
												{...field_args}
											/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										<MaybeEdit
												fl={fields.em_con_rel}
												{...field_args}
											/>
										{app.settings.iscr2 && <Divider />}
									</Grid.Column>
								</Grid.Row>
							</>}

							{edit && <Divider hidden />}
							

							{fields.password && (
								<>
									<Grid.Row>
										<Grid.Column width={16}>
											<MaybeEdit
												fl={fields.password}
												{...field_args}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={16}>
											<MaybeEdit
												fl={fields.verify_password}
												{...field_args}
											/>
										</Grid.Column>
									</Grid.Row>
								</>
							)}

						{fields.cons_has_allergies && (
								<Grid.Row>
									{edit && <Grid.Column width={16} style={{ marginBottom: 8 }} ><p>{fields.cons_allergy.header}</p></Grid.Column>}

									{edit && fields.cons_allergy && values.cons_has_allergies !== 'no' && (
										<Grid.Column width={16} style={{ marginBottom: 8 }}>
											<MaybeEdit fl={fields.cons_allergy} {...field_args} />
										</Grid.Column>
									)}

									<Grid.Column width={16}>
										{edit && <MaybeEdit fl={fields.cons_has_allergies} {...field_args} />}
										{!edit && <CheckboxField title="Allergies" value={values.cons_allergy} checked={values.cons_has_allergies}  />}
									</Grid.Column>
								</Grid.Row>
							)}

						{fields.cons_has_meds && (
								<Grid.Row>
									{edit && <Grid.Column width={16} style={{ marginBottom: 8 }} ><p>{fields.cons_meds.header}</p></Grid.Column>}

									{edit && fields.cons_meds && values.cons_has_meds !== 'no' && (
										<Grid.Column width={16} style={{ marginBottom: 8 }}>
											<MaybeEdit fl={fields.cons_meds} {...field_args} />
										</Grid.Column>
									)}

									<Grid.Column width={16}>
										{edit && <MaybeEdit fl={fields.cons_has_meds} {...field_args} />}
										{!edit && <CheckboxField title="Medications" value={values.cons_meds} />}
									</Grid.Column>
								</Grid.Row>
							)}

						{fields.cons_has_medhist && (
								<Grid.Row>
									{edit && <Grid.Column width={16} style={{ marginBottom: 8 }} ><p>{fields.cons_medhist.header}</p></Grid.Column>}

									{edit && fields.cons_medhist && values.cons_has_medhist !== 'no' && (
										<Grid.Column width={16} style={{ marginTop: 8 }}>
											<MaybeEdit fl={fields.cons_medhist} {...field_args} />
										</Grid.Column>
									)}

									<Grid.Column width={16}>
										{edit && <MaybeEdit fl={fields.cons_has_medhist} {...field_args} />}
										{!edit && <CheckboxField title="Medical History" value={values.cons_medhist} />}
									</Grid.Column>

								</Grid.Row>
							)}

							{fields.chro_cond && <Grid.Row>
									<Grid.Column width={16}>
										<ChroCondField {...field_args} />
									</Grid.Column>
								</Grid.Row>}

							{fields.consent && <Grid.Row>
								<Grid.Column width={16}>
									<MaybeEdit
										fl={fields.consent}
										{...field_args}
									/>
								</Grid.Column>
							</Grid.Row>}

							{fields.yogp && (
								<Grid.Row style={{ marginTop: 0, marginBottom: 16 }}>
									<Grid.Column width={16}>
										<YogpField {...field_args} renderSaveForLater={false} />
									</Grid.Column>
								</Grid.Row>
							)}

							{edit && !shouldInlineEdit && (btnSave || btnCancel) && (
								<Grid.Row>
									<Grid.Column width={16} className="buttons">
										{btnSave && (
											<BtnSaveAs
												loading={saving}
												disabled={(btnSave === 'Update Profile' && !app.user?.user_in) ? true : saving}
												primary
												style={{marginRight: '.5rem'}}
												type={app.runtime?.login_type=='popup' && step == 'signup' ? "button" : "submit"}
												data-testid='button-save'
												onClick={ _=> uf_this.actForm() }
											>
												{btnSave}
											</BtnSaveAs>
										)}
										{btnCancel && (
											<Button
												disabled={saving}
												onClick={onCancel}
											>
												{btnCancel}
											</Button>
										)}
									</Grid.Column>
								</Grid.Row>
							)}

						{shouldInlineEdit && (
								<Grid.Row>
									<Grid.Column width={16}>
										<Button type="button" fluid primary onClick={() => uf_this.actForm()} loading={saving} disabled={Object.values(valids).some((v) => !v)}>
											Save
										</Button>
									</Grid.Column>
								</Grid.Row>
							)}
						</Grid>
					</>
				);
			}}
		</UniForm>
	);

			
}
 



export default ProfileEdit;