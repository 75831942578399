import React from 'react';
import { XIcon } from '@heroicons/react/solid'
import { cls } from './utils';
import SIcon from './StreamlineIcons/SIcon';

const Alert = ({ warning, visible = true, error, success, info, afterHr, wait, ext, doc, header, content, list, className, testId = null, children, onDismiss, basic, bg, hideIcon}) => {
    
    if (!visible)
        return null
    const background =  bg && bg
                || warning && 'bg-yellow-50'
                || error && 'bg-red-50'
                || (success || wait) && 'bg-is-green-20'
                || afterHr && 'bg-red-100'
                || (info || ext || doc) && 'bg-is-blue-20'
                || 'bg-gray-100'
    const color =  'text-black'
    const iconColor = warning && 'text-yellow-400'
                    || error && 'text-red-400'
                    || success && 'text-green-400'
                    || afterHr && 'text-red-100 fill-red-100'
                    || (info || ext) && 'text-is-blue fill-is-blue'
                    || 'text-is-black'
    const icon_name = error && 'road-sign-warning-bold'
        || (warning || afterHr) && 'alert-circle'
        || success && 'check-circle-bold'
        || wait && 'clock'
        || ext && 'external'
        || doc && 'doc-steth'
        || info && 'info-circle'

    return ( 
        <div className={cls("flex msg gap-2 w-full p-4 justify-start rounded-lg", background, color, className, !afterHr && "mb-4")} data-testid={testId}>

                {icon_name && !hideIcon && <SIcon name={icon_name} size="xs" cls={iconColor} bold/>}
                
                <div className="w-full relative">
                    {header && <h5>{header}</h5>}
                    {/*<p className="font-bold whitespace-pre-wrap">{header}</p>*/}
                    <>
                        {onDismiss && <>
                            <div className="-mx-1.5 -my-1.5 absolute right-0 top-0">
                                <button
                                    type="button"
                                    onClick={() => onDismiss()}
                                    className={cls("inline-flex rounded-md p-1.5  focus:outline-none focus:ring-2 focus:ring-offset-2", color)}
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <XIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </>}
                        
                        {(list || content || !!children) && <div className="content">
                            {list && <ul role="list" className={cls(" list-disc pl-5 space-y-1 text-sm")}>
                                {list.map((l, i) => <li key={i}>{l}</li>)}
                            </ul>}
                            {content}
                            {children}
                        </div>}
                    </>
                </div>
          
        </div>
        
     );
}
 
export default Alert;