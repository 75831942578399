import { GuardAction, useGuardAction } from "@/xAppLib/UniForm/FormGuardActions";
import React, { useState } from "react";
import { Form, Popup, Icon, Button, Label, Divider, List } from "semantic-ui-react";
import Photos from "xAppLib/Photos/Photos";
import script_model from "models/script_model";
import UniFormMed from "views/med/UniFormMed";
import { obj_map } from "@/xAppLib/helpers/obj_map";
import { cls } from "@/views/NUI/utils";

function boldify(text) {
	return text.split('**').map((t, i) => i % 2
		? <strong key={i}>{t}</strong>
		: <React.Fragment key={i}>{t}</React.Fragment>
	);
}

function determine_question_visibility(questions, answers) {
	const visible = {};
	function is_looping(key, seen = []) {
		if (seen.includes(key)) return false;

		const qs = questions[key]?.config?.cond?.qs || [];
		for (const q of qs) {
			if (is_looping(q.q, seen.concat(key))) return true;
		}

		return false;
	}

	function is_visible(key) {
		if (key in visible) return visible[key];

		const question = questions[key];
		const qs = question.config?.cond?.qs || [];
		const sticky = question.config?.cond?.sticky;

		if (!qs.length) return true;

		for (const q of qs) {
			// checks for misconfiguration, if anything wrong, default to showing the question
			// no parent q&a, either not conditional visibility or not configured correctly
			if (!(q.q && q.a?.length > 0)) return true;
			// dependent question or answer doesn't exist?
			if (!(q.a.some(a => questions[q.q]?.a?.[a]))) return true;

			// once a "sticky" question is visible, it stays visible regardless of the dependent question's answer
			if (typeof answers[key] !== 'undefined' && sticky) return true;

			// our dependant question isn't visible, so neither are we
			if (!is_visible(q.q)) return false

			// finally, display if dependent question's answer is what we're expecting
			if (q.a.includes(answers[q.q])) return true;
		}

		return false;
	}

	for (const k in questions) {
		// if the questions have been configured in a loop, just show them all, somebody messed up
		visible[k] = is_looping(k) || is_visible(k);
	}

	return visible;
}

const MedicalConsultation = (props) => {
	const { med_data, script_type, req_type, qs, qs_sorted, Section, formData, divided, singleAsCheckbox } = props
	const title = props.title ?? 'Digital medical consultation'
	const numbered = props.numbered ?? true;

	const [visibility, setVisibility] = useState(() => determine_question_visibility(qs, formData));
	const fields = React.useMemo(() => ( script_type!='pthl-needlestick' && script_model.prep_radio_form_fields(qs_sorted, numbered) || [] ),
		[med_data, script_type, req_type, qs_sorted, numbered]
	);
	const applicableQs = React.useMemo(() => fields.filter(f => visibility[f.name] !== false), [fields, visibility]);

	const [action, setAction] = useGuardAction()

	if (qs_sorted.length==0)
		return null

	return (
		<UniFormMed
			{...props}
			section="consultation"
			fields={applicableQs}
			onChange={(target, value, values) => {
				const res = props.onChange(target, value, values);

				const vis = determine_question_visibility(qs, values);
				setVisibility(vis);

				// Setting to undefined takes the answer out of the form
				// Setting as null ensures a sticky answer is kept in the form even if it would normally be hidden.
				const answers_to_keep = obj_map(vis, (visible, k) => visible ? (typeof values[k] !== 'undefined' ? values[k] : null) : undefined);

				return {
					...res,
					...answers_to_keep
				};
			}}
			onAction={setAction}
		>
			{(values, valids, uf_this, fields) => {

				const handleChange = async (name, value) => uf_this.handleInputChange({target: {name, value}});

				return <Section>
						{action && <GuardAction action={action}/>}
						{title && <Section.Header>{title}</Section.Header>}
						<Section.Content>
						{applicableQs.filter(f => f.type !== 'hidden').map((f, qnum) => {
								const k = f.name
								const q = qs[k];
								const config = qs[k].config || {}
								const choice_field = fields[`${k}_choice`];
								const choices = Object.entries(choice_field && values[choice_field.name] || {});

								return <div key={"form_radio_q_"+k} className="py-2">
									{divided && qnum > 0 && <Divider/>}
									<Form.Group
										grouped
										required
										className={q.c}
										data-testid="question-group"
									>
									<label>
										<h4 className={cls(valids && !valids[k] && 'text error', 'mb-6')}>
											{numbered && <span>{qnum+1}. </span>}
											<span data-testid='question-text'>{q.txt}</span>
										</h4>
										
										{ q.desc &&
											<>&nbsp; &nbsp; &nbsp;
											<Popup
												trigger={<Icon name='question circle outline' />}
												content={q.desc}
											/></>
										}
										
									</label>
									{q.details && <pre className="text-tertiary">{boldify(q.details)}</pre>}

									<div className="q-buttons">
										{Object.keys(q.a ?? {}).map((ak, _ai, all) => (
											<div key={"form_radio_q_" + k + "_a_" + ak} className={all.length > 2 && "!min-w-full lg:!min-w-[calc(50%_-_.5rem)]"}>
												{all.length === 1 && singleAsCheckbox ? ( 
													<Form.Checkbox label={q.a[ak].txt}
																   className={cls(
																	   "border rounded-lg w-full [&_*]:w-full [&>.ui.checkbox]:p-4",
																	   valids && !valids[k] && 'border-is-red',
																   )}
																   checked={values[k] === ak}
																   onChange={(_e, {checked}) => handleChange(k, checked ? ak : undefined, f)}
													/>
												) : (
												<Button
													type="button"
													basic={app.settings.iscr2 ? true : (values[k] !== ak)}
													color={values[k] === ak ? (app.settings.icosm ? 'black' : 'blue') : (valids && !valids[k] ? 'red' : 'grey')}
													size="small"
													onClick={() => handleChange(k, ak, f)}
												>
													{q.a[ak].txt}
												</Button>
												)}

												{q.a[ak].desc &&
													<Popup
														trigger={<Label as="a" basic color="teal" pointing="left">
															<Icon name="question circle" style={{marginRight: 0}}
																  size="large"/>
														</Label>}
														content={q.a[ak].desc}
													/>
												}
											</div>),
										)}
									</div>

										{choices.length > 0 && (
											<div className="mt-2">
												<p>Selected:</p>
												<List bulleted>
													{choices.map(([id, value]) => (
														<List.Item key={id}>{value}</List.Item>
													))}
												</List>
												<Button type="button" basic onClick={() => handleChange(k, values[k])}>
													Edit
												</Button>
											</div>
										)}
									{config.file && (!config.file_answer || config.file_answer==values[k]) && <React.Fragment>
										<h4 className="mb-1 mt-2">{config.file_message}</h4>
										<Photos
											show_disclaim
											target="medreq"
											hideComment={true}
											tag={k}
											tag_label={q.txt}
											inline
											size='calc(100% / 3 - 2em)'
											data = {values['phts'] && values['phts'].filter(p=>p.tag==k) || []}
											onChange = { ps => {
												const other_photos = (values['phts'] || []).filter(p=>p.tag!=k);
												const value = other_photos.concat(ps);
												return uf_this.handleInputChange(null, {name:'phts', value});
											} }
										/>
									
									</React.Fragment>}

									<ExtraInfoField
										{...{config, values, valids }}
										questionId={k}
										onChange={uf_this.handleInputChange}
									/>


								</Form.Group>
							</div>
							}
						) }
						</Section.Content>
                    </Section>;
			}}
		</UniFormMed>
	);
};

function ExtraInfoField({values, questionId, valids, config, onChange}) {
	if (!config.text || (config.text_answer && config.text_answer !== values[questionId])) {
		return null;
	}

	// Validation function is on the base question, so determine error from there. See script_model.prep_radio_form_fields
	const error = valids && !valids[questionId];
	const inputName = `${questionId}_text`;

	return (
		<React.Fragment>
			<Form.Input
				label={config.text_prompt}
				name={inputName}
				value={values[inputName] || ''}
				onChange={(e, {name, value}) => onChange({target: {name, value}})}
				error={error}
			/>
			{config?.text_desc && <p className="text-sm">{config.text_desc}</p>}
		</React.Fragment>
	);
}

export default MedicalConsultation;
