import React, { useCallback, useEffect, useState } from "react";

/**
 * Provides a mechanism to ensure additional information or checks are completed before a particular answer can be
 * commited. Typically, we would just show additional form fields given some answer (e.g. answering "Yes" to "Are you
 * allergic to any medication?" might then display an input box that needs to be filled out). However, the new UX has
 * a drawer that appears and requires the patient to make some selections, rather than simply rendering a dozen
 * checkboxes.
 *
 * @param {Object} props - The properties for the component.
 * @param {Object} props.action - The action configuration object.
 * @param {Function} props.action.resolve - Callback function to resolve with the selection.
 * @param {Function} props.action.reject - Callback function to reject the action.
 * @param {string=} props.action.handler - React Component to render, that must accept an `action` prop
 * @param {Object} props.action.config - Custom config passed to the provider handler component
 */
export function GuardAction({action}) {
	useEffect(() => {
		return () => {
			// This will have no effect if the action has already been resolved/rejected. But otherwise we want to
			// ensure the action is cancelled so we don't have an unfulfilled promise hanging around.
			action.reject(false);
		}
	}, [action]);

	if (action.handler) {
		const Handler = action.handler;

		return <Handler action={action} />;
	}

	return <AbortGuardAction action={action} />;
}

export function useGuardAction() {
	const [action, setAction] = useState(null);
	const onAction = useCallback(async action => {
		setAction({ ...action, active: true });
		try {
			await action.promise
		} finally {
			setAction({ ...action, active: false });
			// Dirty w to give the modal or drawer or whatever a chance to animate away.
			// This will come back to bite us if some action _doesn't_ need an ending animation :shrug:
			await new Promise(resolve => setTimeout(resolve, 500));
			setAction(null);
		}
	}, []);

	return [action, onAction];
}

function AbortGuardAction({action}) {
	useEffect(() => {
		action.reject(false);
		console.error(`Unhandled guard action`, action);
	}, [action]);

	return null;
}

