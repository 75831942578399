import React from "react";
import ServiceCard from "./ServiceCard";
import menu_model from "../../../../models/menu_model";
import { cls } from "../../../NUI/utils";

function ServicesMenu({ onMenuChange }) {

    const services = menu_model.get_servicesmenu()
 
    return (
        <div className="services-menu">
            {services.map((category) => (
                <div key={category.category} className="mt-12 lg:mt-9">
                    <h3 className="text-lg text-left text-gray-800 mt-9 mb-6">{category.category}</h3>
                    <div className={cls("grid grid-cols-1 xs:grid-cols-2 gap-4", category.category.includes("Specialised") ? "lg:grid-cols-1" : "lg:grid-cols-3 ")}>
                        {category.items.map((item) => (
                            <ServiceCard
                                key={item.title}
                                icon={item.icon}
                                title={item.abbr||item.title}
                                description={item.description}
                                price={item.price}
                                href={item.href}
                                onMenuChange={onMenuChange}
                            />
                        ))}
                    </div>
                </div>
            ))}
            <p className="text-xs text-is2-gray-150 text-center mt-6">* After Medicare rebate</p>
        </div>
    );
}

export default ServicesMenu;