import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Icon } from 'semantic-ui-react';

export const MARKER_NAME = 'content-table-marker';

function onClassChange(node, callback) {
    // keep track of class changes on page elements
    if (!(typeof MutationObserver === "function")) return;

    let lastClassString = node?.classList?.toString();
  
    const mutationObserver = new MutationObserver((mutationList) => {
        for (const item of mutationList) {
            if (item.attributeName === "class") {
                const classString = node?.classList?.toString();
                if (classString !== lastClassString) {
                    callback(mutationObserver);
                    lastClassString = classString;
                    break;
                }
            }
        }
    });
  
    mutationObserver.observe(node, { attributes: true });
  
    return mutationObserver;
}

const Marker = ({active = false, children, marker }) => {

    // we can use the marker prop instead of wrapping text when when there isn't any and we dont want to add any visual text to the area,
    // but still want the index to track it for navigation

    return (
        <span
            name={MARKER_NAME}
            className={`${active ? "active" : ""} ${ marker ? 'invisible block' : ''}`}
            style={{
                scrollMarginTop: `${document.getElementById("main-menu")?.offsetHeight}px`,
                height: children ? "fit-content" : "1px",
            }}    
        >
            {marker || children}
        </span>
    )
}

const NavLink = ({el, setOpen, current}) => {

    const [active, setActive] = useState(el?.attributes?.class?.value?.includes("active"));

    useEffect(() => {
        // if the class has been updated, and is now active in the following case, the link will keep track of it
        // in this case we are using it to track accordion sections being opened 
        // - but can add a prop to track specific classes when the need arise
        const mutationObserver = onClassChange(el, () => setActive(el?.attributes?.class?.value?.includes("active")));

        return () => mutationObserver.disconnect();
    }, []);

    return (
        <Link 
            className={`cursor-pointer flex flex-row ${current ? 'font-bold' : ''} items-baseline space-x-2 leading-[1]`} 
            onClick={(e) => {
                e.preventDefault();
                if (!active)
                    el.click();

                if (app.settings.is_mobile)
                    setOpen?.();

                el.scrollIntoView({block: "start", behavior: "smooth"});
            }}
        >
            <span className='text-sm min-[2000px]:text-lg min-[3000px]:text-xl'>{el.innerHTML}</span>
            {active ? <Icon size="small" name="eye" /> : null}
        </Link>
    )
}

const Content = ({ Header }) => {

    const [markers, setMarkers] = useState([]);

    const [open, setOpen] = useState(!app.settings.is_mobile);
    const [current, setCurrent] = useState();

    const [contentMarkers] = useState(() => document.getElementsByName(MARKER_NAME));

    useEffect(() => {

        if (!(typeof IntersectionObserver === "function")) return;
        
        const observer = new IntersectionObserver(([entry]) => {
            const title = entry.target.innerHTML;
            if (entry.isIntersecting) {
                setCurrent(title);
            }

        }, {
            rootMargin: `-110px 0px -80% 0px`,
        });
    
        const els = Array.from(contentMarkers);
        els.forEach((element) => observer.observe(element));
        setMarkers(els);
    
        return () => observer.disconnect();
    }, [contentMarkers.length]);

    const onSetOpen = useCallback(() => setOpen(!open), [open, setOpen]);

    return (
        <div
            onClick={open ? undefined : onSetOpen}
            style={{
                position: 'fixed',
                left: app.settings.is_mobile ? '-1rem' : 0,
                isolation: 'isolate',
                top: app.settings.is_mobile ? '0px' : `${document.getElementById("main-menu")?.offsetHeight}px`,
                width: open ? '18%' : '60px',
                minHeight: '60px',
                zIndex: 1000,
            }}
            data-testid='table-of-content'
        >
            <Segment
                raised
                className='min-h-[60px] fixed top-6'
            >
                <div className='flex flex-row-reverse items-center justify-between'>
                    <div className='cursor-pointer' data-testid='icon-close' onClick={open ? onSetOpen : undefined}>
                        <Icon name={`chevron ${open ? 'left' : 'right'}`} />
                    </div>
                    {open ? <Header /> : null}
                </div>
                
                {open ? (
                    <div className='flex flex-col space-y-3 mt-2'>
                        {markers?.map((mark, key) => <NavLink key={key} setOpen={onSetOpen} current={mark.innerHTML === current} el={mark} />)}
                    </div>
                ) : null}
            </Segment>
        </div>
    );
}

export default {
    Content, Marker
};
