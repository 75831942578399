import React, { useMemo } from 'react';
import { cls } from "@/views/NUI/utils";
import { Link } from 'react-router-dom';
import { useProfile } from '@/xAppLib/Hooks';
import SIcon from '@/views/NUI/StreamlineIcons/SIcon';
import { Progress } from "@/components/ui/progress";
import { useOrderProgress } from 'views/ordering/hooks/useOrderProgress';
import { EstimatedWaitTime } from 'views/ordering/components/EstimatedWaitTime';
import moment from 'moment';
import { getClientBookingTimezone } from 'views/booking/shared';
import { FRIENDLY_DATETIME_DAY_MONTH } from 'views/booking/formatting';

/**
 * Renders an individual active consultation item with status, progress bar, and navigation
 * @param {Object} props
 * @param {Object} props.consult - The consultation data object
 * @param {string} props.clsn - Additional CSS classes
 */
function ActiveConsultItem({ consult, clsn }) {
	const { data } = useOrderProgress(consult.sid);

	const bookingTimeZone = getClientBookingTimezone();
	const time =
		moment(data?.appointment?.start_time).tz(bookingTimeZone)
			.format(FRIENDLY_DATETIME_DAY_MONTH);

	const isAppointment = data?.appointment?.active === true;
	const isPrescription = (data?.script_type === 'docscr' && data?.req_type === 'doccons') ||
		(['qualbuy', 'qualcons', 'qualclick', 'medcons', 'medbuy', 'medclick', 'exprcons', 'exprbuy', 'exprclick']
			.includes(data?.script_type) && ['premed'].includes(data?.req_type));
	const isPathoRequest = (!data?.is_script && ['pathoreq'].includes(data?.script_type));

	const statusText = data?.status_header || consult.status;
	const status = data?.status || consult.status;
	const showProgressBar = !['in_doccall', 'done_doccall'].includes(status) && !isAppointment;
	const awaitingConsult = ['doccons', 'qualcons', 'qualbuy', 'qualclick'].includes(data?.script_type) &&
		['await_doccall', 'in_doccall'].includes(status);
	const awaitingMedCert = status === 'auto_approved_delay';

	return (
		<div className={cls("relative bg-is2-green lg:bg-white lg:rounded px-6 py-4 lg:py-9 mb-0 lg:mx-0", clsn)} key={data?.sid}>
			<h2 className="text-is2-gray-700 text-left mb-0 lg:mb-4">
				Your <span className="hidden lg:inline-block">{!isAppointment ? 'active' : 'upcoming'}</span> {data?.med_db_data?.name}{isPrescription && " script request"}:
			</h2>
			<div className="flex flex-col space-y-4 lg:space-y-6 bg-is2-green lg:p-4">
				<div className="grid grid-cols-[1fr_auto] lg:grid-cols-[170px_1fr_40px] xl:grid-cols-[186px_1fr_40px] gap-4 items-start lg:items-center">
					<div className="col-span-1 lg:col-span-1">
						<div className="flex flex-col text-sm text-is2-gray-body">
							<p className="text-sm m-0 text-is2-gray-body hidden lg:block text-left">Status</p>
							<h4 className="mt-0 mb-2 font-bold lg:font-normal text-is2-gray-700 text-base lg:text-[20px] leading-[30px] text-left">
								{isAppointment ? 'Scheduled' : awaitingConsult ? statusText : 'Awaiting Doctor review'}
							</h4>
						</div>
						<div className="text-left lg:px-4 lg:hidden">
							{showProgressBar && !awaitingMedCert && !isPathoRequest && !isAppointment && (
								<p className="text-is2-gray-700">
									You should receive a call from the doctor in the next{' '}
									<EstimatedWaitTime eta_minutes={data?.progress_eta?.max} />
								</p>
							)}
							{awaitingMedCert && !isPathoRequest && !awaitingConsult && !isAppointment &&
								<p className="text-is2-gray-700">Your certificate request will be reviewed in the next <EstimatedWaitTime eta_minutes={10} /></p>
							}
							{isPathoRequest && !isAppointment &&
								<p className="text-is2-gray-700">A doctor will review your test request shortly.</p>
							}
							{isAppointment &&
								<p className="text-is2-gray-700">
									Appointment on <strong>{time}</strong> <small>({bookingTimeZone}) </small>
								</p>
							}
						</div>
					</div>
					<div className="hidden lg:block text-left min-w-[calc(100%-246px)]">
						{showProgressBar && !awaitingMedCert && !isPathoRequest && !isAppointment && (
							<p className="text-is2-gray-700">You should receive a call from the doctor in the next{' '}
								<EstimatedWaitTime eta_minutes={data?.progress_eta.max} /></p>
						)}
						{awaitingMedCert && !isPathoRequest && !awaitingConsult && !isAppointment &&
							<p className="text-is2-gray-700">Your certificate request will be reviewed in the next <EstimatedWaitTime eta_minutes={10} /></p>
						}
						{isPathoRequest && !isAppointment &&
							<p className="text-is2-gray-700">A doctor will review your test request shortly.</p>
						}
						{isAppointment &&
							<p className="text-is2-gray-700">
								Appointment on <strong>{time}</strong> <small>({bookingTimeZone}) </small>
							</p>
						}
					</div>
					<div className="flex items-center justify-center h-full">
						<Link to={`order/${data?.sid}`}>
							<div className={cls("bg-white text-is2-gray-700 rounded-full w-[40px] h-[40px] flex items-center justify-center cursor-pointer -rotate-[90deg]")}>
								<SIcon name="chevron" size="xxs" bold />
							</div>
						</Link>
					</div>
				</div>

				{showProgressBar && !awaitingMedCert && (
					<div className="w-full">
						<Progress
							value={data?.progress}
							showDot={true}
							className="mb-10 mb-2 h-5 z-5"
							fg="hsl(var(--progress-active-consultation-foreground))"
							bg="hsl(var(--progress-active-consultation-background))"
						/>
					</div>
				)}
			</div>
		</div>
	);
}

/**
 * Displays active consultations from user's history
 * Filters for:
 * - Consultations within last 2 months
 * - Specific consultation types (doccons, qualcons, qualbuy and qualclick.)
 * - Active statuses (await_doccall, in_doccall)
 * - Pathology requests, medical certificates, appointments and doctor referrals
 * 
 * @param {Object} props
 * @param {string} [props.clsn=""] - Optional CSS class
 * @param {number} [props.limit] - Optional limit on number of consultations to display
 * @param {number} [props.skip=0] - Optional number of consultations to skip from the beginning
 * @returns {JSX.Element[]} Array of ActiveConsultItem components
 */
export function ActiveConsult({ clsn = "", limit = 0, skip = 0 }) {
	const [prof] = useProfile();

	const active = useMemo(() => {
		const twoMonthsAgo = moment().subtract(2, 'months');

		const filteredConsults = (prof?.hist ?? []).filter((r) => {
			const consultDate = moment(r.add_tm, 'D/M/YY HH:mm:ss');
			const isRecent = consultDate.isAfter(twoMonthsAgo);

			return isRecent && (
				(
					['doccons', 'qualcons', 'qualbuy', 'qualclick'].includes(r.script_type) &&
					['await_doccall', 'in_doccall'].includes(r.status)
				)
				|| (!r.is_script && ['pathoreq'].includes(r.script_type))
				|| ['medcert'].includes(r.req_type) && !['doc_approved'].includes(r.status)
				|| (!r.is_script && ['docrefr'].includes(r.script_type))
			);
		});

		const processedConsults = filteredConsults.slice(skip);
		const limitedConsults = limit > 0? processedConsults.slice(0, limit) : processedConsults;

		return limitedConsults.map(consult => ({
			...consult,
			appointment: prof?.pts_appointments?.find(appt => appt.sid === consult.sid) || null
		}));
	}, [prof, limit, skip]);

	return active.map(consult => (
		<ActiveConsultItem key={consult.sid} consult={consult} clsn={clsn} />
	));
}
