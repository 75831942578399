import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Segment, Divider, Header, Modal, Loader } from 'semantic-ui-react'
import results_model from 'models/results_model'
import { supportsPDFs } from '../../xAppLib/helpers/pdf'
import API_service from '../../xAppLib/providers/API_service'
import { layoutHL7ResultWithRange } from 'xAppLib/Results/util'
import PathoResultsView from 'views/patho/PathoResultsView'
import { layoutTextResult } from 'xAppLib/Results/util'
import ShareDownloadButton from "../../xAppLib/UIelems/ShareDownloadButton";
import api_file from "../../helpers/api_file";


const PathoResultsPatientReport = ({ value = null, med_db_data, extra, sid }) => {
	const [scr, setScriptWithResults] = useState({})
	const [expanded_results, setExpandedResults] = useState([])
	const [show_simple, setShowSimple] = useState(false)
	const [view_file, setViewFile] = useState(undefined)
	const [view_file_data, setViewFileData] = useState(undefined)
	const [view_url, setViewURL] = useState(undefined)
	const [resultsAreLoading, setResultsAreLoading] = useState(false)

	useEffect(() => {
        const getData = async () => {
            setResultsAreLoading(true)
            const results = await results_model.get_raw_results_by_sid({ sid: sid })
            setScriptWithResults(results.list[0])
            setResultsAreLoading(false)
        };
        getData();
	}, [])

	const toggleResult = (rid) => {
		const expanded = [...expanded_results]
		if (expanded.includes(rid))
			expanded.splice(expanded.indexOf(rid), 1)
		else
			expanded.push(rid)
		setExpandedResults(expanded)
	}

	const handleFileClick = async (params = {}) => {
		const { scr, data, i = 0, j = 0 } = params
		if (view_file) console.log('Already viewing, please wait...')
		else {
			if (data && !view_url) {
				const is_pdf = data.file_fn.toLowerCase().includes('pdf')
				const url = api_file(data)
				setViewURL(is_pdf && !supportsPDFs() && `/pdf-view/viewer.html?file=` + encodeURIComponent(url) || url)
			}
			setViewFileData(data)
			setViewFile(true)
		}
	}

	const handleFileClose = async (params = {}) => {
		setViewFile(false)
		setViewURL(undefined)
		setViewFileData(undefined)
	}

	const render_view_file = () => {
		return (
			<React.Fragment>
				{view_file && view_url && view_file_data &&
					<Modal open={true}
						onClose={_ => { handleFileClose() }}
						closeIcon>
						<Modal.Header>
							{view_file_data.file_fn}
						</Modal.Header>
						<Modal.Content>
							<iframe src={view_url} style={{ width: '100%', height: '70vh' }} frameBorder="0"></iframe>
						</Modal.Content>
						<Modal.Actions>
							<ShareDownloadButton url={view_url} filename={view_file_data.file_fn}/>
							<Button
								content="Close"
								onClick={_ => { handleFileClose() }}
								icon="close"
								size="small"
								color="grey"
							/>
						</Modal.Actions>
					</Modal>
				}
			</React.Fragment>
		)
	}

	const layoutAttachedWithoutHL7 = (att, j) => {
		const expanded = expanded_results.includes(att.file_fn)
		return (
			<>
				<ul className={`flex font-semibold hover:text-blue-600 hover:cursor-pointer mt-3`}
					style={{ maxWidth: '50rem' }}
					key={att.file_fn}
					onClick={_ => toggleResult(att.file_fn)}>
					<li className="flex-none w-5 mr-1">
						<i className={`caret ${(expanded) ? 'down' : 'right'} icon text-gray-800 hover:text-blue-600`}></i>
					</li>
					<li className="flex-none w-24">{att.tm.substr(0, 10)}</li>
					<li className="flex-auto">{att.comn}</li>
					<li className="flex-none text-center w-16">
					</li>
				</ul>
				<div className={`${(expanded) ? 'display' : 'hidden'} mb-6`} style={{ maxWidth: '50rem' }}>
					<ul className="flex mt-6">
						<li className="flex-none w-5"></li>
						<li className="flex-auto">
							{att.file_fn}
						</li>
					</ul>
					<ul className="flex mt-4 mb-14">
						<li className="flex-none w-5"></li>
						<li className="flex-none">
							{(att.comn !== undefined && att.comn.substr(0, 9) !== '[LAB_PDF]') &&
								<Button
									size="mini"
									content="View / Download PDF"
									onClick={_ => handleFileClick({ scr, data: att, i: 0, j })} />
							}
						</li>
					</ul>
				</div>
			</>
		)
	}

	const layoutHL7Requests = () => {
		// scr is the script object with the attch and the rid attachments
		const results = []
		// Loop the attch
		scr.attch.map((att, j) => {
			if (['patho', 'txt', 'PIT'].includes(att.type)) return null
			// Request detail
			const result = scr.results_raw.filter(item => item.rid === att.rid)
			if (result.length === 0)
				results.push(layoutAttachedWithoutHL7(att, j))
			else {
				const request = result[0].data_json.request
				// Results / observations
				const obx = layoutHL7ResultWithRange(scr, att, 0, j, false, false)
				const expanded = expanded_results.includes(att.rid)
				results.push(
					<Fragment key={j}>
						<ul className={`flex font-semibold hover:text-blue-600 hover:cursor-pointer mt-3`}
							style={{ maxWidth: '50rem' }}
							key={att.rid}
							onClick={_ => toggleResult(att.rid)}>
							<li className="flex-none w-5 mr-1">
								<i className={`caret ${(expanded) ? 'down' : 'right'} icon text-gray-800 hover:text-blue-600`}></i>
							</li>
							<li className="flex-none w-24">{request.obs_time.substr(0, 10)}</li>
							<li className="flex-auto">{layoutTextResult(request.sn, false)}</li>
							<li className="flex-none text-center w-16">
								{(obx.warning > 0) && <i className="text-md exclamation triangle icon text-yellow-500"></i>}
								{(obx.corrected_result > 0) && <i className="text-md exchange icon text-red-500" title="Corrected result!"></i>}
							</li>
						</ul>
						<div className={`${(expanded) ? 'display' : 'hidden'} mb-6`} style={{ maxWidth: '50rem' }}>
							<ul className="flex">
								<li className="flex-none w-5"></li>
								<li className="flex-auto">
									{obx.layout}
								</li>
							</ul>
							<ul className="flex mt-6 mb-14">
								<li className="flex-none w-5"></li>
								<li className="flex-none">
									{(att.comn !== undefined && att.comn.substr(0, 9) !== '[LAB_PDF]') &&
										<Button
											size="mini"
											content="View / Download PDF"
											onClick={_ => handleFileClick({ scr, data: att, i: 0, j })} />
									}
								</li>
							</ul>
						</div>
					</Fragment>
				)
			}
		})
		//If we are using the lab pdfs (DHM) rather than ours, for the moment we cannot guarantee each pdf is aligned with each hl7, so list at the bottom
		const lab_pdfs = []
		scr.attch.map((att, j) => {
			if ((att.comn !== undefined && att.comn.substr(0, 9) === '[LAB_PDF]'))
				lab_pdfs.push(
					<Fragment key={j}>
						<Button
							size="mini"
							content="Lab PDF"
							onClick={_ => handleFileClick({ scr, data: att, i: 0, j })} />
					</Fragment>
				)
		})
		return (
			<>
				<ul className="mb-3">
					{results.map((item, i) => <li key={i}>{item}</li>)}
				</ul>
				{(lab_pdfs.length > 0) &&
					<ul className="mt-4 ml-5 flex">
						{lab_pdfs.map((item, i) => <li key={i} className="mr-2">{item}</li>)}
					</ul>
				}
			</>
		)
	}

	return <>
		{resultsAreLoading && 
		<div className="flex-row space-x-2">
			<Loader inline active size="tiny" />
			<span>Loading Pathology Results</span>
		</div>
	  	}
		{(scr === undefined) &&
			<PathoResultsView value={value} med_db_data={med_db_data} extra={extra} sid={sid} />
		}
		{(scr !== undefined && Object.keys(scr).length > 0) &&
			<div>
				<h3 className="pb-2 md:mx-0 mb-0 mt-4">Pathology Results</h3>
				<Segment raised>
					{layoutHL7Requests(scr, 0, 0)}
				</Segment>

				{/* <h3 className="pb-2 md:mx-0 mt-10">Simplified Report</h3>
				<Segment raised>
					<p>
						Simplified pathology test results are now prepared for your <strong>"{scr.med_db_data.name}"</strong>.
					</p>
					<p>
						Would you like to view a simplified version of your pathology test results?
					</p>
					<p>
						Simplified reports contain comments and advice provided by InstantScripts doctors and do not reflect comments
						or advice provided by the pathology laboratory. Comments and advice are general in nature and you should discuss
						any results in consultation with an InstantScripts doctor.
					</p>
					<Checkbox
						label="Yes, I understand"
						checked={show_simple}
						onChange={_ => { setShowSimple(!show_simple) }}
					/>
					{(show_simple) &&
						<PathoResultsView value={value} med_db_data={med_db_data} extra={extra} sid={sid} />
					}
				</Segment> */}
				<Divider />
				<Segment
					basic
					as={Link}
					to="/med/DocConsCov"
					style={{ paddingLeft: 0, paddingRight: 0 }}
				>
					<Header>Doctor Consultation</Header>
					<p className='text grey'>
						You can request a consultation with an InstantScripts doctor to discuss your results.
						Our doctors have a broad range of knowledge and skills to treat a wide range of health conditions.
					</p>
					<Button color="green">
						Request Doctor Consultation
					</Button>

				</Segment>

				{render_view_file()}
			</div>
		}
	</>
}


export default PathoResultsPatientReport
