import React from 'react';
import { cls } from "../../../NUI/utils";
import { HeaderNav } from './HeaderNav';

function DefaultContentWrapper({clsn, padding, sidebar, children}) {
    const className = cls(
        clsn,
        padding || "px-6 pb-10 pt-12 md:py-12 md:mx-auto",
        "flex flex-col md:w-[700px]",
        sidebar ? "lg:w-full" : "lg:w-[744px] z-40"
    );

    return (
        <div className={className}>
            {children}
        </div>
    )
}

Header.Title = ({children}) => {
    return <h1 className="!text-white my-0 font-medium">{children}</h1>
}

export function Header(props) {
    const {
        sub_title,
        title,
        desc,
        note,
        nav,
        children,
        clsn,
        wrapper,
        contentWrapper: ContentWrapper = DefaultContentWrapper,
        padding,
        current,
        sidebar,
    } = props;

    return (
        <div className={cls("header bg-is2-blue-300 text-white relative", sidebar ? "lg:w-[269px]" : "", wrapper, clsn)} data-testid="area-header">
            <ComponentOrElement as={ContentWrapper} clsn={clsn} sidebar={sidebar} padding={padding}>
                {sub_title && <p className="text-is2-blue-100">{sub_title}</p>}

                {title && <Header.Title className="!text-white my-0 font-medium">{title}</Header.Title>}

                {desc && <p className="text-is2-blue-100 mb-5 mt-3">{desc}</p>}

                {children}

                {note && <p className="text-sm text-[#99C1FA] mt-3">{note}</p>}
            </ComponentOrElement>

            {nav && <HeaderNav curr={current||title} sidebar={sidebar}/>}

        </div>
    );
}

/**
 * Renders a React component or a JSX element, determined by the `as` prop.
 * If the `as` prop is a valid React element, it will clone the element with the provided props.
 * Otherwise, it will render the `as` prop as a JSX element with the provided props.
 *
 * Basically it lets you do something like:
 *  <Header contentWrapper={SomeComponentToConstrainWidth}/>
 * or with some overrides for special situations:
 *  <Header contentWrapper={<SomeComponentToConstrainWidth wide />}/>
 * @param {Object} props - The props object.
 * @param {React.Element|React.ComponentType} props.as - A React element or a component to render.
 * @param {Object} [props.props] - Additional props to pass to the element or component.
 * @return {React.Element} A rendered React element.
 */
function ComponentOrElement({as: C, ...props}) {
    return React.isValidElement(C) ? React.cloneElement(C, props) : <C {...props}/>;
}
