export const DVA_COLOUR_LIST = {
    'Gold': 'G',
    'White': 'W',
    'Orange': 'O'
};

export const validateDVA = (value) => {
    if (!value) return true; // Optional field
    if (typeof value !== 'object') return false;
    if (value.colour === '' && value.number === '') return true;
    return value.number && value.colour;
};

export const validateDVANumber = (value) => {
    if (typeof value !== 'string') return false;
    return value.match(/^[NVQWST]([A-Za-z ]\d{6}|[A-Za-z]{2}\d{5}|[A-Za-z]{3}\d{4})[A-Za-z]?$/);
};

export const validateDVAColour = (value) => {
    if (typeof value !== 'string') return false;
    return Object.values(DVA_COLOUR_LIST).includes(value);
};