import { omit } from "@/helpers/json_functions";
import React from "react";
import { Button, Checkbox, Form, Icon, Input, List, Popup, Select, Table } from "semantic-ui-react";
import { v4 as generate_id } from 'uuid';

export const byChoiceOrder = ([, x], [, y]) => x.order - y.order;

export function ChoiceConfigPopup({config, answers}) {
	if (!config?.choice) return null;

	return (
		<Popup
			flowing
			trigger={<Icon name="list alternate outline" color="grey"/>}
			content={<>
				Prompt to select options {config.choice_answer && <>
				if answer is <em>{answers?.[config.choice_answer]?.txt}</em>:
				<List bulleted>
					{Object.entries(config?.choices ?? {}).sort(byChoiceOrder).map(
						([id, choice]) => <List.Item key={id}>{choice.text}</List.Item>,
					)}
				</List>
			</>}
			</>}
		/>
	);
}

export function ChoiceConfigRow({answers, config = {}, onChange}) {
	const choices = config.choices ?? {};

	function addOption(e) {
		e.preventDefault();
		const text = new FormData(e.target).get('text').trim();
		if (text.length) {
			const id = generate_id();
			const order = Math.max(0, ...Object.values(choices).map(choice => choice.order)) + 1;
			const updated = {...config, choices: {...choices, [id]: {text, order}}};
			onChange(updated);
			e.target.reset();
		}
	}

	function deleteOption(id) {
		const updated = {...config, choices: omit(choices, [id])};
		onChange(updated);
	}

	function updateOption(id, change) {
		const updated = {...config, choices: {...choices, [id]: {...choices[id], ...change}}};
		onChange(updated);
	}

	return (
		<Table.Row>
			<Table.Cell colSpan={6}>
				<div className="ui form">
					<Form.Group>
						<Form.Field width="4">
							<label>Require Selection</label>
							<Checkbox checked={!!config.choice} label="Yes" onChange={(e) => {
								onChange({...config, choice: !config.choice});
							}}/>
						</Form.Field>
						<Form.Field width="5">
							<label>Prompt</label>
							<Input disabled={!config.choice} value={config.choice_prompt} type="text"
								   onChange={(e, {value}) => {
									   onChange({...config, choice_prompt: value});
								   }}/>
						</Form.Field>
						<Form.Field width="5">
							<label>Choice Description</label>
							<Input disabled={!config.choice} value={config.choice_desc} type="text"
								   onChange={(e, {value}) => {
									   onChange({...config, choice_desc: value});
								   }}/>
						</Form.Field>
						<Form.Field width="6">
							<label>Show only for answer</label>
							<Select disabled={!config.choice}
									value={config.choice_answer}
									options={[
										{key: null, value: null, text: 'N/A (Always show)'},
										...Object.keys(answers ?? {}).map(k => ({
											key: k,
											value: k,
											text: answers[k].txt,
										})),
									]}
									onChange={(e, {value}) => {
										onChange({...config, choice_answer: value});
									}}
							>
							</Select>
						</Form.Field>
					</Form.Group>
					{config.choice && (
						<Form.Group>
							<Form.Field width="4">&nbsp;</Form.Field>

							<Form.Field width="5">
								<label>Choices</label>
								<div className="space-y-2">
									{Object.entries(choices).sort(byChoiceOrder).map(([id, choice]) => (
										<Input key={id}
											   type="text"
											   action
											   value={choice.text}
											   onChange={e => updateOption(id, {text: e.target.value})}
										>
											<input required/>
											<Button type="button" color="red"
													onClick={() => deleteOption(id)}>Delete</Button>
										</Input>
									))}
								</div>
								{Object.keys(choices).length === 0 && <div>None</div>}
								<form onSubmit={addOption} className="mt-8">
									<Input type="text" name="text" placeholder="Add option" action>
										<input required/>
										<Button type="submit">Add</Button>
									</Input>
								</form>
							</Form.Field>
						</Form.Group>
					)}
				</div>
			</Table.Cell>
		</Table.Row>

	);
}