import React, { Component } from 'react';
import {DocPayEditScript} from './../docpay/edit_script/DocPayEditScript'
import { Button, Header, Icon, Message, Input, Accordion } from 'semantic-ui-react'

import moment from 'moment-timezone'

import API_service from 'xAppLib/providers/API_service'

import obj_diff from 'xAppLib/helpers/obj_diff'

import doc_model from 'models/doc_model'
import script_model from 'models/script_model'

import {ViewEditDets} from 'xAppLib/DataList'

import SelectOrg from 'xAppLib/Orgs/SelectOrg'

import ProfileSyncButton from 'xAppLib/UIelems/ProfileSyncButton';
import { AdjustMedCertDate } from "../../doc/doccons/AdjustMedCertDate";
import { AdminEditAppScriptEnrolment } from "../../doc/doccons/FinConsActions";
import { AdminEditMentalHealthCarePlan } from "../../doc/DocCons_mhcp";
import {EditAssignedDoctor} from '../../doc/EditAssignedDoctor';
import DeliverySelector from '../../evermed/DeliverySelector';
import { PARTN } from '../../../constants';
import { v4 as uuidv4 } from 'uuid';
const DEFAULT_SCRIPT_DAYS_LIMIT = 14


export default class EditScript extends Component {

	constructor(props) {
		super(props);

		this.state = {
				panel: 'patient-details',
				password: '',
				message: '',
				loading: false,
				error: false
			};
	}

	//	------------------------	------------------------	------------------------

	async componentDidMount () {
	
		// this.load_recs()

		const docs = await doc_model.get_record()

		this.setState({docs_data: {'-':{name: 'Empty'}, ...docs}}) 

	}

	// 		--------------------------------		--------------------------------		---------

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
				// newemail: nextProps.scr_data.email==prevState.iniemail ? prevState.newemail : nextProps.scr_data.email,
				// newname: nextProps.scr_data.displayName==prevState.ininame ? prevState.newname : nextProps.scr_data.displayName,
			}
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	async save_dets (part, d, ca) {

		const { scr_data, onChange } = this.props

		if (part=='cosm_det' && this.cosm_det_doc) {
			d.doc = this.cosm_det_doc
		}

		let data_diff = {}
		 if (part == 'shipping_address' || part == 'shipping_method'){
			const orig =
				part === 'shipping_address'
					? { shipping_address_components: scr_data.form_data?.shipping_address_components || {} }
					: { delivery: scr_data.form_data?.delivery };
			data_diff = obj_diff(
				orig,
				{...d}
			)
		} else {
			data_diff = obj_diff(scr_data[part], d)
		}

		console.log('EditScript :: save_dets', part, scr_data.pts_uid, data_diff, Object.keys(data_diff).length)

		// med_model.update_record( med_code, d )

		// this.setState({det_mode: 'view'})
		if (!data_diff.to || !Object.keys(data_diff).length || (data_diff.to && !Object.keys(data_diff.to).length))	return


		this.setState({
						message:'',
						error:false,
						loading:true
					})

		let params = {sid:scr_data.sid, diff:{...data_diff, part}}
		const upd_res = await API_service.load_data(
									'scripts/upd_script', 
									params,
									r => ca && ca(r)
								) 

		// console.log("upd_res = %o", upd_res)

		// admin_users_model.upd_user_prof( params,  
		// 	result => {
		// 		console.log("result = %o", result)
		// 		this.setState({
		// 						newname: '',
		// 						message: result.res=='ok' && 'New profile set' || result.err_msg,
		// 						error: result.res != 'ok',
		// 						loading: false
		// 					})
		// 	} )
		let res = {[part]:d}
		if(upd_res.res == 'ok'){
			
			if (part=='shipping_address' || part == 'shipping_method') {
				res = {form_data: {...scr_data.form_data, ...d}}
			}
			if(params?.diff?.to?.mobile && scr_data.status === 'wrong_number') {
				await API_service.load_data('chgStat', {sid: scr_data.sid, stat: 'await_doccall'})
			}
		}
		onChange && onChange({...scr_data, ...res})
		ca && ca()
	}

	// 		--------------------------------		--------------------------------		---------

	save_dets_fld (part, f, d, ca) {

		const { scr_data } = this.props
		console.log('EditScript :: save_dets_fld', part, f, d, scr_data.uid, scr_data)

		// this.save_dets({[f]: d}, ca)
		// med_model.update_record( med_code, { [f]: d } )

		ca && ca()
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	handle_expiry_change(e, d) {
		const { scr_data } = this.props
		const scr_date = scr_data && (scr_data.tm && moment(scr_data.tm)) || (scr_data.add_tm && moment(scr_data.add_tm, "DD/MM/YY HH:mm:ss")) // .tm wasn't availible on some accounts (K\B)
		const new_date = d && d.value && moment(d.value)
		if (new_date && new_date.isValid() && scr_date && scr_date.isValid() && new_date > scr_date) {
			console.log('Valid date', {new_date, d, scr_date})
			this.setState({new_days_limit: new_date.diff(scr_date, 'days')})
		} else {
			console.log('Invalid date', {new_date, d, scr_date, scr_data})
			this.setState({new_days_limit: undefined})
		}
	}

	// 		--------------------------------		--------------------------------		---------

	async handle_set_expiry() {
		const { scr_data } = this.props
		if (this.state.loading_days_limit) console.log('Already loading, please wait...')
		else this.setState({loading_days_limit: true}, async _ => {
			if (typeof this.state.new_days_limit != 'undefined') await this.save_dets('meta', {patho_expiry: `${this.state.new_days_limit}` })
			this.setState({loading_days_limit: false, new_days_limit: undefined})
		})
	
	}

	// 		--------------------------------		--------------------------------		---------

	async handle_mdcr(mdcr) {
		await this.save_dets('meta', { inv_mdcr: mdcr } )
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	render() {
		const { scr_data, onChange } = this.props
		const { password, newname, newemail, message, error, loading } = this.state

		scr_data.dob = moment(scr_data.dob).format("YYYY-MM-DD")

		const days_limit = scr_data && (scr_data.meta && scr_data.meta.patho_expiry || scr_data.med_db_data && scr_data.med_db_data.days_limit)
		const days_limit_num = typeof days_limit != 'undefined' && Number(days_limit) || DEFAULT_SCRIPT_DAYS_LIMIT
		const script_date = scr_data && (scr_data.tm && moment(scr_data.tm)) || (scr_data.add_tm && moment(scr_data.add_tm, "DD/MM/YY HH:mm:ss"))
		const expiry_date = !isNaN(days_limit_num) && script_date && script_date.add(days_limit_num + 1, 'days').format('YYYY-MM-DD')

		const docList = this.state.docs_data ? Object.keys(this.state.docs_data).map( k => ({key: k, value: k, text: this.state.docs_data[k].name,})) : null 

		const syncProfile = { scr_data, onScriptChange: onChange };
		// let user_fields_data = script_model.COMM_USR_FIELDS

		// if (scr_data.script_type=='medbuy') {
		// 	user_fields_data
		// 		.forEach ( f => {
		// 			if (f.name == 'medicare') {
		// 				f.valid_not_required = !!this.state.no_medicare
		// 			}
		// 			if (f.name == 'address') {
		// 				f.valid_not_required = false
		// 			}
		// 		} );
		// }

		// user_fields_data.push({
		// 							name: "conc_card",
		// 							type: "text",
		// 							valid_not_required: true,
		// 							pattern: "^$|^[a-zA-Z0-9]{7,12}$",
		// 							label: "If you have a valid concession card, please enter the card number",
		// 							placeholder: 'Your concession card number',
		// 						})

		const accordion_props = (index) => ({
			index,
			active: this.state.panel===index,
			onClick: (_, e) => e && this.setState(({ panel: this.state.panel === e.index ? null : e.index })),
		});

		return <div style={{width: '600px'}}>

			<h2>Edit Script Request details for script <br/> {scr_data.displayName} <b>{scr_data.email}</b></h2>

				{message && 
					<Message negative={error} positive={!error}>
						<p>{message}</p>
					</Message>
				}

			<Accordion styled>

			<Accordion.Title {...accordion_props("patient-details")}>
				<Header as='h3'><Icon name='dropdown' /> Patient Details</Header>
			</Accordion.Title>
			<Accordion.Content {...accordion_props("patient-details")}>

			<ViewEditDets 
					size='mini'
					itm_data = {scr_data.spd_data}
					det_fields={[
						...script_model.COMM_USR_FIELDS_EXT,
					]}

					allow_fld_edit = {false}

					// onSave_dets = { (d, ca) => console.log('EditScript :: onSave_dets', 'obj_diff', obj_diff(user, d) ) }
					// onSave_dets_fld = { (f, d, ca) => console.log('EditScript :: onSave_dets_fld', f, d, ca) }
					onSave_dets = { (d, ca) => this.save_dets('spd_data', d, ca) }
					onSave_dets_fld = { (f, d, ca) => this.save_dets_fld('spd_data', f, d, ca) }
				/>

				<ProfileSyncButton itm_data={scr_data.spd_data} syncProfile={syncProfile} containerClass="mt-4" />
			</Accordion.Content>

			<Accordion.Title {...accordion_props("assigned-doctor")}>
				<Header as='h3'><Icon name='dropdown' /> Assigned Doctor</Header>
			</Accordion.Title>
			<Accordion.Content {...accordion_props("assigned-doctor")}>
				<EditAssignedDoctor doctorList={docList} scriptData={scr_data} onUpdate={this.props.onChange}/>
			</Accordion.Content>
			{scr_data.paym?.docpay && (<>
				<Accordion.Title {...accordion_props("docpay")}>
				<Header as='h3'><Icon name='dropdown' />Doc Pay</Header>
			</Accordion.Title>
			<Accordion.Content {...accordion_props("docpay")}>
				<DocPayEditScript script={scr_data} /> 
			</Accordion.Content>
			</>)}
			

			{['mentplan','mentrev'].includes(scr_data.script_type) && <>
			<Accordion.Title {...accordion_props("mhcp-doctor-referral")}>
				<Header as='h3'><Icon name='dropdown' /> MHCP Doctor Referral</Header>
			</Accordion.Title>
			<Accordion.Content {...accordion_props("mhcp-doctor-referral")}>
				<AdminEditMentalHealthCarePlan req_scr_obj={scr_data} onUpdate={this.props.onChange} />
			</Accordion.Content>
			</>}

			{['medcert'].includes(scr_data.req_type) && <>
			<Accordion.Title {...accordion_props("med-cert-date-adjust")}>
				<Header as='h3'><Icon name='dropdown' /> Adjust Med Cert Date</Header>
			</Accordion.Title>
			<Accordion.Content {...accordion_props("med-cert-date-adjust")}>
				<AdjustMedCertDate req_scr_obj={scr_data} onUpdate={this.props.onChange} />
			</Accordion.Content>
			</>}

			{false && scr_data.meta && 'enrol_app_scripts' in scr_data.meta && <>
			<Accordion.Title {...accordion_props("enrol-app-scripts")}>
				<Header as='h3'><Icon name='dropdown' /> App Script Enrolment</Header>
			</Accordion.Title>
			<Accordion.Content {...accordion_props("enrol-app-scripts")}>
				<AdminEditAppScriptEnrolment scr_obj={scr_data} onUpdate={this.props.onChange}/>
			</Accordion.Content>
			</>}


			{/* { scr_data.script_type=='cosm' && 
				<React.Fragment>
				<Accordion.Title {...accordion_props("cosmetic-treatment-details")}>
					<Header as='h3'><Icon name='dropdown' /> Cosmetic Treatment Details</Header>
				</Accordion.Title>
					<Accordion.Content {...accordion_props("cosmetic-treatment-details")}>

					<ViewEditDets 
						size='mini'

						itm_data = {scr_data.cosm_det} 
						det_fields = { fields_helper(script_model.COMM_COSM_FIELDS, 'scredit') }

						cust_fields = {{
										doc_set: <SelectOption
														editButton
														value={scr_data.cosm_det.doc}
														placeholder='Select user doctor'
														// name={`user ${r.displayName || r.last_name} (${r.email}) - set doctor `}

														onSelect={ value=> this.cosm_det_doc = value }
														// onClear={ _=> this.del_usr_doc(r.uid) }

														noConfirm={true}

														options={ doc_list }
													/>,
									}}

						allow_fld_edit = {false}

						// onSave_dets = { (d, ca) => console.log('EditScript :: onSave_dets', 'obj_diff', obj_diff(user, d) ) }
						// onSave_dets_fld = { (f, d, ca) => console.log('EditScript :: onSave_dets_fld', f, d, ca) }
						onSave_dets = { (d, ca) => this.save_dets('cosm_det', d, ca) }
						onSave_dets_fld = { (f, d, ca) => this.save_dets_fld('cosm_det', f, d, ca) }
					/>
					</Accordion.Content>
				</React.Fragment>
			} */}

			{scr_data && scr_data.script_type && ['pathoreq'].includes(scr_data.script_type) && 
				<React.Fragment>
				<Accordion.Title {...accordion_props("change-expiry")}>
					<Header as='h3'><Icon name='dropdown' /> Change expiry</Header>
				</Accordion.Title>
					<Accordion.Content {...accordion_props("change-expiry")}>
					{/* <p>Expiry date: {expiry_date && expiry_date.format('YYYY-MM-DD') || 'Unknown'}</p> */}
					<Input
						type='date'
						defaultValue={expiry_date}
						onChange={(e, d) => this.handle_expiry_change(e, d)}
						//loading={this.state.loading_days_limit}
						disabled={this.state.loading_days_limit}
					/>
					<Button
						content='Set'
						onClick={_ => this.handle_set_expiry()}
						color='green'
						style={{marginLeft: '10px'}}
						loading={this.state.loading_days_limit}
						disabled={this.state.loading_days_limit || typeof this.state.new_days_limit == 'undefined'}
					/>
					{typeof this.state.new_days_limit != 'undefined' && 
						<p>New days limit: {this.state.new_days_limit}</p> ||
						<p>Days limit: {typeof days_limit != 'undefined' && days_limit || `default (${DEFAULT_SCRIPT_DAYS_LIMIT})`}</p>
					}
					</Accordion.Content>
				</React.Fragment>


			}

			<Accordion.Title {...accordion_props("org")}>
				<Header as='h3'><Icon name='dropdown' /> Org</Header>
			</Accordion.Title>
			<Accordion.Content {...accordion_props("org")}>
			<SelectOrg
				leafOnly={false}
				oid={scr_data.org_data?.oid}
				// onSelect={ org => console.log('set org', org) }
				onSelect={ async org => (await app.confirm(`Set Organisation (${org.onum}) ${org.name} ${org.type}`, `To script for ${scr_data.spd_data.first_name} ${scr_data.spd_data.email}` ) == 'yes') && this.save_dets('org_data', {oid:org.oid, name:org.name})  }
				placeholder='Set script org'
				setBtn={true}
				required={true}
			/>
			</Accordion.Content>
			{['medsub', 'medbuy', 'exprbuy', 'qualbuy', 'medclick', 'exprclick', 'qualclick'].includes(scr_data.script_type) && scr_data.form_data?.shipping_address_components && <>
			<Accordion.Title {...accordion_props("shipping-address-edit")}>
				<Header as='h3'><Icon name='dropdown' />Shipping Address</Header>
			</Accordion.Title>
			<Accordion.Content {...accordion_props("shipping-address-edit")}>
			<ViewEditDets 
					size='mini'
					itm_data = {scr_data.form_data} 
					det_fields = {[...script_model.COMM_SHIPPING_FIELDS]}
					allow_fld_edit = {true}
					onSave_dets = { (d, ca) => this.save_dets('shipping_address', d, ca) }
					onSave_dets_fld = { (f, d, ca) => this.save_dets_fld('shipping_address', f, d, ca) }
				/>
			</Accordion.Content>
			</>}
			{
			scr_data.form_data?.delivery
			&& [scr_data.org_data?.oid, scr_data.org__oid].includes(PARTN.EVERMED)
			 && <>
			<Accordion.Title {...accordion_props("delivery-method-edit")}>
				<Header as='h3'><Icon name='dropdown' />Change Delivery Method</Header>
			</Accordion.Title>
			<Accordion.Content {...accordion_props('delivery-method-edit')}>
				{this.state.panel === 'delivery-method-edit' && <DeliverySelector
					shipping_address_components={scr_data.form_data?.shipping_address_components}
					med_params={{
						pbsCode: scr_data.med_db_data.PBSCode,
						mppAmtCode: scr_data.med_db_data?.epresc?.AMT_MPP,
						qty: scr_data.med_db_data?.epresc?.Quantity
					}}
					orig_delivery={{
							...scr_data.paym?.items.find((i) => i.id === 'SHIPPING'),
						 	code: scr_data.form_data?.delivery?.replace('EM_', '')
						}}
					handleSubmit={async (d) => await this.save_dets(
						'shipping_method',
						d,
						r => {if(r){
							const ok = r.res === 'ok'
							app.trigger(
								app.events.SHOW_MESSAGE,
								ok ? 'Delivery method updated' : 'Failed to update',
								'',
								ok ? 'positive' : 'negative',
								`doccons_change_delivery_method_${uuidv4()}`
								)
							}}
					)}
				/>}
			</Accordion.Content>
			</>}

			{scr_data?.paym?.mdcr_itm && <>
				<Accordion.Title {...accordion_props("inv_mdcr")}>
					<Header as='h3'><Icon name='dropdown' />Invoice Medicare</Header>
				</Accordion.Title>
				<Accordion.Content {...accordion_props("inv_mdcr")}>
					<>
						<p>{scr_data?.paym?.mdcr_itm?.desc} - ${scr_data?.paym?.mdcr_itm?.amount} (#{scr_data?.paym?.mdcr_itm?.code})</p>
						<Button
							primary
							content={!scr_data?.meta?.inv_mdcr ? 'Add' : 'Remove'}
							onClick={_ => this.handle_mdcr(!scr_data?.meta?.inv_mdcr)}
						/>
					</>
				</Accordion.Content>
				</>
			}

			</Accordion>

		</div>
	}

}
