import React from "react";
import { CallToAction } from "../CallToAction";

export const DescriptionAndInfo = ({
    descriptionAndInfoComponent,
    descriptions,
    overlayLinkUrl,
    overlayLinkText,
    postDescriptionComponent,
    postDescription,
    cta,
    icon,
  }) => {
    return (
      <>
        {descriptionAndInfoComponent ? (
          descriptionAndInfoComponent
        ) : (
          <>
            {" "}
            {icon && (
              <div className="mb-9 mx-auto">
                <img src={icon} />
              </div>
            )}
            {descriptions &&
              descriptions.map((description) => (
                <p
                  key={description}
                  className="text-is2-black-20 mb-2 mx-4 sm:mx-5 text-is2_md"
                >
                  {description}
                  <a
                    href={overlayLinkUrl}
                    target="_blank"
                    className="no-underline text-is2-blue hover:text-blue-400"
                  >
                    {overlayLinkText}
                  </a>
                </p>
              ))}
            {postDescription && (
              <span className="text-sm text-gray-500 mt-2">
                {postDescription}
              </span>
            )}
            {postDescriptionComponent && postDescriptionComponent}
            {cta && <CallToAction {...cta} />}
          </>
        )}
      </>
    );
  };
  