import React, {useContext} from 'react'
import {EcommerceDataContext} from "@/xAppLib/context";
import {useNavigate} from "react-router-dom";
import {get_cart_item_count} from "../EcommUtils";
import ShopAccessWrapper from "./ShopAccessWrapper";
import SIcon from "@/views/NUI/StreamlineIcons/SIcon";

const PharmacyButtonWithCartCount = ({onlyShowWhenPopulated = false}) => {
    const {cart} = useContext(EcommerceDataContext)
    const navigate = useNavigate()
    const items = cart?.items || []

    if(onlyShowWhenPopulated && !items.length) return <></>

    return (
        <ShopAccessWrapper showMessage={false}>
            <span className={'item dropdown'} id={'cart-icon-button'} onClick={_ => navigate('/prescription-checkout')}>
                <span className="hidden lg:block">Delivery</span>
                <div className="relative">
                    <SIcon name={'delivery-pill'} cls="p-[9px]" bold svg_cls="h-[18px] w-[18px]" />
                    {!!items.length &&
                        <span
                            className="absolute top-0.5 right-1 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-is2-orange rounded-full"
                        >
                            {get_cart_item_count(cart)}
                        </span>
                    }
                </div>
            </span>
        </ShopAccessWrapper>
    )
}
export default PharmacyButtonWithCartCount
