import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { Modal } from "semantic-ui-react";
import BarCode from "../UIelems/BarCode";

export function ViewEscriptModal({scid, trigger: Trigger}) {
	if (!scid) {
		return null;
	}

	return (
		<Modal closeIcon style={{maxWidth: '400px', padding: '1%'}} trigger={Trigger}>
			<Modal.Header>Scan eScript</Modal.Header>
			<Modal.Content>
				<div>
					<QRCodeSVG value={scid} width="100%" height="100%" level="H"/>
				</div>
				<div style={{margin: '2em 0'}}>
					<BarCode value={scid}/>
				</div>
				<div>
					<svg width="100%" height="100%" viewBox="0 0 20 1">
						<text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="1"
							  fontFamily="monospace">
							DSPID: {scid}
						</text>
					</svg>
				</div>
				<p style={{textAlign: 'center', fontSize: '100%'}}></p>
			</Modal.Content>
		</Modal>

	);
}