import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import getNotificationIcon from './getNotificationIcon';

const NotificationBannerItem = ({
    notification,
    onDismiss,
    usePlugin,
}) => {
    const navigate = useNavigate();
    const handleClick = () => {
        if (notification.link || notification.banner_link) {
            onDismiss();
            if (notification.link) {
                navigate(notification.link, notification.link_options || undefined);
            } else if (notification.banner_link) {
                navigate(notification.banner_link, notification.banner_link_options || undefined);
            }
        }
    };

    const plugin = usePlugin?.(notification.type);

    const banner_variant = getBannerVariant(notification);

    const isActive = handleClick && (notification.link || notification.banner_link);

    return (
        <div
            className={`notification-banner-item flex flex-row ${isActive ? 'group/container' : ''}`}
        >
            <MainBannerButton
                active={isActive}
                onClick={handleClick}
                banner_variant={banner_variant}
                persistent={notification.overrides?.persistent}
            >
                {!!notification.overrides?.show_banner_icon && (
                    <div className="pt-1">
                        <Icon
                            {...getNotificationIcon(notification)}
                            size="large"
                            className={banner_variant.icon_color_class}
                        />
                    </div>
                )}
                <div className="flex-1 text-left">
                    <h2 className="notification-banner-item--title !text-sm md:!text-[1.25rem] md:!leading-[2rem] font-semibold text-gray-800 mb-1">
                        <span>{notification.title}</span>
                        {(notification.link || notification.banner_link) && (
                            <span className="pl-0 group-hover:pl-1 group-focus-within:pl-1 transition-all duration-150"> ›</span>
                        )}
                    </h2>
                    {notification.body && !plugin?.banner && (
                        <div className="notification-banner-item--body line-clamp-4 md:line-clamp-2 group-hover:line-clamp-none group-focus-within:line-clamp-none">
                            {notification.body}
                        </div>
                    )}
                    {plugin?.banner && (
                        <div>
                            <plugin.banner notification={notification} />
                        </div>
                    )}
                </div>
            </MainBannerButton>
            {!notification.overrides?.persistent && (
                <button
                    onClick={onDismiss}
                    className={`
                    banner-main-button
                    ${banner_variant.background_class}
                    ${banner_variant.background_active_class}
                    ${banner_variant.border_color_class}
                    px-4
                    md:px-8
                    rounded-r-md
                    hover:${banner_variant.background_active_class}
                    transition-colors
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:relative
                    focus:isolation-isolate
                    focus:z-10
                    ${banner_variant.focus_ring_class}
                    flex items-start pt-8
                    group/closebutton
                `}
                    title="Dismiss notification"
                >
                    <span className={`
                    flex items-center justify-center rounded-full p-2
                    ${banner_variant.icon_background_class}
                    group-hover/container:bg-white/50
                    group-focus-within/container:bg-white/50
                    group-hover/closebutton:!bg-white/90
                    group-focus-within/closebutton:!bg-white/90
                `}>
                        <Icon
                            name="close"
                            size="large"
                            className={`
                            !m-0
                            !text-[1.5rem]
                            !leading-[1rem]
                            ${banner_variant.icon_color_class}
                        `}
                            aria-hidden="true"
                        />
                    </span>
                </button>
            )}
        </div>
    );
};

const info = {
    background_class: 'bg-blue-50',
    background_active_class: 'hover:bg-blue-100 group-hover/container:bg-blue-100',
    icon_color_class: 'text-blue-500',
    icon_background_class: 'bg-blue-100',
    focus_ring_class: 'focus:ring-blue-500 group-focus-within/container:bg-blue-100',
};
const positive = {
    background_class: 'bg-green-50',
    background_active_class: 'hover:bg-green-100 group-hover/container:bg-green-100',
    icon_color_class: 'text-green-500',
    icon_background_class: 'bg-green-100',
    focus_ring_class: 'focus:ring-green-500 group-focus-within/container:bg-green-100',
};
const negative = {
    background_class: 'bg-red-50',
    background_active_class: 'hover:bg-red-100 group-hover/container:bg-red-100',
    icon_color_class: 'text-red-500',
    icon_background_class: 'bg-red-100',
    focus_ring_class: 'focus:ring-red-500 group-focus-within/container:bg-red-100',
};
const warning = {
    background_class: 'bg-yellow-50',
    background_active_class: 'hover:bg-yellow-100 group-hover/container:bg-yellow-100',
    icon_color_class: 'text-yellow-500',
    icon_background_class: 'bg-yellow-100',
    focus_ring_class: 'focus:ring-yellow-500 group-focus-within/container:bg-yellow-100',
};

function getBannerVariant(notification) {
    switch (notification?.overrides?.banner_variant) {
        case 'info':
            return info;
        case 'positive':
            return positive;
        case 'negative':
            return negative;
        case 'warning':
            return warning;
        default:
            return info;
    }
}

function MainBannerButton({
    active,
    onClick,
    children,
    banner_variant,
    persistent,
}) {
    if (active) {
        return (
            <button
                onClick={onClick}
                className={`
                    group
                    banner-main-button
                    flex flex-wrap items-start gap-4
                    px-6 py-8
                    transition-colors
                    cursor-pointer
                    flex-grow
                    rounded-l-md
                    ${persistent ? 'rounded-r-md' : ''}
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:relative
                    focus:isolation-isolate
                    focus:z-10
                    ${banner_variant.focus_ring_class}
                    ${banner_variant.background_class}
                    ${banner_variant.background_active_class}
                `}
                title="Go to page"
            >
                {children}
            </button>
        );
    }
    return (
        <div
            className={`
            banner-main-no
            flex flex-wrap items-center gap-4
            px-6 py-8
            transition-colors
            flex-grow
            rounded-l-md
            ${banner_variant.background_class}
        `}
        >
            {children}
        </div>
    );

}

export default NotificationBannerItem;
