import React, { Component } from 'react'
import user_model from 'models/user_model'

import {
	Form,
	Icon,
	Message,
	Checkbox,
	Radio,
	Button,
	Modal
} from 'semantic-ui-react'

import MaskedInput from 'react-text-mask'
import { cls } from "views/NUI/utils";

import payment from '../providers/payment'
import gtm from '../providers/gtm'
import { PaymentCard } from '../../views/UIelems/v2/PaymentCard';
import StyledDrawer from '../../views/NUI/StyledDrawer';

const DEBUG = false

export function defaultSelectedCard(cards, initialSelected) {
	const validCards = (cards ?? []).filter(card => card.CC_tok.error === undefined);

	return initialSelected
		? validCards.find(card => card.CC_tok.token === initialSelected)
		: validCards[0];
}

export default class PaymentForm extends Component {

	constructor (props) {
		super(props)

		const ccs = (app.user.prof?.ccs || [])
		const { allowDelete = true } = this.props;

		const selected_card =
			this.props.initialSelected === 'paypal' && this.props.acceptPaypal && 'paypal'
			|| defaultSelectedCard(ccs, this.props.initialSelected)
			|| 'new';

		this.state = {
			values: [],
			ccs,
			selected_card,
			saveCard: !!app.user.user_in,
			allowDelete,
			show_modal: false,
			newCard: []
		}

		this.handleInputChange = this.handleInputChange.bind(this);
	}

	// 		--------------------------------		--------------------------------		---------

	componentDidMount() {
		if (this.state.selected_card != 'new') {
			this.props.onCompleteInput?.(false)
		}
		this.props?.onMount?.(this.state.selected_card)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.visible && !prevProps.visible) {
			if (this.state.selected_card != 'new') {
				this.props.onCompleteInput?.(false)
			}
		}
		if (prevState.selected_card !== 'paypal' && this.state.selected_card === 'paypal'){
			this.setState({
				values: []
			});
		}
	}

	handleCardSelected(card) {
		this.setState({selected_card: card});
		this.props.onCardSelected?.(card);
	}

	handleInputChange(event, data) {

		const { values } = this.state

		const target = data || event.target;

		// target.pattern && DEBUG && console.log( new RegExp("([^"+target.pattern+"])", "gi"), target.value.replace(new RegExp("([^"+target.pattern+"])", "gi") , '-') );

		values[target.name] = target.type == 'checkbox' ? !target.value : target.pattern ? target.value.replace(new RegExp("([^"+target.pattern+"])", "gi") , '-') : target.value;

		const numFields = Object.keys(values).reduce((tot,k)=>tot+((values[k]||'').length>0?1:0),0)
		if (numFields == 4)
			this.props.onCompleteInput?.(true)

		this.setState({
			values,
		});

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	async get_token() {

		const { values:vals, selected_card } = this.state
		const { address } = this.props

		DEBUG && console.log('PaymentForm :: get_token ', {selected_card,vals});
		if (selected_card != 'new') {
			return selected_card
		}

		const {paym_name, paym_ccnum, paym_ccexp, paym_cccvc} = vals

		if (!paym_ccnum || !paym_ccexp || !paym_cccvc)
			throw new Error("Missing card details");

		const valids = {}

		for (const f of ['paym_name','paym_ccnum','paym_ccexp','paym_cccvc']) {
			const val = vals[f]
			valids[f] = !!val && val.length>0
		}

		DEBUG && console.log({valids});

		this.setState({valids})

		const some_errors = valids && Object.values(valids).some(v=>!v) || false

		if (some_errors)
			throw new Error("Invalid card details");


		const [paym_ccexpm, paym_ccexpy] = paym_ccexp.split("/")

		const str_card = {
					number: paym_ccnum.replace(/[\-_]/g, ''),
					cvc: paym_cccvc,
					expiry_month: paym_ccexpm,
					expiry_year: "20" + paym_ccexpy,
					name: paym_name,
					address_line1: address || '.',
					address_city: '.',
					address_country: 'AU',
		}

		DEBUG && console.log('PaymentForm :: get_token - payment.tokeniseCard ', str_card);
		// DEBUG && console.log(vals, {paym_want, paym_name, paym_ccnum, paym_ccexpm, paym_ccexpy, paym_cccvc}, str_card);

		try {

			const CC_tok = await payment.tokeniseCard(str_card, true)
			!!(this.state.saveCard) ? 
				user_model.save_card(
					CC_tok, paym_name, paym_ccnum, paym_ccexp,
					(card, index) => {
						DEBUG && console.log("Response", { card, index })
						if (card)
							this.handleCardSelected(card)
					}
				)
			: 
				this.setState({
					newCard: {
						type: 'new',
						CC_tok,
						name: paym_name, 
						short: paym_ccnum.substring(paym_ccnum.length - 4)
					}
				})
			return { name: paym_name, CC_tok }

		} catch (err) {

			DEBUG && console.log('CATCH ERROR - PaymentForm::get_token - ', err.message);
			this.setState({valids:{
					paym_name: false,
					paym_ccnum: false,
					paym_ccexp: false,
					paym_cccvc: false,
				}
			})
			throw new Error(err.message)

		}

	}

	displayPaypal() {
		const {acceptPaypal = false} = this.props
		return app.settings.show_paypal && acceptPaypal
	}

	getSelectedCard(cards, initialSelected) {
		const validCards = cards.filter(card => card.CC_tok.error === undefined)
		const selected = initialSelected ? validCards.find(card => card.CC_tok.token === initialSelected) : validCards[0];
		return validCards.length > 0 && selected || 'new'
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	
	render () {

		const { values, valids, newCard, selected_card } = this.state
		const { visible = true, clean, isInvalid= false } = this.props
		const img_path = "https://storage.googleapis.com/instant-med-public/"
		const customAddCardMessage = this.props?.addCardMessage

		const ccs = (app.user.prof?.ccs || [])

		if (!visible) return null

		const addCardButton = <Button type="button" data-testid="button-add-card" basic onClick={_ => this.setState({ show_modal: true, selected_card: 'new' })} className="w-full md:max-w-fit">{app.settings.iscr2 && ccs.length ? "Add additional card" : "Add a card"}</Button>;

		return <div className='payment-form flex flex-col'>

			{this.render_ccs(img_path)}

			{this.render_payment_form()}

			{clean
				? addCardButton
				: (
					<div className={cls([
						(!ccs?.length && !newCard?.name ? "order-first border rounded-lg p-4 mb-2" : "order-last bg-white"),
						isInvalid ? 'border-red-300 bg-red-50' : 'border-is-gray-50 bg-is-gray'
					])}>
						{!ccs?.length && !newCard?.name && <p className="text-base">{customAddCardMessage || 'Your card will not be charged until the completion of your doctor consultation.'}</p>}
						{addCardButton}
					</div>
				)}

			{this.displayPaypal() && <div className={cls(["card border p-2 rounded-lg", isInvalid ? 'border-red-300 bg-red-50' : 'border-is-gray-50'])}><Radio label={{ children: <div className="flex items-center text-sm" data-testid="radio-paypal"><span className="shadow-sm rounded-sm flex justify-center mr-2 bg-white h-5 w-[30px]"><img className="h-[10.97px] self-center" src={img_path+"PayPal.png"} border="0" alt="PayPal Logo" /></span>Pay with PayPal</div> }} value={'paypal'} checked={'paypal' == selected_card}
																																							   onChange={_ => this.handleCardSelected('paypal')} /></div>}

		</div>

	}


	render_ccs(img_path) {
		const { selected_card, values, newCard } = this.state
		const { mode = 'select', isInvalid = false } = this.props

		let ccs = (app.user.prof?.ccs || [])

		if (newCard?.name) ccs = [...ccs, newCard]

		if ((!ccs || !ccs.length))
			return null

		return <div className='flex flex-col'>
			{app.settings.iscr2 && mode === "payment-settings" ? 
			<>
				<div className="my-6">
					<p className="font-medium">Primary card:</p>
					<PaymentCard card={selected_card} edit onEdit={_=> this.setState({ show_modal:true, editCard:selected_card, confirmDeleteCard:false })} />
				</div>
				
				<div className="mb-6 flex flex-col gap-3">
					<p className="font-medium mt-6">Other cards:</p>
					{ccs?.filter(c => selected_card?.CC_tok?.token != c?.CC_tok?.token).map((card, i) => <PaymentCard card={card} edit onEdit={_=> this.setState({ show_modal: true, editCard:card, confirmDeleteCard:false })} />)}
				</div>
			</>
			: 
			ccs?.map((card, i) => <div key={card?.CC_tok?.token + i} className={cls(["card border p-2 rounded-lg", isInvalid ? 'border-red-300 bg-red-50' : 'border-is-gray-50'])} data-testid="section-saved-card">
				{mode === 'view' ?
					<label><span className="flex text-sm"><span className="shadow-sm rounded-sm flex justify-center mr-2 bg-white h-5 w-[30px]">
						<img className="h-[10.97px] self-center" src={img_path+"Mastercard.png"} border="0" alt="Mastercard Logo" /></span>
						{card.short} – Expires {card.exp}</span>
						<small>{card.name}{!card?.CC_tok?.token && <span className='text-red-600'>This card token is invalid. Please use a different card.</span>}</small>
					</label> :
					<Radio label={<label><span className="flex text-sm">
						<span className="shadow-sm rounded-sm flex justify-center mr-2 bg-white h-5 w-[30px]"><img className="h-[10.97px] self-center" src={img_path+"Mastercard.png"} border="0" alt="Mastercard Logo" /></span>{card.short}</span><small>{card.name}{!card?.CC_tok?.token && <span className='text-red-600'>This card token is invalid. Please use a different card.</span>}</small></label>} 
						value={i} 
						checked={card?.CC_tok?.token && selected_card?.CC_tok?.token == card?.CC_tok?.token}
						onChange={_ => this.handleCardSelected(card)}
						disabled={!card?.CC_tok?.token}
					/>
				}
				{this.state.allowDelete && card.type != 'new' && (
					<Button type="button" size="tiny" basic className="self-center mr-2" onClick={_ => {
						this.setState({ show_modal: true, confirmDeleteCard: card, confirmDeleteCardMessage: app.settings.iscr2 ? <PaymentCard card={card} clsn="mb-3" /> : <p>This will remove card XXXX-XXXX-XXXX-{card.short} from your saved cards. Are you sure?</p> })
					}}>Remove</Button>
				)}
				</div>)}
			
		</div>
	}

	onSaveCard = async () => {
		this.setState({onSaving:true,error:false})
		try {
			const paym = await this.get_token()
			this.setState({
				onSaving:false,
				show_modal:false,
				values: {
					paym_name: '',
					paym_ccnum: '',
					paym_ccexp: '',
					paym_cccvc: ''
				}
			})
			this.props.onSaved && this.props.onSaved(paym)
			gtm.log('', 'save_card');
		} catch (e) {
			this.setState({
				onSaving: false,
				error: e.message,
			})
			return
		}
	}


	render_payment_form() {
		const { values, valids, selected_card, show_modal, editCard, confirmDeleteCard, confirmDeleteCardMessage, newCard } = this.state
		const short = this.state.editCard?.short||'';
		const title = confirmDeleteCard && app.settings.iscr2 && 'The following card will be removed:' || confirmDeleteCard && 'Remove saved card' || editCard && `Card ${short}` || 'Add a card'

		const content = <>
		{editCard && <div className="flex flex-col gap-3 max-w-[350px] mx-auto">
							{(editCard?.CC_tok?.token && (selected_card.CC_tok?.token != editCard.CC_tok?.token)) && <Button type="button" loading={this.state.onSaving} disabled={this.state.onSaving} basic fluid onClick={_ => {
								const card = editCard
								this.setState({ onSaving: true})
								user_model.primary_card(card, _ => {
									this.handleCardSelected(card)
									this.setState({ show_modal: false, confirmDeleteCard: false, editCard: false, onSaving: false })
								})
								}}>Make primary</Button>}
							<Button type="button" loading={this.state.onSaving} disabled={this.state.onSaving} basic fluid onClick={_ => {this.setState({ confirmDeleteCard: this.state.editCard, editCard: false, confirmDeleteCardMessage: <PaymentCard card={editCard} clsn="mb-3" />}) }}>Remove card</Button>
							<Button type="button" loading={this.state.onSaving} disabled={this.state.onSaving} basic fluid onClick={_ => {this.setState({ show_modal: false, editCard: false })}}>Cancel</Button>
						</div>
						|| confirmDeleteCard && <>
							{confirmDeleteCardMessage}
							<div className="flex gap-2 mt-2">
								<Button type="button" loading={this.state.onSaving} disabled={this.state.onSaving} basic fluid onClick={_ => { this.setState({ show_modal: false, confirmDeleteCard:false }) }}>Cancel</Button>
								<Button type="button" loading={this.state.onSaving} disabled={this.state.onSaving} fluid basic={app.settings.iscr2} color="red" onClick={_ => {
									const card = this.state.confirmDeleteCard
									this.setState({ onSaving: true})
									user_model.delete_card(card, _ => {
										this.handleCardSelected(selected_card.CC_tok?.token == card.CC_tok?.token ? (app.user.prof?.ccs[0] || 'new') : selected_card)
										this.setState({ show_modal: false, confirmDeleteCard: false, onSaving: false })
									})
								}}>{app.settings.iscr2 ? "Confirm" : "Remove"}</Button>
							</div></>
						||
							<><div className='field'>
								<label>Cardholder Name</label>
								<Form.Field
									placeholder='Name of the card'
									control='input'
									type='text'
									name='paym_name'
									data-testid='input-card-name'
									required
									value={values['paym_name'] || ''}
									onChange={this.handleInputChange}
									error={valids && !valids['paym_name']}
									disabled={selected_card === 'paypal'}
									className='ui fluid input mb-2 lg:mb-4'
								/>
							</div>

								<div className="flex flex-wrap lg:flex-nowrap gap-2">
									<div className='w-full lg:w-3/5'>
										<Form.Input
											label={<label style={{ display: 'block', marginRight: 0 }} >
												<div style={{ float: 'right' }}>
													<Icon name='cc visa' />
													<Icon name='cc mastercard' />
													<Icon name='cc amex' />
												</div>
												Card Number
											</label>}
											required
											children={
												<MaskedInput
													mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
													placeholder="XXXX-XXXX-XXXX-XXXX"
													value={values['paym_ccnum'] || ''}
													name='paym_ccnum'
													data-testid='input-card-number'
													onChange={this.handleInputChange}
													type='text'
												/>
											}
											disabled={selected_card === 'paypal'}
											error={valids && !valids['paym_ccnum']}
											className='flex flex-col !mb-3'
										/>
									</div>
									<div className='w-[calc(50%-4px)] lg:w-1/5'>
										<Form.Input
											label='Expiry'
											required
											children={
												<MaskedInput
													mask={[/\d/, /\d/, '/', /\d/, /\d/]}
													placeholder="MM/YY"
													value={values['paym_ccexp'] || ''}
													name='paym_ccexp'
													onChange={this.handleInputChange}
													type='text'
													data-testid='input-card-expiry'
												/>
											}
											disabled={selected_card === 'paypal'}
											error={valids && !valids['paym_ccexp']}
											className='flex flex-col'
										/>
									</div>
									<div className='field required w-[calc(50%-4px)] lg:w-1/5'>
										<label>CVC</label>
										<Form.Field
											placeholder='CVC'
											control='input'
											type='number'
											required
											name='paym_cccvc'
											value={values['paym_cccvc'] || ''}
											data-testid='input-card-cvc'
											onChange={this.handleInputChange}
											error={valids && !valids['paym_cccvc']}
											disabled={selected_card === 'paypal'}
											className='ui input flex flex-col'
										/>
									</div>
								</div>
								{this.state.error && <Message negative>
									<Message.Header>There's a problem</Message.Header>
									<p>{this.state.error}</p>
								</Message>}
								{app.user.user_in && !this.props.showSave && <Checkbox label="Save this card for later" className="-ml-2"
									onChange={_ => this.setState((prevState) => ({ saveCard: !prevState.saveCard }))}
									checked={this.state.saveCard}
									disabled={selected_card === 'paypal'}
								/>}
								{<div className="flex gap-2 mt-4">
									<Button type="button" loading={this.state.onSaving} disabled={this.state.onSaving} basic fluid onClick={_ => {
										this.setState({ show_modal: false, selected_card: defaultSelectedCard(this.state.ccs) })
									}}>Cancel</Button>
									<Button type="button" loading={this.state.onSaving} disabled={this.state.onSaving} data-testid="button-save-card" primary fluid onClick={this.onSaveCard}>Save Card</Button>
								</div>}
							</>}</>

		return app.settings.iscr2 ? 
					<StyledDrawer
						drawerStyle="blue"
						isOpen={this.state.show_modal}
						title={title}
						dismissible={false}
						content={<div className="space-y-4">{content}</div>}
					/>		
				: 	<Modal open={!!show_modal}
						onClose={_ => this.setState({ show_modal: false, confirmDeleteCard: false, editCard: false, selected_card: defaultSelectedCard(this.state.ccs) })}
						closeIcon 
						className="!max-w-[500px] rounded-lg">

						<Modal.Header content={title} />
							
						<Modal.Content className='ui form pt-0'>
								{content}
							</Modal.Content>
					</Modal>
	}
}
