/**
 * Notification priority levels
 * @readonly
 * @type {Object<string, string>}
 * @property {string} LOW - Shows as unread in list
 * @property {string} NORMAL - Shows as unread in list + displays as toast message
 * @property {string} HIGH - Shows as unread in list + displays as pinned toast message + pinned in list until read + sends push notification
 * @property {string} URGENT - Shows as unread in list + displays as pinned banner until dismissed/read + pinned in list until read + sends push notification
 */
const PRIORITY = Object.freeze({
    LOW: 'low',
    NORMAL: 'normal',
    HIGH: 'high',
    URGENT: 'urgent',
});

const PRIORITY_OPTIONS = [PRIORITY.LOW, PRIORITY.NORMAL, PRIORITY.HIGH, PRIORITY.URGENT];

export { PRIORITY, PRIORITY_OPTIONS };
