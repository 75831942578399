import moment from "moment";
import React, { useMemo, useState } from 'react';
import webrtc_logs_model from "models/webrtc_logs_model";
import { FormInput, Tab } from "semantic-ui-react";
import { useAsync } from "xAppLib/Hooks/useAsync";
import { useDebouncedValue } from "../../xAppLib/Hooks";
import Alert from "../NUI/Alert";

export function TwilioTraceLogs({data}) {
	const uids = Array.from(new Set(Object.values(data?.logs ?? {}).map(log => log.uid))).filter(Boolean);
	const sessionId = data.twRoomSid;
	const [filter, setFilter] = useState('');
	const debounced = useDebouncedValue(filter, 600);
	const [filterFn, error] = useMemo(() => {
		const trimmed = debounced.trim().toLowerCase();
		const basicMatch = entry => entry.toLowerCase().includes(trimmed);

		if (trimmed.length === 0) return [_ => true];
		if (trimmed[0] === '/' && trimmed.at(-1) === '/' && trimmed.length > 2) {
			const _re = debounced.slice(1, -1);
			try {
				const re = new RegExp(_re, 'i');
				return [entry => re.test(entry)];
			} catch (e) {
				return [basicMatch, e.message]
			}
		}
		return [basicMatch]

	}, [debounced])

	return (
		<div className="space-y-2">
			<div>
				<FormInput label="Filter Expression" value={filter} onChange={e => setFilter(e.target.value)}/>
				{error && <div className="text-red-700">{error}</div> }
			</div>
			{uids.map(uid => <WatchLogs uid={uid} sessionId={sessionId} filter={filterFn}/>)}
		</div>
	);
}

const WatchLogs = React.memo(function WatchLogsComponent({sessionId, uid, filter}) {
	const rec = useAsync({
		immediate: sessionId && uid,
		fn: () => webrtc_logs_model.load_trace_logs(uid, sessionId),
	}, [uid, sessionId]);

	if (rec.loading) return 'Loading...';
	if (rec.error) return <Alert error header={`Loading logs ${uid}/${sessionId}`}
								 content={rec.error?.err ?? rec.error?.message ?? 'Unknown'}/>;
	if (!rec.data) return 'No data';

	const sessions = Object.entries(rec.data ?? {});

	return (
		<Tab panes={sessions.map(([_id, {name, logs}], i) => ({
			menuItem: `${name} (${i+1}) ${Math.floor(JSON.stringify(logs).length / 1024)}kB`,
			render: () => (
				<pre className="max-h-96 overflow-y-scroll">{Object.entries(logs).map(([_key, log]) => [
						moment(log.datetime).format('HH:mm:ss'),
						log.logLevel.charAt(0).toUpperCase(),
						log.component,
						log.message,
						typeof log.data === 'string' ? log.data : JSON.stringify(log.data),
					].join(' '),
				).filter(filter).join('\n')}</pre>
			),
		}))}/>
	);
});
