import moment from "moment";
import React, { useMemo } from 'react';
import { Accordion, Icon } from "semantic-ui-react";
import { UI_DATETIME_FMT } from "../../helpers/datetime";
import groupBy from "xAppLib/helpers/groupBy";
import { MedSection } from "../doc/doccons/MedSection";
import { inferQuality, STATUS_COLORS } from "./OpenTokNetworkTest";

function QuickStats({stats, title, className, track}) {
	return (
		<div className={className}>
			<div>{title}</div>
			{track && <small>{track.label ?? '?' } ({track.readyState ?? '?'})</small>}
			<div className="flex-horizontal justify-between space-y-2">
				<div className="ml-4">
					<Icon name="video" color={STATUS_COLORS[stats.video.overall_status]}/>{" "}
					{stats.video.bitrate}kbps @ {stats.video.fps}fps{" "}{stats.video.loss}% loss
				</div>
				<div className="ml-4">
					<Icon name="microphone" color={STATUS_COLORS[stats.audio.overall_status]}/>{" "}
					{stats.audio.bitrate}kbps, {stats.audio.loss}% loss
				</div>
			</div>
		</div>
	);
}

export function VidCallPeriodicStats({logs}) {
	const stats = useMemo(() => groupBy(
		Object.values(logs || {})
			.filter(entry => entry.label === 'stats')
			.map(s => ({
				...s,
				// The pub/sub stream ids are flipped from the dr to nurse side, so sort them to ensure the key is
				// consistent, and we can associate the stats from the same call together
				key: [s.pub.streamId, s.sub?.streamId ?? 'alone'].sort().join('-'),
				pub: {
					...s.pub,
					stats: inferQuality(s.pub.stats),
				},
				...(s.sub && {
					sub: {
						...s.sub,
						stats: inferQuality(s.sub.stats),
					},
				}),
			})),
		x => x.key,
	), [logs]);

	if (stats.length === 0) {
		return null;
	}

	return (
		<Accordion className="space-y-2">
			{Object.entries(stats).map(([key, records]) => (
				<MedSection key={key}
							title={records[0].sub
								? `Call between ${records[0].pub.name} and ${records[0].sub.name} (${records.length})`
								: `${records[0].pub.name} waiting`
							}
							startOpen>

					{records.sort((a, b) => a.elapsed - b.elapsed).map(({tm, pub, sub, elapsed, provider}) => (
						<React.Fragment key={pub.streamId + elapsed}>

							<strong>
								{provider && <><Icon color="pink" name="fire extinguisher"/>{provider}, </>}
								{moment(tm).format(UI_DATETIME_FMT)} ({Math.floor(elapsed / 1000)}s)
							</strong>

							{sub ? (
								<>
									<QuickStats title={<>{pub.name} &rarr; {sub.name}</>} stats={pub.stats} track={pub.track}/>
									<QuickStats title={<>{pub.name} &larr; {sub.name}</>} stats={sub.stats}/>
								</>
							) : (
								<QuickStats title={<>{pub.name}</>} stats={pub.stats}/>
							)}
						</React.Fragment>
					))}
				</MedSection>
			))}
		</Accordion>
	);
}