import React from "react";
import { Container } from "semantic-ui-react";
import NotificationBannerItem from './NotificationBannerItem';

const NotificationBanners = ({
    notifications,
    loading,
    setNotificationRead,
    usePlugin,
}) => {
    if (loading || notifications.length === 0) return null;

    const bannerGridNotifications = notifications.filter(n => n.overrides?.banner_grid);
    const bannerNotifications = notifications.filter(n => !n.overrides?.banner_grid);

    return (
        <div className="notification-banners shadow-inner border-b border-gray-200 bg-white">
            <Container>
                <div className="flex flex-col gap-3 mx-auto p-4">
                    {bannerGridNotifications.length > 0 && (
                        <div className={`notification-banners--grid grid gap-3 [&_.banner-main-button]:px-6 [&_.banner-main-button]:py-4 ${
                            bannerGridNotifications.length === 1 ? 'lg:grid-cols-1' : 
                            bannerGridNotifications.length === 2 ? 'lg:grid-cols-2' : 
                            'lg:grid-cols-3'
                        }`}>
                            {bannerGridNotifications.map((notification) => (
                                <NotificationBannerItem
                                    key={notification.notification_id}
                                    notification={notification}
                                    onDismiss={() => {
                                        setNotificationRead(notification.notification_id, true);
                                    }}
                                    usePlugin={usePlugin}
                                />
                            ))}
                        </div>
                    )}
                    {bannerNotifications.length > 0 && (
                        <div className="notification-banners--list flex flex-col gap-3">
                            {bannerNotifications.map((notification) => (
                                <NotificationBannerItem
                                    key={notification.notification_id}
                                    notification={notification}
                                    onDismiss={() => {
                                        setNotificationRead(notification.notification_id, true);
                                    }}
                                    usePlugin={usePlugin}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default NotificationBanners;
