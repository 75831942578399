import React, { useState } from "react";
import { Accordion, Header, Icon } from "semantic-ui-react";
import TableOfContent from "../../../xAppLib/TableOfContent";

export function MedSection({title, startOpen = false, children, className, headerAs = 'h4', extra = null}) {
	const [isSectionOpen, setIsSectionOpen] = useState(startOpen);

	return (
		<>
			<Accordion.Title active={isSectionOpen} data-testid='section-title' onClick={() => setIsSectionOpen(v => !v)}>
				<Header as={headerAs} block textAlign='center'>
					<Icon name='dropdown'/>
					<TableOfContent.Marker active={isSectionOpen}>{title}</TableOfContent.Marker>
				</Header>
			</Accordion.Title>

			<Accordion.Content active={isSectionOpen} style={{marginBottom: '2em'}} className={className}>
				{extra}
				{children}
			</Accordion.Content>
		</>
	)
}

