import moment from "moment-timezone";
import { from_melb_ui_tm } from "../helpers/datetime";

export function withUr(record) {
	return record.user_iding ? ({...record, ur: `UR_${record.user_iding?.substr(0, 10).toUpperCase()}`}) : record;
}

export function sort_med_scripts_for_wr(records) {
	return Object.values(records || {}).map(rec => ({
		...rec,
		add_ts: from_melb_ui_tm(rec.add_tm).valueOf(),
	})).sort((x, y) => {

		if (x.status === 'could_not_call')
			return 1;

		// if no answer status, deprioritize for 1 hr
		const one_hour_ago = moment().subtract(1, 'hour');
		if (["noans_doccall", "wrong_number"].includes(y.status) && one_hour_ago.isBefore(y.upd)) {
			return -1;
		}
		if (["noans_doccall", "wrong_number"].includes(x.status) && one_hour_ago.isBefore(x.upd)) {
			return 1;
		}

		// if they're both null or the same order value, sort oldest to newest
		if (x.ord === y.ord) {
			return x.add_ts - y.add_ts;
		}


		// put any unknown order cases to the end
		if (x.ord == null) return 1;
		if (y.ord == null) return -1;

		// otherwise sort by order value
		return x.ord - y.ord;
	});
}

