import React from 'react';
import moment from 'moment';
import { List } from "semantic-ui-react";
import { UI_DATE_FMT } from "../../helpers/datetime";
import admin_users_model from "../../models/admin_users_model";
import { useAsync } from "../../xAppLib/Hooks/useAsync";
import CopyField from "../../xAppLib/UIelems/CopyField";
import { rejectOnResErr } from "../icosm/treatment/utils";

const TIME_FORMAT = 'HH:mm:ss';

/**
 * @typedef {Object} SeenTimings
 * @property {number} l - time the waiting room was first loaded
 * @property {number} c - client timestamp of when an await/taken record was first seen
 * @property {number} s - server timestamp of when an await/taken record was first seen
 */

/**
 * @typedef {Object} RecordWithTimings
 * @property {number} t0
 * @property {number} t1
 * @property {Record<string, SeenTimings>=} seen
 */

const USER_CACHE = {};
const try_load_user = async uid => (
	await admin_users_model.load_users_list({filters: {srch_str: uid}})
		.then(rejectOnResErr)
		.then(list => list[0])
		.catch(() => null)
);

/**
 * @param {Object} props
 * @param {RecordWithTimings} props.record
 * @return {React.JSX.Element|null}
 * @constructor
 */
export function FlowTimings({record}) {
	const uids = Array.from(new Set(Object.keys(record?.seen || {})));
	const uid_str = uids.sort().join(',');

	const users = useAsync({
		immediate: uids.length,
		fn: async () => {
			const promises = uids.map(async uid => [uid, await (USER_CACHE[uid] ||= try_load_user(uid))]);

			const entries = await Promise.all(promises);

			return Object.fromEntries(entries);
		},
	}, [uid_str]);
	const uidToName = users.data || {};

	if (!record) return null;
	if (!(record.t0 || record.t1)) return null;

	return (
		<div className="flow-timings whitespace-nowrap">
			<div>Submission: {elapsed_time(record.t0, record.t1)} ({time(record.t0)}&ndash;{time(record.t1)})</div>
			{record.seen && Object.keys(record.seen).length > 0 ? (
				<List>
					{uids.map(uid => (
						<List.Item key={uid}>
							<List.Header><CopyField val={uid} label={displayName(uidToName[uid]) ?? uid}/></List.Header>
							<List bulleted>
								{Object.entries(fix_old_records(record.seen[uid])).map(([dvcid, {l, c, s}]) => (
									<List.Item key={dvcid}>
										<code>{dvcid}</code> ({skew(c, s)})
										<List bulleted>
											{Object.entries({
												load: l,
												created: record.t1,
												seen: s,
											}).sort(([_k1, v1], [_k2, v2]) => v1 - v2).map(([event]) => {
												if (event === 'load') {
													return (
														<List.Item key={event}>
															<code>{time(l)}</code>: load {date_if_different(l, s)}
														</List.Item>
													);
												}
												if (event === 'created') {
													return (
														<List.Item key={event}>
															<code>{time(record.t1)}</code>: created
														</List.Item>
													);
												}
												if (event === 'seen') {
													return (
														<List.Item key={event}>
																<code>{time(s)}</code>: seen ({compare_time(Math.max(record.t1, l), s)})
														</List.Item>
													);
												}

												return null;
											})}
										</List>
									</List.Item>
								))}
							</List>
						</List.Item>
					))}
				</List>
			) : (
				<div>Not seen by anyone? 🤔</div>
			)}
		</div>
	);
}

function fix_old_records(record) {
	if ('l' in record) {
		return {'unknown device': record};
	}

	return record;
}

function displayName(user) {
	if (!user) {
		return null;
	}

	return [user.first_name, user.last_name].filter(Boolean).join(' ') || user.email;
}

function time(t) {
	return moment(t).format(TIME_FORMAT);
}

function elapsed_time(t0, t1) {
	const {val, unit} = time_diff(t0, t1);
	return `${val}${unit}`;
}

function skew(client, server) {
	const pos = client > server > 0 ? 'fast' : 'slow';
	const {val, unit} = time_diff(client, server);
	return `${pos} by ${Math.abs(val)}${unit}`;
}

function compare_time(t0, t1) {
	const when = t0 > t1 > 0 ? 'before(?)' : 'after';
	const {val, unit} = time_diff(t0, t1);
	return `${val}${unit} ${when}`;
}

function time_diff(t0, t1) {
	const ms = t1 - t0;
	if (Math.abs(ms) > 10000) {
		return {val: Math.round(ms / 1_000), unit: 's'};
	}
	if (Math.abs(ms) > 1000) {
		return {val: +(ms / 1_000).toFixed(2), unit: 's'};
	}
	return {val: ms, unit: 'ms'};
}

function date_if_different(target, candidate) {
	const t0 = moment(target);
	if (t0.isSame(candidate, 'day')) return null;

	return `(${t0.format(UI_DATE_FMT)})`;
}