import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import HealthRecordOverviewRenderer from './HealthRecordOverviewRenderer';
import DocumentErrorBoundary from './DocumentErrorBoundary';



const iframeStyle = 'w-[1000px] h-full';

const cache = {}

const xsl_prefix = `https://storage.googleapis.com/myhr-public/`

const MyHRPathoXslString = 'DH_Diagnostic_Imaging_And_Pathology_View_CDA_Stylesheet-1.1.2.xsl';
const MyHRCDAXslString = 'DH_Generic_CDA_Stylesheet-1.7.4.xsl';
const MyHRPDVXslString = 'NEHTA_PCEHR_Prescription_and_Dispense_View_CDA_Stylesheet-1.1.1.xsl';


async function load_xsl(url) {
	if (!cache[url])
		cache[url] = fetch(url).then(r=>r.text())

	return await cache[url]
}

const DocumentRendererWrapper = ({ fileData, onOpenDocument, changeView }) => (
	<DocumentErrorBoundary {...{fileData}}>
		<DocumentRenderer {...{ fileData, onOpenDocument, changeView }} />
	</DocumentErrorBoundary>
);

const DocumentRenderer = ({ fileData, onOpenDocument, changeView }) => {
	const ref = useRef(null);
	const fileType = fileData.shortName?.split('.').pop().toLowerCase();
	const renderType = fileData?.renderType;
	const [xmlSrc, setXmlSrc] = useState();
	const loadStylesheet = async () => {
		const xsl = (
			(renderType === 'prdiv' && MyHRPathoXslString) ||
			(renderType === 'pdv' && MyHRPDVXslString) ||
			(renderType === 'cda' && MyHRCDAXslString) ||
			MyHRCDAXslString
		);
		return await load_xsl(`${xsl_prefix}${xsl}`)
	};

	const useGeneralisedXMLRenderer = ['cda', 'prdiv', 'pdv'].includes(renderType) || (!renderType && fileType === 'xml');

	const useHROXMLRenderer = renderType === 'hro';

	useEffect(() => {
		if (useGeneralisedXMLRenderer) {
            const getData = async () => {
                const xml = new DOMParser().parseFromString(atob(fileData.dataBase), 'text/xml');
                const xslStyle = await loadStylesheet();
                const xsl = new DOMParser().parseFromString(xslStyle, 'text/xml');

                const xsltProcessor = new XSLTProcessor();
                xsltProcessor.importStylesheet(xsl);

                const resultDocument = xsltProcessor.transformToDocument(xml);
                const serializer = new XMLSerializer();
                const resultString = serializer.serializeToString(resultDocument);

                const blob = new Blob([resultString], { type: 'text/html' });
                const url = URL.createObjectURL(blob);
                setXmlSrc(url);
            };
            getData();
		}
	}, [fileData]);

	const clickHandler = useCallback((e) => {
		const target = e.currentTarget;
		const href = target.getAttribute('href');
		// console.log("clickHandler",e,target,href)
		if (href?.[0] == '#') return;

		e.preventDefault();
		onOpenDocument?.(href, target);

		return false;
	}, []);

	useEffect(() => {
		if (!useGeneralisedXMLRenderer) return;
		const current = ref.current;
		let matches;

		// poor man's ready event
		setTimeout(() => {
			matches = current.contentDocument.querySelectorAll('a');
			matches.forEach((m) => m.addEventListener('click', clickHandler, true));
		}, 100);

		return () => {
			matches?.forEach((m) => m.removeEventListener('click', clickHandler, true));
		};
	}, [xmlSrc, useGeneralisedXMLRenderer]);

	// Also need to add fallback if XML cannot be parsed.
	return (
		<div className='w-full'>
			{fileData && (
				<p className='py-2 my-0'>
					This file was downloaded from the My Health Record system on{' '}
					{moment(fileData.retrievalDataTime).format('DD-MM-YYYY h:mma')}
				</p>
			)}
			{useGeneralisedXMLRenderer && <iframe ref={ref} src={xmlSrc} className='w-full h-full' />}
			{useHROXMLRenderer && (
				<div className="className='w-full h-full'">
					<HealthRecordOverviewRenderer {...{ data: fileData.dataBase, changeView }} />
				</div>
			)}
			{fileType === 'pdf' && (
				<iframe src={`data:application/pdf;base64,${fileData.dataBase}`} className={iframeStyle} type='application/pdf' />
			)}
			{fileType === 'html' && <iframe srcDoc={atob(fileData.dataBase)} className={iframeStyle} />}
			{fileType === 'png' && <img src={`data:image/png;base64,${fileData.dataBase}`} alt='Description' />}
		</div>
	);
};

export default DocumentRendererWrapper;
