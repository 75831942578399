import { useState, useEffect } from 'react';

export default function useWithTime(fn, interval = 1000) {
	const [value, setValue] = useState(fn);

	useEffect(() => {
		const _interval = setInterval(() => {
			const newVal = fn();
			setValue(newVal);
		}, interval);

		return () => clearInterval(_interval);
	}, []);

	return value;
}
