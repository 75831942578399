import React from 'react';
import PropTypes from 'prop-types';
import useEmblaCarousel from 'embla-carousel-react';
import AutoScroll from 'embla-carousel-auto-scroll'
import { ConditionCardItem } from './ConditionCardItem';

/**
 * A carousel component for condition cards.
 *
 * It uses `embla-carousel` under the hood and will automatically scroll through the
 * condition cards when the component is mounted. The carousel is only visible on
 * mobile devices.
 *
 * @param {{title: string, imageUrl: string, url: string}[]} items - An array of condition card items.
 */
const ConditionCardCarousel = ({ items }) => {
  const [emblaRef] = useEmblaCarousel({
      loop: true,
      align: 'start',
      speed: 6,
      autoplay: true, 
    }, 
    [
      AutoScroll({ 
        speed: 0.6, 
        startDelay: 200, 
        stopOnInteraction: false, 
        stopOnMouseEnter: true 
      })
    ]
  );

  return (
    <section className="sm:hidden overflow-hidden bg-is2-blue-50 lg:bg-white -mx-6 md:mx-0" ref={emblaRef}>
      <div className="flex gap-4">
        {items.map((item, index) => (
          <div key={index} className="flex-shrink-0 pt-[44px] pb-6 lg:p-2">
            <ConditionCardItem {...item} />
          </div>
        ))}
      </div>
    </section>
  );
};

ConditionCardCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ConditionCardCarousel;