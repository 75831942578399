import React from 'react';

import SIcon from 'views/NUI/StreamlineIcons/SIcon';
import TrophySVGIcon from 'styles/public/icons/TrophySVGIcon';

export const BottomMenuItem = ({ expanded, iconName, imageUri, label, onClick }) => {
	return (
		<div className="cursor-pointer flex h-[56px] items-center px-4 py-2" onClick={onClick}>
			{iconName && (
				<div className="pr-4">
					{/* <TrophySVGIcon /> requires defs which do not work with the current <SIcon /> component. */}
					{iconName === 'trophy' ? (
						<TrophySVGIcon fill="#696C70" />
					) : (
						<SIcon bold cls="text-is2-gray-150" name={iconName} size="s" />
					)}
				</div>
			)}
			<div className="flex-1">
				{imageUri && (
					<div className="pr-4">
						<img alt={app.runtime.name} className="h-[24px]" src={imageUri} />
					</div>
				)}
				{label && (
					<div className="pr-4">
						<p className="text-is2-gray-700 text-is2_md">{label}</p>
					</div>
				)}
			</div>
			<div className="bg-is2-blue-50 rounded-full size-[40px]">
				<SIcon bold cls="m-2.5 text-is2-gray-150" name="chevron" size="xxs" svg_cls={expanded && 'rotate-180'} />
			</div>
		</div>
	);
};
