import React from 'react';
import { useProfile } from '../../../../xAppLib/Hooks';
import HeartSVGIcon from '../../../../styles/public/icons/HeartSVGIcon';
import { cls } from '../../../NUI/utils';
import { capitalize } from "@/helpers/prettify";
import { getUserDisplayName } from 'helpers/getUserDisplayName';

export default function Welcome ({clsn, sidebar}) {

	const [prof] = useProfile();
	const newUser = !prof?.hist?.length;
	const name = capitalize(getUserDisplayName({ prof }, {
		format: 'first',
		defaultValue: ''
	}).toLowerCase());
	const defaultName = capitalize(getUserDisplayName({ prof }, {
		format: 'first',
		defaultValue: 'User'
	}).toLowerCase());
	const notif = 4; // placeholder
	const help = <>We hope we can help you today<span className="hidden lg:block"><HeartSVGIcon fill="#A9ABAD" /></span><span className="block lg:hidden"><HeartSVGIcon fill="#FFF" /></span></>

	return  <div className={cls(clsn, !notif && "mb-5")}>
				<p className="font-medium text-is2_lg my-0 lg:mb-0 flex lg:hidden justify-center">
					<span className="lg:hidden mr-2 lg:mr-0">Hi</span> {defaultName}
				</p>
				<h2 className="hidden lg:block text-is2-gray-700 font-bold mb-0 mt-0">{newUser && `Welcome to InstantScripts ${name}` || !notif && `Hey ${defaultName},` || `Welcome back, ${name}`}</h2>
				{(newUser || !notif) && <p className="mt-3 flex gap-1 items-center ">
					{newUser && <>
									<span className="flex items-center lg:hidden gap-2">{help}</span>
								</>
					|| notif && `You have ${notif}` 
					|| <span className="flex items-center gap-2">{help}</span>}
					</p>
				}
			</div>
}