import React, {useCallback} from "react";

const TermsLink = ({label, type}) => {
    const handleTermsClick = useCallback(_ => {
        const config = {
            "customer-terms-and-conditions": {
                pt: "cx_txt",
                txt: "Customer Terms and Conditions",
                pn: "customer-terms-and-conditions",
            },
            "platform-terms-and-conditions": {
                pt: "cx_txt",
                txt: "Platform Terms and Conditions",
                pn: "platform-terms-and-conditions",
            },
            "privacy-policy": {pt:"cx_txt",	txt:'Privacy', pn:'privacy-policy'},
        }

        app.trigger(app.events.SHOW_POPUP, config[type]);
    }, [])

    return <span
        className="font-bold text-blue-600 pt-3 !m-0 cursor-pointer"
        onClick={(e) => {
            e.preventDefault();
            handleTermsClick('');
        }}
    >
        {label}
    </span>
}

export default TermsLink