import React, { useMemo } from 'react';
import { Link } from "react-router-dom";
import moment from "moment";
import { Header, List, Table } from "semantic-ui-react";
import { useAsync } from "xAppLib/Hooks/useAsync";
import instcons_model from "../../models/instcons_model";
import Alert from "../NUI/Alert";
import { cls } from "../NUI/utils";
import { FieldRawPopup } from "./fields/FieldRawPopup";
import { identityToDisplayName } from "./TwilioConference/utils";

const VIDEO_ROOMS_PREFIX = 'https://console.twilio.com/us1/monitor/insights/video/video-rooms';

export function TwilioRoomInsights({sid, data}) {
	const insights = useAsync({
		immediate: sid,
		fn: async () => {
			if (data) {
				return data;
			}

			const res = await instcons_model.load_insights(sid);
			return res.data;
		},
	}, [sid]);

	const getSpan = useMemo(() => {
		if (!Array.isArray(insights.data?.participants)) return null;

		const ts = tm => moment(tm).toDate().getTime();

		const first_join = ts(insights.data.participants.map(p => p.joinTime).sort()[0]);
		const last_leave = ts(insights.data.participants.map(p => p.leaveTime).sort().at(-1));
		const duration = last_leave - first_join;

		return (join, leave) => {
			const join_tm = ts(join);
			const leave_tm = ts(leave);
			return {
				left: `${(join_tm - first_join) / duration * 100}%`,
				right: `${(last_leave - leave_tm) / duration * 100}%`,
			};
		};
	}, [insights.data]);


	if (insights.loading) {
		return <>Loading...</>;
	}

	if (insights.error) {
		return (
			<Alert error
				   header="Error loading insights"
				   content={insights.error?.error ?? insights.error?.message ?? String(insights.error)}
			/>
		);
	}

	if (!insights.data) {
		return <Alert error header="No data"/>;
	}

	const {
		roomSid,
		roomType,
		roomStatus,
		uniqueParticipants,
		totalParticipantDurationSec,
	} = insights.data.room;

	return (
		<>
			<div className="flex justify-between items-start">
				<List>
					<List.Item>
						<List.Content>
							<List.Header>Room ID</List.Header>
							<List.Description>
								<Link to={`${VIDEO_ROOMS_PREFIX}/${roomSid}`} target="_blank">
									{roomSid}
								</Link>
							</List.Description>
						</List.Content>
					</List.Item>
					<List.Item>
						<List.Content>
							<List.Header>Room Type / Status</List.Header>
							<List.Description>{roomType} / {roomStatus}</List.Description>
						</List.Content>
					</List.Item>
					<List.Item>
						<List.Content>
							<List.Header>Unique Participants (Duration)</List.Header>
							<List.Description>{uniqueParticipants} ({totalParticipantDurationSec}s)</List.Description>
						</List.Content>
					</List.Item>
				</List>
				<FieldRawPopup row={insights.data}/>
			</div>

			<Header as="h3">Participants</Header>
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Participant</Table.HeaderCell>
						<Table.HeaderCell>Dur(s)</Table.HeaderCell>
						<Table.HeaderCell>Status</Table.HeaderCell>
						<Table.HeaderCell>Codec(s)</Table.HeaderCell>
						<Table.HeaderCell>End Reason (error)</Table.HeaderCell>
						<Table.HeaderCell>A/V Tracks</Table.HeaderCell>
						<Table.HeaderCell>Browser / Device / Platform</Table.HeaderCell>
						<Table.HeaderCell>Location</Table.HeaderCell>
						<Table.HeaderCell>SDK</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{(insights.data.participants ?? []).length === 0 ? (
						<Table.Row><Table.Cell colspan={9}>No participant data</Table.Cell></Table.Row>
					) : (
						insights.data.participants.map(p => (
							<React.Fragment key={p.participantSid}>
								<Table.Row style={{position: 'relative'}}>
									<Table.Cell>{identityToDisplayName(p.participantIdentity)}</Table.Cell>
									<Table.Cell>{p.durationSec}s</Table.Cell>
									<Table.Cell>{p.status}</Table.Cell>
									<Table.Cell>{(p.codecs ?? []).join(', ') ?? '-'}</Table.Cell>
									<Table.Cell>
										{p.endReason} <TwilioError errorCode={p.errorCode}
																   errorCodeUrl={p.errorCodeUrl}/>
									</Table.Cell>
									<Table.Cell>{p.properties.num_audio_tracks}/{p.properties.num_video_tracks}</Table.Cell>
									<Table.Cell>
										{p.publisherInfo.browser_name} {p.publisherInfo.browser_version} /{" "}
										{p.publisherInfo.device_manufacturer} {p.publisherInfo.device_model} /{" "}
										{p.publisherInfo.platform_name} {p.publisherInfo.platform_version}
									</Table.Cell>
									<Table.Cell>{p.publisherInfo.location?.city ?? '-'}</Table.Cell>
									<Table.Cell>
										{p.publisherInfo.sdk_version}
										<span
											className={cls("absolute left-0 right-0 bottom-0", p.errorCode ? 'bg-red-400' : 'bg-green-300')}
											style={{height: 2, ...getSpan(p.joinTime, p.leaveTime)}}
										/>
									</Table.Cell>
								</Table.Row>
							</React.Fragment>
						))
					)}
				</Table.Body>
			</Table>
		</>
	);
}

function TwilioError({errorCode, errorCodeUrl}) {
	if (!errorCode) return null;

	const Wrapper = errorCodeUrl
		? ({children}) => <Link to={errorCodeUrl} target="_blank">{children}</Link>
		: React.Fragment;

	return (
		<Wrapper>
			(<code>{errorCode}</code>)
		</Wrapper>
	);
}
