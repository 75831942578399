import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "styles/tailwind.css";

/**
 * A reusable tab component that handles active state and notifies parent on changes.
 *
 * @param {{id: string|number, label: string}[]} tabs - Array of tab objects with `id` and `label`.
 * @param {Function} onTabChange - Callback when the active tab changes.
 * @param {String|Number} defaultActiveId - ID of the tab to be active by default.
 */
export function TabComponent({ tabs, onTabChange, defaultActiveId, active }) {
  const [activeId, setActiveId] = useState(defaultActiveId || tabs[0]?.id);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const tabsContainerRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (tabsContainerRef.current) {
        const { scrollWidth, clientWidth } = tabsContainerRef.current;
        setIsOverflowing(scrollWidth > clientWidth);
      }
    };


    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);

  }, [tabs]);

  useEffect(() => {
    setActiveId(active)
  }, [active]);

  const activeIndex = tabs.findIndex(tab => tab.id === activeId);

  return (
    <div
      className="tab-component w-full overflow-hidden bg-is2-blue-50 hover:bg-is2-blue-100/50 transition-colors duration-75 rounded-[44px]"
      role="tablist"
      aria-orientation="horizontal"
    >
      <div
        ref={tabsContainerRef}
        className="flex relative gap-2 overflow-x-auto whitespace-nowrap"
      >
        {!isOverflowing && (
          <div
            className="tab-component__active-indicator absolute h-full bg-is2-blue rounded-[44px] transition-all duration-300 ease-out"
            style={{
              width: `calc((100% / ${tabs.length}) - 8px)`,
              height: 'calc(100% - 8px)',
              left: `calc(${activeIndex} * (100% / ${tabs.length}) + 4px)`,
              top: '4px',
            }}
          />
        )}
        {tabs.map(({ id, label }) => (
          <button
            key={id}
            role="tab"
            aria-selected={activeId === id}
            aria-controls={`tab-${id}-content`}
            id={`tab-${id}`}
            onClick={() => {
              setActiveId(id);
              onTabChange?.(id);
            }}
            className={`px-6 py-3 rounded-full justify-center items-center gap-2 cursor-pointer relative z-10 ${isOverflowing ? "min-w-fit" : "flex-1"
              } ${activeId === id
                ? isOverflowing
                  ? "bg-is2-blue text-white m-1 transition-colors duration-150 ease-out"
                  : "text-white m-1 transition-colors duration-150 ease-out"
                : "text-is2-gray-body hover:text-is2-gray-900 transition-colors duration-75 ease-out"
              }`}
          >
            <span className="text-base leading-normal text-center whitespace-nowrap">
              {label}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}

TabComponent.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onTabChange: PropTypes.func,
  defaultActiveId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TabComponent.defaultProps = {
  onTabChange: null,
  defaultActiveId: null,
};
