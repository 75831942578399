import {useCurrent} from "./useCurrent";
import {useEffect, useReducer} from "react";

/** @type {SubscriptionState} */
const INITIAL_STATE = {
	data: null,
	error: null,
	status: 'initial',
};

/** @type {SubscriptionState} */
const INITIAL_LOADING_STATE = {
	...INITIAL_STATE,
	status: 'loading',
};

/**
 * @param {SubscriptionState} state
 * @param {SubscriptionAction} action
 * @returns {SubscriptionState}
 */
function subscriptionReducer(state, action) {
	switch (action.type) {
		case 'loading':
			return {...INITIAL_STATE, data: null, status: action.type};
		case 'error':
			return {...state, status: action.type, error: action.data};
		case 'data':
			return {error: null, status: 'loaded', data: action.data};
	}
	return state;
}

/**
 * @param {Partial<UseSubscriptionConfig> | WatchFunction} config
 * @param config
 * @return {UseSubscriptionConfig}
 */
function makeConfig(config) {
	return {
		immediate: true,
		map: value => value,
		...(typeof config === 'function' ? {fn: config} : config),
	};
}

/**
 *
 * @param {UseSubscriptionConfig<T = string> | WatchFunction} config
 * @param {Array<*>?} deps
 * @return {UseSubscriptionResponse<T>}
 * @example
 * // Most basic usage, watch a med
 * const {data: med, loading} = useSubscription(
 * 	(onData, ce) => med_model.watch_med(mid, onData, {ce}),
 * 	[mid]
 * );
 * @example
 * // Conditional watch, optional mapping
 * const {data: med, loading} = useSubscription({
 *   immediate: !!mid,
 *   fn: (onData, ce) => med_model.watch_med(mid, onData, {ce}),
 *   map: ({ hist, ...med }) => med // omit the history field
 * }, [mid]);
 */
export function useSubscription(config, deps = []) {
	const cfg = makeConfig(config);
	const cfgRef = useCurrent(cfg);

	const initial = cfg.immediate ? INITIAL_LOADING_STATE : INITIAL_STATE;
	const [state, dispatch] = /** @type {[SubscriptionState, Dispatch<SubscriptionAction>]} */ useReducer(subscriptionReducer, initial, undefined);

	useEffect(() => {
		dispatch({type: 'loading'});

		if (!cfgRef.current.immediate) return;

		const onData = data => dispatch({type: 'data', data: cfgRef.current.map(data)});
		const onError = error => dispatch({type: 'error', data: error});

		const unsub = cfgRef.current.fn(onData, onError);

		return () => {
			unsub();
		};
	}, [cfg.immediate, ...deps]);

	return {
		...state,
		get loading() {
			return state.status === 'loading';
		},
	};
}

/**
 * @callback WatchFunction<T>
 * @param {OnDataFunction<T>} onData
 * @param {OnErrorFunction} onError
 * @return {UnsubscribeFunction}
 * @template T
 */

/**
 * @callback OnDataFunction<T>
 * @param {T} data
 * @template T
 */

/**
 * @callback OnErrorFunction
 * @param {Error} error
 */

/**
 * @callback UnsubscribeFunction
 */

/**
 * @typedef {Object} UseSubscriptionConfig
 * @property {WatchFunction} fn - load a record
 * @property {boolean = false} immediate - if the function should be invoked on mount/deps change
 */

/**
 * @typedef {SubscriptionState<T> & {loading: boolean;}} UseSubscriptionResponse<T>
 */

/**
 * @typedef {Object} SubscriptionState<T>
 * @property {T} data
 * @property {string|null} error
 * @property {'initial'|'loading'|'loaded'|'error'} status
 */
/**
 * @typedef {Object} SubscriptionDataAction
 * @property {'data'} type
 * @property {string} data
 */
/**
 * @typedef {Object} SubscriptionLoadingAction
 * @property {'loading'} type
 */
/**
 * @typedef {Object} SubscriptionErrorAction
 * @property {'error'} type
 * @property {string} data
 */
/**
 * @typedef {SubscriptionLoadingAction | SubscriptionErrorAction | SubscriptionDataAction} SubscriptionAction
 */

