import React, { Component } from 'react';
import {Button, Input, Card, Segment, Loader, Modal} from 'semantic-ui-react'
import { v4 as generate_id } from 'uuid'

import API_service from '../providers/API_service'

import { getExifOrientation } from '../libs/image_orient'
import gtm from '../providers/gtm';
import Badge from 'views/NUI/Badge'
import imageResizer from "../libs/imageResizer";
import file_to_base64 from "../helpers/file_to_base64";
const DEBUG = false
const DEFAULT_MAX_FILE_SIZE_IN_MB = 5;

const bytesToMb = (bytes) => bytes / 1024 / 1024

class TakePhoto extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uniqueId: generate_id(),
			files: [],
			image_source: 'canvas',
			show_modal: false,
			error : false,
			loading_preview: false,
		};
		
		this.videoRef = React.createRef()
		this.canvasRef = React.createRef()
		this.fileRef = React.createRef()
	}
	
	componentWillUnmount() {
		this.shutdown()
	}

	// 		--------------------------------		--------------------------------		---------

	init() {
		navigator.mediaDevices
			.getUserMedia({ video: { width: 468, height: 400 } })
			.then(stream => {
				this.videoRef.current.addEventListener("playing", this.videoReady);
				this.videoRef.current.srcObject = stream;
				this.videoRef.current.play();
				this.stream = stream
			})
			.catch((err) => {
				alert("This device cannot access the camera.")
				this.setState({show:'button'})
			});
	}
	
	videoReady = () => {
		this.setState({videoReady:true})
	}

	get some_photo_uploading() {
		return this.state.files.some(f => f.uploading);
	}

	get block_uploading() {
		return this.props.oneAtATime && this.props.autoUpload && this.some_photo_uploading;
	}

	shutdown() {
		if (!this.stream)
			return
		this.videoRef.current.removeEventListener("playing", this.videoReady);
		
		this.stream.getTracks().forEach(function(track) {
		  track.stop();
		});
		
		this.stream = null
	}
	// 		--------------------------------		--------------------------------		---------

	max_file_size_in_bytes = DEFAULT_MAX_FILE_SIZE_IN_MB * 1024 * 1024 * ((this.props?.target || 'cosm-trt-pts') === 'cosm-trt-pts' ? 2 : 1);

	async resize_img(img_base64) {
		const blob = await fetch(img_base64).then(res => res.blob());
		try {
			const resizedImage = await imageResizer({file: blob, quality: 90, maxWidth:1500, maxHeight:1500}); // Returns base64
			const newBlob = await fetch(resizedImage).then(res => res.blob())
			DEBUG && console.log(`Original size: ${bytesToMb(blob.size)} MB, Resized size: ${bytesToMb(newBlob.size)} MB | ${Math.round((blob.size / newBlob.size) * 100) - 100}% savings`)
			// Check to see if we actually saved space, if not, return the original image
			const resizedImageIsSmaller = blob.size > newBlob.size
			DEBUG && console.log("resizedImageIsSmaller", resizedImageIsSmaller)
			return {
				resized_file_data: resizedImageIsSmaller ? resizedImage : img_base64,
				resized_file_size: resizedImageIsSmaller ? newBlob.size : blob.size
			}
		} catch (e) {
			DEBUG && console.log("Couldn't resize this file", e)
			 return {
				resized_file_data: img_base64,
				resized_file_size: blob.size
			}
		}
	}

	async assert_within_size_limits(size) {
		const MAX_FILE_SIZE_IN_BYTES = this.max_file_size_in_bytes
		const sizeLimitInMb = bytesToMb(MAX_FILE_SIZE_IN_BYTES)
		if (size > MAX_FILE_SIZE_IN_BYTES) {
			throw Error(`File size is too large. Max size is ${sizeLimitInMb}MB`)
		}
	}

	// 		--------------------------------		--------------------------------		---------

	async on_image_upload_select(event, setOrientation) {
		const {
			autoUpload = false,
			defaultComment
		} = this.props
		// Confirm there is a file
		const files = Array.from(event.target.files)

		if (!files.length) return

		this.setState({loading_preview:true}, async () => {

            const fileStates = await Promise.all(files.map(async file => {
                try {
                    // Extract the base64 representation of the image
                    let file_data = await file_to_base64(file)
                    // Try to resize the image
                    const {resized_file_data, resized_file_size} = await this.resize_img(file_data)
                    await this.assert_within_size_limits(resized_file_size)

                    const payload =  {
                        id: generate_id(),
                        img: resized_file_data,
                        show: 'preview',
                        image_source: 'upload',
                        img_name: file.name,
                        type: file.type,
                        error: false,
                        loading_preview: false,
                        ...(autoUpload && {comn: defaultComment}),
                    }

                    // Get the orientation of the image if needed
                    if(setOrientation){
                        payload.orientation = await getExifOrientation(file)
                        DEBUG && console.log("orientation",payload.orientation);
                    }

                    return payload

                } catch (e){
                    DEBUG && console.log("error",e);
                    return {show:'button', error: e.message || String(e), img:null}
                }
            }))

            this.setState({loading_preview:false, files: [...this.state.files, ...fileStates]}, async () => {
                if (autoUpload && this.props.multiple) {
                    const upd_res = await Promise.all(fileStates.map( async f => await this.upl_photo(f.id, true)))
                    this.props.onPhoto(upd_res.filter(Boolean))
                    this.setState({files: []})
                } else if (autoUpload) {
                    fileStates.forEach(f => this.upl_photo(f.id))
                } else {
                    // Manually upload the files with button click
                }
            })

            // // Clear the input value (to allow the same file to be uploaded again)
            if (this.fileRef.current) {
                this.fileRef.current.value = ''
            }
        })
	}

	// 		--------------------------------		--------------------------------		---------

	async upl_photo(id, returnToCaller) {
		try {
			const {
				target = 'cosm-trt-pts',
			} = this.props

            let files = this.state.files.map(f => ({
                ...f,
                uploading: f.id === id ? true : f.uploading,
                error: f.id === id ? null : f.error
            }));

			this.setState({ files })

			const file = files.find(f => f.id === id)
			const uploadType = file?.type === 'application/pdf' ? 'attachment' : 'photo'

			const res = await API_service.load_data(
				`file/${uploadType}/store/${target}/snap`,
				{
					img_data: file.img,
					comn: file.comn || file.img_name,
					o: file.orientation,
				}
			);

			if(res.res !== 'ok'){
				throw new Error('Error occured when uploading file')
			}

			res.is_attachment = res.tag === 'attachment';
			if (this.props.tag)
				res.tag = this.props.tag
			if (this.props.tag_label)
				res.tag_label = this.props.tag_label

			res.image_source = file.image_source

			DEBUG && console.log('uploaded photo', res);

			if(returnToCaller){
				return res
			}
			const {onPhoto} = this.props
			onPhoto && onPhoto(res)

            files = files.filter(f => f.id !== id);
			this.setState({ files })

			gtm.log('', 'upl_pic');
		} catch (e){
			console.log(e)
			// Could do extra logging here
			this.setState({files: this.state.files.map(f => f.id === id ? {...f, uploading: false, error: e.message} : f)})
		}

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	render_ios_button() {
		const acceptedTypes = this.props.acceptedTypes?.reduce((res, t) => `${res}${res && ','} ${t.value}`, "") || "image/*,.pdf";
		const enableImageUpload = this.props.enableImageUpload ?? false;
		return <label className={`ui basic button !ml-2 ${(this.state.loading_preview || this.block_uploading) ? 'loading' : ''}`}>
			<input type="file" ref={this.fileRef} accept={acceptedTypes} multiple={this.props.multiple} onChange={async e=> this.on_image_upload_select(e, true)} style={{
				width:'0.1px',
				height:'0.1px',
				opacity: 0,
				overflow:'hidden',
				position: 'absolute',
				zIndex: -1
			}}/>
			{customContentOrDefault(enableImageUpload, 'Upload file')}
			</label>
	}
	
	render_canvas_button() {
		const acceptedTypes = this.props.acceptedTypes?.reduce((res, t) => `${res}${res && ','} ${t.value}`, "") || "image/*,.pdf";
		const fieldId = this.state.uniqueId;
		const enableImageUpload = this.props.enableImageUpload ?? false;
		const enableTakePhoto = this.props.enableTakePhoto ?? true; // backwards compatible

		const buttons = (
			<>
				{enableImageUpload && (
					<React.Fragment>
						<Button
							type="button"
							basic
							secondary
							content={customContentOrDefault(enableImageUpload, 'Upload file')}
							data-testid="button-upload-file"
							loading={this.state.loading_preview || this.block_uploading}
							onClick={_ => {
								document.getElementById(`form-${fieldId}-uploader-input`).click();
							}}
						/>

						<input
							type="file"
							multiple={this.props.multiple}
							accept={acceptedTypes}
							style={{display: 'none'}}
							data-testid="input-upload-file"
							id={`form-${fieldId}-uploader-input`}
							ref={this.fileRef}
							onChange={e => this.on_image_upload_select(e)}
						/>
					</React.Fragment>
				)}
				{enableTakePhoto && acceptedTypes.indexOf("image") > -1 && <Button
					loading={this.block_uploading}
					type="button"
					basic
					content={customContentOrDefault(enableTakePhoto, 'Take photo')}
					onClick={_ => {
						this.setState({show: 'canvas', show_modal: true}, () => this.init());
					}}
				/>}
			</>
		);

		if (this.props.basic) return buttons;

		return (
			<div className="mx-2.5">
				<div className="flex gap-2">
					{buttons}
				</div>
			</div>
		);
	}

	render_canvas() {
		const { show_modal } = this.state
		return <>
				<Modal 
				open={!!show_modal}
				onClose={_=>{
					this.shutdown()
					this.setState({show_modal:false, img:null, show:'button'})}}
				closeIcon
				className="text-is-black max-w-[500px] max-h-[730px] h-fit-content"
				>
					<Modal.Header content="Take a photo"/>
					<Modal.Content>
						{this.state.img ? 
							<><div className="w-full mx-auto rounded-lg bg-black h-[400px] bg-cover mb-2 bg-no-repeat bg-center" style={{ backgroundImage: `url('${this.state.img}')`}}/>
								<p className="text-is-black">Review the photo to make sure that it is clear and accurately captures the area you need to show the Doctor. If necessary, retake the photo to get a better shot.</p>
								<Button type="button" color="green" fluid
									onClick={_=>{ this.setState({show:'preview'})}}
									style={{marginBottom: '.5em'}}>Upload Photo</Button>
								<Button basic fluid onClick={_=>{this.setState({files: []}, () => this.init())}}>Re-take photo</Button>
							</> 
						:   <>
								<video className="rounded-md max-w-full bg-black h-[400px]" ref={this.videoRef} width="100%" height="400" autoPlay></video>
								<canvas ref={this.canvasRef} width="468" height="400" className='hidden'></canvas>
								<ol className="text-is-black list-decimal ml-6 mb-5 mt-2">
									<li>Make sure you find a well-lit area to take the photo</li>
									<li>Hold the camera still</li>
									<li>Take the photo</li>
									<li>Check the photo</li>
									<li>Upload the photo</li>
								</ol>
								{this.state.videoReady && <><Button color="blue" fluid
									loading={this.block_uploading}
									content="Take Photo"
									type="button"
									style={{marginBottom: '.5em'}}
									onClick={_=> {
										this.canvasRef.current.getContext('2d').drawImage(this.videoRef.current, 0, 0, 468, 400);
										const img = this.canvasRef.current.toDataURL("image/jpeg");
										this.shutdown()
										const id = generate_id()
										// No need to pass this through the resize function as the canvas is already a reduced size.
										this.setState({files: [...this.state.files || [], {id, img, show: 'preview', image_source: 'canvas'}], show_modal:false, show:'button'}, () => {
											this.props.autoUpload && this.upl_photo(id)
										});

									}}/>
									<Button basic fluid
										content="Cancel" 
										onClick={_=>{
											this.shutdown()
											this.setState({show_modal:false, files:[], show:'button'})
										}}/>
								</>}
							</>
						}
					</Modal.Content>
				  </Modal>
				</>
	}
	
	render_preview() {
		const handleUpload = (id) => this.upl_photo(id)
		const handleFileRemoval = (id) => this.setState({files: this.state.files.filter(f => f.id !== id)})
		const handleCommentChange = (id, value) => this.setState({files: this.state.files.map(f => f.id === id ? {...f, comn: value} : f)})

		return <div className={'mb-4 mx-2'}>
			{this.state.files.map((file, ) => {
				const otherFileIsUploading = this.state.files.some(f => f.uploading && f.id !== file.id)
				return (
					<React.Fragment key={file.id}>
						<Card fluid className={'!p-2'}>
							<div className={'flex flex-col md:flex-row justify-between'}>
								<div className={'flex flex-row gap-1 items-center md:max-w-[40%] space-x-4'}>
									<img className="h-10" src={file.img} alt=""/>
									<p className={'truncate text-is-blue'}>{file.img_name}</p>
								</div>
								<div className={'flex flex-col sm:flex-row justify-end gap-1 pt-5'}>
									{file.uploading ?
										<div className="p-2">
											<Loader size='tiny' active inline/>
											<span className="ml-2 text-sm">Uploading...</span>
										</div>
										: <>
											{!this.props.hideComment && <Input
												placeholder='Comment'
												defaultValue={file.comn}
												onChange={(e, {value}) => handleCommentChange(file.id, value)}
												className="w-full md:w-[150px] mt-0"
											/>}
											<Button
												basic
												type={'button'}
												className='w-[100px] h-fit'
												onClick={_ => handleFileRemoval(file.id)}
												content="Cancel"
											/>
											<Button
												color="green"
												type={'button'}
												className='w-[100px] h-fit'
												data-testid="button-upload"
												onClick={_ => {handleUpload(file.id)}}
												content="Upload"
												disabled={otherFileIsUploading}
												basic={otherFileIsUploading}
											/>
										</>
									}
								</div>
							</div>
						</Card>
						{file.error && <span className={'text-is-red text-xs pl-2'}>{file.error}</span>}
					</React.Fragment>
				)
			})}
		</div>
	}

	render_button() {
		return app.settings.is_mob ? this.render_ios_button() : this.render_canvas_button()
	}

	render() {
		const {basic} = this.props;
		const {show = 'button', files} = this.state

		if (basic) {
			return (
				<>
					{show == 'button' && this.render_button()}
					{show == 'canvas' && this.render_canvas()}
				</>
			)
		}

		return (
			<>
				<Segment
					basic
					style={{
					padding: 0,
					margin: '.25em',
					height: '100%',
					width: '100%'
				}}
				>
					<div>
						{files.some(f => f.show === 'preview') && this.render_preview()}
						{show == 'button' && this.render_button()}
						{show == 'canvas' && this.render_canvas()}
					</div>
				</Segment>
				<div className={'flex flex-row gap-2 my-2 pl-3 sm:pl-4'}>
					<Badge className="!text-[9.5px] sm:!text-xs" small>
						Max file size {bytesToMb(this.max_file_size_in_bytes)}MB
					</Badge>
					<Badge className="!text-[9.5px] sm:!text-xs" small>
						Supported type{this.props.acceptedTypes?.length == 1 ? '' : 's'}{" "}:
						{this.props.acceptedTypes
						?.reduce((res, t) => `${res}${res && ','} ${t.label}`, "") || "JPEG, PNG, PDF"}
					</Badge>
				</div>
			</>

		)
	}
}

const customContentOrDefault = (contentOrFlag, defaultContent) => typeof contentOrFlag === 'boolean' ? defaultContent : contentOrFlag;

export default TakePhoto;
