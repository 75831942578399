import db_lib from 'xAppLib/libs/db_lib';
import firebase_database from "../xAppLib/providers/firebase_database";

const _FRDB_LOC = 'webrtc_logs';

export default class webrtc_logs_model extends db_lib {
	static get FRDB_LOC() { return _FRDB_LOC; }

	static lazy_logger(sessionId, extra = null) {
		let log;

		return async (data) => {
			if (!log) {
				log = this.create_session_log(sessionId, extra);
			}

			const id = await log;

			return this.update_session_log(id, data);
		}
	}

	static async create_session_log(sessionId, extra = null) {
		return webrtc_logs_model.add_record(app.user.uid, JSON.parse(JSON.stringify({
			...extra,
			sessionId,
			name: app.user.name,
			dvcid: app.dvc.dvcid,
		})))
	}

	static async update_session_stats(id, stats) {
		return webrtc_logs_model.update_record(`${app.user.uid}/${id}`, {stats})
	}

	static async update_session_log(id, data) {
		try {
			const cloned = JSON.parse(JSON.stringify(data));
			return webrtc_logs_model.add_record(`${app.user.uid}/${id}/logs`, cloned, {timestamp: true});
		} catch (e) {
			// likely circular reference, nothing we can do about that
			return null;
		}
	}

	static async get_last_session_log_for_device() {
		return new Promise((resolve) => {
			const loc = this.prep_loc(app.user.uid);
			const unsub = firebase_database.watch_record(
				loc,
				rec => {
					unsub();
					resolve(rec && Object.values(rec)[0]);
				},
				{
					limit_last: 1,
					where_key: 'dvcid',
					where_val: app.dvc.dvcid,
				}
			);
		})
	}

	static async load_trace_logs(uid, sessionId) {
		const loc = this.prep_loc(uid);
		return firebase_database.get_records(loc, null, { where_key: 'sessionId', where_val: sessionId });
	}
}
