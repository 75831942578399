import React from "react";

import { PayPalButtons } from "@paypal/react-paypal-js";
import API_service from "xAppLib/providers/API_service";

const PaypalButton = ({ color = 'blue', ...props }) => {

    

    return (
        <>
            <PayPalButtons
                fundingSource="paypal"
                style={{ color, label: "pay", height: props.btnHeight ? props.btnHeight : 42 }}
                createOrder={() => API_service.load_data( 'paypal/createOrder', { purchaseAmount: props.purchaseAmount } ).then((res) => res.order.id)}
                onApprove={(data, actions) => {
                    const paypalOrderDetails = { ...data, approvedDateTime: new Date() }
                    if (props.paypalOrderDetails) {
                        props.paypalOrderDetails.current = paypalOrderDetails
                    }
                    props.onConfirm(paypalOrderDetails)
                }}
                onCancel={({data, actions}) => {
                    // Don't report cancel as error = https://developer.paypal.com/sdk/js/reference/
                }}
            />
        </>
    );
};

export default PaypalButton;
