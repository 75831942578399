// ref: https://github.com/scurker/currency.js/issues/191#issuecomment-529201820
export function omitZeroCents(currency, options) {
	// call original format function to get its string value
	let format = options.format(currency, options);

	if(currency.cents() === 0) {
		// trim decimal and any trailing zeros
		return format.slice(0, format.length - (options.precision + 1));
	}

	return format;
}