import React, { useCallback, useState, useEffect, useRef } from "react";
import { Icon, Pagination } from "semantic-ui-react";
import NotificationItem from './NotificationItem';

const NotificationsList = ({
    loading,
    notifications,
    pageSize = 7,
    hasUnreadButton = false,
    setNotificationRead,
    autoFocus = false,
    usePlugin,
    onNotificationClick,
    onPageChange,
    onClose,
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const containerRef = useRef(null);
    useEffect(() => {
        if (autoFocus && containerRef.current) {
            const firstButton = containerRef.current.querySelector('button');
            firstButton?.focus?.();
        }
    }, []);

    const handlePageChange = useCallback((_, { activePage }) => {
        setCurrentPage(activePage);
        onPageChange?.({
            previousPage: currentPage,
            previousPageNotifications: notifications.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
            ),
            nextPage: activePage,
            nextPageNotifications: notifications.slice(
                activePage * pageSize,
                (activePage + 1) * pageSize
            ),
        });
    }, [currentPage, pageSize, notifications]);

    const paginatedNotifications = getPaginatedNotifications(notifications, currentPage, pageSize);

    const totalPages = Math.ceil(notifications.length / pageSize);

    if (loading) {
        return (
            <div className="flex justify-center p-8">
                <Icon name="spinner" loading size="large" title="Loading notifications" />
            </div>
        );
    }

    if (notifications.length === 0) {
        return (
            <div className="text-center p-8 text-gray-500">
                No notifications to display
            </div>
        );
    }

    return (
        <article ref={containerRef} className="notifications-list column">
            <ul className="[&>li]:p-0 [&>li]:m-0" role="list" aria-label="Notifications">
                {paginatedNotifications.map((notification) => (
                    <li
                        key={notification.notification_id}
                        role="listitem"
                        className={`[&>button]:w-full border-b-2 ${(notification.isDynamic || notification.read)
                                ? 'border-white'
                                : 'border-[#ECF4FE]'
                            }`}
                    >
                        <NotificationItem
                            notification={notification}
                            onClick={onNotificationClick}
                            hasUnreadButton={hasUnreadButton}
                            setNotificationRead={setNotificationRead}
                            usePlugin={usePlugin}
                            onClose={onClose}
                        />
                    </li>
                ))}
            </ul>
            <div className="flex justify-center mt-4">
                <Pagination
                    activePage={currentPage}
                    onPageChange={handlePageChange}
                    totalPages={totalPages}
                />
            </div>
        </article>
    );
}

export const getPaginatedNotifications = (notifications, currentPage, pageSize) => {
    return notifications.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );
};

export default NotificationsList;