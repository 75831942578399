import moment from "moment";
import {useEffect, useState} from "react";
import {useCurrent} from "xAppLib/Hooks/useCurrent";

/**
 * @param {UseStaleTimeProps} props
 * @return {boolean}
 */
export function useStaleTime(props) {
	const {stale_time, delay_ms = 0, min_delay_ms = 0, onStale, enabled = true} = props;

	const onStaleRef = useCurrent(onStale);
	const [stale, setStale] = useState(false);

	useEffect(() => {
		if (!enabled) {
			return;
		}


		// Will default to Date.now() if display_tm is undefined so don't need to check it
		const start = moment(stale_time).toDate().getTime();
		const display_at = start + delay_ms - Date.now();
		const sleep_ms = Math.max(isNaN(display_at) ? 0 : display_at, min_delay_ms);

		if (sleep_ms > 0) {
			// Reset in case we're re-enabling
			setStale(false);
		}

		const id = setTimeout(() => setStale(true), sleep_ms);
		return () => {
			onStaleRef.current?.(true);
			clearTimeout(id);
		};
	}, [enabled, stale_time, delay_ms, min_delay_ms]);

	return Boolean(enabled && stale);
}

/**
 * @typedef {Object} UseStaleTimeProps
 * @property {Date} stale_time
 * @property {number} [delay_ms=0]
 * @property {number} [min_delay_ms=0]
 * @property {function(boolean): void} [onStale]
 * @property {boolean} [enabled=true]
 */