import React from 'react';
import {cls} from "../NUI/utils";

export function Card({children, className, mobile, slim}) {
	return (
		<div className={cls(
			slim ? 'p-[--card-padding-x]' : 'px-[--card-padding-x] py-[--card-padding-y]',
			'md:bg-card md:rounded-md mt-4 md:shadow-md',
			mobile && 'bg-card rounded-md mt-4',
			className
		)}>
			{children}
		</div>
	)
}