import React from 'react'
import ProductCard from "../../../ecommerce/components/ProductCard";
import {getSelectedDeliveryMethod} from "../../../../helpers/evermed";
import ExclamationText from "xAppLib/UIelems/ExclamationText";

const SuggestedProducts = (props) => {
    const { evermed, onUpdate, formData, logSuggestedProducts } = props || {};
    const suggested_products = evermed?.sp || []

    const handleProductSelect = (product) => {
        const updatedProducts = [...(formData?.suggested_products || []), {...product, qty: 1}]
        onUpdate({ suggested_products: updatedProducts })
    }

    const handleProductRemove = (product) => {
        const updatedProducts = formData.suggested_products.filter(p => p.id !== product.id)
        onUpdate({ suggested_products: updatedProducts })
    }
    
    const handleProductQuantityChange = (product) => {
        const updatedProducts = formData.suggested_products.map(p => p.id === product.id ? product : p)
        onUpdate({ suggested_products: updatedProducts })
    }

    logSuggestedProducts?.()

    const notAvailableFromSelectedPharmacy = formData?.col_pharm?.suitableForSP === false
    const notAvailableForSelectedDelivery = getSelectedDeliveryMethod(evermed, formData.delivery)?.suitableForSP === false

    return (
        <>
            <p className={'text-gray-700'}>Would you like to add any suggested products?</p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 lg:gap-6">
                {suggested_products.map((product) => {
                    const productInCart = formData?.suggested_products?.find(p => p.id === product.id)
                    return (
                        <ProductCard
                            key={product.id}
                            product={product}
                            productInCart={productInCart}
                            onProductSelect={handleProductSelect}
                            onProductRemove={handleProductRemove}
                            onProductQuantityChange={handleProductQuantityChange}
                            allowMultiple
                            disableActions={notAvailableFromSelectedPharmacy || notAvailableForSelectedDelivery}
                            tapToExpand
                        />
                    )
                })}
            </div>
            {(notAvailableFromSelectedPharmacy || notAvailableForSelectedDelivery) &&
                <ExclamationText text={
                    `Suggested products are not available with the selected ${notAvailableFromSelectedPharmacy ? 'pharmacy' : 'delivery method'}`
                }/>
            }
        </>
    );
}
export default SuggestedProducts