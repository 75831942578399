import React from 'react'
import {Message} from "semantic-ui-react";
import { meets_minimum_version } from "../../../xAppLib/helpers/meets_minimum_version";
import { useSiteStatus } from "../../../xAppLib/Hooks";

const ShopClosedMessage = ({message, messageProps}) => {
    return <Message {...messageProps}>{message}</Message>
}

const ShopAccessWrapper = ({children, showMessage = false, messageProps}) => {
    const enabled = app.site_status?.enable_ecommerce
    const min_version_compliant = meets_minimum_version(useSiteStatus('min_ver_ecomm'))

    if (!enabled || !min_version_compliant){
        if(!showMessage) return <></>

        const message = !enabled ? 'The shop is currently disabled' : 'Please update your app to access the shop'
        return <ShopClosedMessage message={message} messageProps={messageProps}/>
    }

    return children
}

export default ShopAccessWrapper
