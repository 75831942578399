import { pick } from "../../helpers/json_functions";
import { obj_map } from "../../xAppLib/helpers/obj_map";
import obj_filter_by_key from "../../xAppLib/helpers/obj_filter_by_key";

export function omitHidden(questions) {
	return obj_filter_by_key(questions, (k, q) => q.ord !== 'hide');
}

/**
 * @typedef {Object} CustomQuestionsConfig
 * @property {boolean=false} cosmVaccine
 * @property {boolean=false} k10
 * @property {false|undefined|null|string[]} extra
 * @property {boolean=true} understand
 * @property {boolean=true} inAustralia
 */

/**
 *
 * @param {*} existingQuestions
 * @param {Partial<CustomQuestionsConfig>} overrides
 * @return {*}
 */
export function withCustomQuestions(existingQuestions, overrides) {
	const config = {
		cosmVaccine: false,
		k10: false,
		extra: [],
		understand: true,
		inAustralia: true,
		...overrides,
	};

	return {
		// k10 can include config that will be applied to each k10 question
		// Useful to conditionally include k10 based on a previous answer, e.g. MHCP Review
		// (There isn't a UI to do this, the easiest way is to create a dummy question configured how you want,
		// then copy the config from the dummy question to the use_k10 config)
		// Alternative, it can be true to just take the config values from the extra_Qs
		...(config.k10 && obj_map(extra_Qs["k10"], val => ({...val, ...config.k10}))),

		// Due to a quirk in how questions are ordered, putting the k10 questions before the existing questions makes
		// them appear in the middle of the questionnaire (because questions we want to appear before k10 are given
		// negative sort orders). If we just tack everything on at the end, other questions like "Are you in
		// Australia?" _also_ appear in the middle, when actually we want that to be at the end.
		// If the question ordering logic is ever fixed, this will probably break.
		...existingQuestions,

		...(config.understand && {
			'extra_Qs-understand': extra_Qs.understand,
		}),

		...(config.inAustralia && {
			'extra_Qs-in_australia': extra_Qs.in_australia,
		}),

		...(config.cosmVaccine && {
			'cosm-vac': extra_Qs["cosm-vac"],
		}),

		...(Array.isArray(config.extra) && pick(extra_Qs, config.extra)),
	};
}

const extra_Qs = {
	"cosm-vac": {
		"num": "",
		"txt": "Have you had a COVID vaccine in the past 3 days or do you intend to have one in the next 3 days?",
		"a": {
			"X-vac-yes": {
				"txt": "Yes",
				"res": "f",
				"err_txt": "You must wait 3 days either side of your COVID vaccine before having this treatment",
			},
			"X-vac--no": {
				"txt": "No",
				"res": "p",
			},
		},
	},
	"ASC-Temples": {
		"num": "",
		"txt": "Do you intend today to inject filler into the patient's glabella or nose?",
		"a": {
			"ASC-Temples-yes": {
				"txt": "Yes",
				"res": "f",
				"err_txt": "ASC nurses should not be conducting treatments involving filler to nose or glabella",
			},
			"ASC-Temples-no": {
				"txt": "No",
				"res": "p",
			},
		},
	},
	"understand": {
		"num": "",
		"txt": "Do you understand everything that is written above or do you require assistance or language interpretation?",
		"a": {
			"understand-yes": {
				"txt": "Yes, I understand",
				"res": "p",
			},
			"understand-no": {
				"txt": "No, I need help",
				"res": "f",
				"err_txt": "Please request doctor consultation to discuss your needs",
			},
		},
	},
	"in_australia": {
		"num": "",
		"txt": "Are you currently located in Australia?",
		"a": {
			"in_australia-yes": {
				"txt": "Yes",
				"res": "p",
			},
			"in_australia-no": {
				"no_rc": true,
				"no_xc": true,
				"txt": "No",
				"res": "f",
				"err_txt": "Our doctors can only consult patients located in Australia.",
			},
		},
	},
	"k10": {
		"k10_1": {
			"ord": "3",
			"txt": "During the last 30 days, about how often did you feel tired out for no good reason?",
			"c": "a-5",
			"a": {
				"1": {
					"txt": "None of the time",
				},
				"2": {
					"txt": "A little of the time",
				},
				"3": {
					"txt": "Some of the time",
				},
				"4": {
					"txt": "Most of the time",
				},
				"5": {
					"txt": "All of the time",
				},
			},
		},
		"k10_2": {
			"ord": "4",
			"txt": "During the last 30 days, about how often did you feel nervous?",
			"c": "a-5",
			"a": {
				"1": {
					"txt": "None of the time",
				},
				"2": {
					"txt": "A little of the time",
				},
				"3": {
					"txt": "Some of the time",
				},
				"4": {
					"txt": "Most of the time",
				},
				"5": {
					"txt": "All of the time",
				},
			},
		},
		"k10_3": {
			"ord": "5",
			"txt": "During the last 30 days, about how often did you feel so nervous that nothing could calm you down?",
			"c": "a-5",
			"a": {
				"1": {
					"txt": "None of the time",
				},
				"2": {
					"txt": "A little of the time",
				},
				"3": {
					"txt": "Some of the time",
				},
				"4": {
					"txt": "Most of the time",
				},
				"5": {
					"txt": "All of the time",
				},
			},
		},
		"k10_4": {
			"ord": "6",
			"txt": "During the last 30 days, about how often did you feel hopeless?",
			"c": "a-5",
			"a": {
				"1": {
					"txt": "None of the time",
				},
				"2": {
					"txt": "A little of the time",
				},
				"3": {
					"txt": "Some of the time",
				},
				"4": {
					"txt": "Most of the time",
				},
				"5": {
					"txt": "All of the time",
				},
			},
		},
		"k10_5": {
			"ord": "7",
			"txt": "During the last 30 days, about how often did you feel restless or fidgety?",
			"c": "a-5",
			"a": {
				"1": {
					"txt": "None of the time",
				},
				"2": {
					"txt": "A little of the time",
				},
				"3": {
					"txt": "Some of the time",
				},
				"4": {
					"txt": "Most of the time",
				},
				"5": {
					"txt": "All of the time",
				},
			},
		},
		"k10_6": {
			"ord": "8",
			"txt": "During the last 30 days, about how often did you feel so restless you could not sit still?",
			"c": "a-5",
			"a": {
				"1": {
					"txt": "None of the time",
				},
				"2": {
					"txt": "A little of the time",
				},
				"3": {
					"txt": "Some of the time",
				},
				"4": {
					"txt": "Most of the time",
				},
				"5": {
					"txt": "All of the time",
				},
			},
		},
		"k10_7": {
			"ord": "9",
			"txt": "During the last 30 days, about how often did you feel depressed?",
			"c": "a-5",
			"a": {
				"1": {
					"txt": "None of the time",
				},
				"2": {
					"txt": "A little of the time",
				},
				"3": {
					"txt": "Some of the time",
				},
				"4": {
					"txt": "Most of the time",
				},
				"5": {
					"txt": "All of the time",
				},
			},
		},
		"k10_8": {
			"ord": "10",
			"txt": "During the last 30 days, about how often did you feel that everything was an effort?",
			"c": "a-5",
			"a": {
				"1": {
					"txt": "None of the time",
				},
				"2": {
					"txt": "A little of the time",
				},
				"3": {
					"txt": "Some of the time",
				},
				"4": {
					"txt": "Most of the time",
				},
				"5": {
					"txt": "All of the time",
				},
			},
		},
		"k10_9": {
			"ord": "11",
			"txt": "During the last 30 days, about how often did you feel so sad that nothing could cheer you up?",
			"c": "a-5",
			"a": {
				"1": {
					"txt": "None of the time",
				},
				"2": {
					"txt": "A little of the time",
				},
				"3": {
					"txt": "Some of the time",
				},
				"4": {
					"txt": "Most of the time",
				},
				"5": {
					"txt": "All of the time",
				},
			},
		},
		"k10_10": {
			"ord": "12",
			"txt": "During the last 30 days, about how often did you feel worthless?",
			"c": "a-5",
			"a": {
				"1": {
					"txt": "None of the time",
				},
				"2": {
					"txt": "A little of the time",
				},
				"3": {
					"txt": "Some of the time",
				},
				"4": {
					"txt": "Most of the time",
				},
				"5": {
					"txt": "All of the time",
				},
			},
		},
	},
};
