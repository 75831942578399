import { useEffect } from 'react';
import { useNotifications } from '../../notifications/hooks/useNotifications';
import { useUserData } from "xAppLib/Hooks"
import { useApp } from 'xAppLib/Hooks';

const UNSEEN_PATIENT_RESULTS = 'unseen_patient_results';
const UNSEEN_EXPRESS_RESULTS = 'unseen_express_results';

export const usePathologyResults = () => {
    const { setDynamicNotification, removeDynamicNotification } = useNotifications();
    const [value] = useUserData('unseen_results', 0);
    const [valueExpress] = useUserData('unseen_results_express', 0);
    const app = useApp();

    useEffect(() => {
        if (app?.settings?.icosm) return;

        if (!value) {
            removeDynamicNotification(UNSEEN_PATIENT_RESULTS);
        } else {
            setDynamicNotification(UNSEEN_PATIENT_RESULTS, {
                type: UNSEEN_PATIENT_RESULTS,
                title: `${value} patient referral${value > 1 ? 's' : ''} with unseen results`,
                icon: 'tint',
                link: '/results_followup_all',
                overrides: {
                    banner: true,
                    banner_variant: 'warning',
                },
            });
        }

        if (!valueExpress) {
            removeDynamicNotification(UNSEEN_EXPRESS_RESULTS);
        } else {
            setDynamicNotification(UNSEEN_EXPRESS_RESULTS, {
                type: UNSEEN_EXPRESS_RESULTS,
                title: `${valueExpress} express referral${valueExpress > 1 ? 's' : ''} with unseen results`,
                icon: 'tint',
                link: '/results_followup_all',
                overrides: {
                    banner: true,
                    banner_variant: 'warning',
                },
            });
        }
    }, [value, valueExpress, app?.settings?.icosm]);
}
