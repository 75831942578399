import React, { useState } from "react";
import { useDebounce } from "../hooks/useDebounce";

/**
 * A reusable Search component with a button to clear the search.
 *
 * @param {Object} props - Props for the Search component.
 * @param {string} props.placeholder - Placeholder text for the input field.
 * @param {function} props.onSearch - Callback when a search is triggered.
 * @param {function} props.onClear - Callback when the search is cleared.
 * @param {string} props.searchIcon - URL for the search icon.
 * @param {string} props.clearIcon - URL for the clear icon.
 * @param {string} props.className - Custom class for styling the component.
 * @param {string} props.variant - Determines the style variant ("default" or "secondary").
 */
export function Search({
    placeholder,
    onSearch,
    onClear,
    searchIcon,
    clearIcon,
    className,
    variant
}) {
    const [searchValue, setSearchValue] = useState("");
    const [isSearching, setIsSearching] = useState(false);

    const debouncedSearch = useDebounce((value) => {
        onSearch(value);
    }, 400);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value.trim()) {
            setIsSearching(true);
            debouncedSearch(value.trim());
        } else {
            debouncedSearch(value.trim());
            setIsSearching(false);
        }
    };

    const handleSearch = () => {
        if (searchValue.trim()) {
            setIsSearching(true);
            onSearch(searchValue.trim());
        }
    };

    const handleClear = () => {
        const clearedValue = searchValue;
        setSearchValue("");
        setIsSearching(false);
        onClear(clearedValue);
    };

    return (
        <div
            className={`flex relative items-center gap-2 rounded-full border border-is2-gray-100 py-1 bg-white ${variant === "secondary"
                ? "pr-2 pl-2"
                : "pr-1 pl-4"
                } ${className}`}
        >
            {variant === "default" && (
                <label htmlFor="search_input" className="text-gray-500">
                    <img
                        src={searchIcon}
                        alt="Search Icon"
                        className="h-6"
                        aria-hidden="true"
                    />
                </label>
            )}
            <input
                id="search_input"
                type="text"
                value={searchValue}
                onChange={handleInputChange}
                placeholder={placeholder}
                className={`flex-1 outline-none rounded-full border border-transparent text-base focus:ring-0 focus:ring-transparent focus:border-transparent ${isSearching ? "text-black" : "text-gray-700"
                    }`}
                aria-label="Search input"
            />

            {variant === "default" && (
                isSearching ? (
                    <button
                        onClick={handleClear}
                        aria-label="Clear search"
                        type="button"
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 pr-6 h-14"
                    >
                        <img
                            src={clearIcon}
                            alt="Clear Icon"
                            className="h-6"
                            aria-hidden="true"
                        />
                    </button>
                ) : (
                    <button
                        onClick={handleSearch}
                        aria-label="Search"
                        type="button"
                        className="absolute right-1 top-1/2 transform -translate-y-1/2 bg-blue-600 font-semibold text-white text-xs xs:text-sm sm:text-md px-3 xs:px-4 sm:px-6 h-[85%] rounded-full"
                    >
                        Search
                    </button>
                )
            )}

            {variant === "secondary" && (
                <button
                    onClick={handleSearch}
                    aria-label="Search"
                    type="button"
                    className="text-gray-500"
                >
                    <img
                        src={searchIcon}
                        alt="Search Icon"
                        className="h-6 pr-3"
                        aria-hidden="true"
                    />
                </button>
            )}
        </div>
    );
}

Search.defaultProps = {
    placeholder: "Search...",
    searchIcon: "https://storage.googleapis.com/instant-med-public/v2/search.svg",
    clearIcon: "https://storage.googleapis.com/instant-med-public/v2/closeIcon.svg",
    className: "",
    variant: "default",
};

export default Search;
