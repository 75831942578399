export const PARTN = {
	PA: 'Zz50m1I',
	DUKASA: 'iD_cmXA',
	BLS: 'szgbqzk',
	C2U: 'qrGpLwI',
	EVERMED: 'vkHODYE',
	ADORA: 'eAIt8fU',
	INST_PHARM_PARENT: 'S-7LgMU' // InstantScripts Pharmacy Parent Org
}

export const ONEPASS_DISCOUNT_CODE = 'op';

export const DOCID_NODR = '-NMXGoMnCzUVo-XIzLwy'

export const MAX_K10_SCORE = 29; // 30 or higher, and we cannot provide async services; a consult is required

export const SECOND = 1000;

export const JOIN_X_AUSTRALIANS_SPECIFIC = 2_530_326;

export const PRESCRIPTIONS_ROUTE = '/m/pharm/online/prescription'
export const GENERAL_CONSULTATION_ROUTE = '/m/pharm/DocCons/DocConsCov'