import React from 'react';

export default function CloseSVGIcon({ width = 46, height = 46, fill = "transparent" }) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26" fill="none">
            <rect width="26" height="26" rx="13" fill={fill} />
            <g clipPath="url(#clip0_9884_27111)">
                <path d="M6.5 19.2279L19.2279 6.49998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.5 6.5L19.2279 19.2279" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_9884_27111">
                    <rect width="15" height="15" fill="currentColor" transform="translate(5.5 5.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}
