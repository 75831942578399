import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from "react-helmet"
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import OIDC from '@awesome-cordova-library/oidc';

import { 
		Segment, 
		Form,
		Button,
		Container,
		Checkbox,
		Breadcrumb,
		BreadcrumbSection,
		BreadcrumbDivider
	} from 'semantic-ui-react'
import SIcon from '../../views/NUI/StreamlineIcons/SIcon';
import user_model from 'models/user_model'
import NotificationsRequest from 'xAppLib/UIelems/NotificationsRequest'
import ProfileSelect from 'xAppLib/Users/ProfileSelect'
import PaymentForm from '../UIelems/PaymentForm'
import Feedback from '../UIelems/Feedback'
import UserAvatar from './UserAvatar'
import AccessMyHR from 'views/myhr/AccessMyHR'
import { useUser } from 'xAppLib/Hooks'
import { useUserPrefs } from "xAppLib/Hooks";
import MFA from '../UIelems/MFA'
import OnePassLogo from '../../views/content/OnePassLogo';
import moment from 'moment';
import Refer from '../../views/pages/Refer';
import currency from 'currency.js';
import NurseProfileSection from "./NurseProfileSection";
import { Header } from '../../views/UIelems/v2/Header/Header';
import { capitalize } from "@/helpers/prettify";
import UserPic from './UserPic';
import { getUserDisplayName } from 'helpers/getUserDisplayName';

const SHOW_REFERRAL_CODE = app?.settings?.iscr
const DEBUG = false

function UserPreferences() {
	const [prefs, setPrefs] = useUserPrefs();

	const preferences = [
		user_model.check_access('GP') && (
			<Checkbox key="doc_cons_ppr" toggle checked={!!prefs.doc_cons_ppr} onChange={(e,{checked})=>{setPrefs('doc_cons_ppr',checked)}}
					  label='Send Paper Scripts in Consultation (off for eScripts, on for Paper)'
			/>
		),
		user_model.check_access('doc') && (
			<Checkbox key="instconst_wr_toast" toggle checked={!!prefs.instconst_wr_toast} onChange={(e,{checked})=>{setPrefs('instconst_wr_toast',checked)}}
					  label='Show Instant Consultation waiting room floating panel'
			/>
		),
	].filter(Boolean);

	if (preferences.length === 0) {
		return null;
	}

	const title = (() => {
		if (user_model.check_access('doc') || user_model.check_access('GP')) return 'Doctor Preferences';
		return 'User Preferences';
	})();

	return (
		<Segment className="flex flex-col">
			<h5>{title}</h5>

			{preferences}
		</Segment>
	)
}

const UserProfile = () => {
	const user = useUser()
	const [prefs,setPrefs] = useUserPrefs()
	user_model.limit_access('user');
	const is2 = app.settings.iscr2;
	const location = useLocation()
	const [view, setView] = useState();
	
	useEffect(() => {
		const loc = location?.hash.substring(1)
		setView(loc || is2 && "details")
	}, [location.hash]);

	DEBUG && console.log('user profile :: user - ', user, user.user_det?.photoURL, app.dvc);

	return <>
			{is2 && <Header sub_title={
							<Breadcrumb>
								<BreadcrumbSection><Link to='/'>Profile</Link></BreadcrumbSection>
								<BreadcrumbDivider icon='right chevron' />
								<BreadcrumbSection active><Link to='/profile#details'>Account</Link></BreadcrumbSection>
								{view === "family-add"&& <>
									<BreadcrumbDivider icon='right chevron' />
									<BreadcrumbSection active><Link to='/profile#family'>Family</Link></BreadcrumbSection>
								</>}
							</Breadcrumb>
						} title={view === "family" && "Family members" || view == "family-add" && "Add Family Member" || (view && view != "details" && capitalize(view)) || 'My profile:'}/>}
			
			<Container className={is2 && "!m-0"}>
				<Helmet>
					<title>Profile</title>
				</Helmet>

				{!is2 && <>
					<div style={{display:'flex'}}>
						<div>
							<UserAvatar size={50} />
						</div>
						<div style={{flex:1, marginLeft:'10px'}}>
							<h3 style={{marginBottom:0}}>{user.name} </h3>
							
							<p>{user.email}</p>
							
							{user.role != '' && user.role != 'Patient' &&
								<p style={{marginTop:0,marginBottom:0}}><strong>{user.role}</strong></p>
							}
							{app.user.org?.name && 
								<p style={{marginTop:0,marginBottom:0}}><strong>{app.user.org.name} ({app.user.org.address})</strong></p>
							}
						</div>
					</div>

					<footer className="my-5 flex gap-2">
						<Button basic content='Logout' onClick={ ()=>user_model.logout() }  />
						{user.usePassword && <Button basic as={Link} to={'/profile-password'} content='Change Password' />}
						<Button style={{marginLeft:'auto'}} basic content='Full Logout' onClick={ async ()=>user_model.full_logout() }  />
					</footer>
				</>}

				<Segment basic id='user_signed_area' size='big' style={{padding: "0rem"} } >
					<aside>

						{(!is2||["details", "family", "family-add"].includes(view)) && <>
						<Segment className={is2 && "!px-6 !py-9"}>
							{is2 ? <h2 className="mb-6">{view === "family" && "Family members:"|| view === "family-add" && "Patient details:" || "Your details:"} </h2> : <h5>My Profile</h5>}
							<Form size="large">
								{is2 && view === "details" && <>
									<div className="w-fit !border !border-white !border-2 !rounded-full flex items-center justify-center mb-6 lg:mb-3">
										<UserPic
											photoURL={user?.user_det?.photoURL}
											email={user?.user_det?.email}
											name={getUserDisplayName(user, { 
												format: 'full',
												defaultValue: 'User' 
											})}
											size={72}
											className="!w-[72px] !h-[72px] !rounded-full !bg-cover !bg-no-repeat"
										/>
									</div>
									<h5 className="font-medium !mt-3 !mb-6">{user.email}
										{user.role != '' && user.role != 'Patient' && <><br/>{user.role}</>}
										{app.user.org?.name && <><br/>{app.user.org.name} ({app.user.org.address})</>}
									</h5>
								</>}
								
								<ProfileSelect fields={is2 && user_model.PROF_FIELDS_IS2} editProfile={!is2||view === "details"} canDisplayProfile={!is2} canBrowse={!is2} showFamily={view === 'family'} showDetails={view === "details"} hideCondBtn={view === "details"} editNew={view ==="family-add"} />
								
							</Form>
						</Segment>
						{app.acl.is_nurse && <NurseProfileSection />}
						</>}
						{(!is2||view === "settings") && <AccessMyHR onUpdateAccess={(authorised)=>console.log({authorised})}/>}

						{(!is2||view === "details") && <UserPreferences />}

						{(!is2||view === "settings") && <MFA showFactors/>}
		
						{!app.settings.ileg && <>
							{!is2 && <span className="mt-6"></span>}
							{(!view || view === "settings") && <NotificationsRequest/>}
							{(!view || view === "payment") && <Segment className="!py-9 !px-6">
						
								{is2 ? <h2>Credit cards</h2> : <h5>My Payment Methods</h5>}

								<Form size='large' className='meds'>
									<PaymentForm
										mode="payment-settings"
										showSave={true}
										// onSave={_=>this.setState({refresh:new Date().getTime()})}
									/>
								</Form>
							</Segment>}

							{!!user.prof?.meta?.cred && <Segment>
								<h5>Credit</h5>	
								<p>You have {currency(user.prof?.meta?.cred).format()} credit available on your account.</p>
							</Segment>}

							
							{SHOW_REFERRAL_CODE && (!view || view === "settings") && <Refer page="profile"/> }
						
						</>}

							{(!is2||view === "settings") && (
							app?.user?.prof?.onepass ? (
								<Segment>
									<OnePassLogo height={42} style={{ margin: '-8px 0 0' }} />
									<div className="flex flex-wrap md:flex-nowrap items-center justify-between border border-is-gray-50 py-3 px-2 rounded-lg mt-4">
										<div className="flex items-center mb-4 md:mb-0 w-full md:w-fit"><SIcon name="check-circle-bold" color='green' size="s" bold /><p className="mb-0 text-sm md:p-0 ml-4">Your account has been linked</p></div>
										<div className="flex flex-wrap md:flex-nowrap items-center">
											<Button basic icon type='button' as={Link} to="/onepass">View <span className="hidden md:inline-block">member</span><span className="inline-block md:hidden">my</span> benefits</Button>
											<a href="https://my.onepass.com.au/account/subscription-management?utm_source=instantscripts&utm_medium=referral-web&utm_campaign=profile_submanagement" className="w-full md:w-fit mt-6 md:mt-0"><span className='flex items-center text-sm text-is-blue underline hover:no-underline md:ml-4 font-bold'>Manage membership <SIcon name="external" bold cls="h-[20px] w-[20px] order-2 md:order-none ml-1 md:ml-2"/></span></a>
										</div>
									</div>
								</Segment>
							) : app.settings.onepass_avail && <>
								<Segment>
								{is2 && <h2>OnePass</h2>}
								<h5 className='flex items-center'><span>Link your</span> <OnePassLogo height={42} style={{ margin: '-8px 8px 0' }} /> <span>Account</span></h5>
								<p>Link your OnePass membership to your InstantScripts account and you could enjoy 5% off eligible InstantScripts services and free standard delivery on prescription medication in Australia.<sup>*</sup></p>
                                <p className="mt-4" style={{fontSize: 12}}><sup>*</sup>Terms & conditions, exclusions and eligibility criteria apply. To view our full OnePass terms and conditions, <Link to={'/onepass'}>click here</Link></p>
								<Button onClick={async () => {
                                    try {
                                        if(app.settings.is_ionic){
// 											const settings = {
// 												authority: 'https://auth.sit.onepass.com.au/authorize',
// 												client_id: 'x0T2ai8t68jDJkjEsyXko0Uhg5Rh0qMi',
// 												redirect_uri: 'app.instantscripts.com.au/profile', // Based on the configured Hostname and Scheme
// 												response_type: 'code',
// 												scope: 'openid profile email',
// 												userStore: new WebStorageStateStore({ store: window.localStorage }),
// 											};
// 											const userManager = new UserManager(settings);
// 											userManager.signinRedirect();
// 											userManager.signinRedirectCallback()
//   .then(user => {
//     console.log('Authenticated:', user);
//     // Handle successful authentication
//   })
//   .catch(error => {
//     console.error('Authentication failed:', error);
//   });
											// Initiate authorization request
											OIDC.presentAuthorizationRequest({
												configuration: {
													authorizationEndpoint: 'https://auth.sit.onepass.com.au/authorize' //app.dev_env ? 'https://auth.sit.onepass.com.au' : 'https://auth.onepass.com.au',
												},
												scope: 'openid profile email',
												clientId: 'x0T2ai8t68jDJkjEsyXko0Uhg5Rh0qMi',
												redirectUrl: "app.instantscripts.com.au/profile", // see notes on redirectUrl below
												responseType: "code" // authorization code flow
											},
												function (resp) {
													console.log({resp});
													// Success
													// Use the following info to perform your token request:
													// resp.authorizationCode -- The authorization code
													// resp.request.codeVerifier -- The codeVerifier. Generated by the plugin (currently no option to pass in or disable PKCE). Needed as part of the token request.
													// resp.request.nonce -- The nonce. Generated by the plugin (currently no option to pass in or disable). Needed as part of ID token validation.
												},
												function (err) {
													// Error. Inspect err.oidcType to see what kind. See below for more on errors and error types.
													// Example of handling a specific error type:
													if (err.oidcType === cordova.plugins.oidc.basic.ErrorType.USER_CANCELLED) {
														// User cancelled the authorizationr request
													}
													console.log(err);
													// Can handle other error types as needed
												}
											);
										}
										else{
											const auth0 = new Auth0Client({
												domain: app.dev_env ? 'https://auth.sit.onepass.com.au' : 'https://auth.onepass.com.au',
												// domain: 'https://adv-sit.au.auth0.com',
												clientId: app.dev_env ? 'x0T2ai8t68jDJkjEsyXko0Uhg5Rh0qMi' : 'X4bjPjwtfqzY4TOLkJAHUl4tHK7bpHTS',
												authorizationParams: {
													redirect_uri: window.location.href
												}
											});
											await auth0.loginWithPopup({}, {timeoutInSeconds: 600});
											const { __raw } = await auth0.getIdTokenClaims();
											user_model.save_prof({ onepass: __raw, meta: { ...(app?.user?.prof?.meta || {}), op_src: 'iscr', op_tm: moment().format('X') } });
											app.trigger(app.events.SHOW_POPUP, {
												pt: "cx_txt", txt: '', pn: 'onepass-linked', noHeader: true, noCloseBtn: true, size: 'tiny'
											})
										}
                                    } catch (error) {
                                        console.error('Error retrieving ID token:', error);
                                        return null;
                                    }
                                }} size={'small'} content={'Agree and link account'} basic/>
                                <p className='mt-4' style={{textAlign: 'left', fontSize: 12}}>By activating OnePass you agree that InstantScripts and OnePass will confirm you have an account with the other to enable your accounts to be linked and you to receive your benefits and communications. Your personal information will be handled in accordance with the InstantScripts <Link onClick={() => app.trigger(app.events.SHOW_POPUP, { pt: "cx_txt", txt: 'Privacy', pn: 'privacy-policy' })}>Privacy Policy</Link>.</p>
								</Segment>
							</>)}

						{(view === "settings" && user.usePassword) && <Segment>
							<h2 className="mb-9">Password</h2>
							<Button basic as={Link} to={'/profile-password'} content='Update my password' />
						</Segment>}
					
						{(!is2||view === "settings") && <Segment>
        					{is2 ? <h2 className="mb-9">Account Closure</h2> : <h5>Account Closure</h5>}
							<>{prefs.account_del_req && <p>Your account closure request has been submitted on <strong>{prefs.account_del_req}</strong>.</p> 
								|| (is2 ? <p>Please note. Closing your account will also close all family member profiles linked to this account.</p> : 
								<p>If you would like to contact us to close your account, please complete the following form to place a request with our support team.</p>)}
							<Feedback trigger={<Button basic={is2} size={'small'} content={'Request Closure'} disabled={prefs.account_del_req && true || false} color='grey'/>} request='account_del_req' /></>
    					</Segment>}

					</aside>

				</Segment>
			</Container>
</>;
}
 
export default UserProfile;
